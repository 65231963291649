<header class="dashboard-header">

    <div class="dashboard-navbar" ng-app="myApp" ng-controller="myCtrl">
        <div class="custom-container">
            <nav class="navbar navbar-expand-md">
                <div *ngIf="currentUser" style="margin-right: 50px;">
                    <div *ngIf="(currentUser.role == '1')">
                        <a class="navbar-brand" href="/dashboard">
                            <img src="assets/img/VeriDocLogbook_Logo_RGB_H_Black.webp" alt="logo">
                        </a>
                    </div>
                    <div *ngIf="(currentUser.role == '2')">
                        <a class="navbar-brand" href="/master-admin-dashboard">
                            <img src="assets/img/VeriDocLogbook_Logo_RGB_H_Black.webp" alt="logo">
                        </a>
                    </div>

                </div>
                <div *ngIf="!currentUser" style="margin-right: 50px;">
                    <a class="navbar-brand" href="/master-admin-dashboard">
                        <img src="assets/img/VeriDocLogbook_Logo_RGB_H_Black.webp" alt="logo">
                    </a>
                </div>
                <button class="navbar-toggler-custom collapsed" type="button" data-toggle="collapse"
                    data-target="#navbarcustom" aria-controls="navbarsExample03" aria-expanded="false"
                    aria-label="Toggle navigation">
                </button>

                <div class="navbar-collapse collapse justify-content-start" id="navbarcustom">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <div *ngIf="currentUser">
                                <div *ngIf="(currentUser.role == '1')">

                                    <div *ngIf="(currentRoute == '/dashboard')">
                                        <a class="nav-link active" href='/dashboard' id="dashboardmenu">Dashboard</a>
                                    </div>
                                    <div *ngIf="(currentRoute != '/dashboard')">
                                        <a class="nav-link" href='/dashboard' id="dashboardmenu">Dashboard</a>
                                    </div>
                                </div>
                                <div *ngIf="(currentUser.role == '2')">
                                    <div *ngIf="(currentRoute == '/master-admin-dashboard')">
                                        <a class="nav-link active" href='/master-admin-dashboard'
                                            id="admindashboard">Dashboard</a>
                                    </div>
                                    <div *ngIf="(currentRoute != '/master-admin-dashboard')">
                                        <a class="nav-link" href='/master-admin-dashboard'
                                            id="admindashboard">Dashboard</a>
                                    </div>

                                </div>
                            </div>
                        </li>

                        <li class="nav-item">
                            <div *ngIf="currentUser">
                                <div *ngIf="(currentUser.role == '1')">
                                    <div *ngIf="(currentRoute != '/generateqr')">
                                        <a class="nav-link" href='/generateqr' id="generateqr"> Create New QR</a>
                                    </div>
                                    <div *ngIf="(currentRoute == '/generateqr')">
                                        <a class="nav-link active" href='/generateqr' id="generateqr"> Create New QR</a>
                                    </div>
                                </div>
                                <div *ngIf="(currentUser.role == '2')">
                                    <div *ngIf="(currentRoute != '/master-admin-registeredvisitors')">
                                        <a class="nav-link" href='/master-admin-registeredvisitors'
                                            id="visitor">Registered Visitors</a>
                                    </div>
                                    <div *ngIf="(currentRoute == '/master-admin-registeredvisitors')">
                                        <a class="nav-link active" href='/master-admin-registeredvisitors'
                                            id="visitor">Registered Visitors</a>
                                    </div>

                                </div>
                            </div>
                        </li>

                        <li class="nav-item mob-user">
                            <div *ngIf="currentRoute !='/myaccount'||'/master-admin-myaccount'">
                                <div *ngIf="(currentUser.role == '2')">
                                    <div *ngIf="(currentRoute != '/master-admin-myaccount')">
                                        <a class="nav-link" href="/master-admin-myaccount">Settings</a>
                                    </div>
                                    <div *ngIf="(currentRoute == '/master-admin-myaccount')">
                                        <a class="nav-link active" href="/master-admin-myaccount"
                                            id="myProfile">Settings</a>
                                    </div>
                                </div>
                                <div *ngIf="(currentUser.role == '1')">

                                    <div *ngIf="(currentRoute != '/myaccount')">
                                        <a class="nav-link" href="/myaccount">Settings</a>
                                    </div>
                                    <div *ngIf="(currentRoute == '/myaccount')">
                                        <a class="nav-link active" href="/myaccount" id="myProfile">Settings</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item mob-user">
                            <a class="nav-link" href="javascript:void(0);" (click)="logout()"
                                routerLinkActive="active">Logout</a>
                        </li>
                    </ul>
                </div>


                <div *ngIf="currentUser" class="justify-content-end custom-dropdown">
                    <div class="top-bar-right top-bar-right-account">
                        <div class="account-link">
                            <a class="user-dropdown" href="javascript:void(0)">{{ currentUser.data.full_name }}<i
                                    class="fa fa-chevron-down"></i>
                            </a>

                        </div>
                        <ul id="accountdropdown" class="account-dropdown">
                            <!-- <li><a href="javascript:void(0);">{{ currentUser.data.full_name }}</a></li> -->
                            <!-- <li class="drpdwn"><a href="/dashboard">Dashboard</a></li> -->
                            <li class="drpdwn">
                                <div *ngIf="currentUser">
                                    <div *ngIf="(currentUser.role == '1')">

                                        <div *ngIf="(currentRoute == '/dashboard')">
                                            <a href="/dashboard">Dashboard</a>
                                        </div>
                                        <div *ngIf="(currentRoute != '/dashboard')">
                                            <a href="/dashboard">Dashboard</a>
                                        </div>
                                    </div>
                                    <div *ngIf="(currentUser.role == '2')">
                                        <div *ngIf="(currentRoute == '/master-admin-dashboard')">

                                            <a href="/master-admin-dashboard">Dashboard</a>
                                        </div>
                                        <div *ngIf="(currentRoute != '/master-admin-dashboard')">

                                            <a href="/master-admin-dashboard">Dashboard</a>
                                        </div>

                                    </div>
                                </div>
                            </li>
                            <div *ngIf="currentRoute !='/myaccount'||'/master-admin-myaccount'">
                                <div *ngIf="(currentUser.role == '2')">
                                    <li class="drpdwn"><a href="/master-admin-myaccount">Settings</a></li>
                                </div>
                                <div *ngIf="(currentUser.role == '1')">
                                    <li class="drpdwn"><a href="/myaccount">Settings</a></li>
                                </div>
                            </div>

                            <!-- <div *ngIfElse="currentRoute ='/myaccount')">
                                <div>
                                    <li><a href="/">Dashboard</a></li>
                                </div>
                            </div> -->
                            <li class="drpdwn"><a href="javascript:void(0);" (click)="logout()"
                                    routerLinkActive="active">Logout</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>

</header>