import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inforpage',
  templateUrl: './inforpage.component.html',
  styleUrls: ['./inforpage.component.css']
})
export class InforpageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
