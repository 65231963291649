import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
export function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const passval: string = control.value;

    const validations = {
        charLength: passval.length > 7,
        hasUpperCase: /[A-Z]/.test(passval),
        hasLowerCase: /[a-z]/.test(passval),
        hasNumber: /[0-9]/.test(passval),
        hasSpecialChar: /^[a-zA-Z0-9- ]*$/.test(passval),
      };
  
      const errors: ValidationErrors = {};
  
      if (!validations.charLength) {
        errors.charLength = true;
      }
  
      if (!validations.hasUpperCase) {
        errors.hasUpperCase = true;
      }
  
      if (!validations.hasLowerCase) {
        errors.hasLowerCase = true;
      }
  
      if (!validations.hasNumber) {
        errors.hasNumber = true;
      }
  
      if (validations.hasSpecialChar) {
        errors.hasSpecialChar = true;
      }
  
      return Object.keys(errors).length ? errors : null;
  };
}
