import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../_services';
import { DynamicGrid } from '../_models';

import { User, Organisation, OrganisationUpdate } from '../_models';

@Injectable({ providedIn: 'root' })
export class UserService {

  dynamicArray: Array<DynamicGrid> = [];
  newDynamic: any = {};
  // billingApi = 'https://webjob.veridoclogbook.com/';
  // serverUrl = 'http://127.0.0.1:8000/';
  serverUrl = 'https://veridoclogbook-backend.azurewebsites.net/';

  constructor(private http: HttpClient, private authenticationService: AuthenticationService,) { }
  getdashboard() {
    const token = localStorage.getItem('token');
    // let headers = new HttpHeaders({
    //   'Authorization': `Token ${token}`
    // });
    return this.http.post<any>(this.serverUrl + "api/genrateqrlist/", {
      // headers: headers,
      responseType: 'json',
    });
  }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      console.log(errorMessage);
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      console.log(errorMessage);
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  createqr(qrname, data_view,redirecturl,venue_name,venue_address) {
    console.log("from api call");
    console.log(qrname)
    let body = new HttpParams();
    // console.log(qrdata.viewerlist);
    // var myObj = JSON.parse(qrdata.viewerlist);
    // console.log("after parsing")
    // console.log(myObj);

    // var myJSON = JSON.stringify(qrdata.viewerlist);
    // this.dynamicArray.push(qrdata.viewerlist);
    // myJSON = "["+myJSON+"]"
    // console.log(myJSON);
    body = body.set('qr_name', qrname);
    body = body.set('viewerlist', encodeURIComponent(data_view));
    if(redirecturl != "")
    {
      body = body.set('redirect_url', redirecturl);
    }
    if(venue_name != ""){
      body = body.set('venue_name', venue_name);
    }

    if(venue_address != ""){
      body = body.set('venue_address', venue_address);
    }

    console.log(body);
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(`${this.serverUrl}api/genrateqr/`, body);
  }
//for getting qr code details
  getqrdetails(uniqueid)
  {
    console.log(uniqueid)
    let body = new HttpParams();
    body = body.set('qr_guid', uniqueid);

    return this.http.post(`${this.serverUrl}api/getgenrateqrdetail/`, body);
  }
  updateqrcode(qrguid,qrname,data_view,redirecturl,venue_name,venue_address)
  {

    let body = new HttpParams();
    body = body.set('qr_name', qrname);
    body = body.set('qr_guid', qrguid);

    body = body.set('viewerlist', encodeURIComponent(data_view));
    if(redirecturl != "")
    {
      body = body.set('redirect_url', redirecturl);
    }

    if(venue_name != "")
    {
      body = body.set('venue_name', venue_name);
    }

    if(venue_address != "")
    {
      body = body.set('venue_address', venue_address);
    }


    console.log(body);
    return this.http.post(`${this.serverUrl}api/updategenrateqr/`, body);
  }
  createqr2(qrname, data_view, org_uniqueid,redirecturl,venue_name,venue_address) {
    let body = new HttpParams();
    body = body.set('qr_name', qrname);
    body = body.set('viewerlist', encodeURIComponent(data_view));
    body = body.set('org_uniqueid', org_uniqueid);
    if(redirecturl != "")
    {
      body = body.set('redirect_url', redirecturl);
    }

    if(venue_name != ""){
      body = body.set('venue_name', venue_name);
    }

    if(venue_address != ""){
      body = body.set('venue_address', venue_address);
    }

    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', localStorage.getItem('token'));
    return this.http.post(`${this.serverUrl}api/mastergenrateqr/`, body);
  }
  registerorg(org: Organisation) {
    // let token = "Token 55582a1cb5cc8eaeeb18c37718a91361d4bb6b4c";
    // localStorage.getItem('token');
    // let headers = new HttpHeaders({
    //   'Authorization': token
    // });
    // org.phone_number = org.country_code.concat(org.phone_number)
    console.log(org.full_name)
    return this.http.post<any>(`${this.serverUrl}api/organisation/`, org);
  }

  updateOrganisation(org: OrganisationUpdate, org_uniqueid: string) {
    // let token = "Token 55582a1cb5cc8eaeeb18c37718a91361d4bb6b4c";
    // localStorage.getItem('token');
    // let headers = new HttpHeaders({
    //   'Authorization': token
    // });
    // org.phone_number = org.country_code.concat(org.phone_number);
    console.log(org.network)
    org.org_uniqueid = org_uniqueid;
    return this.http.post<any>(`${this.serverUrl}api/updateorganisation/`, org);
  }

  getadmindashboard() {
    const token = localStorage.getItem('token');
    // let headers = new HttpHeaders({
    //   'Authorization': `Token ${token}`
    // });
    return this.http.post<any>(this.serverUrl + "api/organisationlist/", {
      // headers: headers,
      responseType: 'json'
    });
  }

  getvisitors() {
    const token = localStorage.getItem('token');
    // let headers = new HttpHeaders({
    //   'Authorization': `Token ${token}`
    // });
    return this.http.post<any>(this.serverUrl + "api/visitorlist/", {
      // headers: headers,
      responseType: 'json'
    });
  }
  getmasteradminaccount() {
    let email = this.authenticationService.currentUserValue.data.email;
    const token = localStorage.getItem('token');
    let body = new HttpParams();
    body = body.set('email', encodeURIComponent(email));
    return this.http.post<any>(this.serverUrl + "api/masteradminprofile/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  getadminaccount() {
    let email = this.authenticationService.currentUserValue.data.email;
    // const token = localStorage.getItem('token');
    let body = new HttpParams();
    body = body.set('email', encodeURIComponent(email));
    return this.http.post<any>(this.serverUrl + "api/adminprofile/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  getcurrentactiveplan() {
    // let email = this.authenticationService.currentUserValue.data.email;
    // // const token = localStorage.getItem('token');
    // let body = new HttpParams();
    // body = body.set('email', encodeURIComponent(email));
    return this.http.get<any>(this.serverUrl + "api/getactiveplan/");
  }
  getcurrentbillinghistory() {
    // let email = this.authenticationService.currentUserValue.data.email;
    // // const token = localStorage.getItem('token');
    // let body = new HttpParams();
    // body = body.set('email', encodeURIComponent(email));
    return this.http.get<any>(this.serverUrl + "api/getcurrentbillinghistory/");
  }
  updateCardDetails() {
    // let email = this.authenticationService.currentUserValue.data.email;
    // // const token = localStorage.getItem('token');
    // let body = new HttpParams();
    // body = body.set('email', encodeURIComponent(email));
    return this.http.get<any>(this.serverUrl + "api/updatecarddetails/");
  }
  cancelBilling(feedback: string) {

    let body = new HttpParams();
    body = body.set('feedback', feedback);
    return this.http.post<any>(this.serverUrl + "api/cancelplan/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  changeSubscription(planName: string, price: string, planTimespan:string) {

    let body = new HttpParams();
    if(planName != ""){
      body = body.set('planName', planName);
    }
    if(price != ""){
      body = body.set('price', price);
    }
    if(planTimespan != ""){
      body = body.set('planTimespan', planTimespan);
    }   
    return this.http.post<any>(this.serverUrl + "api/updateplan/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  getqrlistforadmin(org_uniqueid: string) {

    let body = new HttpParams();
    body = body.set('org_uniqueid', org_uniqueid);
    return this.http.post<any>(this.serverUrl + "api/orggenrateqrlist/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  getviewerlistforadmin(guid: string) {

    let body = new HttpParams();
    body = body.set('guid', guid);
    console.log(body)
    return this.http.post<any>(this.serverUrl + "api/getviewerslist/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }

  organisationstatus(uniqueid: string, check: any) {

    let body = new HttpParams();
    body = body.set('org_uniqueid', uniqueid);
    body = body.set('status', check);

    return this.http.post<any>(this.serverUrl + "api/organisationstatus/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }

  qrstatus(uniqueid: string, check: any) {

    let body = new HttpParams();
    body = body.set('guid', uniqueid);
    body = body.set('status', check);

    return this.http.post<any>(this.serverUrl + "api/genrateqrstatus/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  downapi(uniqueid: string) {
    let body = new HttpParams();
    body = body.set('guid', uniqueid);
    // body = body.set('status',check);
    return this.http.post<any>(this.serverUrl + "api/downloadqr/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }

  downloadPDFTemplate(guid: string): Observable<any> {
    // Assuming you want to use GET method for downloading
    const url = this.serverUrl + `api/download_pdf/${guid}/`;

    return this.http.get<any>(url, {
      responseType: 'arraybuffer' as 'json', // Set responseType to 'arraybuffer' for binary data
      observe: 'response' // To access full HTTP response
    });
  }

  getmasteradmineventhistory(ID: any) {
    let body = new HttpParams();
    body = body.set('phone_number', encodeURIComponent(ID));
    // body = body.set('status',check);

    return this.http.post<any>(this.serverUrl + "api/csv/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  searchEntries(datetime_check,qrname_check,uniqueid_check) {

    let body = new HttpParams();
    // body = body.set('email',encodeURIComponent(ID));
    body = body.set('qr_name', qrname_check);
    if(datetime_check!=""){
      body = body.set('date',datetime_check);
    }
    body = body.set('guid',uniqueid_check);


    return this.http.post<any>(this.serverUrl + "api/qrsearch/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }

  searchEntriesorg(searchBoxdate,searchBoxorganisationid,searchBoxorganisationame,searchBoxnetwork) {

    let body = new HttpParams();
    // body = body.set('email',encodeURIComponent(ID));
    body = body.set('org_name', searchBoxorganisationame);
    if(searchBoxdate!="")
    {
      body = body.set('date', searchBoxdate);

    }
    body = body.set('org_uniqueid', searchBoxorganisationid);
    body = body.set('network',searchBoxnetwork);
    return this.http.post<any>(this.serverUrl + "api/orgsearch/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  searchEntriesvisitor(searchBoxdate,searchBoxemail,searchBoxphoneno,searchBoxvisitorname,searchBoxhomeaddress) {

    let body = new HttpParams();
    // body = body.set('email',encodeURIComponent(ID));
    body = body.set('visitor_name', searchBoxvisitorname);
    body = body.set('email', encodeURIComponent(searchBoxemail));
    body = body.set('phone_number', encodeURIComponent(searchBoxphoneno));
    body = body.set('home_address', searchBoxhomeaddress);
    if(searchBoxdate!="")
    {
      body = body.set('date', searchBoxdate);

    }
    return this.http.post<any>(this.serverUrl + "api/visitorsearch/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }


  searchEntriesmasteradmin(searchBoxuid,searchBox2,searchBoxdate,searchBoxname) {

    let body = new HttpParams();
    // body = body.set('email',encodeURIComponent(ID));
    body = body.set('qr_name', searchBoxname);
    body = body.set('org_uniqueid', searchBox2);
    if(searchBoxdate!="")
    {
      body = body.set('date', searchBoxdate);

    }
    body = body.set('guid', searchBoxuid);
    return this.http.post<any>(this.serverUrl + "api/qrsearch/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }

  searchLoghistory(phno,searchBoxdate2,searchBoxvisitor,searchboxlocation) {
    console.log(phno,searchBoxdate2,searchBoxvisitor,searchboxlocation)
    let body = new HttpParams();
    // body = body.set('email',encodeURIComponent(ID));
    body = body.set('phone_number', encodeURIComponent(phno));
    if(searchBoxdate2!=""){
      body = body.set('date', searchBoxdate2);
    }
    if(searchBoxvisitor!=""){
      body = body.set('name', searchBoxvisitor);
    }
    if(searchboxlocation!=""){
      body = body.set('location', searchboxlocation);
    }

    // body = body.set('homeaddress', searchboxhomeaddress);
    return this.http.post<any>(this.serverUrl + "api/logsearch/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }


  addviewerlistforadmin(data_view, uniqueid) {
    let body = new HttpParams();
    body = body.set('guid', uniqueid);
    body = body.set('viewerlist', encodeURIComponent(data_view));

    return this.http.post<any>(this.serverUrl + "api/updateviewerslist/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }

  getorganisationdetails(uniqueid){
    let body = new HttpParams();
    body = body.set('org_uniqueid', uniqueid);
    return this.http.post<any>(this.serverUrl + "api/organisationdetails/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }
  checkVerifyOtp(guID) {
    let body = new HttpParams();
    body = body.set('guid', guID);
    return this.http.post<any>(this.serverUrl + "api/accessviewerlist/", body, {
      // headers: headers,
      responseType: 'json'
    });
  }


  //end
}
