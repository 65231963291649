import { Renderer2,Component, OnInit ,Inject} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
// import {MatRadioModule} from '@angular/material/radio';
import { DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';
import { AlertService, UserService, AuthenticationService } from '../_services';
import { Organisation } from '../_models';
import { ThrowStmt } from '@angular/compiler';


enum CheckBoxType { TESTNET, MAINNET, NONE };

@Component({
  selector: 'app-create-new-organisation',
  templateUrl: './create-new-organisation.component.html',
  styleUrls: ['./create-new-organisation.component.css']
})
export class CreateNewOrganisationComponent implements OnInit {
  
  isUpdateOrganization = false;
  organisation: any = {};
  selectedLevel;
  rmcolnetwork = false;
  selecttimezone: Array<Object> = [
    { id:0,name:"Select Timezone"},
    { id:1,name:"Africa/Abidjan"},
    { id:2,name:"Africa/Accra"},
    { id:3,name:"Africa/Addis_Ababa"},
    { id:4,name:"Africa/Algiers"},
    { id:5,name:"Africa/Asmara"},
    { id:6,name:"Africa/Asmera"},
    { id:7,name:"Africa/Bangui"},
    { id:8,name:"Africa/Banjul"},
    { id:9,name:"Africa/Bissau"},
    { id:10,name:"Africa/Blantyre"},
    { id:11,name:"Africa/Brazzaville"},
    { id:12,name:"Africa/Bujumbura"},
    { id:13,name:"Africa/Cairo"},
    { id:14,name:"Africa/Casablanca"},
    { id:15,name:"Africa/Ceuta"},
    { id:16,name:"Africa/Conakry"},
    { id:17,name:"Africa/Dakar"},
    { id:18,name:"Africa/Dar_es_Salaam"},
    { id:19,name:"Africa/Djibouti"},
    { id:20,name:"Africa/Douala"},
    { id:21,name:"Africa/El_Aaiun"},
    { id:22,name:"Africa/Freetown"},
    { id:23,name:"Africa/Gaborone"},
    { id:24,name:"Africa/Harare"},
    { id:25,name:"Africa/Johannesburg"},
    { id:26,name:"Africa/Juba"},
    { id:27,name:"Africa/Kampala"},
    { id:28,name:"Africa/Khartoum"},
    { id:29,name:"Africa/Kigali"},
    { id:30,name:"Africa/Kinshasa"},
    { id:31,name:"Africa/Lagos"},
    { id:32,name:"Africa/Libreville"},
    { id:33,name:"Africa/Lome"},
    { id:34,name:"Africa/Luanda"},
    { id:35,name:"Africa/Lubumbashi"},
    { id:36,name:"Africa/Lusaka"},
    { id:37,name:"Africa/Malabo"},
    { id:38,name:"Africa/Maputo"},
    { id:39,name:"Africa/Maseru"},
    { id:40,name:"Africa/Mbabane"},
    { id:41,name:"Africa/Mogadishu"},
    { id:42,name:"Africa/Monrovia"},
    { id:43,name:"Africa/Nairobi"},
    { id:44,name:"Africa/Ndjamena"},
    { id:45,name:"Africa/Niamey"},
    { id:46,name:"Africa/Nouakchott"},
    { id:47,name:"Africa/Ouagadougou"},
    { id:48,name:"Africa/Porto-Novo"},
    { id:49,name:"Africa/Sao_Tome"},
    { id:50,name:"Africa/Timbuktu"},
    { id:51,name:"Africa/Tripoli"},
    { id:52,name:"Africa/Tunis"},
    { id:53,name:"Africa/Windhoek"},
    { id:54,name:"America/Adak"},
    { id:55,name:"America/Anchorage"},
    { id:56,name:"America/Anguilla"},
    { id:57,name:"America/Antigua"},
    { id:58,name:"America/Araguaina"},
    { id:59,name:"America/Argentina/Buenos_Aires"},
    { id:60,name:"America/Argentina/Catamarca"},
    { id:61,name:"America/Argentina/ComodRivadavia"},
    { id:62,name:"America/Argentina/Cordoba"},
    { id:63,name:"America/Argentina/Jujuy"},
    { id:64,name:"America/Argentina/La_Rioja"},
    { id:65,name:"America/Argentina/Mendoza"},
    { id:66,name:"America/Argentina/Rio_Gallegos"},
    { id:67,name:"America/Argentina/Salta"},
    { id:68,name:"America/Argentina/San_Juan"},
    { id:69,name:"America/Argentina/San_Luis"},
    { id:70,name:"America/Argentina/Tucuman"},
    { id:71,name:"America/Argentina/Ushuaia"},
    { id:72,name:"America/Aruba"},
    { id:73,name:"America/Asuncion"},
    { id:74,name:"America/Atikokan"},
    { id:75,name:"America/Atka"},
    { id:76,name:"America/Bahia"},
    { id:77,name:"America/Bahia_Banderas"},
    { id:78,name:"America/Barbados"},
    { id:79,name:"America/Belem"},
    { id:80,name:"America/Belize"},
    { id:81,name:"America/Blanc-Sablon"},
    { id:82,name:"America/Boa_Vista"},
    { id:83,name:"America/Bogota"},
    { id:84,name:"America/Boise"},
    { id:85,name:"America/Buenos_Aires"},
    { id:86,name:"America/Cambridge_Bay"},
    { id:87,name:"America/Campo_Grande"},
    { id:88,name:"America/Cancun"},
    { id:89,name:"America/Caracas"},
    { id:90,name:"America/Catamarca"},
    { id:91,name:"America/Cayenne"},
    { id:92,name:"America/Cayman"},
    { id:93,name:"America/Chicago"},
    { id:94,name:"America/Chihuahua"},
    { id:95,name:"America/Coral_Harbour"},
    { id:96,name:"America/Cordoba"},
    { id:97,name:"America/Costa_Rica"},
    { id:98,name:"America/Creston"},
    { id:99,name:"America/Cuiaba"},
    { id:100,name:"America/Curacao"},
    { id:101,name:"America/Danmarkshavn"},
    { id:102,name:"America/Dawson"},
    { id:103,name:"America/Dawson_Creek"},
    { id:104,name:"America/Denver"},
    { id:105,name:"America/Detroit"},
    { id:106,name:"America/Dominica"},
    { id:107,name:"America/Edmonton"},
    { id:108,name:"America/Eirunepe"},
    { id:109,name:"America/El_Salvador"},
    { id:110,name:"America/Ensenada"},
    { id:111,name:"America/Fort_Nelson"},
    { id:112,name:"America/Fort_Wayne"},
    { id:113,name:"America/Fortaleza"},
    { id:114,name:"America/Glace_Bay"},
    { id:115,name:"America/Godthab"},
    { id:116,name:"America/Goose_Bay"},
    { id:117,name:"America/Grand_Turk"},
    { id:118,name:"America/Grenada"},
    { id:119,name:"America/Guadeloupe"},
    { id:120,name:"America/Guatemala"},
    { id:121,name:"America/Guayaquil"},
    { id:122,name:"America/Guyana"},
    { id:123,name:"America/Halifax"},
    { id:124,name:"America/Havana"},
    { id:125,name:"America/Hermosillo"},
    { id:126,name:"America/Indiana/Indianapolis"},
    { id:127,name:"America/Indiana/Knox"},
    { id:128,name:"America/Indiana/Marengo"},
    { id:129,name:"America/Indiana/Petersburg"},
    { id:130,name:"America/Indiana/Tell_City"},
    { id:131,name:"America/Indiana/Vevay"},
    { id:132,name:"America/Indiana/Vincennes"},
    { id:133,name:"America/Indiana/Winamac"},
    { id:134,name:"America/Indianapolis"},
    { id:135,name:"America/Inuvik"},
    { id:136,name:"America/Iqaluit"},
    { id:137,name:"America/Jamaica"},
    { id:138,name:"America/Jujuy"},
    { id:139,name:"America/Juneau"},
    { id:140,name:"America/Kentucky/Louisville"},
    { id:141,name:"America/Kentucky/Monticello"},
    { id:142,name:"America/Knox_IN"},
    { id:143,name:"America/Kralendijk"},
    { id:144,name:"America/La_Paz"},
    { id:145,name:"America/Lima"},
    { id:146,name:"America/Los_Angeles"},
    { id:147,name:"America/Louisville"},
    { id:148,name:"America/Lower_Princes"},
    { id:149,name:"America/Maceio"},
    { id:150,name:"America/Managua"},
    { id:151,name:"America/Manaus"},
    { id:152,name:"America/Marigot"},
    { id:153,name:"America/Martinique"},
    { id:154,name:"America/Matamoros"},
    { id:155,name:"America/Mazatlan"},
    { id:156,name:"America/Mendoza"},
    { id:157,name:"America/Menominee"},
    { id:158,name:"America/Merida"},
    { id:159,name:"America/Metlakatla"},
    { id:160,name:"America/Mexico_City"},
    { id:161,name:"America/Miquelon"},
    { id:162,name:"America/Moncton"},
    { id:163,name:"America/Monterrey"},
    { id:164,name:"America/Montevideo"},
    { id:165,name:"America/Montreal"},
    { id:166,name:"America/Montserrat"},
    { id:167,name:"America/Nassau"},
    { id:168,name:"America/New_York"},
    { id:169,name:"America/Nipigon"},
    { id:170,name:"America/Nome"},
    { id:171,name:"America/Noronha"},
    { id:172,name:"America/North_Dakota/Beulah"},
    { id:173,name:"America/North_Dakota/Center"},
    { id:174,name:"America/North_Dakota/New_Salem"},
    { id:175,name:"America/Ojinaga"},
    { id:176,name:"America/Panama"},
    { id:177,name:"America/Pangnirtung"},
    { id:178,name:"America/Paramaribo"},
    { id:179,name:"America/Phoenix"},
    { id:180,name:"America/Port-au-Prince"},
    { id:181,name:"America/Port_of_Spain"},
    { id:182,name:"America/Porto_Acre"},
    { id:183,name:"America/Porto_Velho"},
    { id:184,name:"America/Puerto_Rico"},
    { id:185,name:"America/Rainy_River"},
    { id:186,name:"America/Rankin_Inlet"},
    { id:187,name:"America/Recife"},
    { id:188,name:"America/Regina"},
    { id:189,name:"America/Resolute"},
    { id:190,name:"America/Rio_Branco"},
    { id:191,name:"America/Rosario"},
    { id:192,name:"America/Santa_Isabel"},
    { id:193,name:"America/Santarem"},
    { id:194,name:"America/Santiago"},
    { id:195,name:"America/Santo_Domingo"},
    { id:196,name:"America/Sao_Paulo"},
    { id:197,name:"America/Scoresbysund"},
    { id:198,name:"America/Shiprock"},
    { id:199,name:"America/Sitka"},
    { id:200,name:"America/St_Barthelemy"},
    { id:201,name:"America/St_Johns"},
    { id:202,name:"America/St_Kitts"},
    { id:203,name:"America/St_Lucia"},
    { id:204,name:"America/St_Thomas"},
    { id:205,name:"America/St_Vincent"},
    { id:206,name:"America/Swift_Current"},
    { id:207,name:"America/Tegucigalpa"},
    { id:208,name:"America/Thule"},
    { id:209,name:"America/Thunder_Bay"},
    { id:101,name:"America/Tijuana"},
    { id:101,name:"America/Toronto"},
    { id:101,name:"America/Tortola"},
    { id:101,name:"America/Vancouver"},
    { id:101,name:"America/Virgin"},
    { id:101,name:"America/Whitehorse"},
    { id:101,name:"America/Winnipeg"},
    { id:101,name:"America/Yakutat"},
    { id:101,name:"America/Yellowknife"},
    { id:101,name:"Antarctica/Casey"},
    { id:101,name:"Antarctica/Davis"},
    { id:101,name:"Antarctica/DumontDUrville"},
    { id:101,name:"Antarctica/Macquarie"},
    { id:101,name:"Antarctica/Mawson"},
    { id:101,name:"Antarctica/McMurdo"},
    { id:101,name:"Antarctica/Palmer"},
    { id:101,name:"Antarctica/Rothera"},
    { id:101,name:"Antarctica/South_Pole"},
    { id:101,name:"Antarctica/Syowa"},
    { id:101,name:"Antarctica/Troll"},
    { id:101,name:"Antarctica/Vostok"},
    { id:101,name:"Arctic/Longyearbyen"},
    { id:101,name:"Asia/Aden"},
    { id:101,name:"Asia/Almaty"},
    { id:101,name:"Asia/Amman"},
    { id:101,name:"Asia/Anadyr"},
    { id:101,name:"Asia/Aqtau"},
    { id:101,name:"Asia/Aqtobe"},
    { id:101,name:"Asia/Ashgabat"},
    { id:101,name:"Asia/Ashkhabad"},
    { id:101,name:"Asia/Baghdad"},
    { id:101,name:"Asia/Bahrain"},
    { id:101,name:"Asia/Baku"},
    { id:101,name:"Asia/Bangkok"},
    { id:101,name:"Asia/Barnaul"},
    { id:101,name:"Asia/Beirut"},
    { id:101,name:"Asia/Bishkek"},
    { id:101,name:"Asia/Brunei"},
    { id:101,name:"Asia/Calcutta"},
    { id:101,name:"Asia/Chita"},
    { id:101,name:"Asia/Choibalsan"},
    { id:101,name:"Asia/Chongqing"},
    { id:101,name:"Asia/Chungking"},
    { id:101,name:"Asia/Colombo"},
    { id:101,name:"Asia/Dacca"},
    { id:101,name:"Asia/Damascus"},
    { id:101,name:"Asia/Dhaka"},
    { id:101,name:"Asia/Dili"},
    { id:101,name:"Asia/Dubai"},
    { id:101,name:"Asia/Dushanbe"},
    { id:101,name:"Asia/Gaza"},
    { id:101,name:"Asia/Harbin"},
    { id:101,name:"Asia/Hebron"},
    { id:101,name:"Asia/Ho_Chi_Minh"},
    { id:101,name:"Asia/Hong_Kong"},
    { id:101,name:"Asia/Hovd"},
    { id:101,name:"Asia/Irkutsk"},
    { id:101,name:"Asia/Istanbul"},
    { id:101,name:"Asia/Jakarta"},
    { id:101,name:"Asia/Jayapura"},
    { id:101,name:"Asia/Jerusalem"},
    { id:101,name:"Asia/Kabul"},
    { id:101,name:"Asia/Kamchatka"},
    { id:101,name:"Asia/Karachi"},
    { id:101,name:"Asia/Kashgar"},
    { id:101,name:"Asia/Kathmandu"},
    { id:101,name:"Asia/Katmandu"},
    { id:101,name:"Asia/Khandyga"},
    { id:101,name:"Asia/Kolkata"},
    { id:101,name:"Asia/Krasnoyarsk"},
    { id:101,name:"Asia/Kuala_Lumpur"},
    { id:101,name:"Asia/Kuching"},
    { id:101,name:"Asia/Kuwait"},
    { id:101,name:"Asia/Macao"},
    { id:101,name:"Asia/Macau"},
    { id:101,name:"Asia/Magadan"},
    { id:101,name:"Asia/Makassar"},
    { id:101,name:"Asia/Manila"},
    { id:101,name:"Asia/Muscat"},
    { id:101,name:"Asia/Nicosia"},
    { id:101,name:"Asia/Novokuznetsk"},
    { id:101,name:"Asia/Novosibirsk"},
    { id:101,name:"Asia/Omsk"},
    { id:101,name:"Asia/Oral"},
    { id:101,name:"Asia/Phnom_Penh"},
    { id:101,name:"Asia/Pontianak"},
    { id:101,name:"Asia/Pyongyang"},
    { id:101,name:"Asia/Qatar"},
    { id:101,name:"Asia/Qyzylorda"},
    { id:101,name:"Asia/Rangoon"},
    { id:101,name:"Asia/Riyadh"},
    { id:101,name:"Asia/Saigon"},
    { id:101,name:"Asia/Sakhalin"},
    { id:101,name:"Asia/Samarkand"},
    { id:101,name:"Asia/Seoul"},
    { id:101,name:"Asia/Shanghai"},
    { id:101,name:"Asia/Singapore"},
    { id:101,name:"Asia/Srednekolymsk"},
    { id:101,name:"Asia/Taipei"},
    { id:101,name:"Asia/Tashkent"},
    { id:101,name:"Asia/Tbilisi"},
    { id:101,name:"Asia/Tehran"},
    { id:101,name:"Asia/Tel_Aviv"},
    { id:101,name:"Asia/Thimbu"},
    { id:101,name:"Asia/Thimphu"},
    { id:101,name:"Asia/Tokyo"},
    { id:101,name:"Asia/Tomsk"},
    { id:101,name:"Asia/Ujung_Pandang"},
    { id:101,name:"Asia/Ulaanbaatar"},
    { id:101,name:"Asia/Ulan_Bator"},
    { id:101,name:"Asia/Urumqi"},
    { id:101,name:"Asia/Ust-Nera"},
    { id:101,name:"Asia/Vientiane"},
    { id:101,name:"Asia/Vladivostok"},
    { id:101,name:"Asia/Yakutsk"},
    { id:101,name:"Asia/Yekaterinburg"},
    { id:101,name:"Asia/Yerevan"},
    { id:101,name:"Atlantic/Azores"},
    { id:101,name:"Atlantic/Bermuda"},
    { id:101,name:"Atlantic/Canary"},
    { id:101,name:"Atlantic/Cape_Verde"},
    { id:101,name:"Atlantic/Faeroe"},
    { id:101,name:"Atlantic/Faroe"},
    { id:101,name:"Atlantic/Jan_Mayen"},
    { id:101,name:"Atlantic/Madeira"},
    { id:101,name:"Atlantic/Reykjavik"},
    { id:101,name:"Atlantic/South_Georgia"},
    { id:101,name:"Atlantic/St_Helena"},
    { id:101,name:"Atlantic/Stanley"},
    { id:101,name:"Australia/ACT"},
    { id:101,name:"Australia/Adelaide"},
    { id:101,name:"Australia/Brisbane"},
    { id:101,name:"Australia/Broken_Hill"},
    { id:101,name:"Australia/Canberra"},
    { id:101,name:"Australia/Currie"},
    { id:101,name:"Australia/Darwin"},
    { id:101,name:"Australia/Eucla"},
    { id:101,name:"Australia/Hobart"},
    { id:101,name:"Australia/LHI"},
    { id:101,name:"Australia/Lindeman"},
    { id:101,name:"Australia/Lord_Howe"},
    { id:101,name:"Australia/Melbourne"},
    { id:101,name:"Australia/NSW"},
    { id:101,name:"Australia/North"},
    { id:101,name:"Australia/Perth"},
    { id:101,name:"Australia/Queensland"},
    { id:101,name:"Australia/South"},
    { id:101,name:"Australia/Sydney"},
    { id:101,name:"Australia/Tasmania"},
    { id:101,name:"Australia/Victoria"},
    { id:101,name:"Australia/West"},
    { id:101,name:"Australia/Yancowinna"},
    { id:101,name:"Brazil/Acre"},
    { id:101,name:"Brazil/DeNoronha"},
    { id:101,name:"Brazil/East"},
    { id:101,name:"Brazil/West"},
    { id:101,name:"CET"},
    { id:101,name:"CST6CDT"},
    { id:101,name:"Canada/Atlantic"},
    { id:101,name:"Canada/Central"},
    { id:101,name:"Canada/East-Saskatchewan"},
    { id:101,name:"Canada/Eastern"},
    { id:101,name:"Canada/Mountain"},
    { id:101,name:"Canada/Newfoundland"},
    { id:101,name:"Canada/Pacific"},
    { id:101,name:"Canada/Saskatchewan"},
    { id:101,name:"Canada/Yukon"},
    { id:101,name:"Chile/Continental"},
    { id:101,name:"Chile/EasterIsland"},
    { id:101,name:"Cuba"},
    { id:101,name:"EET"},
    { id:101,name:"EST"},
    { id:101,name:"EST5EDT"},
    { id:101,name:"Egypt"},
    { id:101,name:"Eire"},
    { id:101,name:"Etc/GMT"},
    { id:101,name:"Etc/GMT+0"},
    { id:101,name:"Etc/GMT+1"},
    { id:101,name:"Etc/GMT+10"},
    { id:101,name:"Etc/GMT+11"},
    { id:101,name:"Etc/GMT+12"},
    { id:101,name:"Etc/GMT+2"},
    { id:101,name:"Etc/GMT+3"},
    { id:101,name:"Etc/GMT+4"},
    { id:101,name:"Etc/GMT+5"},
    { id:101,name:"Etc/GMT+6"},
    { id:101,name:"Etc/GMT+7"},
    { id:101,name:"Etc/GMT+8"},
    { id:101,name:"Etc/GMT+9"},
    { id:101,name:"Etc/GMT-0"},
    { id:101,name:"Etc/GMT-1"},
    { id:101,name:"Etc/GMT-10"},
    { id:101,name:"Etc/GMT-11"},
    { id:101,name:"Etc/GMT-12"},
    { id:101,name:"Etc/GMT-13"},
    { id:101,name:"Etc/GMT-14"},
    { id:101,name:"Etc/GMT-2"},
    { id:101,name:"Etc/GMT-3"},
    { id:101,name:"Etc/GMT-4"},
    { id:101,name:"Etc/GMT-5"},
    { id:101,name:"Etc/GMT-6"},
    { id:101,name:"Etc/GMT-7"},
    { id:101,name:"Etc/GMT-8"},
    { id:101,name:"Etc/GMT-9"},
    { id:101,name:"Etc/GMT0"},
    { id:101,name:"Etc/Greenwich"},
    { id:101,name:"Etc/UCT"},
    { id:101,name:"Etc/UTC"},
    { id:101,name:"Etc/Universal"},
    { id:101,name:"Etc/Zulu"},
    { id:101,name:"Europe/Amsterdam"},
    { id:101,name:"Europe/Andorra"},
    { id:101,name:"Europe/Astrakhan"},
    { id:101,name:"Europe/Athens"},
    { id:101,name:"Europe/Belfast"},
    { id:101,name:"Europe/Belgrade"},
    { id:101,name:"Europe/Berlin"},
    { id:101,name:"Europe/Bratislava"},
    { id:101,name:"Europe/Brussels"},
    { id:101,name:"Europe/Bucharest"},
    { id:101,name:"Europe/Budapest"},
    { id:101,name:"Europe/Busingen"},
    { id:101,name:"Europe/Chisinau"},
    { id:101,name:"Europe/Copenhagen"},
    { id:101,name:"Europe/Dublin"},
    { id:101,name:"Europe/Gibraltar"},
    { id:101,name:"Europe/Guernsey"},
    { id:101,name:"Europe/Helsinki"},
    { id:101,name:"Europe/Isle_of_Man"},
    { id:101,name:"Europe/Istanbul"},
    { id:101,name:"Europe/Jersey"},
    { id:101,name:"Europe/Kaliningrad"},
    { id:101,name:"Europe/Kiev"},
    { id:101,name:"Europe/Kirov"},
    { id:101,name:"Europe/Lisbon"},
    { id:101,name:"Europe/Ljubljana"},
    { id:101,name:"Europe/London"},
    { id:101,name:"Europe/Luxembourg"},
    { id:101,name:"Europe/Madrid"},
    { id:101,name:"Europe/Malta"},
    { id:101,name:"Europe/Mariehamn"},
    { id:101,name:"Europe/Minsk"},
    { id:101,name:"Europe/Monaco"},
    { id:101,name:"Europe/Moscow"},
    { id:101,name:"Europe/Nicosia"},
    { id:101,name:"Europe/Oslo"},
    { id:101,name:"Europe/Paris"},
    { id:101,name:"Europe/Podgorica"},
    { id:101,name:"Europe/Prague"},
    { id:101,name:"Europe/Riga"},
    { id:101,name:"Europe/Rome"},
    { id:101,name:"Europe/Samara"},
    { id:101,name:"Europe/San_Marino"},
    { id:101,name:"Europe/Sarajevo"},
    { id:101,name:"Europe/Simferopol"},
    { id:101,name:"Europe/Skopje"},
    { id:101,name:"Europe/Sofia"},
    { id:101,name:"Europe/Stockholm"},
    { id:101,name:"Europe/Tallinn"},
    { id:101,name:"Europe/Tirane"},
    { id:101,name:"Europe/Tiraspol"},
    { id:101,name:"Europe/Ulyanovsk"},
    { id:101,name:"Europe/Uzhgorod"},
    { id:101,name:"Europe/Vaduz"},
    { id:101,name:"Europe/Vatican"},
    { id:101,name:"Europe/Vienna"},
    { id:101,name:"Europe/Vilnius"},
    { id:101,name:"Europe/Volgograd"},
    { id:101,name:"Europe/Warsaw"},
    { id:101,name:"Europe/Zagreb"},
    { id:101,name:"Europe/Zaporozhye"},
    { id:101,name:"Europe/Zurich"},
    { id:101,name:"GB"},
    { id:101,name:"GB-Eire"},
    { id:101,name:"GMT"},
    { id:101,name:"GMT+0"},
    { id:101,name:"GMT-0"},
    { id:101,name:"GMT0"},
    { id:101,name:"Greenwich"},
    { id:101,name:"HST"},
    { id:101,name:"Hongkong"},
    { id:101,name:"Iceland"},
    { id:101,name:"Indian/Antananarivo"},
    { id:101,name:"Indian/Chagos"},
    { id:101,name:"Indian/Christmas"},
    { id:101,name:"Indian/Cocos"},
    { id:101,name:"Indian/Comoro"},
    { id:101,name:"Indian/Kerguelen"},
    { id:101,name:"Indian/Mahe"},
    { id:101,name:"Indian/Maldives"},
    { id:101,name:"Indian/Mauritius"},
    { id:101,name:"Indian/Mayotte"},
    { id:101,name:"Indian/Reunion"},
    { id:101,name:"Iran"},
    { id:101,name:"Israel"},
    { id:101,name:"Jamaica"},
    { id:101,name:"Japan"},
    { id:101,name:"Kwajalein"},
    { id:101,name:"Libya"},
    { id:101,name:"MET"},
    { id:101,name:"MST"},
    { id:101,name:"MST7MDT"},
    { id:101,name:"Mexico/BajaNorte"},
    { id:101,name:"Mexico/BajaSur"},
    { id:101,name:"Mexico/General"},
    { id:101,name:"NZ"},
    { id:101,name:"NZ-CHAT"},
    { id:101,name:"Navajo"},
    { id:101,name:"PRC"},
    { id:101,name:"PST8PDT"},
    { id:101,name:"Pacific/Apia"},
    { id:101,name:"Pacific/Auckland"},
    { id:101,name:"Pacific/Bougainville"},
    { id:101,name:"Pacific/Chatham"},
    { id:101,name:"Pacific/Chuuk"},
    { id:101,name:"Pacific/Easter"},
    { id:101,name:"Pacific/Efate"},
    { id:101,name:"Pacific/Enderbury"},
    { id:101,name:"Pacific/Fakaofo"},
    { id:101,name:"Pacific/Fiji"},
    { id:101,name:"Pacific/Funafuti"},
    { id:101,name:"Pacific/Galapagos"},
    { id:101,name:"Pacific/Gambier"},
    { id:101,name:"Pacific/Guadalcanal"},
    { id:101,name:"Pacific/Guam"},
    { id:101,name:"Pacific/Honolulu"},
    { id:101,name:"Pacific/Johnston"},
    { id:101,name:"Pacific/Kiritimati"},
    { id:101,name:"Pacific/Kosrae"},
    { id:101,name:"Pacific/Kwajalein"},
    { id:101,name:"Pacific/Majuro"},
    { id:101,name:"Pacific/Marquesas"},
    { id:101,name:"Pacific/Midway"},
    { id:101,name:"Pacific/Nauru"},
    { id:101,name:"Pacific/Niue"},
    { id:101,name:"Pacific/Norfolk"},
    { id:101,name:"Pacific/Noumea"},
    { id:101,name:"Pacific/Pago_Pago"},
    { id:101,name:"Pacific/Palau"},
    { id:101,name:"Pacific/Pitcairn"},
    { id:101,name:"Pacific/Pohnpei"},
    { id:101,name:"Pacific/Ponape"},
    { id:101,name:"Pacific/Port_Moresby"},
    { id:101,name:"Pacific/Rarotonga"},
    { id:101,name:"Pacific/Saipan"},
    { id:101,name:"Pacific/Samoa"},
    { id:101,name:"Pacific/Tahiti"},
    { id:101,name:"Pacific/Tarawa"},
    { id:101,name:"Pacific/Tongatapu"},
    { id:101,name:"Pacific/Truk"},
    { id:101,name:"Pacific/Wake"},
    { id:101,name:"Pacific/Wallis"},
    { id:101,name:"Pacific/Yap"},
    { id:101,name:"Poland"},
    { id:101,name:"Portugal"},
    { id:101,name:"ROC"},
    { id:101,name:"ROK"},
    { id:101,name:"Singapore"},
    { id:101,name:"Turkey"},
    { id:101,name:"UCT"},
    { id:101,name:"US/Alaska"},
    { id:101,name:"US/Aleutian"},
    { id:101,name:"US/Arizona"},
    { id:101,name:"US/Central"},
    { id:101,name:"US/East-Indiana"},
    { id:101,name:"US/Eastern"},
    { id:101,name:"US/Hawaii"},
    { id:101,name:"US/Indiana-Starke"},
    { id:101,name:"US/Michigan"},
    { id:101,name:"US/Mountain"},
    { id:101,name:"US/Pacific"},
    { id:101,name:"US/Pacific-New"},
    { id:101,name:"US/Samoa"},
    { id:101,name:"UTC"},
    { id:101,name:"Universal"},
    { id:101,name:"W-SU"},
    { id:101,name:"WET"},
    { id:101,name:"Zulu"},

  ];
  selectitems: Array<Object> = [
    { id: 250, name: "Select country", value: "0" },
    { id: 0, name: "United States( +1 )", value: "+1" },
    { id: 1, name: "Afghanistan( +93 )", value: "+93" },
    { id: 2, name: "Albania( +355 )", value: "+355" },
    { id: 3, name: "Algeria( +213 )", value: "+213" },
    // {id: 4, name: "American Samoa( +1 )", value:"+1"},
    { id: 5, name: "Andorra( +376 )", value: "+376" },
    { id: 6, name: "Angola( +244 )", value: "+244" },
    { id: 7, name: "Anguilla( +1264 )", value: "+1264" },
    { id: 8, name: "Antarctica( +672 )", value: "+672" },
    { id: 9, name: "Antigua and Barbuda( +1268 )", value: "+1268" },
    { id: 10, name: "Argentina( +54 )", value: "+54" },
    { id: 11, name: "Armenia( +374 )", value: "+374" },
    { id: 12, name: "Aruba( +297 )", value: "+297" },
    { id: 13, name: "Australia( +61 )", value: "+61" },
    { id: 14, name: "Austria( +43 )", value: "+43" },
    { id: 15, name: "Azerbaijan( +994 )", value: "+994" },
    { id: 16, name: "Bahamas( +1242 )", value: "+1242" },
    { id: 17, name: "Bahrain( +973 )", value: "+973" },
    { id: 18, name: "Bangladesh( +880 )", value: "+880" },
    { id: 19, name: "Barbados( +1246 )", value: "+1246" },
    { id: 20, name: "Belarus( +375 )", value: "+375" },
    { id: 21, name: "Belgium( +32 )", value: "+32" },
    { id: 22, name: "Belize( +501 )", value: "+501" },
    { id: 23, name: "Benin( +229 )", value: "+229" },
    { id: 24, name: "Bermuda( +1441 )", value: "+1441" },
    { id: 25, name: "Bhutan( +975 )", value: "+975" },
    { id: 26, name: "Bolivia( +591 )", value: "+591" },
    { id: 27, name: "Bosnia and Herzegowina( +387 )", value: "+387" },
    { id: 28, name: "Botswana( +267 )", value: "+267" },
    { id: 29, name: "Bouvet Island( +47 )", value: "+47" },
    { id: 30, name: "Brazil( +55 )", value: "+55" },
    { id: 31, name: "British Indian Ocean Territory( +246 )", value: "+246" },
    { id: 32, name: "Brunei Darussalam( +673 )", value: "+673" },
    { id: 33, name: "Bulgaria( +359 )", value: "+359" },
    { id: 34, name: "Burkina Faso( +226 )", value: "+226" },
    { id: 35, name: "Burundi( +257 )", value: "+257" },
    { id: 36, name: "Cambodia( +855 )", value: "+855" },
    { id: 37, name: "Cameroon( +237 )", value: "+237" },
    // {id: 38, name: "Canada( +1 )", value:"+1"},
    { id: 39, name: "Cape Verde( +238 )", value: "+238" },
    { id: 40, name: "Cayman Islands( + 345 )", value: "+345" },
    { id: 41, name: "Central African Republic( +236 )", value: "+236" },
    { id: 42, name: "Chad( +235 )", value: "+235" },
    { id: 43, name: "Chile( +56 )", value: "+56" },
    { id: 44, name: "China( +86 )", value: "+86" },
    // { id: 45, name: "Christmas Island( +61 )", value: "+61" },
    // { id: 46, name: "Cocos (Keeling) Islands( +61 )", value: "+61" },
    { id: 47, name: "Colombia( +57 )", value: "+57" },
    { id: 48, name: "Comoros( +269 )", value: "+269" },
    { id: 49, name: "Congo( +242 )", value: "+242" },
    { id: 50, name: "Congo (Democratic Republic of the)( +243 )", value: "+243" },
    { id: 51, name: "Cook Islands( +682 )", value: "+682" },
    { id: 52, name: "Costa Rica( +506 )", value: "+506" },
    { id: 53, name: "Cote D'Ivoire( +225 )", value: "+225" },
    { id: 54, name: "Croatia( +385 )", value: "+385" },
    { id: 55, name: "Cuba( +53 )", value: "+53" },
    { id: 56, name: "Cyprus( +357 )", value: "+357" },
    { id: 57, name: "Czech Republic( +420 )", value: "+420" },
    { id: 58, name: "Denmark( +45 )", value: "+45" },
    { id: 59, name: "Djibouti( +253 )", value: "+253" },
    { id: 60, name: "Dominica( +1767 )", value: "+1767" },
    { id: 61, name: "Dominican Republic( +1849 )", value: "+1849" },
    { id: 62, name: "East Timor( +670 )", value: "+670" },
    { id: 63, name: "Ecuador( +593 )", value: "+593" },
    { id: 64, name: "Egypt( +20 )", value: "+20" },
    { id: 65, name: "El Salvador( +503 )", value: "+503" },
    { id: 66, name: "Equatorial Guinea( +240 )", value: "+240" },
    { id: 67, name: "Eritrea( +291 )", value: "+291" },
    { id: 68, name: "Estonia( +372 )", value: "+372" },
    { id: 69, name: "Ethiopia( +251 )", value: "+251" },
    { id: 70, name: "Falkland Islands (Malvinas)( +500 )", value: "+500" },
    { id: 71, name: "Faroe Islands( +298 )", value: "+298" },
    { id: 72, name: "Fiji( +679 )", value: "+679" },
    { id: 73, name: "Finland( +358 )", value: "+358" },
    { id: 74, name: "France( +33 )", value: "+33" },
    { id: 75, name: "French Guiana( +594 )", value: "+594" },
    { id: 76, name: "French Polynesia( +689 )", value: "+689" },
    { id: 77, name: "French Southern Territories(  )", value: "" },
    { id: 78, name: "Gabon( +241 )", value: "+241" },
    { id: 79, name: "Gambia( +220 )", value: "+220" },
    { id: 80, name: "Georgia( +995 )", value: "+995" },
    { id: 81, name: "Germany( +49 )", value: "+49" },
    { id: 82, name: "Ghana( +233 )", value: "+233" },
    { id: 83, name: "Gibraltar( +350 )", value: "+350" },
    { id: 84, name: "Greece( +30 )", value: "+30" },
    { id: 85, name: "Greenland( +299 )", value: "+299" },
    { id: 86, name: "Grenada( +1473 )", value: "+1473" },
    { id: 87, name: "Guadeloupe( +590 )", value: "+590" },
    { id: 88, name: "Guam( +1671 )", value: "+1671" },
    { id: 89, name: "Guatemala( +502 )", value: "+502" },
    { id: 90, name: "Guinea( +224 )", value: "+224" },
    { id: 91, name: "Guinea-bissau( +245 )", value: "+245" },
    { id: 92, name: "Guyana( +595 )", value: "+595" },
    { id: 93, name: "Haiti( +509 )", value: "+509" },
    { id: 94, name: "Heard and Mc Donald Islands(  )", value: "+" },
    { id: 95, name: "Honduras( +504 )", value: "+504" },
    { id: 96, name: "Hong Kong( +852 )", value: "+852" },
    { id: 97, name: "Hungary( +36 )", value: "+36" },
    { id: 98, name: "Iceland( +354 )", value: "+354" },
    { id: 99, name: "India( +91 )", value: "+91" },
    { id: 100, name: "Indonesia( +62 )", value: "+62" },
    { id: 101, name: "Iran (Islamic Republic of)( +98 )", value: "+98" },
    { id: 102, name: "Iraq( +964 )", value: "+964" },
    { id: 103, name: "Ireland( +353 )", value: "+353" },
    { id: 104, name: "Israel( +972 )", value: "+972" },
    { id: 105, name: "Italy( +39 )", value: "+39" },
    { id: 106, name: "Jamaica( +1876 )", value: "+1876" },
    { id: 107, name: "Japan( +81 )", value: "+81" },
    { id: 108, name: "Jordan( +962 )", value: "+962" },
    { id: 109, name: "Kazakhstan( +77 )", value: "+77" },
    { id: 110, name: "Kenya( +254 )", value: "+254" },
    { id: 111, name: "Kiribati( +686 )", value: "+686" },
    { id: 112, name: "Korea( +82 )", value: "+82" },
    { id: 113, name: "Korea, Democratic People's Republic of( +850 )", value: "+850" },
    { id: 114, name: "Kuwait( +965 )", value: "+965" },
    { id: 115, name: "Kyrgyzstan( +996 )", value: "+996" },
    { id: 116, name: "Lao People's Democratic Republic( +850 )", value: "+850" },
    { id: 117, name: "Latvia( +371 )", value: "+371" },
    { id: 118, name: "Lebanon( +961 )", value: "+961" },
    { id: 119, name: "Lesotho( +266 )", value: "+266" },
    { id: 120, name: "Liberia( +231 )", value: "+231" },
    { id: 121, name: "Libyan Arab Jamahiriya( +218 )", value: "+218" },
    { id: 122, name: "Liechtenstein( +423 )", value: "+423" },
    { id: 123, name: "Lithuania( +370 )", value: "+370" },
    { id: 124, name: "Luxembourg( +352 )", value: "+352" },
    { id: 125, name: "Macau( +853 )", value: "+853" },
    { id: 126, name: "Macedonia( +389 )", value: "+389" },
    { id: 127, name: "Madagascar( +261 )", value: "+261" },
    { id: 128, name: "Malawi( +265 )", value: "+265" },
    { id: 129, name: "Malaysia( +60 )", value: "+60" },
    { id: 130, name: "Maldives( +960 )", value: "+960" },
    { id: 131, name: "Mali( +223 )", value: "+223" },
    { id: 132, name: "Malta( +356 )", value: "+356" },
    { id: 133, name: "Marshall Islands( +692 )", value: "+692" },
    { id: 134, name: "Martinique( +596 )", value: "+596" },
    { id: 135, name: "Mauritania( +222 )", value: "+222" },
    { id: 136, name: "Mauritius( +230 )", value: "+230" },
    { id: 137, name: "Mayotte( +262 )", value: "+262" },
    { id: 138, name: "Mexico( +52 )", value: "+52" },
    { id: 139, name: "Micronesia( +691 )", value: "+691" },
    { id: 140, name: "Moldova( +373 )", value: "+373" },
    { id: 141, name: "Monaco( +377 )", value: "+377" },
    { id: 142, name: "Mongolia( +976 )", value: "+976" },
    { id: 143, name: "Montenegro( +382 )", value: "+382" },
    { id: 144, name: "Montserrat( +1664 )", value: "+1664" },
    { id: 145, name: "Mauritania( +222 )", value: "+222" },
    { id: 146, name: "Morocco( +212 )", value: "+212" },
    { id: 147, name: "Mozambique( +258 )", value: "+258" },
    { id: 148, name: "Myanmar( +95 )", value: "+95" },
    { id: 149, name: "Namibia( +264 )", value: "+264" },
    { id: 150, name: "Nauru( +674 )", value: "+674" },
    { id: 151, name: "Nepal( +977 )", value: "+977" },
    { id: 152, name: "Netherlands( +31 )", value: "+31" },
    { id: 153, name: "Netherlands Antilles( +599 )", value: "+599" },
    { id: 154, name: "New Caledonia( +687 )", value: "+687" },
    { id: 155, name: "New Zealand( +64 )", value: "+64" },
    { id: 156, name: "Nicaragua( +505 )", value: "+505" },
    { id: 157, name: "Niger( +227 )", value: "+227" },
    { id: 158, name: "Nigeria( +234 )", value: "+234" },
    { id: 159, name: "Niue( +683 )", value: "+683" },
    { id: 160, name: "Norfolk Island( +672 )", value: "+672" },
    { id: 161, name: "Northern Mariana Islands( +1670 )", value: "+1670" },
    { id: 162, name: "Norway( +47 )", value: "+47" },
    { id: 163, name: "Oman( +968 )", value: "+968" },
    { id: 164, name: "Pakistan( +92 )", value: "+92" },
    { id: 165, name: "Palau( +680 )", value: "+680" },
    { id: 166, name: "Palestine( +970 )", value: "+970" },
    { id: 167, name: "Panama( +507 )", value: "+507" },
    { id: 168, name: "Papua New Guinea( +675 )", value: "+675" },
    { id: 169, name: "Paraguay( +595 )", value: "+595" },
    { id: 170, name: "Norfolk Island( +672 )", value: "+672" },
    { id: 171, name: "Peru( +51 )", value: "+51" },
    { id: 172, name: "Philippines( +63 )", value: "+63" },
    { id: 173, name: "Pitcairn( +872 )", value: "+872" },
    { id: 174, name: "Poland( +48 )", value: "+48" },
    { id: 175, name: "Portugal( +351 )", value: "+351" },
    { id: 176, name: "Puerto Rico( +1939 )", value: "+1939" },
    { id: 177, name: "Qatar( +974 )", value: "+974" },
    { id: 178, name: "Reunion( +262 )", value: "+262" },
    { id: 179, name: "Romania( +40 )", value: "+40" },
    { id: 180, name: "Russian Federation( +7 )", value: "+7" },
    { id: 181, name: "Rwanda( +250 )", value: "+250" },
    { id: 182, name: "Saint Kitts and Nevis( +1869 )", value: "+1869" },
    { id: 183, name: "Saint Lucia( +1758 )", value: "+1758" },
    { id: 184, name: "Saint Vincent and the Grenadines( +1784 )", value: "+1784" },
    { id: 185, name: "San Marino( +378 )", value: "+378" },
    { id: 186, name: "Sao Tome and Principe( +239 )", value: "+1939" },
    { id: 187, name: "Saudi Arabia( +966 )", value: "+966" },
    { id: 188, name: "Senegal( +221 )", value: "+221" },
    { id: 189, name: "Serbia( +381 )", value: "+381" },
    { id: 190, name: "Seychelles( +248 )", value: "+248" },
    { id: 191, name: "Sierra Leone( +232 )", value: "+232" },
    { id: 192, name: "Singapore( +65 )", value: "+65" },
    { id: 193, name: "Slovakia (Slovak Republic)( +421 )", value: "+421" },
    { id: 194, name: "Slovenia( +386 )", value: "+386" },
    { id: 195, name: "Solomon Islands( +677 )", value: "+677" },
    { id: 196, name: "Somalia( +252 )", value: "+252" },
    { id: 197, name: "South Africa( +27 )", value: "+27" },
    { id: 198, name: "South Georgia & South Sandwich Islands( +500 )", value: "+500" },
    { id: 199, name: "South Sudan( +211 )", value: "+211" },
    { id: 200, name: "Spain( +34 )", value: "+34" },
    { id: 201, name: "Sri Lanka( +94 )", value: "+94" },
    { id: 202, name: "St. Helena( +290 )", value: "+290" },
    { id: 203, name: "St. Pierre and Miquelon( +508 )", value: "+508" },
    { id: 204, name: "Sudan( +249 )", value: "+249" },
    { id: 205, name: "Suriname( +597 )", value: "+597" },
    { id: 206, name: "Svalbard and Jan Mayen Islands( +47 )", value: "+47" },
    { id: 207, name: "Swaziland( +268 )", value: "+268" },
    { id: 208, name: "Sweden( +46 )", value: "+46" },
    { id: 209, name: "Switzerland( +41 )", value: "+41" },
    { id: 210, name: "Syrian Arab Republic( +963 )", value: "+963" },
    { id: 211, name: "Taiwan( +886 )", value: "+886" },
    { id: 212, name: "Tajikistan( +992 )", value: "+992" },
    { id: 213, name: "Tanzania( +255 )", value: "+255" },
    { id: 214, name: "Thailand( +66 )", value: "+66" },
    { id: 215, name: "Togo( +228 )", value: "+228" },
    { id: 216, name: "Tokelau( +690 )", value: "+690" },
    { id: 217, name: "Tonga( +676 )", value: "+676" },
    { id: 218, name: "Trinidad and Tobago( +1868 )", value: "+1868" },
    { id: 219, name: "Tunisia( +216 )", value: "+216" },
    { id: 220, name: "Turkey( +90 )", value: "+90" },
    { id: 221, name: "Turkmenistan( +993 )", value: "+993" },
    { id: 222, name: "Turks and Caicos Islands( +1649 )", value: "+1649" },
    { id: 223, name: "Tuvalu( +688 )", value: "+688" },
    { id: 224, name: "Uganda( +256 )", value: "+256" },
    { id: 225, name: "Ukraine( +380 )", value: "+380" },
    { id: 226, name: "United Arab Emirates( +971 )", value: "+971" },
    { id: 227, name: "United Kingdom( +44 )", value: "+44" },
    // {id: 228, name: "United States minor outlying islands( +1 )", value:"+1"},
    { id: 229, name: "Uruguay( +598 )", value: "+598" },
    { id: 230, name: "Uzbekistan( +998 )", value: "+998" },
    { id: 231, name: "Vanuatu( +678 )", value: "+678" },
    { id: 232, name: "Vatican City State (Holy See)( +84 )", value: "+84" },
    { id: 233, name: "Venezuela( +58 )", value: "+58" },
    { id: 234, name: "Viet Nam( +7 )", value: "+7" },
    // {id: 235, name: "Virgin Islands (British)( +1 )", value:"+1"},
    // {id: 236, name: "Virgin Islands (U.S.)( +1 )", value:"+1"},
    { id: 237, name: "Wallis and Futuna Islands( +681 )", value: "+681" },
    { id: 238, name: "Western Sahara( +212 )", value: "+212" },
    { id: 239, name: "Yemen( +967 )", value: "+967" },
    { id: 240, name: "Zambia( +260 )", value: "+260" },
    { id: 241, name: "Zimbabwe( +263 )", value: "+263" },


  ];
  selectedCode:string;
  selectedzone:string;
  // selectedCode:{ id: 13, name: "Australia( +61 )", value: "+61" };
  registerForm: FormGroup;
  // updateForm: FormGroup;

  loading = false;
  submitted = false;
  showSuccessMessage = false;
  showcode2 = false;
  showcode = false;
  showcode3 = false;
  showcode4 = false;
  showcode5 = false;
  showcode6 = false;
  showcode7 = false;
  showcode8 = false;
  showcode13 = false;
  showcode14 = false;
  ntcode = false;
  nt = false;
  isDisabled = false;
  check_box_type = CheckBoxType;
  currentlyChecked: CheckBoxType;
  isBillingSame: Boolean;


  selectCheckBox(targetType: CheckBoxType) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    if (this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.TESTNET;
      return;
    }

    // if (targetType == 1) {
    //   let check = "1";
    //   this.registerForm.get('network').setValue(check)
    // } else {
    //   let check = "0";
    //   this.registerForm.get('network').setValue(check)
    // }

    this.currentlyChecked = targetType;
  }
  selectBilling(targetType: boolean) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    
    this.isBillingSame = targetType;
    console.log(this.isBillingSame);
  }
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document 
  ) { }
  selected() {
    console.log(this.selectedLevel);
  }
  selected2() {
    console.log(this.selectedzone);
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  public noWhitespacePassValidator(control: FormGroup) {
    const isSpace = (control.value || '').match(/\s/g);
    return isSpace ? {'whitespace': true} : null;
  }

  public removeSpaces(control: FormGroup) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      control.setValue([]);
    }
    return null;
  }
  

  ngOnInit() {
    // ${this.currentlyChecked}
    const data: string = this.route.snapshot.queryParamMap.get('data');
    // this.currentlyChecked = CheckBoxType.TESTNET;
  
    this.registerForm = this.formBuilder.group({
      org_name: ['', [Validators.required,Validators.maxLength(100),this.noWhitespaceValidator]],
      email: ['', [Validators.required, Validators.email]],
      country_code: ['', Validators.required],
      phone_number: ['', [Validators.required,Validators.pattern('^\\d*$'), Validators.minLength(6),Validators.maxLength(15)]],
      full_name: ['',[ Validators.required,Validators.maxLength(100),this.noWhitespaceValidator]],
      org_address: ['', [Validators.required,Validators.maxLength(500),this.noWhitespaceValidator]],
      password: ['', [Validators.required, Validators.minLength(6), this.noWhitespacePassValidator]],
      subscription_counter: ['', [Validators.required, Validators.pattern('^\\d*$'),Validators.maxLength(7),this.noWhitespaceValidator]],
      network: ['',Validators.required],
      isBillingSame :[Boolean],
      org_timezone:['', Validators.required],
      billing_company: [''],
      billing_address: [''],
      billing_city: [''],
      billing_state: [''],
      billing_country: [''],
      billing_zip: [''],
      
    },
    );


    // this.updateForm = this.formBuilder.group({
    //   org_name: ['', Validators.required],
    //   email: ['', Validators.required],
    //   country_code: ['', Validators.required],
    //   phone_number: ['', Validators.required],
    //   full_name: ['', Validators.required],
    //   org_address: ['', Validators.required],
    //   subscription_counter: ['', Validators.required],
    //   network: ['', Validators.required]
    // });

    //   if (data == null) {
    //     this.isUpdateOrganization = false;
    //   } else {
    //     this.organisation = JSON.parse(data);
    //     console.log("organisation:" + JSON.stringify(this.organisation));
    //     this.isUpdateOrganization = true;

    //     this.f1.org_name.setValue(this.organisation.org_name);
    //     this.f1.email.setValue(this.organisation.email);
    //     this.f1.phone_number.setValue(this.organisation.phone_number);
    //     this.f1.subscription_counter.setValue(this.organisation.subscription_counter);
    //     this.f1.full_name.setValue(this.organisation.full_name);
    //     this.f1.org_address.setValue(this.organisation.org_address);
    //     for(let i in this.selectitems){
    //       // console.log(this.selectitems[i].toString);
    //       if(this.selectitems[i]['value'] == this.organisation.country_code){
    //         this.selectedLevel = this.selectitems[i];
    //         console.log(this.selectitems[i])
    //       }

    //     }
    //     if (this.organisation.network == 1) {
    //       this.currentlyChecked = CheckBoxType.MAINNET;
    //       this.selectCheckBox(CheckBoxType.MAINNET);
    //       // let check = "1";
    //       // this.f1.network.setValue(check)
    //     } else {
    //       this.currentlyChecked = CheckBoxType.TESTNET;
    //       this.selectCheckBox(CheckBoxType.TESTNET);
    //       // let check = "0";
    //       // this.f1.network.setValue(check)
    //     }
    //   }
  }
  get f() { return this.registerForm.controls; }
  // get f1() { return this.updateForm.controls; }

  onSubmit() {
    
    
    this.submitted = true;
    this.isDisabled = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.isDisabled = false;
      return;
    }
  
    this.loading = true;
    console.log(this.f.country_code['value'])
    for (let i in this.selectitems) {
      if (this.selectitems[i]['value'] == this.selectedCode) {
        this.selectedLevel = this.selectitems[i];
        this.f.country_code.setValue(this.selectitems[i]['value'])
        this.selectedCode = this.selectitems[i]['value'];
        console.log("here")
      }

    }
    console.log(this.f.org_timezone.value)
    let timezonedatahere = this.f.org_timezone.value;
    let passtime = timezonedatahere.nameValue
    this.registerForm.value['org_timezone'] = `${passtime}`;
    console.log(passtime)
    // if(!this.selectedzone)
    // {
    //   this.f.org_timezone.setValue("Australia/Brisbane")
    //   console.log(this.f.org_timezone)
    // }else{
    //   for (let i in this.selecttimezone) {
    //     if (this.selecttimezone[i]['name'] == this.selectedzone) {
    //       // this.selectedLevel = this.selectitems[i];
    //       this.f.org_timezone.setValue(this.selecttimezone[i]['name'])
    //       // this.selectedCode = this.selectitems[i]['value'];
    //       // console.log("here")
    //     }
  
    //   }
    // }



    this.registerForm.value['network'] = `${this.currentlyChecked}`;
    console.log(this.registerForm.value['org_timezone']);

    let org_name = this.registerForm.get("org_name").value;
    if(org_name){
      this.registerForm.value['org_name'] = org_name.trim();
    }else{
      this.registerForm.value['org_name'] = "";
    }

    let full_name = this.registerForm.get("full_name").value;
    if(full_name){
      this.registerForm.value['full_name'] = full_name.trim();
    }else{
      this.registerForm.value['full_name'] = "";
    }

    let email = this.registerForm.get("email").value;
    if(email){
      this.registerForm.value['email'] = email.trim();
    }else{
      this.registerForm.value['email'] = "";
    }

    let org_address = this.registerForm.get("org_address").value;
    if(org_address){
      this.registerForm.value['org_address'] = org_address.trim();
    }else{
      this.registerForm.value['org_address'] = "";
    }
    let isBillingSame = this.registerForm.get("isBillingSame").value;
    if(isBillingSame == "true"){
      this.registerForm.value['isBillingSame'] = true;
    
    }else{
      this.registerForm.value['isBillingSame'] = false;
    }

    let billing_company = this.registerForm.get("billing_company").value;
    if(billing_company){
      this.registerForm.value['billing_company'] = billing_company.trim();
    }else{
      this.registerForm.value['billing_company'] = "";
    }
    let billing_address = this.registerForm.get("billing_address").value;
    if(billing_address){
      this.registerForm.value['billing_address'] = billing_address.trim();
    }else{
      this.registerForm.value['billing_address'] = "";
    }
    let billing_city = this.registerForm.get("billing_city").value;
    if(billing_city){
      this.registerForm.value['billing_city'] = billing_city.trim();
    }else{
      this.registerForm.value['billing_city'] = "";
    }
    let billing_state = this.registerForm.get("billing_state").value;
    if(billing_state){
      this.registerForm.value['billing_state'] = billing_state.trim();
    }else{
      this.registerForm.value['billing_state'] = "";
    }

    let billing_country = this.registerForm.get("billing_country").value;
    if(billing_country){
      this.registerForm.value['billing_country'] = billing_country.trim();
    }else{
      this.registerForm.value['billing_country'] = "";
    }

    let billing_zip = this.registerForm.get("billing_zip").value;
    if(billing_zip){
      this.registerForm.value['billing_zip'] = billing_zip.trim();
    }else{
      this.registerForm.value['billing_zip'] = "";
    }

    console.log(this.registerForm.value);
    this.userService.registerorg(this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data['returncode'] == '1' || data['returncode'] == 1) {
            this.showSuccessMessage = true;
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'Organisation created successfully.',
              timer: 1000, // Show for 2 seconds and automatically close
              showConfirmButton: false,
            }).then(() => {
              this.router.navigate(['/master-admin-dashboard']);
            });
            
            
          } else if (data['returncode'] == '2' || data['returncode'] == 2) {

            this.showSuccessMessage = false;
            this.showcode2 = true;
            this.showcode3 = false;
            this.showcode4 = false;
            this.showcode5 = false;
            this.showcode6 = false;
            this.showcode7 = false;
            this.showcode8 = false;
            this.showcode13 = false;
            this.showcode14 = false;
            this.isDisabled = false;

          } else if (data['returncode'] == '3' || data['returncode'] == 3) {
            this.showSuccessMessage = false;
            this.showcode2 = false;
            this.showcode3 = true;
            this.showcode4 = false;
            this.showcode5 = false;
            this.showcode6 = false;
            this.showcode7 = false;
            this.showcode8 = false;
            this.showcode13 = false;
            this.showcode14 = false;
            this.isDisabled = false;

          } else if (data['returncode'] == '5' || data['returncode'] == 5) {
            this.showSuccessMessage = false;
            this.showcode2 = false;
            this.showcode3 = false;
            this.showcode4 = false;
            this.showcode5 = true;
            this.showcode6 = false;
            this.showcode7 = false;
            this.showcode8 = false;
            this.showcode13 = false;
            this.showcode14 = false;
            this.isDisabled = false;

          }
          else if (data['returncode'] == '6' || data['returncode'] == 6) {
            this.showSuccessMessage = false;
            this.showcode2 = false;
            this.showcode3 = false;
            this.showcode4 = false;
            this.showcode5 = false;
            this.showcode6 = true;
            this.showcode7 = false;
            this.showcode8 = false;
            this.showcode13 = false;
            this.showcode14 = false;
            this.isDisabled = false;
            // this.reloadform()

          }
          else if (data['returncode'] == '7' || data['returncode'] == 7) {
            this.showSuccessMessage = false;
            this.showcode2 = false;
            this.showcode3 = false;
            this.showcode4 = false;
            this.showcode5 = false;
            this.showcode6 = false;
            this.showcode7 = true;
            this.showcode8 = false;
            this.showcode13 = false;
            this.showcode14 = false;
            this.isDisabled = false;
            // this.reloadform()
            

          } else if (data['returncode'] == '8' || data['returncode'] == 8) {
            this.showSuccessMessage = false;
            this.showcode2 = false;
            this.showcode3 = false;
            this.showcode4 = false;
            this.showcode5 = false;
            this.showcode6 = false;
            this.showcode8 = true;
            this.showcode7 = false;
            this.showcode13 = false;
            this.showcode14 = false;
            this.isDisabled = false;

          } else if (data['returncode'] == '13' || data['returncode'] == 13) {
            this.showSuccessMessage = false;
            this.showcode2 = false;
            this.showcode3 = false;
            this.showcode4 = false;
            this.showcode5 = false;
            this.showcode6 = false;
            this.showcode13 = true;
            this.showcode7 = false;
            this.showcode8 = false;
            this.showcode14 = false;
            this.isDisabled = false;

          } else if (data['returncode'] == '14' || data['returncode'] == 14) {
            this.showSuccessMessage = false;
            this.showcode2 = false;
            this.showcode3 = false;
            this.showcode4 = false;
            this.showcode5 = false;
            this.showcode6 = false;
            this.showcode14 = true;
            this.showcode7 = false;
            this.showcode8 = false;
            this.showcode13 = false;
            this.isDisabled = false;
          }
          else {
            this.showcode = true;
            this.isDisabled = false;
          }
        },
        error => {
          this.alertService.error(error);
          this.loading = false;
          this.isDisabled = false;
        });
    // }
  }

  private reloadform()
  {
    console.log(this.registerForm.value)
    this.f.org_name.setValue(this.registerForm.value['org_name'])
    this.f.full_name.setValue(this.registerForm.value['full_name'])
    this.f.email.setValue(this.registerForm.value['email'])
    this.f.phone_number.setValue(this.registerForm.value['phone_number'])
    this.f.subscription_counter.setValue(this.registerForm.value['subscription_counter'])
    this.f.org_address.setValue(this.registerForm.value['org_address'])
    
    // this.f.network.setValue(this.currentlyChecked);
    // console.log()
    

    let netval =this.registerForm.value['network'] 
    console.log(netval)
    this.rmcolnetwork = true;
    // this.f.network.reset()
    if (netval == 0 || netval == '0') {
      // this.f.network.reset()
      this.currentlyChecked = CheckBoxType.TESTNET;
      console.log(this.currentlyChecked)
      console.log("testnet")
      let script = this._renderer2.createElement('script');
      script.type = "text/javascript";
      script.id = "firstscr"
      script.src = "assets/js/search.js";
      this._renderer2.appendChild(this._document.body, script);
      // return;
    }else{
      // this.f.network.reset()
      this.currentlyChecked = CheckBoxType.MAINNET;
      console.log(this.currentlyChecked)
      console.log("mainnet")
      let script2 = this._renderer2.createElement('script');
      script2.type = "text/javascript";
      script2.id = "firstscr2"
      script2.src = "assets/js/search2.js";
      this._renderer2.appendChild(this._document.body, script2);

    }  
    //   // this.f.network.setValue(this.currentlyChecked = CheckBoxType.TESTNET)
    // } else if(this.editregisterForm.value['network'] == 1 || this.editregisterForm.value['network'] == '1'){
    //   this.currentlyChecked = CheckBoxType.MAINNET;
    //   // this.f.network.setValue(this.currentlyChecked = CheckBoxType.MAINNET)
    //   // this.currentlyChecked = CheckBoxType.MAINNET
    //   // this.f.network.setValue('')
    // }
    // this.editregisterForm.value['network'] = `${this.currentlyChecked}`;
    // this.currentlyChecked = 
    // this.f.network.setValue(`${this.currentlyChecked}`)
    // console.log(this.data.country_code)
    for (let i in this.selectitems) {
      // console.log(this.selectitems[i].toString);
      if (this.selectitems[i]['value'] == this.registerForm.value['country_code'] || this.selectitems[i]['name'] == this.registerForm.value['country_code']) {
        this.selectedLevel = this.selectitems[i];
        console.log(this.selectitems[i])
      }

    }
    
  }
  // onUpdate() {
  //   this.submitted = true;

  //   // stop here if form is invalid
  //   if (this.updateForm.invalid) {
  //     return;
  //   }

  //   if (this.currentlyChecked == 1) {
  //     let check = "1";
  //     this.updateForm.get('network').setValue(check)
  //   } else {
  //     let check = "0";
  //     this.updateForm.get('network').setValue(check)
  //   }
  //   console.log(this.updateForm.value);

  //   if (this.isUpdateOrganization) {
  //     this.loading = true;
  //     this.userService.updateOrganisation(this.updateForm.value, this.organisation.org_uniqueid)
  //       .pipe(first())
  //       .subscribe(
  //         data => {
  //           console.log(data);
  //           if (data['returncode'] == '1' || data['returncode'] == 1) {
  //             this.showSuccessMessage = true;
  //             this.router.navigate(['/master-admin-dashboard']);
  //           } else if (data['returncode'] == '2' || data['returncode'] == 2) {
  //             this.showcode2 = true;
  //           } else if (data['returncode'] == '3' || data['returncode'] == 3) {
  //             this.showcode3 = true;
  //           } else if (data['returncode'] == '5' || data['returncode'] == 5) {
  //             this.showcode5 = true;
  //           } else if (data['returncode'] == '6' || data['returncode'] == 6) {
  //             console.log(this.showcode6);
  //             this.showcode6 = true;
  //             console.log(this.showcode6);
  //           } else if (data['returncode'] == '7' || data['returncode'] == 7) {
  //             this.showcode7 = true;
  //           } else if (data['returncode'] == '8' || data['returncode'] == 8) {
  //             this.showcode8 = true;
  //           } else if (data['returncode'] == '13' || data['returncode'] == 13) {
  //             this.showcode13 = true;
  //           } else {
  //             this.showcode = true;
  //           }
  //         },
  //         error => {
  //           this.alertService.error(error);
  //           this.loading = false;
  //         });
  //   }
  // }
}
