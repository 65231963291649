<div class="main-wrapper">
  <div class="wrapper-fix-footer">
    <header class="homepage-header">
        <div class="custom-container">
            <nav class="navbar navbar-expand-lg">
                <a class="navbar-brand" href="/">
                    <img src="assets/img/vdg-logbook-logo-login.png" alt="logo">
                </a>
                <button class="navbar-toggler-custom collapsed" type="button" data-toggle="collapse" data-target="#navbarhome" aria-controls="navbarsExample03" aria-expanded="false" aria-label="Toggle navigation">
                </button>
          
                <div class="navbar-collapse collapse justify-content-end" id="navbarhome">
                  <ul class="navbar-nav">
                    <li class="nav-item">
                      <a class="nav-link active" href="#logbook-features-benefits">Features & Benefits</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#vdg-logbook-business">VeriDoc Logbook</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#how-does-it-work">How Does It Work?</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#logbook-faq">FAQs</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" href="#contact-us">Contact Us</a>
                      </li>
                      <li class="nav-item nav-login-item">
                        <a class="nav-link" href="/">
                            <img class="login-icon" src="assets/img/home-login -icon.png" alt="login icon">
                            <img class="login-active-icon" src="assets/img/home-green-icon.png" alt="login icon hover">
                            <span class="nav-login-mb-btn">Login</span>
                        </a>
                      </li>
                  </ul>
                </div>
              </nav>
        </div>
    </header>
    <div class="homepage-body">
      <!-- <div class="finish-blockchain-img">
        <img src="assets/img/FinishedBlockchainSolution.png" alt="img">
      </div> -->
      <div class="homepage-banner-section">
          <div class="container">
            <div class="row homepage-center-map">
              <div class="col-lg-6">
                <div class="homebanner-left-div">
                    <h1>
                      scan & trace with <span>veridoc logbook</span>
                    </h1>
                    <p>
                      VeriDoc Logbook combines the revolutionary secure technologies of blockchain and QR codes with the ubiquitous ease of using your mobile phone to create a practical, stress-free electronic blockchain logbook.
                    </p>
                    <a href="#contact-us" class="gradiant-btn">
                      Contact Us
                    </a>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="homebanner-right-div">
                  <img src="assets/img/vdglogbook-app-banner.png" alt="Veridoc Logbook app">
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="vdg-log-features-benefits" id="logbook-features-benefits">
        <div class="container">
            <div class="product-line-green-title">
                <h2>
                    FEATURES & BENEFITS OF <br />
                    ELECTRONIC LOGBOOK SOLUTIONS
                </h2>
            </div>
            <div class="product-key-features-div">
                <div class="product-key-features-box">
                    <img src="assets/img/vdg-logbook-mobile-phone.png" alt="VDGLogbook Mobile Phone" />
                    <h3>
                        All That’s Needed Is<br />
                        A Mobile Phone
                    </h3>
                    <p>
                        Using VeriDoc Logbook, you can easily
                        create an extensive record of the
                        times, locations, and attendance that
                        have been logged. Simply by getting
                        people entering to scan the QR code
                        with their phone.
                    </p>
    
                </div>
                <div class="product-key-features-box">
    
                    <img src="assets/img/reliability.png" alt="VDGLogbook Blockchain Security" />
    
    
                    <h3>Blockchain Security</h3>
                    <p>
                        Each block on a blockchain is
                        connected to the previous block,
                        making it impossible to change
                        without being detected. This means
                        that information entered into a
                        blockchain is safe, reliable, and
                        accurate.
                    </p>
    
                </div>
                <div class="product-key-features-box">
    
                    <img src="assets/img/data-tracking-managing.png" alt="VDGLogbook Important Data Tracked and Managed" />
    
                    <h3>
                        All Important Data<br />
                        Tracked and Managed
                    </h3>
                    <p>
                        VeriDoc Logbook verifies information
                        creates reports and can be
                        customised to your requirements. It
                        can be implemented using your
                        existing processes and databases.
                    </p>
    
                </div>
                <div class="product-key-features-box">
    
                    <img src="assets/img/detailed-reports-instantly.png" alt="VDGLogbook Reports Instantly" />
    
    
                    <h3>Reports Instantly</h3>
                    <p>
                        Instant, precise information is simple
                        and easily customisable for all users.
                        Your detailed reports, the way that you
                        want them.
                    </p>
    
                </div>
                <div class="product-key-features-box">
    
                    <img src="assets/img/industry.png" alt="VDGLogbook Widespread Universal Industry Use" />
    
    
                    <h3>
                        Widespread<br />
                        Universal Industry Use
                    </h3>
                    <p>
                        VeriDoc Logbooks encompass and
                        reinforce entire industries with
                        increases in security, transparency,
                        and accountability. Multiple sectors
                        are already using blockchain as their
                        backbone.
                    </p>
    
                </div>
                <div class="product-key-features-box">
    
                    <img src="assets/img/custom-style.png" alt="VDGLogbook Custom Style with User Flexibility" />
    
    
                    <h3>
                        Simple, Custom Style<br />
                        with User Flexibility
                    </h3>
                    <p>
                        VeriDoc Logbook’s QR code solutions
                        are integrated with pre-existing
                        software and provide genuine quality
                        reporting. Set up and customised to
                        your satisfaction, the software is
                        flexible with how you conduct your
                        business.
                    </p>
    
                </div>
            </div>
        </div>
    </div>
    <div class="vdg-logbook-in-business" id="vdg-logbook-business">
      <div class="container">
          <div class="product-line-green-title">
              <h2><span>VERIDOC LOGBOOK</span> IN YOUR BUSINESS</h2>
          </div>
          <div class="vdg-logbook-business-inner-div">
              <div class="vdg-logbook-business-box">
                  <img src="assets/img/employees.png" alt="employees icon" />
                  <span>Employees</span>
                  <ul>
                      <li>
                          An employee’s mobile phone
                          easily collects QR code data by
                          simply using the scanner/camera.
                      </li>
                      <li>
                          At all times, logged data is
                          presented and easily visible.
                      </li>
                      <li>
                          Logged information on our
                          blockchain logbooks will always
                          be available and stored.
                      </li>
                  </ul>
              </div>
              <div class="vdg-logbook-business-box">
                  <img src="assets/img/supervisors.png" alt="supervisors icon" />
                  <span>Supervisors</span>
                  <ul>
                      <li>
                          All new information can be clearly
                          seen by supervisors and will show
                          them a complete and detailed
                          overview of any and all information
                          collected.
                      </li>
                      <li>
                          Blockchain security verifies all
                          information and the supervisors
                          work is electronically signed off.
                      </li>
                      <li>
                          Live data is reviewed and can be
                          seen at all times.
                      </li>
                  </ul>
              </div>
              <div class="vdg-logbook-business-box">
                  <img src="assets/img/management-staff.png" alt="management staff icon" />
                  <span>Management Staff</span>
                  <ul>
                      <li>
                          All levels of productivity are
                          reported on - whether it is the
                          amount performed or the quality
                          of performance.
                      </li>
                      <li>
                          All logged information can be
                          viewed immediately.
                      </li>
                      <li>
                          Blockchain provides an easy to
                          use electronic logbook tool with
                          absolute trust and transparency.
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div>
  <div class="vdg-logbook-flow-tab-section" id="how-does-it-work">
    <div class="container">
        <div class="product-line-green-title">
            <h2>
                HOW DOES IT WORK?
            </h2>
        </div>
        <!-- <ul class="nav nav-tabs">
            <li class="active"><a data-toggle="tab" href="#logbook-userapp">User App </a></li>
            <li><a data-toggle="tab" href="#logbook-admin-access">Admin Access</a></li>
        </ul> -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#logbook-userapp" role="tab" aria-selected="true">User App</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#logbook-admin-access" role="tab" aria-selected="false">Admin Access</a>
          </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="logbook-userapp" role="tabpanel">
          <div class="logbook-user-app-div">
            <div class="logbook-item-li log-p-after-line user-app-order-1" data-label="01">
                <h4>Create New Account</h4>
                <p>
                    Anyone can create a new account
                    quickly and easily with the Logbook
                    App for your device.
                </p>
                <img src="assets/img/01_App_Create-New-Account.gif" alt="Logbook Create New Account screen" />
                <img class="log-step-1" src="assets/img/log-step-1-line.png" alt="step-line" />
            </div>
            <div class="logbook-item-li user-app-order-3" data-label="03">
                <h4>Logbook Scan</h4>
                <p>
                    Just point your device at the QR code
                    and scan with the Logbook App.
                </p>
                <img src="assets/img/03_App_Logbook-Scan.gif" alt="Logbook Export Scan History" />
                <img class="log-step-1" src="assets/img/log-step-1-line.png" alt="step-line" />
            </div>
            <div class="logbook-item-li logbook-mb-0 user-app-order-5" data-label="05">
                <h4>Export Scan History</h4>
                <p>
                    You can export your scan history into
                    CSV format and download it
                    whenever you wish.

                </p>
                <img src="assets/img/05_App_Export-Scan-History.gif" alt="Logbook Create New Account screen" />

            </div>
            <div class="logbook-item-li user-app-order-2" data-label="02">
                <h4>
                    Create Additional Profiles
                </h4>
                <p>
                    Create profiles for families, groups
                    and children with access controls.

                </p>
                <img src="assets/img/02_App_Create-Additional-Profiles.gif" alt="Logbook Create Additional Profiles screen" />
                <img class="log-step-2" src="assets/img/log-step-2-line.png" alt="step-line" />
            </div>
            <div class="logbook-item-li last-log-margin user-app-order-4" data-label="04">
                <h4>View Scan History</h4>
                <p>
                    A full history of your logbook scans
                    that can be accessed, viewed from
                    your device.
                </p>
                <img src="assets/img/04_App_View-Scan-History.gif" alt="Logbook View Scan History screen" />
                <img class="log-step-2" src="assets/img/log-step-2-line.png" alt="step-line" />
            </div>
        </div>
        </div>
        <div class="tab-pane fade" id="logbook-admin-access" role="tabpanel">
          <div class="logbook-admin-access-div">
            <div class="logbook-admin-access-li">
                <div class="logbook-admin-access-content" data-label="01">
                    <h4>Admin Login</h4>
                    <p>
                        Owners and Admins can log in from
                        anywhere in the world using the
                        VeriDoc Logbook sign in page.
                    </p>
                </div>
                <div class="logbook-admin-access-img">
                    <img src="assets/img/01_Web_Admin-Login.gif" alt="VDGLogbook Admin Login" />
                </div>
            </div>
            <div class="logbook-admin-access-li">
                <div class="logbook-admin-access-img">
                    <img src="assets/img/02_Web_Create-New-QR-Code-Download-QR-Code.gif" alt="VDGLogbook Create New QR Code" />
                </div>
                <div class="logbook-admin-access-content" data-label="02">
                    <h4 class="logbook-screen-title-pt-0">
                        Create New QR Code &
                        Download With Ease
                    </h4>
                    <p>
                        Admins can create as many QR
                        codes as they need and they can be
                        downloaded and distributed quickly.
                    </p>
                </div>
            </div>
            <div class="logbook-admin-access-li">
                <div class="logbook-admin-access-content" data-label="03">
                    <h4>
                        Manage
                        Viewer Access
                    </h4>
                    <p>
                        You can manage who can view QR
                        code information and restrict access
                        or give permissions.
                    </p>
                </div>
                <div class="logbook-admin-access-img">
                    <img src="assets/img/03_Web_Manage-Viewer-Access.gif" alt="VDGLogbook Manage Viewer Access" />
                </div>
            </div>
            <div class="logbook-admin-access-li">
                <div class="logbook-admin-access-img">
                    <img src="assets/img/04_Web_View-Privite-Verification-Page.gif" alt="VDGLogbook  View Private Verification Page" />
                </div>
                <div class="logbook-admin-access-content" data-label="04">
                    <h4>
                        View Private
                        Verification Page

                    </h4>
                    <p>
                        You can make verifications pages
                        private or restricted with secure
                        data access and permissions on
                        who can view the pages.
                    </p>
                </div>
            </div>
        </div>
        </div>
      </div>
        
    </div>

</div>
<div class="vdg-logbook-faq-section" id="logbook-faq">
    <div class="container">
        <div class="product-line-green-title">
            <h2>
                FAQ’S
            </h2>
        </div>
        <div id="accordion" class="logbook-faq-accordion">
            <div class="faq-item">
              <div class="faq-heading">
              <h5 data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Who beneﬁts from companies using QR codes with VeriDoc Logbook technology? 
               </h5>
            </div>
              <div id="collapseOne" class="collapse show" data-parent="#accordion">
                <div class="faq-ans">
                    <p>
                        QR codes and blockchain technology in logbooks helps businesses and software companies, along with HR departments make their work more eﬃcient. VeriDoc Logbooks make it easy to collaborate between departments with more eﬃciency than traditional log book methods, and are able to save money while generating more potent information. This solution produces trust and accountability using a secure, transparent and decentralised public digital ledger. Real time information is vital and VeriDoc Logbook provides that for businesses.
                    </p>
                </div>
              </div>
            </div>
            <div class="faq-item">
                <div class="faq-heading">
                <h5 data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    What is blockchain? 
                </h5>
            </div>
                <div id="collapseTwo" class="collapse" data-parent="#accordion">
                <div class="faq-ans">
                    <p>
                        Blockchain stores blocks of data linked to the previous entry in a code. Each block is made up of data that is placed on a network of computers in a database. After this, the block of data is connected to the previous block, which makes it impossible to change without the system ﬂagging this on other computers. Times, locations, personnel and resources can only be veriﬁed by its relationship in the chain and is validated by all of the computers using the database.
                </p>
                </div>
              </div>
            </div>
            <div class="faq-item">
                <div class="faq-heading">
                <h5 data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    What is QR code technology? 
                </h5>
            </div>
                <div id="collapseThree" class="collapse" data-parent="#accordion">
                <div class="faq-ans">
                    <p>
                        QR, or Quick Response, is a block of code that is quickly read by a mobile phone and entered onto the phone device. These codes already appear everywhere in advertising, but their ability to store up to 3kb of data in each block is what makes their potential use for data collection even more exciting.<br><br>
                        Not only do QR codes store much more data than a standard barcode, they are able to record time, resource data, personnel and GPS location. They don’t require an app, as they can used on a standard mobile phone. Combining the exciting, simple technology of QR codes with the revolutionary and immutable blockchain creates a secure system for capturing data and reporting. It’s a simpler system with trust and transparency.<br><br>
                        VeriDoc Logbook’s QR codes work with existing technology through an Application Programming Interface (API). Once the code is scanned with the mobile phone device, the details get entered into your database.
                </p>
                </div>
              </div>
            </div>
            <div class="faq-item">
                <div class="faq-heading">
                <h5 data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    What are the advantages of QR codes and blockchain?
                </h5>
            </div>
                <div id="collapseFour" class="collapse" data-parent="#accordion">
                <div class="faq-ans">
                    <p>
                        Blockchain is incorruptible and once data is enter cannot be changed. This makes it extremely secure for businesses. QR codes can be easily accessed and scanned on a mobile phone which makes it widely accessible. By combining the two into VeriDoc Logbook’s ﬁnished solution, you can feed logged and appropriate data from employee’s phones, and safely secure it with an immutable decentralised ledger.  
                </p>
                </div>
              </div>
            </div>
            <div class="faq-item">
                <div class="faq-heading">
                <h5 data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    When is the data available?
                </h5>
            </div>
                <div id="collapseFive" class="collapse" data-parent="#accordion">
                <div class="faq-ans">
                    <p>
                        The data is available instantaneously.
                </p>
                </div>
              </div>
            </div>
            <div class="faq-item">
                <div class="faq-heading">
                <h5 data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                    Why should my business use blockchain? Why is this innovation a beneﬁt?
                </h5>
            </div>
                <div id="collapseSix" class="collapse" data-parent="#accordion">
                <div class="faq-ans">
                    <p>
                        Blockchain is a revolutionary technology that has become vital for companies wanting to safely secure recorded and stored data easily without fear of compromise. By using blockchain, we can  record basically anything and everything, which creates a digital record that is impossible to corrupt or lose. Historically, blockchain has been used in property, identity, balancing accounts and medical records. It was also essential in developing cryptocurrencies. When you combine blockchain with logbooks using QR codes that are incorruptible and accountable, you are increasing the eﬃciency of your business and making things easier.
                </p>
                </div>
              </div>
            </div>
            <div class="faq-item">
                <div class="faq-heading">
                <h5 data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                    How is a QR code entered in to the system?
                </h5>
            </div>
                <div id="collapseSeven" class="collapse" data-parent="#accordion">
                <div class="faq-ans">
                    <p>
                        Employees use their own mobile device to log in or submit data. The QR code scanner uses pre-existing mobile phone technology (GPS, camera) which feeds the data into a logbook. This QR code document management data is protected by the blockchain, which is secure and impossible to corrupt.
                </p>
                </div>
              </div>
            </div>
            <div class="faq-item">
                <div class="faq-heading">
                <h5 data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                    Where is electronic logbook data stored?
                </h5>
            </div>
                <div id="collapseEight" class="collapse" data-parent="#accordion">
                <div class="faq-ans">
                    <p>
                        The data on an electronic logbook is validated at the click of a button. Data is stored all together in the logbook, and populated on a secured service. All of the information is stored with a unique hash value on the blockchain.
                </p>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
<div class="time-tracking-div">
    <div class="container">
        <div class="row time-tracking-div-row">
            <div class="col-lg-5 col-md-12 mobile-order-bottom">
                <div class="time-tracking-left-img">
                    <img src="assets/img/VDGLogbook-App-2.png" alt="VDG Logbook App Screen" />
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="time-tracking-content">
                    <div class="time-tracking-title vdg-logbbok-app-title">
                        <img src="assets/img/logbook-app-icon.png" alt="VDG Logbook App Icon" />
                        <span>VERIDOC LOGBOOK APP</span>
                    </div>
                    <div class="time-tracking-text">
                        <p>
                            This app provides venue and business owners with a digital record of
                            everyone visiting their venue or shop and the use of their services.
                        </p>
                        <h4>
                            FEATURES
                        </h4>
                        <p>
                            Digital Logbook: In-built QR code scanner allows visitors to scan a QR code
                            at the venue using their mobile device. It's quick, requires no paper or writing
                            it down.
                        </p>
                        <h4>
                            FLEXIBLE
                        </h4>
                        <p>
                            Check-in, multiple people from the same device. Easily add additional
                            profiles to check-in visitors or children who do not have a mobile device.
                        </p>
                        <p class="last-p-vdg-log">
                            It can be downloaded now for free from the Apple Store or Google Play Store.
                            It contains no ads and can be used on any device.
                        </p>
                    </div>
                    <div class="download-icon-veridoc-hr-app">
                        <div class="ios-app"><a href="https://apps.apple.com/in/app/vdg-logbook/id1531827458" target="_blank"><img src="assets/img/ios-play-store.png" class="img-responsive" alt="ISO App Store Icon"></a></div>
                        <div class="android-app">
                            <a href="https://play.google.com/store/apps/details?id=com.vdglogbook.android1" target="_blank"><img src="assets/img/google-play-store.png" class="img-responsive " alt="Android App Store Icon"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="logbook-contact-sec" id="contact-us">
	<div class="container">
		<div class="logbook-contact-sec-inner">
			<div class="log-contact-title text-center">
				<h2>CONTACT US</h2>
			</div>
            <div class="log-contact-form">
				<form role="form" [formGroup]="verifyCaptchaForm" (ngSubmit)="onSubmitCaptcha()">
					<div class="row">
						<div class="col-md-12">
                            <div *ngIf="captchaerror" id="wrong-captcha" class="wrong-capcta-msg">The reCAPTCHA response is invalid or malformed. Please try again.</div>
                            <div *ngIf="contactsuccess" id="wrong-captcha" class="wrong-capcta-msg">Email sent successfully. We will get in touch with you shortly.</div>
                        </div>
						<div class="col-lg-6">
							<div class="form-group">
								<label for="FullName">Name<sup>*</sup></label>
                                <input class="form-control" formControlName="name" maxlength="200" name="FullName" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Please enter your name." type="text" value="" >
                                <div *ngIf="submitted && f.name.errors?.required">
                                    <span class="field-validation-valid">Please enter name</span>
                                </div>
                                <div *ngIf="submitted && f.name.errors?.maxLength">
                                    <span class="field-validation-valid">Name can be max 200 characters long.</span>
                                </div>

							</div>
							
							
						</div>
						<div class="col-lg-6">
							<div class="form-group">
								<label for="Email">Email<sup>*</sup></label>
								
                                <input class="form-control" name="Email" formControlName="email" maxlength="200" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Please enter your email address." type="text" value="">
                                <div *ngIf="submitted && f.email.errors?.required">
                                    <span class="field-validation-valid" >Please enter email</span>
                                </div>
                                <div *ngIf="submitted && f.email.errors?.email">
                                    <span class="field-validation-valid" >Please enter valid email</span>
                                </div>
                                <div *ngIf="submitted && f.email.errors?.maxLength">
                                    <span class="field-validation-valid">Email can be max 200 characters long.</span>
                                </div>
							</div>
							
							
						</div>
						<div class="col-lg-12">
							<div class="form-group">
								<label for="Enquiry">Message<sup>*</sup></label>
								<textarea class="form-control" formControlName="message" name="Message" placeholder="Please enter your message." maxlength="500" rows="2"></textarea>
                                <div *ngIf="submitted && f.message.errors?.required">
                                    <span class="field-validation-valid" >Please enter message</span>
                                </div>
                                <div *ngIf="submitted && f.message.errors?.maxLength">
                                    <span class="field-validation-valid" >Message can be max 500 characters long.</span>
                                </div>
                                

							</div>
						</div>
						
							<div class="col-lg-12">
								<div class="captcha-div">
									<div class="captcha-image-left">
										<img [src]="captchaUrl" title="Captcha" alt="captcha" class="captcha-images">
									</div>
									<div class="captcha-image-right">
										<div class="">
											<label for="CaptchaValue">Type the number displayed:</label>
                                            <div class="captcha-input-div">
                                                <div class="number-displaying">
                                                    
                                                    <input class="form-control" formControlName="captchanumber" id="CaptchaValue" name="CaptchaValue" placeholder="Type in the number here" type="text">
                                                </div>
                                                <div class="refresh-captcha">
                                                    <img (click)="getCaptcha()" src="assets/img/recaptcha.png" alt="recaptcha">
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                   
                                </div>
                                <div *ngIf="submitted && f.captchanumber.errors">
                                    <span class="field-validation-valid" >Enter captcha</span>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <input value="Send" class="send-btn-contactus" type="submit">
                            </div>
						</div>
                            
                    </form>
			</div>

			<div id="success-contactus" hidden="" class="meesage-sent-successfully">Your Enquiry has been sent successfully.</div>

		</div>
	</div>
</div>
    </div>
</div>
<a id="smooth-scroll"></a>
<app-footer></app-footer>
</div>
