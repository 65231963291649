import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  serverUrl = 'https://veridoclogbook-backend.azurewebsites.net/';
  // serverUrl = 'http://127.0.0.1:8000/';
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  // showsuccessmsg = false;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username, password) {
    console.log(username);
    console.log(password);
    // username = encodeURI(username);

    let body = new HttpParams();
    // const isadmin: boolean;
    body = body.set('email', encodeURIComponent(username));
    body = body.set('password', password);
    // let encodedJson = encodeURI(JSON.stringify(myObj));
    // let body = "{""}"
    console.log(body);

    return this.http.post<any>(`${this.serverUrl}auth/adminlogin/`, body).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        console.log(user);
        if (user) {
          console.log(user);

          if (user.token) {
            console.log('valid');
            // localStorage.setItem('currentUser', user);
            localStorage.setItem('fullname', user.data.full_name);
            localStorage.setItem('role', user.role);
            localStorage.setItem('token', user.token);
            localStorage.setItem('currentUser', JSON.stringify(user));
            // console.log(localStorage.getItem('token'));
            // console.log(localStorage.getItem('currentUser'));
            // console.log(user['returncode'])
            this.currentUserSubject.next(user);
            return user;
          } else {
            console.log('invalid');
            return user;
          }
        }
      })
    );
  }

  adminaccountupdate(
    full_name,
    email,
    country_code,
    phone_number,
    home_address,
    isBillingSame,
    billing_company,
    billing_address,
    billing_city,
    billing_state,
    billing_country,
    billing_zip
  ) {
    // console.log(username);
    // console.log(password);
    // username = encodeURI(username);

    let body = new HttpParams();
    // const isadmin: boolean;
    // email =
    body = body.set('email', encodeURIComponent(email));
    body = body.set('full_name', full_name);
    body = body.set('phone_number', phone_number);
    body = body.set('home_address', home_address);
    body = body.set('country_code', encodeURIComponent(country_code));
    body = body.set('is_billing_same', isBillingSame);
    body = body.set('billing_company', billing_company);
    body = body.set('billing_address', billing_address);
    body = body.set('billing_city', billing_city);
    body = body.set('billing_state', billing_state);
    body = body.set('billing_country', billing_country);

    body = body.set('billing_zip', billing_zip);


    // let encodedJson = encodeURI(JSON.stringify(myObj));
    // let body = "{""}"
    console.log(body);

    return this.http
      .post<any>(`${this.serverUrl}api/updateadminprofile/`, body)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          console.log(user);
          if (user) {
            console.log(user);

            if (user.token) {
              console.log('valid');
              localStorage.setItem('fullname', user.data.full_name);
              localStorage.setItem('role', user.role);
              localStorage.setItem('token', user.token);
              localStorage.setItem('currentUser', JSON.stringify(user));
              // localStorage.setItem('currentUser', user);
              // localStorage.setItem('fullname',user.data.full_name);
              // localStorage.setItem('role',user.role);
              // localStorage.setItem('token',user.token);
              // localStorage.setItem('currentUser', JSON.stringify(user));
              // console.log(localStorage.getItem('token'));
              // console.log(localStorage.getItem('currentUser'));
              // console.log(user['returncode'])
              this.currentUserSubject.next(user);
              // this.showsuccessmsg = true;
              return user;
            } else {
              console.log('invalid');
              return user;
            }
          }
        })
      );
  }

  uploadProfilePicture(formData: FormData) {
    // Make a POST request to your API endpoint.
    const url = this.serverUrl + `api/file_upload/`;
    return this.http.post<any>(url, formData, {
      responseType:'json', 
      observe: 'response' 
    });
  }

  orgProfilePicRemove(){
    const url = this.serverUrl + `api/image_delete/`;
    return this.http.get<any>(url,{
      responseType:'json', // Set responseType to 'arraybuffer' for binary data
      observe: 'response' // To access full HTTP response
    });
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    localStorage.removeItem('role');
    localStorage.removeItem('pguid');
    localStorage.removeItem('qrGUID');
    localStorage.removeItem('token');
    localStorage.removeItem('fullname');

    // localStorage.removeItem('token');
    this.currentUserSubject.next(null);
    let url = `${this.serverUrl}auth/logout/`;
    // console.log(otp)
    // let body = new HttpParams();
    return this.http.post(url, {
      responseType: 'json',
    });
  }

  masteradminaccountupdate(fullname, email) {
    // console.log(username);
    // console.log(password);
    // username = encodeURI(username);

    let body = new HttpParams();
    // const isadmin: boolean;
    body = body.set('email', encodeURIComponent(email));
    body = body.set('full_name', fullname);
    // body = body.set('lastname', lastname);
    // let encodedJson = encodeURI(JSON.stringify(myObj));
    // let body = "{""}"
    console.log(body);

    return this.http
      .post<any>(`${this.serverUrl}api/masteradminupdateprofile/`, body)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          console.log(user);
          if (user) {
            console.log(user);

            if (user.token) {
              console.log('valid');
              localStorage.setItem('fullname', user.data.full_name);
              localStorage.setItem('role', user.role);
              localStorage.setItem('token', user.token);
              localStorage.setItem('currentUser', JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
              // this.showsuccessmsg = true;
            } else {
              console.log('invalid');
              return user;
            }
          }
        })
      );
  }

  adminchangepass(oldpass, newpass) {
    // console.log(username);
    // console.log(password);
    // username = encodeURI(username);

    let body = new HttpParams();
    // const isadmin: boolean;
    // body = body.set('email', encodeURIComponent(email));
    body = body.set('new_password', oldpass);
    body = body.set('new_confirm_password', newpass);
    // let encodedJson = encodeURI(JSON.stringify(myObj));
    // let body = "{""}"
    console.log(body);

    return this.http
      .post<any>(`${this.serverUrl}api/change-password/`, body)
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          console.log(user);
          if (user) {
            console.log(user);

            if (user.token) {
              console.log('valid');
              localStorage.setItem('currentUser', user);
              this.currentUserSubject.next(user);
              return user;
            } else {
              console.log('invalid');
              return user;
            }
          }
        })
      );
  }
}
