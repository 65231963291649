import { Renderer2,Component, OnInit ,Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AlertService, AuthenticationService } from '../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from '../_services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {
  captchaUrl: string = "assets/img/capcha-demo.png";
  verifyCaptchaForm: FormGroup;
  captchaerror = false;
  submitted = false;
  contactsuccess = false;
  contactfail = false;
  constructor(
    private _renderer2: Renderer2,
    private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private publicService: PublicService,
        private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private _document: Document
  ) {
    if (this.authenticationService.currentUserValue) {
      // authenticationService.logout()
      console.log(this.authenticationService.currentUserValue);
      if(this.authenticationService.currentUserValue.role == '1')
      {
          this.router.navigate(['dashboard']);
      }else{
          this.router.navigate(['master-admin-dashboard']);
      }
      
    }
   }

  ngOnInit(): void {
    this.getCaptcha()
    let script = this._renderer2.createElement('script');
    script.type = "text/javascript";
    script.src = "assets/js/scrolltop.js";
    script.id = "f_scrip_1";
    this._renderer2.appendChild(this._document.body, script);
    this.verifyCaptchaForm = this.formBuilder.group({
      email: ['', [Validators.required,Validators.email,Validators.maxLength(200)]],
      name: ['', [Validators.required,,Validators.maxLength(200)]],
      message:['', [Validators.required,Validators.maxLength(500)]],
      captchanumber: ['', Validators.required]
    });
  }
  getCaptcha() {
    this.publicService.getCaptcha()
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data['returncode'] == '1' || data['returncode'] == 1) {

            // console.log(data['pguid']);
            // console.log(data['url']);

            localStorage.setItem('ipguid', data['pguid']);
            this.captchaUrl = data['url'];
          }
        },
        error => {
          // this.alertService.error(error);
          // this.loading = false;
        });
  }
  get f() { return this.verifyCaptchaForm.controls; }
  onSubmitCaptcha() {
    this.submitted = true;
    // this.userEmail = this.f.email.value;
    if (this.verifyCaptchaForm.invalid) {
      return;
    }
    let captcha: any = {};
    captcha.captchanumber = this.f.captchanumber.value;
    captcha.guid = localStorage.getItem('ipguid');

    this.publicService.verifyCaptcha(captcha)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data['returncode'] == '1' || data['returncode'] == 1) {
            // this.sendOtp();
            this.captchaerror = false;
            this.publicService.contactus(this.f.email.value,this.f.message.value,this.f.name.value)
            .pipe(first())
            .subscribe(
              data2 => {
                if(data2['returncode'] == '1' || data2['returncode'] == 1)
                {
                  this.contactsuccess = true;
                  this.contactfail = false;
                  this.captchaerror = false;
                  this.verifyCaptchaForm.reset()
                  this.submitted = false;
                  this.getCaptcha()
                  console.log(data2)
                }else{
                  this.contactfail = true;
                  this.contactsuccess = false;
                  this.captchaerror = false;
                  this.getCaptcha()
                  console.log(data2)
                }
              }
            )

            console.log(this.f.email.value,this.f.name.value,this.f.message.value)
          } else if (data['returncode'] == '3' || data['returncode'] == 3) {
            // this.isCaptchaVerifyed = false;
            this.captchaerror = true;
            this.contactsuccess = false;
            this.contactfail = false;
            // this.getCaptcha()
            // this.errorMessage1 = true;
            // this.errorMessage2 = false;
          } else {
            // this.isCaptchaVerifyed = false;
            this.captchaerror = true;
            this.contactsuccess = false;
            this.contactfail = false;
            // this.getCaptcha()
            // this.errorMessage1 = false;
            // this.errorMessage2 = true;
          }
        },
        error => {
          // this.isCaptchaVerifyed = false;
          this.captchaerror = true;
          this.contactsuccess = false;
          this.getCaptcha()
          // this.errorMessage1 = true;
          // this.errorMessage2 = false;
        });
  }

}
