<div class="main-wrapper">
  <div class="wrapper-fix-footer">
    <app-header></app-header>

    <div class="page-body-wrapper">
      <div class="custom-container">
        
        <div class="page-body-content">
          <div class="logbook-page-notfound-div">
            <div class="page-not-found-box">

              <p>We've emailed you instructions for setting your password, if an account exists with the email you
                entered.
                You should receive them shortly.</p>
              <p>If you don't receive an email, please make sure you've entered the address you registered with,
                and check your spam folder.</p>
              <!-- <li>The page no longer exists. In this case, we profusely apologize for the inconvenience and for any damage this may cause.</li> -->
              <!-- </ul> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>