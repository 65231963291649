<div class="main-wrapper">
    <div class="wrapper-fix-footer">
        <app-header ></app-header>
        <div class="page-body-wrapper">
            <div class="custom-container">
              
<div class="page-body-content">
    <div class="go-back-btn-div" *ngIf="(currentUser.role == '1')">
      <a href="/dashboard" class="back-btn"><i class="fa fa-angle-left mr-2" aria-hidden="true"></i>Back</a>
    </div>
    <div class="go-back-btn-div" *ngIf="(currentUser.role == '2')">
      <a href="/master-admin-dashboard" class="back-btn"><i class="fa fa-angle-left mr-2" aria-hidden="true"></i>Back</a>
    </div>

      <div class="page-top-heading current-viewer-heading">
          <h1 class="page-title">Current Viewers List</h1>
          <div class="current-viewer-search-box">
          <div class="page-search">
              <div class="search-div">
                <!-- (keyup)="changeUser($event)" -->
              <input class="form-control search-custom" [(ngModel)]="searchText" name="DocumentName" id="searchText"  placeholder="Search" type="text" value="">
              <span class="search-icon-bar"><i class="fa fa-search" aria-hidden="true"></i></span>
              </div>
          </div>
          <button type="button" class="gradiant-btn ml-3" [disabled]="!enable1" (click)="addRow()"><i class="fa fa-plus mr-1"></i> ADD NEW VIEWER
          </button>

      </div>

      </div>
      <div *ngIf="showerr6">
        <span class="error-msg"> Invalid Email</span>
      </div>
      <div *ngIf="showerr7">
        <span class="error-msg">EEmail entered already exists in the viewer list.</span>
      </div>

      <div *ngIf="showmsgsuccess">
        <span class="success-msg">Viewer List updated successfully</span>
      </div>

      <div class="currebt-viewer-list">
          <div class="table-green">
              <div class="table-responsive">
              <table class="table table-bordered table-striped-even" id="viewers-list-table" ng-app="myApp" ng-controller="myCtrl">
                  <thead>
                    <tr>
                      <th class="v-name">Viewer Name*</th>
                      <th class="v-email">Email*</th>
                      <th class="v-info">Additional Information (Optional)</th>
                      <th class="v-action">Actions</th>
                    </tr>
                  </thead>
                  <tbody >

                    <tr *ngFor="let i = index;let dynamic of dynamicArray">
                      <td data-label="Viewer Name*">
                          <input type="text" placeholder="Viewer Name" class="form-control table-row-input-height table-row-input" value="{{dynamicArray[i].viewername}}" [(ngModel)]="dynamicArray[i].viewername" [ngClass]="{'error-border-red': !dynamicArray[i].viewername || error1 }" [disabled]="!dynamic.isEditable"/>
                      </td>
                      <td data-label="Email*">
                          <input type="text" placeholder="Email" class="form-control table-row-input-height table-row-input" value="{{dynamicArray[i].email}}" [(ngModel)]="dynamicArray[i].email"  [ngClass]="{'error-border-red': !dynamicArray[i].email || error2}" [disabled]="!dynamic.isEditable" />
                      </td>
                      <td data-label="Additional Information (Optional)">
                          <input type="text" placeholder="Additional Information (Optional)"  class="form-control table-row-input-height table-row-input" value="{{dynamicArray[i].additional_info}}" [(ngModel)]="dynamicArray[i].additional_info" [ngClass]="{'error-border-red': error3}" [disabled]="!dynamic.isEditable" />
                      </td>
                      <td data-label="Actions">
                          <div class="action-icon">
                            <a class="add" title="Add" id="vieweradd" data-toggle="tooltip" data-placement="top" *ngIf="dynamic.isEditable" (click)=add(dynamic) ><i class="fa fa-check" aria-hidden="true"></i></a>
                            <a class="edit" title="Edit " id="vieweredit" (click)=editDomain(dynamic)  *ngIf="!dynamic.isEditable" data-toggle="tooltip" data-placement="top"><i class="fa fa-edit" aria-hidden="true"></i></a>
                            <a class="delete" title="Delete" id="viewerdlt" (click)="deleteRow(i)" data-toggle="tooltip" data-placement="top"><i class="fa fa-trash" aria-hidden="true"></i></a>
                         </div>
                      </td>
                    </tr>

                   <tr *ngIf="dynamicArray.length==0">
                            <td colspan="4" class="text-center">
                             No Record Found
                            </td>
                    </tr>
                    <tr id="norec" style="display: none;">
                      <td colspan="4" class="text-center">
                       No Record Found
                      </td>
                    </tr>
                 </tbody>

                </table>
              </div>
          </div>
      </div>
      <div *ngIf="btnshow">
        <button class="gradiant-btn mt-3" [disabled]="!enable2" (click)="dataadd()">Save</button>
      </div>
  </div>
   </div>
        </div>
    </div>
<app-footer></app-footer>
