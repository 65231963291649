<div class="main-wrapper">
  <app-loader [loadingMessage]="'Loading'" [countTo]="100" *ngIf="loading"></app-loader>
  <div class="wrapper-fix-footer">
    <app-header></app-header>
    <div class="page-body-wrapper">
      <div class="custom-container">
        
        <div class="page-body-content">
          <div class="page-top-heading qr-top-heading">
            <h1 class="page-title"> Edit Organisation</h1>
            <!-- <a class="model-button-info" href="#" data-toggle="modal" data-target="#createorganisation">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
          </a> -->
            <!-- Modal -->
            <div class="modal fade" id="createorganisation" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    ...
                  </div>

                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="editregisterForm" (ngSubmit)="onSubmit()">
            <span class="error-msg" *ngIf="showcode8">Please enter valid email.</span>
            <span class="error-msg" *ngIf="showcode5">Please enter valid phone number.</span>
            <span class="error-msg" *ngIf="showcode6">Your account is not active.</span>
            <span class="error-msg" *ngIf="showcode2">User with this email is already registered.</span>
            <span class="error-msg" *ngIf="showcode7">User with this email is already registered.</span>
            <span class="error-msg" *ngIf="showcode3">Organisation update unsuccessfull.</span>
            <span class="error-msg" *ngIf="showcode">Organisation update unsuccessfull.</span>
            <span class="error-msg" *ngIf="showcode13">Please select network.</span>
            <span class="error-msg" *ngIf="showcode17">Please enter valid Subscription Counter.</span>
            <span class="error-msg" *ngIf="showcode10">User already register with this phone number</span>
            <div class="border-box">
              <div class="border-box-title">
                <h2>Organisation Details</h2>
              </div>

              <div class="form-group row">
                <label for="org_name" class="col-md-3 col-form-label col-custom-label">Organisation Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control" id="org_name" formControlName="org_name" maxlength=100
                    [ngClass]="{ 'is-invalid': submitted && f.org_name.errors }" placeholder="Organisation Name">
                  <div
                    *ngIf="submitted && f.org_name.errors && editregisterForm.controls['org_name'].hasError('whitespace')">
                    <span *ngIf="f.org_name.errors.required" class="error-msg">Please enter valid organisation
                      name.</span>
                    <span
                      *ngIf="!f.org_name.errors.required && editregisterForm.controls['org_name'].hasError('whitespace')"
                      class="error-msg">Please enter valid organisation name.</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="full_name" class="col-md-3 col-form-label col-custom-label">Admin Name</label>
                <div class="col-md-9">
                  <input type="text" formControlName="full_name" class="form-control" maxlength=100 id="full_name"
                    [ngClass]="{ 'is-invalid': submitted && f.full_name.errors }" placeholder="Admin Name">
                  <div
                    *ngIf="submitted && f.full_name.errors && editregisterForm.controls['full_name'].hasError('whitespace')">
                    <span *ngIf="f.full_name.errors.required" class="error-msg">Please enter valid admin name.</span>
                    <span
                      *ngIf="!f.full_name.errors.required && editregisterForm.controls['full_name'].hasError('whitespace')"
                      class="error-msg">Please enter valid organisation name.</span>
                  </div>
                  <!-- <span ng-show="f.full_name.$error.maxlength" class="error-msg"> Please enter valid admin name</span> -->
                </div>
              </div>
              <div class="form-group row">
                <label for="email" class="col-md-3 col-form-label col-custom-label">Email</label>
                <div class="col-md-9">
                  <input type="email" formControlName="email" class="form-control" id="email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email">
                  <div *ngIf="submitted && f.email.errors">
                    <span *ngIf="f.email.errors.required" class="error-msg">Please enter valid email.</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="phone_number" class="col-md-3 col-form-label col-custom-label">Phone Number</label>
                <div class="col-md-9">
                  <div class="row">
                    <div class="col-md-3 mb-sm-3 mb-3">
                      <select [(ngModel)]="selectedLevel" placeholder="Phone Number" formControlName="country_code"
                        class="custom-select country-select" (change)="selected()">
                        <option *ngFor="let item of selectitems" [ngValue]="item">{{item.name}}</option>
                      </select>
                      <div *ngIf="submitted && f.country_code.errors">
                        <span class="error-msg">Select Country</span>
                      </div>

                    </div>
                    <div class="col-md-9">
                      <input type="text" formControlName="phone_number"
                        [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }" class="form-control"
                        id="phone_number" minlength=6 maxlength=15>
                      <div *ngIf="submitted && f.phone_number.errors">
                        <span class="info-msg">Please enter the phone number without plus sign ("+") and country
                          code</span>
                        <span class="error-msg">Please enter valid Phone Number.</span>
                      </div>
                      <div *ngIf="!f.phone_number.errors">
                        <div *ngIf="f.phone_number.hasError('minlength')">
                          <span class="error-msg">Please enter valid Phone Number.</span>
                        </div>
                        <div *ngIf="f.phone_number.hasError('maxlength')">
                          <span class="error-msg">Please enter valid Phone Number.</span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-- <div class="form-group row">
          <label for="password" class="col-md-3 col-form-label col-custom-label">Password</label>
          <div class="col-md-9">
            <input type="Password" formControlName="password" [ngClass]="{ 'is-invalid':submitted && f.password.errors }" class="form-control" id="password" placeholder="Password">
            <div *ngIf="submitted && f.password.errors">
              <span *ngIf="f.password.errors.required" class="error-msg">Please enter valid Password.</span>
            </div>
          </div>
      </div> -->
              <div class="form-group row">
                <label for="org_address" class="col-md-3 col-form-label col-custom-label">Organisation Address</label>
                <div class="col-md-9">
                  <input type="text" formControlName="org_address" maxlength=500
                    [ngClass]="{ 'is-invalid':submitted && f.org_address.errors }" class="form-control" id="org_address"
                    placeholder="Organisation Address">
                  <div
                    *ngIf="submitted && f.org_address.errors && editregisterForm.controls['org_address'].hasError('whitespace')">
                    <span *ngIf="f.org_address.errors.required" class="error-msg">Please enter valid orgsanisation
                      address.</span>
                    <span
                      *ngIf="!f.org_address.errors.required && editregisterForm.controls['org_address'].hasError('whitespace')"
                      class="error-msg">Please enter valid orgsanisation address.</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="sub_counter" class="col-md-3 col-form-label col-custom-label">Subscription Counter</label>
                <div class="col-md-9">
                  <input type="text" maxlength="7" formControlName="subscription_counter"
                    [ngClass]="{ 'is-invalid':submitted && f.subscription_counter.errors }" class="form-control"
                    id="sub_counter" placeholder="Subscription Counter">
                  <div *ngIf="submitted && f.subscription_counter.errors">
                    <span *ngIf="f.subscription_counter.errors.required" class="error-msg">Please enter valid
                      subscription counter.</span>
                    <span
                      *ngIf="!f.subscription_counter.errors.required && editregisterForm.controls['subscription_counter'].hasError('pattern')"
                      class="error-msg">Please enter valid
                      subscription counter.</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="qrname" class="col-md-3 col-form-label col-custom-label">Timezone</label>
                <div class="col-md-9">
                  <!-- <div class="row">
          <div class="col-md-12 mb-sm-3 mb-3"> -->
                  <!-- <select [(ngModel)]="selectedzone" name="selectedzone" placeholder="select Time zone" formControlName="org_timezone" class="custom-select country-select" (change)="selected2()"> -->
                  <!-- <option value="" disabled selected hidden>Select a value</option> -->
                  <!-- <option value="" [selected]="selected">Select country</option> -->
                  <!-- <option *ngFor="let zone of selecttimezone">{{zone.name}}</option> -->
                  <!-- <option *ngFor="let item of selectitems" [ngValue]="item.value">{{item.name}}</option> -->
                  <!-- </select> -->
                  <ng-moment-timezone-picker class="timezone-select-custom" customPlaceholderText="Select Timezone"
                    [formControlName]="'org_timezone'">
                  </ng-moment-timezone-picker>
                  <div *ngIf="submitted && f.org_timezone.errors">
                    <span class="error-msg">Select Timezone</span>
                  </div>

                  <!-- </div> -->


                  <!-- </div> -->
                </div>
              </div>
              <div class="form-group row mb-0 network">
                <label for="" class="col-md-3 col-form-label col-custom-label">Network</label>
                <div class="col-md-9">
                  <div class="check-box-group">
                    <div class="custom-control custom-checkbox mr-3">
                      <input type="checkbox" class="custom-control-input" id="customCheck" formControlName="network"
                        [checked]="currentlyChecked === check_box_type.TESTNET"
                        (click)="selectCheckBox(check_box_type.TESTNET)" value="0" name="example1">
                      <label class="custom-control-label" for="customCheck">Testnet</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="customCheck1" formControlName="network"
                        [checked]="currentlyChecked === check_box_type.MAINNET"
                        (click)="selectCheckBox(check_box_type.MAINNET)" value="1" name="example1">
                      <label class="custom-control-label" for="customCheck1">Mainnet</label>
                    </div>

                  </div>

                </div>
              </div>


              <!-- isbilling -->
              <!-- isbilling -->
              <div class="form-group row mb-0 billing">
                <label for="" class="col-md-3 col-form-label col-custom-label">Are your billing details the same as your
                  sign up details?</label>

                <div class="col-md-9 select">
                  <div class="check-box-group">
                    <div class="custom-control custom-checkbox mr-3">
                      <input [checked]="isBillingOn === true" (click)="selectBilling(true)" type="radio"
                        class="custom-control-input" id="isBillingSameYes" formControlName="isBillingSame" value="true"
                        name="isBillingSame">
                      <label class="custom-control-label" for="isBillingSameYes">Yes</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                      <input [checked]="isBillingOn === false" (click)="selectBilling(false)" type="radio"
                        class="custom-control-input" id="isBillingSameNo" formControlName="isBillingSame" value="false"
                        name="isBillingSame">
                      <label class="custom-control-label" for="isBillingSameNo">No</label>
                    </div>
                  </div>
                </div>

              </div>
              <div *ngIf="!isBillingOn">
                <div class="form-group row">
                  <label for="company_name" class="col-md-3 col-form-label col-custom-label">Company Name</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="company_name" placeholder="Company Name"
                      formControlName="billing_company">
                    <div *ngIf="submitted && f.billing_company.errors"><span class="error-msg">Please enter your Company
                        Name</span></div>

                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-md-3 col-form-label col-custom-label">Address</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" style="margin-bottom: 1rem;" id="address"
                      formControlName="billing_address" placeholder="Address Line 1">
                    <div *ngIf="submitted && f.billing_address.errors"><span class="error-msg">Please enter your Company
                        Address</span></div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6" style="padding-left: 0px !important;">
                          <input type="text" class="form-control" style="margin-bottom: 1rem;" id="city"
                            formControlName="billing_city" placeholder="City">
                          <div *ngIf="submitted && f.billing_city.errors"><span class="error-msg">Please enter
                              City</span></div>

                        </div>
                        <div class="col-md-6" style="padding-right: 0px !important;">
                          <input type="text" class="form-control" style="margin-bottom: 1rem;" id="state"
                            formControlName="billing_state" placeholder="State">
                          <div *ngIf="submitted && f.billing_state.errors"><span class="error-msg">Please enter
                              State</span></div>

                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6" style="padding-left: 0px !important;">
                          <input type="text" class="form-control" style="margin-bottom: 1rem;" id="country"
                            formControlName="billing_country" placeholder="Country">
                          <div *ngIf="submitted && f.billing_country.errors"><span class="error-msg">Please enter
                              Country</span></div>

                        </div>
                        <div class="col-md-6" style="padding-right: 0px !important;">
                          <input type="text" class="form-control" style="margin-bottom: 1rem;" id="postcode"
                            formControlName="billing_zip" placeholder="Postcode">
                          <div *ngIf="submitted && f.billing_zip.errors"><span class="error-msg">Please enter
                              Postcode</span></div>

                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md-3 mb-sm-3 mb-3">
                        <select [(ngModel)]="selectedLevel" name="selectedLevel" placeholder="select country code"
                          formControlName="country_code" class="form-control custom-select country-select"
                          (change)="selected()">
                          
                          <option *ngFor="let country of countries" [selected]="country.name == 'Select country'">
                            {{country.name}}</option>
                         
                        </select>
                        
  
                      </div> -->



                  </div>
                </div>
              </div>


            </div>
            <div class="bottom-btn-group mt-5">
              <!-- <input type="submit" name="submit"> -->
              <button type="button" class="gradiant-btn" (click)="router.navigate(['/master-admin-dashboard']);">Go
                Back</button>
              <button type="submit" class="gradiant-btn">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>