<script>
  $('#myform').on('click', function (ev) {
    // $('#checkmodal').modal({
    //     show: 'true'
    // }); 
    $('#checkmodal').modal('show');

    console.log("called");
    // var data = $(this).serializeObject();
    // json_data = JSON.stringify(data);
    // $("#results").text(json_data); 
    // $(".modal-body").text(json_data); 

    // $("#results").text(data);
    // 
    // ev.preventDefault();
  });
</script>
<div class="main-wrapper">
  <app-loader [loadingMessage]="'Loading'" [countTo]="100" [duration]="5000" *ngIf="loading"></app-loader>
  <div class="wrapper-fix-footer">
    <app-header></app-header>
    <div class="page-body-wrapper">
      <div class="custom-container">
        
        <div class="page-body-content">

          <div class="page-top-heading">
            <h1 class="page-title">Registered Visitors</h1>

            <div class="page-search">

              <div class="search-div" data-toggle="collapse" href="#registered-visitors-search" role="button"
                aria-expanded="false">
                <input class="form-control search-custom" name="DocumentName" placeholder="Search" readonly="readonly"
                  type="text" value="">
                <span class="search-icon-bar"><i class="fa fa-search" aria-hidden="true"></i></span>
                <span class="search-down-cart">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
              </div>

              <form [formGroup]="searchform"
                (ngSubmit)="search(searchBoxdate.value,searchBoxemail.value,searchBoxphoneno.value,searchBoxvisitorname.value,searchBoxhomeaddress.value)">

                <div class="search-dropdown collapse p-0" id="registered-visitors-search">
                  <div class="search-dropdown-pd">
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Date Created</label>
                      <div class="col-lg-8 col-sm-8 angular-calendar">
                        <!-- <input type="text"  formControlName="searchBoxdate" class="form-control date-icon-input" #searchBoxdate placeholder="Select a date" id="datepicker" readonly="readonly"> -->
                        <input class="form-control date-icon-input" #searchBoxdate formControlName="searchBoxdate"
                          placeholder="Select date" name="dp" ngbDatepicker #f="ngbDatepicker" (click)="f.toggle()"
                          readonly="readonly">
                      </div>
                    </div>
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Email</label>
                      <div class="col-lg-8 col-sm-8">
                        <input type="email" formControlName="searchBoxemail" #searchBoxemail class="form-control">
                      </div>
                    </div>
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Phone Number</label>
                      <div class="col-lg-8 col-sm-8">
                        <input type="text" #searchBoxphoneno formControlName="searchBoxphoneno" id="searchBoxphoneno"
                          class="form-control">
                      </div>
                    </div>
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Visitor Name</label>
                      <div class="col-lg-8 col-sm-8">
                        <input type="text" #searchBoxvisitorname formControlName="searchBoxvisitorname"
                          class="form-control" id="searchBoxvisitorname">
                      </div>
                      <!-- <label class="col-lg-4 col-sm-4 col-form-label">Email</label>
                <div class="col-lg-8 col-sm-8">
                  <input type="text" class="form-control" data-ng-model="searchBox2" #searchBox2 id="searchBox2">
                </div> -->

                    </div>
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Home Address</label>
                      <div class="col-lg-8 col-sm-8">
                        <input type="text" #searchBoxhomeaddress formControlName="searchBoxhomeaddress"
                          class="form-control">
                      </div>
                    </div>

                    <div class="search-btn-group">
                      <input type="submit" class="btn-search" value="Search" data-toggle="collapse">
                      <input type="button" class="btn-search ml-3" value="Clear" (click)="clear()" id="clearButton">
                    </div>
                  </div>
                </div>
              </form>

            </div>
            <!-- </form> -->
          </div>

          <div class="dashboard-table">
            <table class="table table-striped table-dash">
              <thead>
                <tr>
                  <th>Date Created</th>
                  <th>Visitor Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Home Address</th>
                  <th>Check Log History</th>

                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let data1 of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                  <td data-label="Date Created">{{ data1.date_joined| date:'dd/MM/yyyy':getFormattedTimeZone()}}</td>
                  <td data-label="Visitor Name">{{ data1.full_name }}</td>
                  <td data-label="Email">{{ data1.email }}</td>
                  <td data-label="Phone Number">{{ data1.phone_number }}</td>
                  <td data-label="Home Address" class="m-visitor-address">{{ data1.home_address }}</td>
                  <td data-label="Check Log History"><button class="gradiant-btn check-btn-table"
                      (click)="open(content,data1.phone_number)">Check</button></td>

                </tr>

                <tr *ngIf="data==null">
                  <td colspan="6" class="text-center">No Record Found</td>
                </tr>
              </tbody>


            </table>

          </div>


          <div *ngIf="data!=null">
            <div class="pager" *ngIf="data.length > 10">
              <ngb-pagination [collectionSize]="data.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
  <ng-template #content let-model let-d="dismiss">
    <!-- <div class="model-header">   -->
    <div class="modalcheck">
      <div class="modal-width-custom">

        <div class="modal-body" ng-app="app" ng-controller="pageCtrl">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
            <span>&times;</span>
          </button>

          <h3 class="modal-log-title">LOG HISTORY</h3>
          <div class="page-top-heading master-dash-heading mb-4">
            <div class="page-search">
              <div class="search-div" data-toggle="collapse" href="#log-history-search" role="button"
                aria-expanded="false">
                <input class="form-control search-custom" placeholder="Search" type="text" value="">
                <span class="search-icon-bar"><i class="fa fa-search"></i></span>
                <span class="search-down-cart">
                  <i class="fa fa-caret-down" aria-hidden="true"></i>
                </span>
              </div>
              <form [formGroup]="searchLogform"
                (ngSubmit)="searchlog(searchBoxdate2.value,searchBoxvisitor.value,searchboxlocation.value)">
                <div class="search-dropdown collapse p-0" id="log-history-search">
                  <div class="search-dropdown-pd">
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Date</label>
                      <div class="col-lg-8 col-sm-8">
                        <div class="input-group angular-calendar">
                          <input class="form-control date-icon-input" #searchBoxdate2 formControlName="searchBoxdate2"
                            placeholder="Select date" name="dp" ngbDatepicker #f="ngbDatepicker" (click)="f.toggle()"
                            readonly="readonly">
                          <!-- <div class="input-group-append">
                                  <button class="btn date-icon-input"  type="button"></button>
                                </div> -->
                        </div>
                        <!-- working example-->
                        <!-- <ejs-datepicker #createdDate class="syncfusion-dtp" placeholder='Enter date' format="dd-MM-yyyyy" aria-label="Created date"
                              [readonly]="false" [disabled]="false"
                              formControlName="createdOn" name="Created On"></ejs-datepicker> -->
                        <!-- working example end -->
                        <!-- <input type="text" class="form-control date-icon-input" id="datepicker" placeholder="Select a date" readonly> -->
                      </div>
                    </div>
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Visitor Name</label>
                      <div class="col-lg-8 col-sm-8">
                        <input type="text" class="form-control" #searchBoxvisitor formControlName="searchBoxvisitor"
                          id="searchBox">
                      </div>
                    </div>
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Location</label>
                      <div class="col-lg-8 col-sm-8">
                        <input type="text" class="form-control" #searchboxlocation formControlName="searchboxlocation">
                      </div>
                    </div>
                    <!-- <div class="form-group row search-form-group mb-2">
                            <label class="col-lg-4 col-sm-4 col-form-label">Home Address</label>
                            <div class="col-lg-8 col-sm-8">
                              <input type="text" class="form-control" #searchboxhomeaddress formControlName="searchboxhomeaddress">
                            </div>
                          </div> -->

                    <div class="search-btn-group">
                      <input type="submit" class="btn-search" value="Search" data-toggle="collapse"
                        href="#log-history-search">
                      <input type="button" class="btn-search ml-3" (click)="clear2()" value="Clear" id="cllearButton">
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div class="responsive-btn-check">
              <button type="button" [disabled]="history==null"
                [ng-style]="{'background-color': history==null ? 'green':'red'}" class="gradiant-btn res-check-btn"
                (click)="download2(history)"><i class="fa fa-plus mr-1"></i> Export as CSV
              </button>
              <!-- <button type="button" class="gradiant-btn res-check-btn ml-2"><i class="fa fa-plus mr-1"></i> Export as PDF 
                    </button> -->
            </div>
          </div>
          <div class="log-history-table">
            <div class="table-responsive horizontal-scroll">
              <table class="table">
                <thead>
                  <tr>
                    <th>Timestamp (local time)</th>
                    <th>Visitor Name</th>
                    <th>Location</th>
                    <th>QR Code Details</th>
                    <th>Verify</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <div *ngIf="history"> -->
                  <tr *ngFor="let data1 of history">
                    <td class="word-nowrap">{{data1.Timestamp}}</td>
                    <td>
                      {{ data1.Profiles}}
                    </td>
                    <td>{{data1.ScannedLocation}}</td>
                    <td>{{data1.Guid}}<br>
                      {{data1.OrganisationName}}<br>
                      {{data1.OrganisationAddress}}
                    </td>
                    <td>
                      <div *ngIf="data1.TransactionLink">
                        <a target="_blank" href="{{data1.TransactionLink}}" id=""
                          class="gradiant-btn verify-gradiant-btn">
                          <img src="assets/img/verify-btn-icon.png"><span class="event-verify-btn">Verify</span>
                        </a>
                      </div>
                    </td>
                  </tr>
                  <!-- </div> -->
                  <tr *ngIf="history==null">
                    <td colspan="5" class="text-center">No Record Found</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </div>

  </ng-template>

  <app-footer></app-footer>