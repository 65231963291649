import { Component, OnInit } from '@angular/core';
import { DynamicGrid } from '../_models';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService, UserService, AuthenticationService } from '../_services';
import { User } from '../_models';
import { stringify } from '@angular/compiler/src/util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-createqr',
  templateUrl: './createqr.component.html',
  styleUrls: ['./createqr.component.css']
})
export class CreateqrComponent implements OnInit {
    generateqrForm: FormGroup;
    loading = false;
    submitted = false;
    currentUser: User;
    showSuccessMessage = false;
    showcode2 = false;
    showcode = false;
    showcode3 = false;
    showcode4 = false;
    showcode5 = false;
    showcode6 = false;
    showcode7 = false;
    error = false;
    error1 = false;
    error2 = false;
    error3 = false;
    enable2 = false;
    enable1 = false;
    dynamicArray: Array<DynamicGrid> = [];  
    newDynamic: any = {}; 
    viewer:any;
    olddynamicArray: Array<DynamicGrid> = [];
    // employees:Employee[];
    multiple_venue = 0;
    validationVAddress = false;
    validationVName = false;

constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService

) { 
  this.currentUser = this.authenticationService.currentUserValue;
  }

  public noWhitespaceValidator(control: FormGroup) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  ngOnInit(): void {

    this.newDynamic = {viewername: this.currentUser.data.full_name, email: this.currentUser.data.email,additional_info:"Issuer"};  
    this.dynamicArray.push(this.newDynamic);
    this.enable1 = true;
    this.enable2 = true;
    
    const array = this.dynamicArray;
    // var jsonObject : any = JSON.parse(array);
    // Object.keys(JSON.parse(array)).map(arr => {
    //   return `${arr}: ${JSON.parse(array)[arr].join(', ')}`;
    // });

    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    }
    
    let daat = localStorage.getItem('viewer');
    //daat = replaceAll(daat,"#####","'");
    daat = JSON.parse(daat);
    
    const reg = '(.*)\\.(.*)'
    //const reg = '(http|https)\:\/\/(.*)\\.(.*)'
    // '((http|ftp|https):\\/\\/)?[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?'
    // '(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'
    // 'http[s]?:(.*)' 
    // 'https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)'

    // '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    
    this.generateqrForm = this.formBuilder.group({
        qr_name: ['', [Validators.required, this.noWhitespaceValidator]],
        viewerlist: daat,
        redirecturl:['',[Validators.pattern(reg)]],
        venue_name: ['', [Validators.maxLength(100)]],
        venue_address: ['', [Validators.maxLength(500)]],
    });

  }
  get f() { return this.generateqrForm.controls; }

  onSubmit() {
    this.enable2 = false;
    
    for(let i in this.dynamicArray)
    {
      if(this.dynamicArray[i]['isEditable']==true)
      {
          this.enable2 = true;
          return;
      }
    }

    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    }
    
    let data_view = JSON.stringify(this.dynamicArray)
    data_view = replaceAll(data_view,"'","#####");
    this.submitted = true;
    
    let venue_name = this.generateqrForm.get("venue_name").value;
    if(venue_name){
      venue_name = venue_name.trim();
    }
    
    let venue_address = this.generateqrForm.get("venue_address").value;
    if(venue_address){
      venue_address = venue_address.trim();
    }

    if(this.multiple_venue == 1){
      if(!venue_name){
         this.validationVName = true;
      }
      
      if(!venue_address){
        this.validationVAddress = true;
      }
    }

    // stop here if form is invalid
    if (this.generateqrForm.invalid) {
        this.enable2 = true;
        return;
    }else{
      if(this.multiple_venue == 1){
      
      if(!venue_name){
         this.validationVName = true;
      }
      
      if(!venue_address){
        this.validationVAddress = true;
      }
      if(!venue_address || !venue_name){
        this.enable2 = true;
        return;
      }
    }
    }
    this.loading = true;
    
    let qrname = this.generateqrForm.get("qr_name").value.trim();
    let redirecturl = this.generateqrForm.get("redirecturl").value.trim();
    
    let redirecturlpath = "";
    if(redirecturl != "" && redirecturl != null && redirecturl != 'null'){
      var validURL = this.validateURL(redirecturl);
    
      if(!validURL){
        redirecturlpath = "https://"+redirecturl;
      }else{
        redirecturlpath = redirecturl;
      }
    }

    this.userService.createqr(qrname,data_view,redirecturlpath,venue_name,venue_address)
    .pipe(first())
    .subscribe(
        data => {
          if(data['returncode']=='1'|| data['returncode']== 1)
          {
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'QR Code generated Successfully.',
              timer: 2000, // Show for 2 seconds and automatically close
              showConfirmButton: false,
            }); 
            // this.showSuccessMessage = true;
            this.router.navigate(['/dashboard']);
          }else if(data['returncode']=='2'|| data['returncode']==2)
          {
            this.showcode2 = true;
          }else if(data['returncode']=='3'|| data['returncode']==3)
          {
            this.showcode3 = true;
          }else if(data['returncode']=='6'|| data['returncode']==6)
          {
            this.showcode6 = true; 
          }
          else if(data['retuncode']=='7'|| data['retuncode']==7)
          {
            this.showcode7 = true;
          }else{
            this.showcode = true;
          }
            // this.alertService.success('Registration successful', true); 
          
          this.enable2 = true;
        },
        error => {
            this.alertService.error(error);
            this.loading = false;
            this.enable2 = true;
        });

  }
  addRow() {    
    this.newDynamic = {viewername: "", email: "",additional_info:"",isEditable: true};  
      this.dynamicArray.push(this.newDynamic);  
    //   this.toastr.success('New row added successfully', 'New Row');  
      console.log(this.dynamicArray);
      const data = JSON.stringify(this.dynamicArray)
      localStorage.setItem('viewer',data);
      console.log(data);
      return true;  
  }
  // getrowval()
  // {
  //   console.log(this.dynamicArray)
  // }  
  deleteRow(index) {  
    if(this.dynamicArray.length ==1) {  
    //   this.toastr.error("Can't delete the row when there is only one row", 'Warning');  
      this.dynamicArray.splice(index, 1);  
      return true;  
    } else {  
        this.dynamicArray.splice(index, 1);  
        // this.toastr.warning('Row deleted successfully', 'Delete row');  
        return true;  
    }  

  }
  editDomain(dynamic){
    dynamic.isEditable = !dynamic.isEditable;
    
  }
  validateEmail(email) {
    //const regularExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regularExpression = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    return regularExpression.test(String(email).toLowerCase());
   }

   validateString(str){
    const regularExpression = /"/g
    return regularExpression.test(str);
   }

   checkuniqueemail(email)
  {

    // console.log("calleddd")
    let dataterewr=false;
    for(let i in this.dynamicArray)
    {
      console.log(this.dynamicArray.length)
      
      if(this.dynamicArray[i]['isEditable']!=true && this.dynamicArray[i]['email']==email)
      {
        console.log(this.dynamicArray[i]['email'])
        // if()
        dataterewr = true;
        // return true;
        break;
      }
      // else{
      //   // dataterewr = false;
      //   return false;
      // }
      
    }

    return dataterewr;
  }
   add(dynamic){
    console.log(dynamic);
    this.allResetValidate();
    // dynamic.isEditable= !dynamic.isEditable;
    if(dynamic)
    {
      if(!dynamic.viewername.trim()){
        this.error1 = true;
      }else{
        if(this.validateString(dynamic.viewername.trim())){
          this.error1 = true;
        }else{
          this.error1 = false;
          if(!dynamic.email.trim()){
            this.error2 = true;
            // this.enable1 = false;
          }else{
            if(this.validateEmail(dynamic.email.trim()))
            {
              if(!this.checkuniqueemail(dynamic.email.trim()))
              {
                this.error2 = false;
                if(this.validateString(dynamic.additional_info.trim())){
                  this.error3 = true;
                }else{
                  dynamic.isEditable = !dynamic.isEditable;
                  this.enable1 = true;
                  this.enable2 = true;
                  this.error2 = false;
                  this.error3 = false;
                  console.log("passs")
                }
              }else{
                this.error2 = true;
                console.log("fail")
              }
              
            }else
            {
              this.error2 = true;
            }
          }
        }

      }
      // console.log(dynamic.viewername)
    }
  }
  
  checkVenue(chkStatus){
    if(chkStatus == 1){
      this.multiple_venue = 1;

    }else if(chkStatus == 0){
      this.multiple_venue = 0; 
    } 
  }

  onRemoveValidationVName(x){
    if(x.target.value.length < 1){
      this.validationVName = true;
    }else{
      this.validationVName = false;
    }
  }

  onRemoveValidationVAdd(x){
    if(x.target.value.length < 1){
      this.validationVAddress = true;
    }else{
      this.validationVAddress = false;
    }
  }

  validateURL(link){
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
      //console.log("The link has http or https.");
      return true;
    }else{
      //console.log("The link doesn't have http or https.");
      return false;
    }
  }

  allResetValidate(){
    this.error1 = true;
    this.error2 = false;
    this.error3 = false;
  }

}
// export class Employee{
//     viewername:string;
//     email:string;
//     additional_info:string;
//   }