<!doctype html>
<html lang="en">

<head>
    <title>VeriDocGlobal | Logbook</title>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">

    <!-- Bootstrap CSS -->
    <!-- <link rel="stylesheet" href="css/bootstrap.min.css">
    <link rel="stylesheet" href="css/font-awesome.min.css">
    <link rel="stylesheet" href="css/style.css"> -->
    <link rel="icon" type="image/png" href="assets/img/favicon.ico">
</head>

<body>

    <div class="main-wrapper">

        <div class="wrapper-fix-footer">
            <nav class="login-header">
                <div class="custom-container">
                    <div class="login-nav">
                        <img class="login-logo" src="/assets/img/VeriDocLogbook_H_White_200px.webp" alt="img">
                    </div>
                </div>
            </nav>

            <div class="login-section">
                <div class="container">
                    <div class="row login-div-row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-12 my-auto login-img-none">
                            <div class="login-page-img">
                                <img class="login-img" src="assets/img/veridoc logbook-main-login-screen-dektop.webp">
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-12 login-outer">
                            <div class="login-div">
                                <div class="login-form">
                                    <div class="login-title">
                                        <h1>Create New Password</h1>
                                    </div>

                                    <form [formGroup]="resetpass" (ngSubmit)="onSubmit()" role="form">
                                        <div class="form-group login-form-group">
                                            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                            <input type="password" class="form-control" id="txt_Password"
                                                formControlName="password" [type]="fieldTextType ? 'text' : 'password'"
                                                placeholder="New Password" (keyup)="onSubmit()">
                                            <i class="toggle-password fa" [ngClass]="{
                        'bi bi-eye-slash-fill': !fieldTextType,
                        'bi bi-eye-fill': fieldTextType
                      }" (click)="toggleFieldTextType()"></i>
                                            <div *ngIf="submitted && f.password.errors">
                                                <span *ngIf="resetpass.get('password').hasError('charLength')"
                                                    class="error-msg">
                                                    Password must be minimum of 8 characters.
                                                </span>
                                                <span *ngIf="resetpass.get('password').hasError('hasUpperCase')"
                                                    class="error-msg">
                                                    Password must include at least one uppercase letter.
                                                </span>
                                                <span *ngIf="resetpass.get('password').hasError('hasLowerCase')"
                                                    class="error-msg">
                                                    Password must include at least one lowercase letter.
                                                </span>
                                                <span *ngIf="resetpass.get('password').hasError('hasNumber')"
                                                    class="error-msg">
                                                    Password must include at least one number.
                                                </span>
                                                <span *ngIf="resetpass.get('password').hasError('hasSpecialChar')"
                                                    class="error-msg">
                                                    Password must include at-least one symbol e.g. (@@!#$?)
                                                </span>
                                            </div>



                                        </div>
                                        <div class="form-group login-form-group">
                                            <span class="input-group-addon"><i class="fa fa-lock"></i></span>
                                            <input type="password" class="form-control" id="txt_CPassword"
                                                [type]="confirmpass ? 'text' : 'password'"
                                                formControlName="confirmPassword" placeholder="Confirm Password">
                                            <i class="toggle-password fa" [ngClass]="{
                                        'bi bi-eye-slash-fill': !confirmpass,
                                        'bi bi-eye-fill': confirmpass
                                      }" (click)="tohggleconfirmpass()"></i>
                                            <div *ngIf="submitted && f.confirmPassword.errors">
                                                <span *ngIf="f.confirmPassword.errors.required" class="error-msg">Please
                                                    enter confirm password.</span>
                                                <span *ngIf="f.confirmPassword.errors.mustMatch" class="error-msg">New
                                                    password and confirm password must match.</span>
                                            </div>
                                        </div>


                                        <div class="login-btn-center"><input type="submit" class="login-btn w-100"
                                                value="Change Password"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer>
            <div class="footer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-3 mb-md-5 mb-4 mb-lg-0">
                            <div class="footer_logo">
                                <a href="https://veridoclogbook.com/"><img style="max-width: 90% !important;"
                                        src="/assets/img/VeriDocLogbook_H_White_200px.webp" class="img-fluid"
                                        alt="VeriDoc Logbook" title="VeriDoc Logbook"></a>
                            </div>
                            <div class="footer_social_icon">
                                <a aria-label="Social" target="_blank" href="https://www.facebook.com/VeriDocGlobal"
                                    class="mr-2">
                                    <span>
                                        <i class="fa fa-facebook-f"></i>
                                    </span>
                                </a>
                                <a aria-label="Social" target="_blank" href="https://twitter.com/VeriDocGlobal"
                                    class="mr-2">
                                    <span>
                                        <i class="fa bi-twitter-x "></i>
                                    </span>
                                </a>
                                <a target="_blank" aria-label="Social" href="https://t.me/veridocglobal" class="mr-2">
                                    <span>

                                        <i class="fa fa-telegram" title="Telegram"></i>
                                    </span>
                                </a>
                                <a aria-label="Social" target="_blank" href="https://www.instagram.com/VeriDocGlobal/"
                                    class="mr-2">
                                    <span>
                                        <i class="fa fa-instagram"></i>
                                    </span>
                                </a>
                                <a aria-label="Social" target="_blank"
                                    href="https://www.linkedin.com/company/veridocglobal" class="mr-2">
                                    <span>
                                        <i class="fa fa-linkedin"></i>
                                    </span>
                                </a>
                                <a aria-label="Social" target="_blank" href="https://veridocglobal.medium.com/"
                                    class="mr-2">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20"
                                            viewBox="0 0 640 512">
                                            <path fill="#0e3553"
                                                d="M180.5 74.3C80.8 74.3 0 155.6 0 256S80.8 437.7 180.5 437.7 361 356.4 361 256 280.2 74.3 180.5 74.3zm288.3 10.6c-49.8 0-90.2 76.6-90.2 171.1s40.4 171.1 90.3 171.1 90.3-76.6 90.3-171.1H559C559 161.5 518.6 84.9 468.8 84.9zm139.5 17.8c-17.5 0-31.7 68.6-31.7 153.3s14.2 153.3 31.7 153.3S640 340.6 640 256C640 171.4 625.8 102.7 608.3 102.7z" />
                                        </svg>
                                    </span>
                                </a>
                                <a target="_blank" aria-label="Social"
                                    href="https://www.pinterest.com.au/veridocglobal/" class="mr-2">
                                    <span>
                                        <i class="fa fa-pinterest" title="Pinrest"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="footer_heading usefull_link">
                                <h3>Useful Links</h3>
                                <ul>
                                    <li class="nav-item">
                                        <a href="https://veridoclogbook.com/feature-and-benefits">Features and
                                            Benefits</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://veridoclogbook.com/faq">FAQs</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://veridoclogbook.com/blog">Blogs</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://veridoclogbook.com/contact">Contact Us</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="https://veridoclogbook.com/privacy-policy">Privacy Policy</a>
                                    </li>
                                </ul>

                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="footer_heading footer-contact">
                                <h3>Contact Us</h3>
                                <ul>
                                    <li>
                                        <a href="https://www.google.com/maps/place/18%2F123+Eagle+St,+Brisbane+City+QLD+4000,+Australia/@-27.4671943,153.0303993,17z/data=!4m6!3m5!1s0x6b915a1db3d4c0d3:0x8f5089497c593473!8m2!3d-27.4671943!4d153.0303993!16s%2Fg%2F11m__t8yv5"
                                            target="_blank" class="d-flex text-white">
                                            <span><i class="fa fa-map-marker"></i></span>
                                            <p style="margin-bottom: 0 !important;">
                                                Level 18, 123 Eagle Street, Brisbane, Queensland 4000 Australia
                                            </p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:admin@veridoclogbook.com"
                                            class="d-flex text-white align-items-center">
                                            <span><i class="fa fa-envelope"></i></span>
                                            <p style="margin-bottom: 0 !important;">
                                                admin@veridoclogbook.com
                                            </p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-2 text-lg-end mb-md-5 mb-lg-0" id="top">
                            <a href="https://veridocglobal.com/" target="_blank">
                                <img src="/assets/img/AVeriDocGlobalProduct_Logo_White_250px.webp"
                                    class="img-fluid veridoc" alt="VeriDoc Global" title="VeriDoc Global">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-copyright">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <p class="text-white" style="margin-bottom: 0 !important;">©
                                {{ currentYear() }} | ALL RIGHTS
                                RESERVED, VERIDOC GLOBAL PTY LTD
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>

    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <!-- <script src="js/jquery-3.2.1.slim.min.js"></script>
    <script src="js/popper.min.js"></script>
    <script src="js/bootstrap.min.js"></script> -->
</body>

</html>