import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

import { Captcha } from '../_models';

@Injectable({ providedIn: 'root' })
export class PublicService {
  private modals: any[] = [];

  serverUrl = 'https://veridoclogbook-backend.azurewebsites.net/';
  // serverUrl = 'http://127.0.0.1:8000/';

  constructor(private http: HttpClient) { }
//   open(id: string) {
//     // open modal specified by id
//     let modal: any = this.modals.filter(x => x.id === id)[0];
//     modal.open();
// }

// close(id: string) {
//     // close modal specified by id
//     let modal: any = this.modals.filter(x => x.id === id)[0];
//     modal.close();
// }

  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
      console.log(errorMessage);
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      console.log(errorMessage);
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getCaptcha() {
    let url = `${this.serverUrl}api/getcaptcha/`;
    return this.http.post<any>(url, {
      responseType: 'json'
    });
  }

  verifyCaptcha(captcha) {
    let url = `${this.serverUrl}api/verifycaptcha/`;
    console.log(captcha)
    let body = new HttpParams();
    return this.http.post(url, captcha,{
      responseType: 'json'
    });
  }

  sendOtp(otp) {
    let url = `${this.serverUrl}api/sendotp/`;
    console.log(otp)
    let body = new HttpParams();
    return this.http.post(url, otp,{
      responseType: 'json'
    });
  }

  verifyOtp(otp) {
    let url = `${this.serverUrl}api/otpverify/`;
    console.log(otp)

    let body = new HttpParams();
    return this.http.post(url, otp,{
      responseType: 'json'
    });
  }

  eventHistory(otp) {
    let url = `${this.serverUrl}api/admineventhistory/`;
    console.log(otp)
    let body = new HttpParams();
    return this.http.post(url, otp,{
      responseType: 'json'
    });
  }

  downloadFile(data, filename='data') {
    console.log(data)
    let csvData = this.ConvertToCSV(data, ['name','email', 'phone_number', 'address', 'timestamp','location','device_id','device_id','qr_verify_link']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}
downloadFile3(data, filename='data')
{
  console.log(data)
  let csvData = this.ConvertToCSV(data, ['VisitorName','Email', 'PhoneNumber', 'HomeAddress', 'Timestamp (local time)','Venue Address','VerifyLink']);
  console.log(csvData)
  let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

downloadFile2(data, filename='data')
{
  console.log(data)
  let csvData = this.ConvertToCSV(data, ['VisitorName','Email', 'PhoneNumber', 'HomeAddress', 'Timestamp (local time)','ScannedLocation','PhoneUniqueId','ScannedBy','VerifyLink']);
  console.log(csvData)
  let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
  if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
     let row = '';

     for (let index in headerList) {
      //  console.log(headerList[index]);
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
     str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {
        let line = ""
        //  let line = (i+1)+'';
         for (let index in headerList) {
            let head = headerList[index];

             line += array[i][head]+",";
         }
         str += line + '\r\n';
     }
     return str;
 }

 forgotpass(email){
  let url = `${this.serverUrl}api/passwordreset/`;
    console.log(email)
    let body = new HttpParams();
    body = body.set('email',encodeURIComponent(email));
    return this.http.post(url, body,{
      responseType: 'json'
    });


 }

 tokenauth(token){
  let url = `${this.serverUrl}api/passwordreset/verify/`;
    console.log(token)
    let body = new HttpParams();
    body = body.set('token',token);
    return this.http.post(url, body,{
      responseType: 'json'
    });
 }

 passchange(token,password){
  let url = `${this.serverUrl}api/passwordreset/verified/`;
  // console.log(token)
  let body = new HttpParams();
  body = body.set('token',token);
  body = body.set('password',password);

  return this.http.post(url, body,{
    responseType: 'json'
  });
 }

 searcheventverify(guid,searchBoxdate2,searchBoxvisitorname,searchBoxphno,searchboxHomeaddress,searchBoxtimehr,searchBoxtimemin)
  {
    let body = new HttpParams();
    if(guid!="")
    {
      body = body.set('guid', guid);
    }
    if(searchBoxvisitorname!="")
    {
      body = body.set('name', searchBoxvisitorname);
    }
    if(searchBoxphno!="")
    {
      body = body.set('phone_number',encodeURIComponent(searchBoxphno));
    }

    if(searchBoxdate2!="")
    {
      body = body.set('date', searchBoxdate2);
    }
    if(searchboxHomeaddress!="")
    {
      body = body.set('home_address',searchboxHomeaddress);
    }
    if(searchBoxtimehr){
      body = body.set('hour',searchBoxtimehr);
    }
    if(searchBoxtimemin){
      body = body.set('minute',searchBoxtimemin);
    }


    return this.http.post<any>(this.serverUrl + "api/verifysearch/", body);
  }

  contactus(email,message,name)
  {
    let url = `${this.serverUrl}api/web_contactus/`;
    // console.log(token)
    let body = new HttpParams();
    body = body.set('email',email);
    body = body.set('message',message);
    body = body.set('full_name',name);
    return this.http.post(url, body,{
      responseType: 'json'
    });
  }

}
