import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, AuthenticationService } from '../_services';

@Component({ templateUrl: 'login.component.html',  styleUrls: ['./login.component.css'] })
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading: boolean = false;
    submitted = false;
    returnUrl: string;
    showErrorMessage = false;
    showPassword = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            // authenticationService.logout()
            console.log(this.authenticationService.currentUserValue);
            if(this.authenticationService.currentUserValue.role == '1')
            {
                this.router.navigate(['dashboard']);
            }else{
                this.router.navigate(['master-admin-dashboard']);
            }
            
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', Validators.required]
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
        // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    }

    currentYear(): number {
        return new Date().getFullYear();
      }
    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.username.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                (data) => {
                    console.log(data);
                    console.log(data.returncode);
                    if(data.returncode == 1 || data.returncode == "1")
                    {
                        if(data.role == "1" || data.role == 1)
                        {
                            this.router.navigate([this.returnUrl]);
                        }else if(data.role == "2" || data.role == 2)
                        {
                            this.router.navigate(['/master-admin-dashboard']);
                        }
                        
                    }else if(data.returncode == "5" || data.returncode == 5)
                    {
                        this.showErrorMessage = true;
                        this.submitted = false;
                        this.loading = false;
                        this.router.navigate(['/']);
                        // this.loginForm.reset();
                        // return;
                        // this.submitted = false;
                    }else{
                        this.showErrorMessage = true;
                        this.submitted = false;
                        this.loading = false;
                        this.router.navigate(['/']);

                        // this.loginForm.reset();
                        // return;
                        // this.submitted = false;  
                    }

                    // console.log(data);
                    
                },
                error => {
                    // showErrorMessage = true;
                    this.alertService.error(error);
                    this.loading = false;
                    this.submitted = false;
                });
    }
    togglePassword() {
        this.showPassword = !this.showPassword;
    } 
}