import { Renderer2,Component, OnInit,Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, AuthenticationService, ModelService } from '../_services';
import { first } from 'rxjs/operators';
import { DynamicGrid } from '../_models';
import { User } from '../_models';
import { ThrowStmt } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import { FilterPipe } from '../_helpers/filter.pipe';
import { NgModel } from '@angular/forms';
import { DOCUMENT } from '@angular/common';

// import {Renderer2} from 
// import { Pipe, PipeTransform } from '@angular/core';
// import { Pipe, PipeTransform } from '@angular/core';
// @Pipe({
//   name: 'filter'
// })
// export class FilterPipe implements PipeTransform {
//   transform(dynamicArray: any[], searchText: string): any[] {
//     console.log(searchText)
//     if(!dynamicArray) return [];
//     if(!searchText) return dynamicArray;
    
//     searchText = searchText.toLowerCase();
//     console.log(dynamicArray)
//     // dynamicArray.forEach(element => {
//       // console.log(element)
//       var dynamicArray = dynamicArray.filter(it =>{
//         console.log(it.viewername)
//         var name = it.viewername.toLowerCase().includes(searchText)
//         if(name){
//           return it.viewername.toLowerCase().includes(searchText)
//         }
//       })
//       console.log(this.dynamicArray)
//       console.log(dynamicArray)
      
//       return dynamicArray

//       // return dynamicArray.filter(it => {
//       //   it.viewername.toLowerCase().includes(searchText)
//       // });

//     // });    
//     // return dynamicArray.filter(
//     //       it => {

//     //       it.viewername.toLowerCase().includes(searchText)
//     //       //   console.log(it)
//     //       //   // return it
//     //       // }
//     //       // var dat2 = it.email.toLowerCase().includes(searchText);
//     //       // var dat3  = it.additional_info.toLowerCase().includes(searchText);
//     //       // return dat1||dat2||dat3;
//     //     });
//    } 
// }

@Component({
  selector: 'app-viewerlist',
  templateUrl: './viewerlist.component.html',
  styleUrls: ['./viewerlist.component.css']
})
export class ViewerlistComponent implements OnInit {
  sub:any;
  keyvalue:any;
  uniqueid:any;
  data : any=[];
  olddynamicArray: Array<DynamicGrid> = [];  
  dynamicArray: Array<DynamicGrid> = [];  
  newDynamic: any = {}; 
  datatoadd :any;
  currentUser: User;
  btnshow = false;
  showerr6 = false;
  showerr7 = false;
  tab1 = true;
  showmsgsuccess = false;
  showmsg = false;
  error1 = false;
  error2 = false;
  error3 = false;
  enable1 = true;
  enable2 = false;
  searchText:any;
  userFilter: any = { viewername: '', email: '', additional_info:''};
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private _renderer2: Renderer2,
    // private FilterPipe
    @Inject(DOCUMENT) private _document: Document )
   { 
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(){
    let script = this._renderer2.createElement('script');
    script.type = "text/javascript";
    script.src = "assets/js/searchforviewer3.js";
    this._renderer2.appendChild(this._document.body, script);

    this.sub = this.route
      .queryParams

    // console.log(this.sub);
      .subscribe(params => {
        this.uniqueid = params['uniqueid'];
        console.log(this.uniqueid);
        this.viewfunc(this.uniqueid)
        // console.log("data...");
        // console.log(res);
        // // console.log()
        // Defaults to 0 if no query param provided.
        // this.page = +params['page'] || 0;
      });
  }
  private viewfunc(guid)
  {
    // this.router.navigateByUrl("/master-admin-qrcodelist");
    // console.log(uniqueid);
    this.userService.getviewerlistforadmin(guid)
        .pipe(first())
        .subscribe(
          data => {
              // console.log("POST call successful value returned in body", 
                
              // val);
              console.log(data);
              if(data['returncode']=="1" || data['returncode']==1)
              {
                this.data = data['viewerlist']
                console.log(this.data);
                // this.data = this.data.slice(1, -1);
                // console.log(this.data)
                function replaceAll(str, find, replace) {
                  return str.replace(new RegExp(find, 'g'), replace);
                }
                this.data = replaceAll(this.data,"False","false");
                this.data = replaceAll(this.data,"#####","'");
                // console.log()
                console.log("from replace all");
                console.log(this.data);
                this.data =  `{"viewerlist":${this.data}}`;
                console.log(this.data);
                localStorage.setItem("letviewer",this.data)
                // let arr = JSON.stringify(this.data)
                // this.data  = JSON.parse('{"viewerlist":[{"viewername": "riya gupta", "email": "riyagupta@tristonsoft.com", "additional_info": "Issuer"}, {"viewername": "sdasdasdasdjasjhfsjdfhgsdjhfgjhsdfgjsgjfhgsdjhfgsdjhfgjsdhfgjhsdgf", "email": "sdfsdf", "additional_info": "sdfsd", "isEditable": false}]}')
                this.data = JSON.parse(this.data);
                console.log(this.data)
                
                
                let i:any;
                for(i=0;i<this.data.viewerlist.length;i++)
                {
                    // this.data.viewerlist[i]['email'] = this.data.viewerlist[i]['email'].replace(" ","+"); 
                    // console.log(this.data.viewerlist[i]['email']);
                    this.dynamicArray.push(this.data.viewerlist[i]);
                    // this.enable1
                    // console.log(this.data.viewerlist[i]);
                    
                }
                  // console.log(this.newDynamic);
                  // this.dynamicArray.push(this.newDynamic);

                // }
                if(this.data.length==0)
                {
                  this.data = null;
                }else{
                  this.data = this.data
                }
                
              }else if(data['returncode']=="5" || data['returncode']==5){
                this.data = null;
              }
              else{
                this.data = null;
              }

          },
          response => {
              console.log("POST call in error", response);
          },
          () => {
              console.log("The POST observable is now completed.");
          });      
    
    

  }

  addRow() {    
    this.olddynamicArray = this.dynamicArray;
    console.log(this.olddynamicArray.length)
    this.newDynamic = {viewername: "", email: "",additional_info:"",isEditable: true};  
      this.dynamicArray.push(this.newDynamic);  
    //   this.toastr.success('New row added successfully', 'New Row');  
      console.log(this.dynamicArray);
      const data = JSON.stringify(this.dynamicArray)
      localStorage.setItem('viewer',data);
      console.log(data);
      // this.enable2
      return true; 
  }
  // getrowval()
  // {
  //   console.log(this.dynamicArray)
  // }  
  deleteRow(index) {  
    if(this.dynamicArray.length ==1) {  
    //   this.toastr.error("Can't delete the row when there is only one row", 'Warning');  
      this.dynamicArray.splice(index, 1); 
      this.btnshow = true;
      this.enable1 = true;
      this.enable2 = true; 
      return true;  
    } else {  
        this.dynamicArray.splice(index, 1);  
        // this.toastr.warning('Row deleted successfully', 'Delete row');  
        this.btnshow = true;
        this.enable1 = true;
        this.enable2 = true; 
        return true;  
    }  

  }
  editDomain(dynamic){
    dynamic.isEditable = !dynamic.isEditable;
    this.showerr6 = false
    this.enable1 = false;
    this.enable2 = false;
  }
  validateEmail(email) {
    //const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = /^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    return re.test(email);
  }

  validateString(str){
    const regularExpression = /"/g
    return regularExpression.test(str);
  }

  checkuniqueemail(email)
  {

    // console.log("calleddd")
    let dataterewr=false;
    for(let i in this.dynamicArray)
    {
      console.log(this.dynamicArray.length)
      
      if(this.dynamicArray[i]['isEditable']!=true && this.dynamicArray[i]['email']==email)
      {
        console.log(this.dynamicArray[i]['email'])
        // if()
        dataterewr = true;
        // return true;
        break;
      }
      // else{
      //   // dataterewr = false;
      //   return false;
      // }
      
    }

    return dataterewr;
  }
  add(dynamic){
    console.log(dynamic);
    this.allResetValidate();
    // dynamic.isEditable= !dynamic.isEditable;
    if(dynamic)
    {
      if(!dynamic.viewername.trim()){
        this.error1 = true;
      }else{
        if(this.validateString(dynamic.viewername.trim())){
          this.error1 = true;
        }else{
          this.error1 = false;
          if(!dynamic.email.trim()){
            this.error2 = true;
            // this.enable1 = false;
          }else{
            if(this.validateEmail(dynamic.email.trim()))
            {
              if(this.checkuniqueemail(dynamic.email.trim()))
              {
                this.error2 = true;
                // console.log("fail")
              }else{
                this.error2 = false;
                if(this.validateString(dynamic.additional_info.trim())){
                  this.error3 = true;
                }else{
                  dynamic.isEditable = !dynamic.isEditable;
                  this.enable1 = true;
                  this.enable2 = true;
                  this.btnshow = true;
                  this.error2 = false;
                  this.error3 = false;
                  // console.log(this.enable2)
                }
                // console.log("passs")
              }
              
            }else{
              this.error2 = true;
            }
          }
        }
      }
      // console.log(dynamic.viewername)
    }
  }

  allResetValidate(){
    this.error1 = true;
    this.error2 = false;
    this.error3 = false;
  }

  dataadd() {
    console.log("from submit view");
    console.log(this.dynamicArray);
    function replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    }
    
    let data_view = JSON.stringify(this.dynamicArray);
    data_view = replaceAll(data_view,"'","#####");
    console.log(this.uniqueid)
    this.userService.addviewerlistforadmin(data_view,this.uniqueid)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if(data['returncode']=='1' || data['returncode']==1)
            {
              this.showmsgsuccess = true;
              this.showerr6 = false;
              this.showerr7 = false;
            }else if(data['returncode']=='6' || data['returncode']==6){
              this.showmsgsuccess = false;
              this.showerr6 = true;
              this.showerr7 = false;

            }else if(data['returncode']=='7' || data['returncode']==7)
            {
              this.showmsgsuccess = false;
              this.showerr6 = false;
              this.showerr7 = true;

              // this.showerr7 = true;
            }
            else if(data['returncode']=='8' || data['returncode']==8)
            {
              this.showmsgsuccess = true;
              this.showerr6 = false;
              this.showerr7 = false;
            }
            else{
              this.showmsg = true;
            }

          });
  }


  changeUser(event) {
    this.tab1 = false
    const searchText = event.target.value;
    // const array = localStorage.getItem("letviewer")
    this.olddynamicArray = this.data.viewerlist
    if(searchText=="" || searchText==" ")
    {
      this.dynamicArray = this.data.viewerlist
      // for(let i=0;i<this.data.viewerlist.length;i++)
      // { 
        // this.dynamicArray.push(this.data.viewerlist[i])    
      // } 
      
    }
    console.log(searchText)

    // this.dynamicArray.splice(0,this.dynamicArray.length)
    return this.dynamicArray.filter(it =>{
    //  console.log(it)
    var index = this.dynamicArray.indexOf(it)
    console.log(index)
      
    if(it['viewername'].toLowerCase().includes(searchText) || it['email'].toLowerCase().includes(searchText) || it['additional_info'].toLowerCase().includes(searchText))
    {
      var index = this.dynamicArray.indexOf(it)
      console.log(index)
      return this.dynamicArray  
      // console.log(it)
      // return this.dynamicArray.filter()
    }else{
      this.dynamicArray.splice(index,1)
      
    }
      // this.dynamicArray 
      // console.log(this.dynamicArray)
      // return this.dynamicArray
      // this.dynamicArray. = it
      // return it
      // console.log(it.viewername)
      // var name = it.viewername.toLowerCase().includes(searchText)
      // if(name){
      //   return it.viewername.toLowerCase().includes(searchText)
      // }
    }
    )
    // console.log(dynamicArray)
    // return dynamicArray
    // const index = this.dynamicArray.indexOf(user);
    // console.log(index)
    // if (index > -1) {
    //   this.dynamicArray.splice(index, 1);
    // } else {
    //   this.dynamicArray.push(user);
    // }

    // this.transform(this., 'name', this.userValue)

    // if (this.userValue.length == 0) {
    //   this.transform(this.usersList, '', this.userValue)
    // }
  }
//end  
}
