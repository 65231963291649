import { Renderer2,Component, OnInit ,Inject} from '@angular/core';
import { UserService, AuthenticationService } from '../_services';
import { User, Organisation } from '../_models';
import { first } from 'rxjs/operators';
import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
// import JwModal from 'jw-react-modal';
import { PublicService } from '../_services';
import { Http, Headers, RequestOptions} from '@angular/http';
import { stringify } from '@angular/compiler/src/util';
import { DOCUMENT } from '@angular/common';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
// import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone'

// import {MatDatepickerModule} from '@angular/material/datepicker';
// import { DatepickerModule} from 'angular-mat-datepicker'
// import {MatDatepickerModule} from '@angular/material/datepicker';

// import {MatDatepickerModule} from '@angular/material/datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap'; 
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
  
@Component({
  selector: 'app-master-admin-registeredvisitors',
  templateUrl: './master-admin-registeredvisitors.component.html',
  styleUrls: ['./master-admin-registeredvisitors.component.css'],
  // scriptUrls: ['']
})
export class MasterAdminRegisteredvisitorsComponent implements OnInit {
  data : any=[];
  history: any=[];
  page = 1;
  pageSize =10;
  closeResult = '';
  searchTerm:string; 
  userFilter: any = { full_name: '', email: ''};
  historyCopy:any=[];
  searchform: FormGroup;
  searchLogform: FormGroup;
  scriptloader=true;
  model: NgbDateStruct;
  loading: boolean = false;
  dots: string = '';
  loadingInterval: any;
  

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private http: Http,
    private modalService: NgbModal,
    private publicService: PublicService,
    private _renderer2: Renderer2,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private _document: Document 
  ) { }

  ngOnInit(): void {
    this.loadingInterval = setInterval(() => this.updateDots(), 600);

    this.searchform = this.formBuilder.group({
      searchBoxdate:[''],
      searchBoxemail:[''],
      searchBoxphoneno:[''],
      searchBoxvisitorname:[''],
      searchBoxhomeaddress:['']

    }) ;

    this.searchLogform = this.formBuilder.group({
      searchBoxdate2:[''],
      searchBoxvisitor:[''],
      searchboxlocation:[''],
      searchboxhomeaddress:[''],
      
    }) ;
    //script call start from here
    
    // let script = this._renderer2.createElement('script');
    // script.type = "text/javascript";
    // script.id = "first"
    // script.src = "assets/js/filterpopup.js";
    // let script2 = this._renderer2.createElement('script');
    // script2.type = "text/javascript";
    // script2.id = "second"
    // script2.src = "https://code.jquery.com/jquery-1.12.4.js";
    
    // let script3 = this._renderer2.createElement('script');
    // script3.type = "text/javascript";
    // script3.id = "third"
    // script3.src = "https://code.jquery.com/ui/1.12.1/jquery-ui.js";

    // //For popup sequence is required for script
    // this._renderer2.appendChild(this._document.body, script2);
    // this._renderer2.appendChild(this._document.body, script3);
    
    // this._renderer2.appendChild(this._document.body, script); 
    


    //end calling scripts

    //Start loading user data
    this.loadAllUsers();

    
  }
  ngOnDestroy(): void {
    clearInterval(this.loadingInterval);
  }
  
  private updateDots(): void {
    if (this.dots.length < 3) {
      this.dots += '.';
    } else {
      this.dots = '';
    }
  }
  public lessThan(subj: any, num: number) {
    console.log(subj);
    console.log(num)
    console.log(subj < num)
    return subj < num;
  }
  public greaterThan(subj: any, num: number) {
    // console.log(subj);
    // console.log(num)
    // console.log(subj < num)
    return subj > num;
  }
  private loadAllUsers() {
    
    
    this.loading = true;
    this.userService.getvisitors()
        .pipe(first())
        .subscribe(
          data => {
              // console.log("POST call successful value returned in body", 
                
              // val);
              console.log(data);
              if(data['returncode']=="1" || data['returncode']==1)
              {
                this.data = data['data']['Visitor List']
                this.data = this.data.sort((a, b) => b.id - a.id);
                console.log(this.data);
                this.loading = false;
                
              }else{
                this.data = null;
                
              }

          },
          response => {
              console.log("POST call in error", response);
          },
          () => {
              console.log("The POST observable is now completed.");
              
          });      
      
    
  }


  
  private callloadata(id:any){
    
  }

  get f() { return this.searchform.controls; }
  open(content,ID) { 
    console.log(ID)

    this.searchLogform.reset();
    // onDateSelect(event) {
    //   let year = event.year;
    //   let month = event.month <= 9 ? '0' + event.month : event.month;;
    //   let day = event.day <= 9 ? '0' + event.day : event.day;;
    //   let finalDate = day + "-" + month + "-" + year;
    //  }  
    // var script1 = document.getElementById("first");
    // var script_2 = document.getElementById("second");
    // var script_3 = document.getElementById("third");
    // script1.remove();
    // script_2.remove();
    // script_3.remove();
    localStorage.setItem('pno',ID);
    this.userService.getmasteradmineventhistory(ID)
        .pipe(first())
        .subscribe(
          data => {
              // console.log("POST call successful value returned in body", 
                
              // val);
              console.log(data);
              if(data['returncode']=="1" || data['returncode']==1)
              {
                // console.
                this.history = data['data']
                // console.log(this.history);
                // console.log()
                this.history.forEach((obj) => {
                  console.log(obj)

                  for (const k in obj) {
                   
                    // console.log(obj["zone"])
                    if (k === "Timestamp") {
                      obj["Timestamp"] = obj[k];
                        
                      // console.log(obj["Timestamp"])
                      // let june = moment(obj["Timestamp"]);
                      console.log(obj["Timestamp"])
                      obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: obj["org_timezone"]});
                      obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                      obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                      console.log(obj["Timestamp"])
                      obj["Timestamp2"] = obj["Timestamp"]
                      obj["Timestamp2"] = this.replaceAll(obj["Timestamp"],'/','-');
                      // console.log(obj["Timestamp2"].split("-")[2])
                      obj["Timestamp2"] = obj["Timestamp2"].split("-")[2].split(" ")[0]+"-"+obj["Timestamp2"].split("-")[1]+"-"+obj["Timestamp2"].split("-")[0]+" "+obj["Timestamp2"].split(" ")[1]+" "+obj["Timestamp2"].split(" ")[2];
                      console.log(obj["Timestamp2"])
                      // delete obj[k]
                      // this.data.push(obj["created_on"])
                    }
                  }
                });
                this.history = this.history.sort((a, b) => <any>new Date(b.Timestamp2) - <any>new Date(a.Timestamp2));
                
              }else{
                this.history = null;
                // console.log(this.history.length)
              }

          },
          response => {
              console.log("POST call in error", response);
          },
          () => {
              console.log("The POST observable is now completed.");
          });      
      
    
    this.modalService.open(content, 
   {ariaLabelledBy: 'checkmodal'}).result.then((result)  => { 
    // this.modal.nativeElement.style.display = 'block';

      this.closeResult = `Closed with: ${result}`; 
    }, (reason) => { 
      this.closeResult =  
         `Dismissed ${this.getDismissReason(reason)}`; 
    });
    this.scriptloader = false;
    // this._renderer2.removeChild(this._document.body, script2);
    
      // let script = this._renderer2.createElement('script');
      // script.type = "text/javascript";
      // script.id = "first"
      // script.src = "assets/js/filterpopup.js";
      // let script2 = this._renderer2.createElement('script');
      // script2.type = "text/javascript";
      // script2.id = "second"
      // script2.src = "https://code.jquery.com/jquery-1.12.4.js";
      
      // let script3 = this._renderer2.createElement('script');
      // script3.type = "text/javascript";
      // script3.id = "third"
      // script3.src = "https://code.jquery.com/ui/1.12.1/jquery-ui.js";
  
      // //For popup sequence is required for script
      // this._renderer2.appendChild(this._document.body, script2);
      // this._renderer2.appendChild(this._document.body, script3);
      
      // this._renderer2.appendChild(this._document.body, script);

     
  } 
  
  private getDismissReason(reason: any): string { 
    if (reason === ModalDismissReasons.ESC) { 
      return 'by pressing ESC'; 
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) { 
      return 'by clicking on a backdrop'; 
    } else { 
      return `with: ${reason}`; 
    } 
  } 

  download(eventHistory){
    this.publicService.downloadFile2(eventHistory, 'eventhistory');
  }
  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
  makeDoubleDigit(x){
    return (x < 10) ? "0" + x : x;
  }
  formateTime(year, month, day, hour, minute, second,am){
    return this.makeDoubleDigit(day) + "/" + 
    this.makeDoubleDigit(month) + "/" + 
    this.makeDoubleDigit(year) + " " + 
    this.makeDoubleDigit(hour) + ":" + 
    this.makeDoubleDigit(minute) + ":" + 
    this.makeDoubleDigit(second) + " " +
    am;
  }
  
  
  replaceTime(string){
    var date = new Date(string);
  
    var year = date.getFullYear();
    var month = (date.getMonth() +1);
    var day = date.getDate();
    
    var hour = date.getHours();
    if(hour > 12)
    {
      hour = hour-12;
    }
    var minute = date.getMinutes();
    var second = date.getSeconds();
    var am = (date.getHours() >= 12 ? "PM" : "AM")
    return this.formateTime(year, month, day, hour, minute, second,am);
  }

  download2(eventHistory){
    console.log("event history")
    console.log(eventHistory);
    // eventHistory = eventHistory
    // eventHistory.forEach((obj) => {
    //   for (const k in obj) {
    //     if (k === "") {
    //       obj["VisitorName"] = obj[k];
    //       // console.log(obj["Profiles"]);
    //       delete obj[k]
    //     }
    //   }});
    let eventHistory45 = eventHistory 
    for(let i in eventHistory45)
    {
      
      // if(eventHistory[i]["Profiles"])
      // {
      //   eventHistory[i]["Profiles"] = this.replaceAll(eventHistory[i]["VisitorName"],',','');  
      // }
      
      let checkstampformat = eventHistory45[i]["Timestamp"].split(" ")[0] 
      console.log(moment(checkstampformat,"dd/MM/YYYY").isValid())
      if(moment(checkstampformat,"dd/MM/YYYY").isValid())
      {
        eventHistory45[i]["Timestamp (local time)"] = eventHistory45[i]["Timestamp"];
        eventHistory45[i]["Timestamp (local time)"] = this.replaceAll(eventHistory45[i]["Timestamp (local time)"],',','');
      }else{
        eventHistory45[i]["Timestamp"] = this.replaceTime(eventHistory45[i]["Timestamp"]);
        eventHistory45[i]["Timestamp (local time)"] = eventHistory45[i]["Timestamp"];
        eventHistory45[i]["Timestamp (local time)"] = this.replaceAll(eventHistory45[i]["Timestamp (local time)"],',','');
      }

        
      eventHistory45[i]["VerifyLink"] = eventHistory45[i]["TransactionLink"];
      eventHistory45[i]["VisitorName"] = eventHistory45[i]["Profiles"];
      eventHistory45[i]["ScannedBy"] = this.replaceAll(eventHistory45[i]["ScannedBy"],',','');
      eventHistory45[i]["HomeAddress"] = this.replaceAll(eventHistory45[i]["HomeAddress"],',','');
      eventHistory45[i]["ScannedLocation"] = this.replaceAll(eventHistory45[i]["ScannedLocation"],',','');
      // eventHistory[i]["OrganisationAddress"] = this.replaceAll(eventHistory[i]["OrganisationAddress"],',','');
    }
    this.publicService.downloadFile2(eventHistory45, 'eventhistory');
  }

  search(searchBoxdate,searchBoxemail,searchBoxphoneno,searchBoxvisitorname,searchBoxhomeaddress) {
    // filter data
    this.page = 1;
    this.pageSize = 10;
    searchBoxemail = searchBoxemail.trim()
    searchBoxphoneno = searchBoxphoneno.trim()
    searchBoxvisitorname = searchBoxvisitorname.trim()
    searchBoxhomeaddress = searchBoxhomeaddress.trim()
    // this.page = 1;
    // this.pageSize = 10;
    if(searchBoxdate=='' && searchBoxemail=='' && searchBoxphoneno=='' && searchBoxvisitorname=='' && searchBoxhomeaddress=='')
    {
      // console.log("this")
      return;
    }
    console.log(searchBoxdate,searchBoxemail,searchBoxphoneno,searchBoxvisitorname,searchBoxhomeaddress);
    
    this.userService.searchEntriesvisitor(searchBoxdate,searchBoxemail,searchBoxphoneno,searchBoxvisitorname,searchBoxhomeaddress)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if(data['returncode']=='1' || data['returncode'] == 1)
            {
              if(this.data!=null){
                this.data.splice(0,this.data.length) 

              }else{
                this.data = []
              }
              let dataoftz= data['data']['Visitor Search']
              
              console.log(dataoftz)
              console.log(dataoftz.length)
              if(dataoftz.length == 0)
              {
                this.data = null
              }else
              {
                dataoftz = dataoftz.sort((a, b) => b.id - a.id);
                if(searchBoxdate=="")
                {
                  console.log(this.data)
                  this.data = dataoftz
                  console.log(dataoftz)
                  console.log(this.data)
                }else{
                  let datewisefilter = []
                  dataoftz.forEach(element => {
                    for (const k in element) {
                      if (k === "created_on") {
                        let l1 = element[k];
                        // let zone = data['org_timezone']
                        // l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                        var l2 = new Date(l1).getDate();
                        if(l2 <= 9)
                        {
                          var l22 = "0"+l2
                        }
                        else{
                          var l22 = stringify(l2)
                        }
                        var l3 = new Date(l1).getMonth() + 1;
                        if(l3 <= 9)
                        {
                          var l33 = "0"+l3
                        }else{
                          var l33 = stringify(l3)
                        }
                        var l4=  new Date(l1).getFullYear()
                        var daaaat = l4+"-"+l33+"-"+l22;
                        
                        if(daaaat===searchBoxdate)
                        {
                            console.log("in if")
                            console.log(element)
                            datewisefilter.push(element)
                            
                        }
                      }
                    }
                  });
                  if(datewisefilter.length < 1 || datewisefilter.length == 0)
                  { 
                    this.data = null
                  }else{
                    this.data = datewisefilter
                  }
                  console.log(this.data)

                }
              


              }
              
              
            }else{
              this.data = null;
              // co
            }
          });

  }

  searchlog(searchBoxdate2,searchBoxvisitor,searchboxlocation) {
    this.pageSize = 10;
    this.page = 1;
    searchBoxvisitor = searchBoxvisitor.trim()
    searchboxlocation = searchboxlocation.trim()
    let phno = localStorage.getItem('pno');
    console.log(searchBoxdate2,searchBoxvisitor,searchboxlocation);
    
    this.userService.searchLoghistory(phno,searchBoxdate2,searchBoxvisitor,searchboxlocation)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if(data['returncode']=='1' || data['returncode'] == 1)
            {
              let eventHistory2 = data['data']['Event Search']
              console.log(eventHistory2)
              if(this.history!=null)
              {
                this.history.splice(0,this.data.length) 

              }else{
                this.history = []
              }
              if(searchBoxdate2!="")
              {
                let datewisefilter2 = [];
                // eventHistory2 = eventHistory2.sort((a, b) => b.id - a.id);
                eventHistory2.forEach(element => {
                  for (const k in element) {
                    if (k === "created_on") {
                      let l1 = element[k];
                      let zone = element['org_timezone']
                      console.log(zone)
                      l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                      var l2 = new Date(l1).getDate();
                      if(l2 <= 9)
                      {
                        var l22 = "0"+l2  
                      }
                      else{
                        var l22 = stringify(l2)
                      }
                      var l3 = new Date(l1).getMonth() + 1;
                      if(l3 <= 9)
                      {
                        var l33 = "0"+l3
                      }else{
                        var l33 = stringify(l3)
                      }
                      var l4=  new Date(l1).getFullYear()
                      var daaaat = l4+"-"+l33+"-"+l22;
                      // console.log(daaaat)
                      // console.log(searchBoxdate)
                      
                      // if(searchBoxdate2 != "")
                      // {
                        if(daaaat===searchBoxdate2)
                        {
                          
                          console.log("in if")
                          console.log(typeof(element))
                          datewisefilter2.push(element)
                        }else{
                          console.log("in else")
                          console.log(element)
                        }
                      // }else{
                      //   this.history.push(element)
                      // }
                    }
                  }
                  this.history = datewisefilter2
                });
                // console.log(this.data);
              }else{
                this.history = eventHistory2
              }
              // let eventHistory2 = this.history;
              console.log(this.history)
              this.history.forEach((obj) => {
                for (const k in obj) {
                  if (k === "name") {
                    obj["Profiles"] = obj[k];
                    console.log(obj["Profiles"]);
                    delete obj[k]
                  }
                  if (k == "email") {
                    obj["Email"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "phone_number") {
                    obj["PhoneNumber"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "address") {
                    obj["HomeAddress"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "timestamp") {
                    obj["Timestamp"] = obj[k];
                    delete obj[k]
                    let june = moment(obj["Timestamp"]);
                    obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: obj["org_timezone"]});
  

                  }
                  if (k == "location") {
                    obj["ScannedLocation"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "device_id") {
                    obj["PhoneUniqueId"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "scanned_by") {
                    obj["ScannedBy"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "qr_verify_link") {
                    obj["VerifyLink"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "shop_name") {
                    obj["OrganisationName"] = obj[k];
                    delete obj[k]
                  }
                  // if (k == "profiles") {
                  //   obj["Profiles"] = obj[k];
                  //   delete obj[k]
                  // }
                  if (k == "qr_guid") {
                    obj["Guid"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "transaction_link") {
                    obj["TransactionLink"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "org_address") {
                    obj["OrganisationAddress"] = obj[k];
                    delete obj[k]
                  }
                }
              })
              if(this.history.length<1)
              {
                this.history=null;
              }
              
            }else{
              this.history = null;
              // co
            }
          });

  }
  getFormattedTimeZone() {
    return new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1].split(' (')[0].replace(/[A-Z]+/,'')
  }
  // formatstr(){
  //   return toString();
  // }
  clear()
  {
    // console.log("this calles")
    this.searchform.reset();
    this.loadAllUsers();   
  }
  clear2()
  {
    // console.log("this calles")
    this.searchLogform.reset();
    let ID = localStorage.getItem('pno');
    console.log(ID);
    this.userService.getmasteradmineventhistory(ID)
        .pipe(first())
        .subscribe(
          data => {
              // console.log("POST call successful value returned in body", 
                
              // val);
              console.log(data);
              if(data['returncode']=="1" || data['returncode']==1)
              {
                // console.
                this.history = data['data']
                console.log(this.history);
                this.history.forEach((obj) => {
                  console.log(obj)

                  for (const k in obj) {
                   
                    // console.log(obj["zone"])
                    if (k === "Timestamp") {
                      obj["Timestamp"] = obj[k];
                        
                      // console.log(obj["Timestamp"])
                      let june = moment(obj["Timestamp"]);
                      obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: obj["org_timezone"]});
    
                      // delete obj[k]
                      // this.data.push(obj["created_on"])
                    }
                  }
                });
                // console.log()
                this.history = this.history.sort((a, b) => <any>new Date(b.Timestamp) - <any>new Date(a.Timestamp));
                
              }else{
                this.history = null;
                // console.log(this.history.length)
              }

          },
          response => {
              console.log("POST call in error", response);
          },
          () => {
              console.log("The POST observable is now completed.");
          });   
  }

  //end
}

