import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, PublicService } from '../../_services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css'],
})
export class ResetpasswordComponent implements OnInit {
  sub: any;
  token: any;
  showErrorMessage = false;
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private publicservice: PublicService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.sub = this.route.queryParams

      // console.log(this.sub);
      .subscribe((params) => {
        this.token = params['token'];
        console.log(this.token);
        this.viewfunc(this.token);
        // console.log()
        // Defaults to 0 if no query param provided.
        // this.page = +params['page'] || 0;
      });
  }
  viewfunc(token) {
    this.publicservice
      .tokenauth(token)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          // console.log(data.returncode);
          if (data['returncode'] == 1 || data['returncode'] == '1') {
            localStorage.setItem('temp_token', token);
            this.router.navigate(['/resetverify']);
            // this.router.navigate(['/emailsent']);
          } else if (data['returncode'] == 4 || data['returncode'] == '4') {
            console.log('saddasd');
          } else {
            this.router.navigate(['/pagenotfound']);
            // this.showErrorMessage = true;
          }

          // }else if(data.returncode == "2" || data.returncode == 2)
          // {
          //     this.showErrorMessage = true;
          // }else{
          //     this.showErrorMessage = true;
          // }

          // console.log(data);
        },
        (error) => {
          // showErrorMessage = true;
          this.alertService.error(error);

          this.router.navigate(['/pagenotfound']);
          // this.loading = false;
        }
      );
  }
  // http://127.0.0.1:8000/api/passwordreset/verify/
}
