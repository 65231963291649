<div class="main-wrapper">
   <app-loader [loadingMessage]="'Loading'" [countTo]="100" *ngIf="loading"></app-loader>
  <div class="wrapper-fix-footer">
    <app-header></app-header>
    <div class="page-body-wrapper">
      <div class="custom-container">
        
        <main class="my-profile-main">
          <section class="my-profile update_details">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 top-heading-sec">
                  <h4>My Profile</h4>
                  <h6>You can manage your personal account information on this page</h6>
                </div>
                <div class="col-lg-3 left-side-content-sec">
                  <div class="side-container">
                    <ul class="nav nav-pills flex-column side-navigation-s" id="experienceTab" role="tablist">
                      <li class="nav-item"><a href="#tabs1" class="nav-link active" id="update-tab" role="tab"
                          data-toggle="tab" aria-controls="update" aria-selected="true">Update My Details</a></li>
                      <li class="nav-item"><a href="#tabs2" class="nav-link" id="account-tab" data-toggle="tab"
                          role="tab" aria-controls="account" aria-selected="false">Account Details</a></li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-9 right-side-content-sec">
                  <div class="inner-section-s account-content">
                    <div class="tab-content" id="experienceTabContent">
                      <div class="tab-pane fade show active my-accout-div" id="tabs1" role="tabpanel"
                        aria-labelledby="update-tab">
                        <div class="">
                          <div class="from-box">
                            <form [formGroup]="accountForm" (ngSubmit)="onSubmit()">
                              <div class="form-box mb-4 mb-xl-0 mb-lg-0 mb-md-4 mb-sm-4">
                                <div class="form-heading">
                                  <h2>Update My Details</h2>
                                </div>
                                <div class="form-body">

                                  <div class="form-group row">
                                    <label for="qrname" class="col-sm-12 col-form-label col-custom-label">Admin
                                      Name</label>
                                    <div class="col-sm-12">
                                      <input type="text" class="form-control" id="qrname" formControlName="fullname"
                                        maxlength="100">
                                      <div *ngIf="submitted1 && f.fullname.errors">
                                        <span class="error-msg">Please enter valid admin name.</span>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div class="form-group row">
                              <label for="qrname" class="col-sm-12 col-form-label col-custom-label">Last Name</label>
                              <div class="col-sm-12">
                                <input type="text" class="form-control" id="qrname"  formControlName="lastname">
                                <div *ngIf="submitted1 && f.lastname.errors">
                                  <span class="error-msg">Please enter valid Last Name.</span>
                                </div>
        
                              </div>
                            </div> -->
                                  <div class="form-group row">
                                    <label for="email" class="col-sm-12 col-form-label col-custom-label">Email</label>
                                    <div class="col-sm-12">
                                      <input type="email" class="form-control" id="email" formControlName="email">
                                      <div *ngIf="submitted1 && f.email.errors">
                                        <span class="error-msg">Please enter valid email.</span>
                                      </div>
                                      <!-- <span class="error-msg">Please enter valid email.</span> -->
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <!-- <div *ngIf="showsuccessmsg">
                                        <div *ngIf="!f.fullname.errors && !f.email.errors">
                                          <span class="green-msg">Account details updated successfully</span>
                                        </div>
                                      </div> -->
                                      <div *ngIf="showErrorMessageacc">
                                        <span class="error-msg">User is already registered with this email.</span>
                                      </div>
                                      <div *ngIf="notadmin">
                                        <span class="error-msg">Invalid User.</span>
                                      </div>
                                      <div *ngIf="invalidemail">
                                        <span class="error-msg">Please enter valid email</span>
                                      </div>
                                      <div *ngIf="updatefailed">
                                        <span class="error-msg">Account update request failed. Please try again.</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="col-sm-12 d-flex mt-4">
                                      <input type="submit" class="gradiant-btn" id="qrname" value="Update">

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div class="from-box mt-4">
                            <form role="form" [formGroup]="changepassform" (ngSubmit)="changepasss()">

                              <div class="form-box mb-xl-5 mb-lg-5 mb-md-4 mb-sm-4 mb-4">
                                <div class="form-heading">
                                  <h2>Change Password</h2>
                                </div>
                                <div class="form-body">
                                  <div class="form-group row">
                                    <label for="old_password" class="col-sm-12 col-form-label col-custom-label">Reset
                                      Password</label>
                                    <div class="col-sm-12">
                                      <input type="password" [type]="fieldTextType ? 'text' : 'password'"
                                        class="form-control" id="old_password" formControlName="old_password"
                                        placeholder="Enter new password">
                                      <i class="toggle-password fa" [ngClass]="{
                                  'fa-eye-slash': !fieldTextType,
                                  'fa-eye': fieldTextType
                                }" (click)="toggleFieldTextType()"></i>
                                      <div *ngIf="submitted && f2.old_password.errors">
                                        <span *ngIf="f2.old_password.errors.required" class="error-msg">Please enter
                                          password.</span>
                                        <span
                                          *ngIf="!f2.old_password.errors.required && changepassform.controls['old_password'].hasError('whitespace')"
                                          class="error-msg">Password cannot contain whitespace.</span>
                                      </div>
                                      <!-- <span class="error-msg">Please enter valid First Name.</span> -->
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label for="new_password" class="col-sm-12 col-form-label col-custom-label">Confirm
                                      Password</label>
                                    <div class="col-sm-12">
                                      <input type="password" [type]="confirmpass ? 'text' : 'password'"
                                        class="form-control" id="new_password" formControlName="new_password"
                                        placeholder="Enter confirm password">
                                      <i class="toggle-password fa" [ngClass]="{
                                'fa-eye-slash': !confirmpass,
                                'fa-eye': confirmpass
                              }" (click)="tohggleconfirmpass()"></i>
                                      <div *ngIf="submitted && f2.new_password.errors">
                                        <div *ngIf="f2.new_password.errors.required">
                                          <span class="error-msg">Please enter valid confirm password.</span>
                                        </div>
                                        <div *ngIf="f2.new_password.errors.mustMatch">
                                          <span class="error-msg">Password and Confirm password must match</span>
                                        </div>
                                      </div>

                                      <!-- <span class="error-msg">Please enter valid First Name.</span> -->
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <div *ngIf="showErrorMessage">
                                        <div *ngIf="!f2.new_password.errors">
                                          <span class="error-msg">Please enter correct password.</span>
                                        </div>

                                      </div>
                                      <div *ngIf="showErrorMessage6">
                                        <div *ngIf="!f2.new_password.errors">
                                          <span class="error-msg">Password and Confirm password must match.</span>
                                        </div>

                                      </div>
                                      <!-- <div *ngIf="success">
                                        <div *ngIf="!f2.new_password.errors">
                                          <span class="green-msg">Password changed successfully</span>
                                        </div>
                                      </div> -->
                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <div class="col-sm-12 d-flex mt-4">
                                      <input type="submit" class="gradiant-btn" id="qrname" value="Save Password">
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade my-accout-div" id="tabs2" role="tabpanel" aria-labelledby="account-tab">
                        <div class="card mb-6">
                          <form id="update_pwd" class="form-horizontal" novalidate="novalidate">
                            <div class="form-heading">
                              <h2>Account Details</h2>
                            </div>
                            <div class="card-body ">
                              <div class="account-detail-actions">
                                <div class="row account-dt-role">
                                  <div class="col-sm-4">
                                    <label>Your Role</label>
                                  </div>
                                  <div class="col-sm-6 ">
                                    <div class="user-role" id="litUserType">{{this.data.role == 2 && "Master Admin"}}
                                    </div>
                                  </div>
                                </div>
                                <div class="row account-dt-act">
                                  <div class="col-sm-4">
                                    <label>Actions Allowed </label>
                                  </div>
                                  <div class="col-sm-6">
                                    <div id="litActions">Create Logbook<br>Manage Users <br> Manage Organisation Details </div>
                                  </div>
                                </div>
                                <!-- <div class="current-admin-wrapper">
                                  Current admins for your organisation
                                </div>
                                <div class="table-responsive">
                                  <table class="myacc-admin-list table table-bordered" id="mytable">
                                    <thead>
                                      <tr>
                                        <th>
                                          User ID
                                        </th>
                                        <th>
                                          First Name
                                        </th>
                                        <th>
                                          Last Name
                                        </th>
                                        <th>
                                          User Email
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>

                                      <tr>
                                        <td data-label="User ID" class="b3">SN-TES1-1</td>
                                        <td data-label="User Name" class="b3">John</td>
                                        <td data-label="User Name" class="b3"></td>
                                        <td data-label="Email" class="b4"><a
                                            style="color: #5e5e5e;text-decoration: none;"
                                            href="mailto:karan@veridocglobal.com">karan@veridocglobal.com</a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td data-label="User ID" class="b3">SN-TES1-7</td>
                                        <td data-label="User Name" class="b3">Guy</td>
                                        <td data-label="User Name" class="b3">Scott</td>
                                        <td data-label="Email" class="b4"><a
                                            style="color: #5e5e5e;text-decoration: none;"
                                            href="mailto:guy.scott@veridocglobal.com">guy.scott@veridocglobal.com</a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td data-label="User ID" class="b3">SN-TES1-8</td>
                                        <td data-label="User Name" class="b3">Mina</td>
                                        <td data-label="User Name" class="b3">Cullen</td>
                                        <td data-label="Email" class="b4"><a
                                            style="color: #5e5e5e;text-decoration: none;"
                                            href="mailto:minacullen80@gmail.com">minacullen80@gmail.com</a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td data-label="User ID" class="b3">SN-TES1-10</td>
                                        <td data-label="User Name" class="b3">test</td>
                                        <td data-label="User Name" class="b3">Admin</td>
                                        <td data-label="Email" class="b4"><a
                                            style="color: #5e5e5e;text-decoration: none;"
                                            href="mailto:testAdmin@testAdmin.co">testAdmin@testAdmin.co</a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div> -->
                              </div>
                            </div>
                          </form>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>