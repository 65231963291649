// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../_services';
import { User, Organisation } from '../_models';
// import { first } from 'rxjs/operators';
import { HttpClient,HttpHeaders,HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AlertService } from '../_services';
import { Http, Headers, RequestOptions} from '@angular/http';
import { stringify } from '@angular/compiler/src/util'
import { MustMatch } from '../_helpers/must-match.validator';

@Component({
  selector: 'app-master-admin-myaccount',
  templateUrl: './master-admin-myaccount.component.html',
  styleUrls: ['./master-admin-myaccount.component.css']
})
export class MasterAdminMyaccountComponent implements OnInit {
  fieldTextType: boolean;
  confirmpass: boolean;
  dots: string = '';
  loadingInterval: any;

  data : any=[];
  accountForm: FormGroup;
  loading:boolean = false;
  submitted = false;
  submitted1 = false;
  returnUrl: string;
  showErrorMessage = false;
  changepassform:FormGroup;
  showsuccessmsg = false;
  notadmin = false;
  updatefailed = false;
  invalidemail = false;
  // showErrorMessage = false;
    showErrorMessage5 = false;
    showErrorMessage6 = false;
    success = false;
    showErrorMessageacc = false;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private http: Http,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private router: Router,
  ) { }

  public noWhitespaceValidator(control: FormGroup) {
    const isSpace = (control.value || '').match(/\s/g);
    return isSpace ? {'whitespace': true} : null;
  }

  ngOnInit(): void {
    this.loadingInterval = setInterval(() => this.updateDots(), 600);

    // this.showsuccessmsg = false
    // this.authenticationService.currentUserValue.data.email
    this.accountForm = this.formBuilder.group({
      fullname: ['', [Validators.required,Validators.maxLength(100)]],
      // lastname: ['', Validators.required],
      email: ['', [Validators.required,Validators.email]]
  });
  console.log(this.accountForm.errors)
  this.changepassform = this.formBuilder.group({
    old_password: ['', [Validators.required,this.noWhitespaceValidator]],
    new_password: ['', [Validators.required]],

  }, {
    validator: MustMatch('old_password', 'new_password')
});
    let email = this.authenticationService.currentUserValue.data.email;
    console.log(email);
    
    this.loadAllUsers();
  }

  ngOnDestroy(): void {
    clearInterval(this.loadingInterval);
  }
  
  private updateDots(): void {
    if (this.dots.length < 3) {
      this.dots += '.';
    } else {
      this.dots = '';
    }
  }
  
  private loadAllUsers() {
    this.loading = true;
    this.userService.getmasteradminaccount()
        .pipe(first())
        .subscribe(
          data => {
              // console.log("POST call successful value returned in body",

              // val);
              console.log(data);
              if(data['returncode']=="1" || data['returncode']==1)
              {
                this.data = data['data']
                this.f.fullname.setValue(this.data.full_name);
                // this.f.lastname.setValue(this.data.lastname);
                this.f.email.setValue(this.data.email);


                console.log(this.data);
              }else{
                this.data = null;
              }

          },
          response => {
              console.log("POST call in error", response);
          },
          () => {
              console.log("The POST observable is now completed.");
              this.loading = false;
          });


  }
  get f() { return this.accountForm.controls; }
  get f2() { return this.changepassform.controls; }
  onSubmit() {

    this.submitted1 = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.accountForm.invalid) {

        return;
    }


    // console.log(this.f.firstname.value);
    this.loading = true;
    this.authenticationService.masteradminaccountupdate(this.f.fullname.value,this.f.email.value)
        .pipe(first())
        .subscribe(
            (data) => {
                console.log(data);
                console.log(data.returncode);
                if(data.returncode == 1 || data.returncode == "1")
                {
                  this.showsuccessmsg = true;
                  Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Account details updated successfully.',
                    timer: 2000, // Show for 2 seconds and automatically close
                    showConfirmButton: false,
                  });
                  this.showErrorMessageacc = false;
                  this.invalidemail = false;
                  this.notadmin = false;                  
                  this.router.navigate(['/master-admin-myaccount']);

                }else if(data.returncode == "2" || data.returncode == 2)
                {
                    this.notadmin = true;
                    
                    this.showsuccessmsg = false;
                    this.showErrorMessageacc = false;
                    this.invalidemail = false;
                    this.updatefailed = false;

                }
                else if(data.returncode == "10" || data.returncode == 10)
                {
                    this.showErrorMessageacc = true;
                    this.notadmin = false;
                    this.showsuccessmsg = false;
                    this.invalidemail = false;
                    this.updatefailed = false;
                }
                else if(data.returncode == "7" || data.returncode == 7)
                {
                    this.invalidemail = true;
                    this.showErrorMessageacc = false;
                    this.notadmin = false;
                    this.showsuccessmsg = false;
                    this.updatefailed = false;
                }

                else{
                    this.updatefailed = true;
                    this.invalidemail = false;
                    this.showErrorMessageacc = false;
                    this.notadmin = false;
                    this.showsuccessmsg = false;
                }

                // console.log(data);

            },
            error => {
                // showErrorMessage = true;
                this.alertService.error(error);
                this.loading = false;
            });
  }
  changepasss(){
    this.submitted = true;


    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.changepassform.invalid) {
      // this.success = false;
        return;
    }
    // if(this.changepassform.)
    this.loading = true;
    this.authenticationService.adminchangepass(this.f2.old_password.value,this.f2.new_password.value)
        .pipe(first())
        .subscribe(
            (data) => {
                console.log(data);
                console.log(data.returncode);
                if(data.returncode == 1 || data.returncode == "1")
                {
                    // if(data.role == "1" || data.role == 1)
                    // {

                    //     this.router.navigate(['/dashboard']);
                    // }
                    this.success = true;
                    Swal.fire({
                      icon: 'success',
                      title: '',
                      text: 'Password changed successfully.',
                      timer: 2000, // Show for 2 seconds and automatically close
                      showConfirmButton: false,
                    });
                    this.showErrorMessage6 = false;
                    this.showErrorMessage5 = false;
                    this.showErrorMessage = false;
                    // this.changepassform.reset(this.changepassform.value);
                    this.router.navigate(['/master-admin-myaccount']);
                    // this.formValues.resetForm();
                    // this.f2.old_password.reset();
                    // this.f2.new_password.reset();

                }else if(data.returncode == "6" || data.returncode == 6)
                {
                    this.showErrorMessage6 = true;
                    this.success = false;
                    // this.showErrorMessage6 = false;
                    this.showErrorMessage5 = false;
                    this.showErrorMessage = false;
                }
                else if(data.returncode == "5" || data.returncode == 5)
                {
                    this.showErrorMessage5 = true;
                    this.showErrorMessage6 = false;
                    this.success = false;
                    this.showErrorMessage = false;
                }else{
                    this.showErrorMessage = true;                    
                    this.showErrorMessage5 = false;
                    this.showErrorMessage6 = false;
                    this.success = false;
                }

                // console.log(data);

            },
            error => {
                // showErrorMessage = true;
                this.alertService.error(error);
                this.loading = false;
            });

  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  tohggleconfirmpass() {
    this.confirmpass = !this.confirmpass;
  }
  //end
}
