<div class="main-wrapper">
    <div class="wrapper-fix-footer">
        <app-header ></app-header>
        
<div class="page-body-wrapper">
    <div class="custom-container">      
      <div class="page-body-content">
         <div class="logbook-page-notfound-div">
            <div class="page-not-found-box">
                <h2>Page not found.</h2>
                <p>The page you requested was not found, and we have a fine guess why.</p>
                <ul>
                  <li>If you typed the URL directly, please make sure the spelling is correct.</li>
                  <li>The page no longer exists. In this case, we profusely apologize for the inconvenience and for any damage this may cause.</li>
                </ul>
            </div>
         </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
        </div>
