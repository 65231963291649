// import { Component, OnInit } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../_services';
import { User, Organisation } from '../_models';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Renderer2,Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Http, Headers, RequestOptions } from '@angular/http';
import { stringify } from '@angular/compiler/src/util'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from "moment";

@Component({
  selector: 'app-master-admin-dashboard',
  templateUrl: './master-admin-dashboard.component.html',
  styleUrls: ['./master-admin-dashboard.component.css']
})
export class MasterAdminDashboardComponent implements OnInit {
  // currentUser: Organisationdata;
  data: any = [];
  page = 1;
  pageSize =10;
  currentUser: User;
  searchform: FormGroup;
  loading: boolean = false;
  dots: string = '';
  loadingInterval: any;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private http: Http,
    private router: Router,
    private _renderer2: Renderer2,
    private formBuilder: FormBuilder,
    @Inject(DOCUMENT) private _document: Document 
  ) {
     this.currentUser = this.authenticationService.currentUserValue;
  }
  // constructor() { }

  ngOnInit() {
    this.loadingInterval = setInterval(() => this.updateDots(), 600);

    console.log(this.currentUser)

    //search form
    this.searchform = this.formBuilder.group({
      searchBoxorganisationid:[''],
      searchBoxdate:[''],
      searchBoxorganisationame:[''],
      searchBoxnetwork:['']
      }) ;
    //end
    // let script = this._renderer2.createElement('script');
    // script.type = "text/javascript";
    // script.src = "assets/js/filterpopup.js";
    // let script2 = this._renderer2.createElement('script');
    // script2.type = "text/javascript";
    // script2.src = "https://code.jquery.com/jquery-1.12.4.js";
    
    // let script3 = this._renderer2.createElement('script');
    // script3.type = "text/javascript";
    // script3.src = "https://code.jquery.com/ui/1.12.1/jquery-ui.js";

    // //For popup sequence is required for script
    // this._renderer2.appendChild(this._document.body, script2);
    // this._renderer2.appendChild(this._document.body, script3);
    
    // this._renderer2.appendChild(this._document.body, script);

    this.loadAllUsers();
  }
  ngOnDestroy(): void {
    clearInterval(this.loadingInterval);
  }
  
  private updateDots(): void {
    if (this.dots.length < 3) {
      this.dots += '.';
    } else {
      this.dots = '';
    }
  }
  goToPage(uniqueid) {
    this.router.navigate(['/master-admin-qrcodelist'], { queryParams: { uniqueid: uniqueid } });
  }

  onEditOrganization(data) {
    this.router.navigate(['/edit-organisation'], { queryParams: { data: data } });
  }
  public lessThan(subj: any, num: number) {
    
    subj = Number(subj)
    return subj <= num;
  }
  public greaterThan(subj: any, num: number) {
    // console.log(subj);
    // console.log(num)
    // console.log(subj > num)
    return subj > num;
  }
  replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }
  makeDoubleDigit(x){
    return (x < 10) ? "0" + x : x;
  }
  formateTime(year, month, day, hour, minute, second,am){
    return this.makeDoubleDigit(day) + "/" + 
    this.makeDoubleDigit(month) + "/" + 
    this.makeDoubleDigit(year) + " " + 
    this.makeDoubleDigit(hour) + ":" + 
    this.makeDoubleDigit(minute) + ":" + 
    this.makeDoubleDigit(second) + " " +
    am;
  }
  
  
  replaceTime(string){
    var date = new Date(string);
  
    var year = date.getFullYear();
    var month = (date.getMonth() +1);
    var day = date.getDate();
    
    var hour = date.getHours();
    if(hour > 12)
    {
      hour = hour-12;
    }
    var minute = date.getMinutes();
    var second = date.getSeconds();
    var am = (date.getHours() >= 12 ? "PM" : "AM")
    return this.formateTime(year, month, day, hour, minute, second,am);
  }

  private loadAllUsers() {
    this.loading = true;
    this.userService.getadmindashboard()
      .pipe(first())
      .subscribe(
        data => {
          // console.log("POST call successful value returned in body", 

          // val);
          //console.log(data);
          if (data['returncode'] == "1" || data['returncode'] == 1) {
            this.data = data['data']['Orginazation List']
            
            this.data.forEach((obj) => {
              for (const k in obj) {
                // let zone;
                // if(k === "org_timezone")
                // {
                //   zone = obj[k]
                // }
                // console.log(zone)
                if (k === "created_on") {
                  obj["created_on"] = obj[k];
                  //console.log(obj["created_on"])
                  // console.log(obj["org_timezone"])
                  // let june = moment(obj["created_on"]);
                  obj["created_on"] = new Date(obj["created_on"]).toLocaleString("en-US", {timeZone: obj["org_timezone"]});
                  obj["Timestamp"] = this.replaceTime(obj["created_on"])
                  obj["Timestamp2"] = this.replaceAll(obj["Timestamp"],'/','-');
                      // console.log(obj["Timestamp2"].split("-")[2])
                  obj["Timestamp2"] = obj["Timestamp2"].split("-")[2].split(" ")[0]+"-"+obj["Timestamp2"].split("-")[1]+"-"+obj["Timestamp2"].split("-")[0]+" "+obj["Timestamp2"].split(" ")[1]+" "+obj["Timestamp2"].split(" ")[2];
                  obj["Timestamp2"] = obj["Timestamp2"].split(",")[0]
                  obj["Timestamp2"] = obj["Timestamp2"].split(" ")[0]
                  //console.log(obj["Timestamp2"])
                  // console.log(obj["created_on"])
                  // delete obj[k]
                  // this.data.push(obj["created_on"])
                }
              }
            });
            this.loading = false;
            // console.log(this.data);
            //this.data = this.data.sort((a, b) => <any>new Date(b.Timestamp2) - <any>new Date(a.Timestamp2));
            this.data = this.data.sort((a, b) => <any>new Date(b.id) - <any>new Date(a.id));
          } else {
            this.data = null;
            
          }

        },
        response => {
          console.log("POST call in error", response);
        },
        () => {
          console.log("The POST observable is now completed.");
          
        });


  }
  private switchfunc(e, uniqueid,i) {
    if (e.target.checked) {
      console.log(uniqueid);
      let check = "1"
      this.userService.organisationstatus(uniqueid, check)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data['returncode'] == "1" || data['returncode'] == 1) {
              // console.log(i);
              // this.router.navigate(['/']);
              // this.data[i].status = true
              this.loadAllUsers()
            } else {
              this.loadAllUsers()
            }
          });
    } else {
      let check = "0"
      console.log(uniqueid);
      this.userService.organisationstatus(uniqueid, check)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if (data['returncode'] == "1" || data['returncode'] == 1) {
              // this.router.navigate(['/']);
              // console.log(i);
              // this.data[i].status = false;
              this.loadAllUsers()
            } else {
              this.loadAllUsers()
            }
          });
    }

  }

  search(searchBoxdate,searchBoxorganisationid,searchBoxorganisationame,searchBoxnetwork) {
    // var e = document.getElementById(searchBoxnetwork);
    // console.log(this.searchform.value)
    this.page = 1;
    this.pageSize =10;
    searchBoxorganisationid = searchBoxorganisationid.trim()
    searchBoxorganisationame = searchBoxorganisationame.trim()

    // console.log(searchBoxdate,searchBoxorganisationid,searchBoxorganisationame,searchBoxnetwork)
    var selectElement =  document.querySelector('#searchBoxnetwork'); 
    // console.log(selectElement);
    // var selValue = e.options[e.selectedIndex].text ;
    if(searchBoxdate=='' && searchBoxorganisationid=='' && searchBoxorganisationame=='' && searchBoxnetwork=='')
    {
      return;
    }
    // console.log(searchBoxdate,searchBoxorganisationid,searchBoxorganisationame,searchBoxnetwork);
    
    
    // console.log(searchBoxnetwork);
    this.userService.searchEntriesorg(searchBoxdate,searchBoxorganisationid,searchBoxorganisationame,searchBoxnetwork)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            if(data['returncode']=='1' || data['returncode'] == 1)
            {
              let orgdata = data['data']['Org search']
              console.log(orgdata)
              if(this.data!=null){
                this.data.splice(0,this.data.length) 

              }else{
                this.data = []
              }
              if(orgdata.length == 0)
              {
                this.data=null
              }else{
                  if(searchBoxdate=="")
                  {
                    console.log(this.data);
                    this.data = orgdata
                    console.log(this.data)
                  }else{
                    let datewisefilter = []
                    orgdata.forEach((element,index) => {
                      for (const k in element) {
                        if (k === "created_on") {
                          let l1 = element[k];
                          let zone = orgdata[index]['org_timezone']
                          console.log(zone)
                          l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                          var l2 = new Date(l1).getDate();
                          if(l2 <= 9)
                          {
                            var l22 = "0"+l2
                          }
                          else{
                            var l22 = stringify(l2)
                          }
                          var l3 = new Date(l1).getMonth() + 1;
                          if(l3 <= 9)
                          {
                            var l33 = "0"+l3
                          }else{
                            var l33 = stringify(l3)
                          }
                          var l4=  new Date(l1).getFullYear()
                          var daaaat = l4+"-"+l33+"-"+l22;
                          
                          if(daaaat===searchBoxdate)
                          {
                              console.log("in if")
                              console.log(element)
                              // element["created_on"] = new Date(element["created_on"]).toLocaleString("en-US", {timeZone: zone});
                         
                              datewisefilter.push(element)
                              
                          }
                        }
                      }
                    });
                    //for end
                  if(datewisefilter.length < 1 || datewisefilter.length == 0)
                  { 
                    this.data = null
                  }else{
                    this.data = datewisefilter
                    this.data.forEach((obj,index) => {
                      for (const k in obj) {
                        if (k === "created_on") {
                          obj["created_on"] = obj[k];
                          obj["created_on"] = new Date(obj["created_on"]).toLocaleString("en-US", {timeZone: this.data[index]['org_timezone']});
                          // delete obj[k]
                        }
                      }
                    });
                    console.log(this.data)
                  }
                  console.log(this.data)
                }
              }
              
              
              // console.log(this.data);

            }else{
              this.data = null;
              // co
            }
          });

  }

  clear()
  {
    console.log("this calles")
    this.searchform.reset();
    this.loadAllUsers()   
  }

  getFormattedTimeZone() {
    return new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1].split(' (')[0].replace(/[A-Z]+/,'')
  }
  //end
}
