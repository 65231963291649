<div class="main-wrapper">
  <app-loader [loadingMessage]="'Loading'" [countTo]="100" [duration]="5000" *ngIf="loading"></app-loader>
  <div class="wrapper-fix-footer">
    <app-header></app-header>
    <div class="page-body-wrapper">
      <div>
        <main class="my-profile-main">
          <div class="custom-container">
            <div class="back-drop"></div>

            <section class="my-profile">
              <div class="sidebar-parent">
                <div class="toggle-icon d-none d-lg-block">
                  <img src="/assets/img/toggle.svg" alt="" srcset="">

                </div>
                <div class="sidebar-main active">
                  <div class="sidebar left-side-content-sec">
                    <div class="side-container">
                      <ul class="nav nav-pills flex-column side-navigation-s" id="experienceTab" role="tablist">
                        <li class="nav-item"><a href="#tabs1" class="nav-link active" id="update-tab" role="tab"
                            data-toggle="tab" aria-controls="update" aria-selected="true">Update My Details</a></li>
                        <li class="nav-item"><a href="#tabs2" class="nav-link" id="account-tab" data-toggle="tab"
                            role="tab" aria-controls="account" aria-selected="false">Account Details</a></li>
                        <li class="nav-item"><a href="#tabs3" class="nav-link" id="membership-tab" data-toggle="tab"
                            role="tab" aria-controls="membership" aria-selected="false">Membership & Billings</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="right-side-content-sec">
                <div class="inner-section-s account-content">
                  <div class="tab-content" id="experienceTabContent">
                    <div class="d-flex align-items-center pb-4 py-lg-0">
                      <div class="arrow d-flex d-lg-none toggle-icon">
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                      </div>
                      <p class="para ml-3 ms-lg-0 mb-0 d-block d-lg-none">You can manage your profile information on
                        this page</p>
                    </div>
                    <div class="tab-pane fade show active my-accout-div" id="tabs1" role="tabpanel"
                      aria-labelledby="update-tab">
                      <div class="">
                        <h6 class="d-none d-lg-block">You can manage your personal account information on this page</h6>
                        <div class="from-box">
                          <form role="form" [formGroup]="adminaccountForm" (ngSubmit)="onSubmit()">
                            <div class="form-box mb-4 mb-xl-0 mb-lg-0 mb-md-4 mb-sm-4">
                              <div class="form-heading">
                                <h2>Update My Details</h2>
                              </div>
                              <div class="form-body">
                                <div class="form-group row">
                                  <label for="qrname" class="col-sm-12 col-form-label col-custom-label">Admin
                                    Name</label>
                                  <div class="col-sm-12">
                                    <input type="text" class="form-control" id="qrname" formControlName="full_name"
                                      maxlength="100" />
                                    <div *ngIf="submitted1 && f.full_name.errors">
                                      <span class="error-msg">Please enter valid Admin Name.</span>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="form-group row">
                                  <label for="qrname" class="col-sm-12 col-form-label col-custom-label">Last Name</label>
                                  <div class="col-sm-12">
                                    <input type="text" class="form-control" id="qrname" value="{{ data.fullname }}"> -->
                                <!-- <span class="error-msg">Please enter valid Last Name.</span> -->
                                <!-- </div> -->
                                <!-- </div> -->
                                <div class="form-group row">
                                  <label for="email" class="col-sm-12 col-form-label col-custom-label">Email</label>
                                  <div class="col-sm-12">
                                    <input type="email" class="form-control" id="email" formControlName="email" />
                                    <div *ngIf="submitted1 && f.email.errors">
                                      <span class="error-msg">Please enter valid email</span>
                                    </div>
                                    <!-- <span class="error-msg">Please enter valid email.</span> -->
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label for="orgname" class="col-sm-12 col-form-label col-custom-label">Organisation
                                    Name</label>
                                  <div class="col-sm-12">
                                    <input type="text" class="form-control" id="orgname" readonly="readonly"
                                      value="{{ data.org_name }}" />
                                    <!-- <span class="error-msg">Please enter valid Organisation Name.</span> -->
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label for="orgAdd" class="col-sm-12 col-form-label col-custom-label">Organisation
                                    Address</label>
                                  <div class="col-sm-12">
                                    <input type="text" class="form-control" id="orgAdd" maxlength="500"
                                      formControlName="home_address" />
                                    <div *ngIf="submitted1 && f.home_address.errors">
                                      <span class="error-msg">Please enter valid Organisation Address.</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label for="timezone" class="col-sm-12 col-form-label col-custom-label">Time
                                    Zone</label>
                                  <div class="col-sm-12">
                                    <input type="text" class="form-control" id="timezone" readonly="readonly"
                                      value="{{ data.org_timezone }}" />
                                    <!-- <span class="error-msg">Please enter valid Organisation Name.</span> -->
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label for="accStat" class="col-sm-12 col-form-label col-custom-label">Account
                                    Status</label>
                                  <div class="col-sm-12">
                                    <input type="text" class="form-control" id="accStat" readonly="readonly"
                                      value="{{ data.org_network }}" />
                                    <!-- <span class="error-msg">Please enter valid Organisation Name.</span> -->
                                  </div>
                                </div>
                                <!-- <div class="form-group row">
                                  <label for="qrname" class="col-sm-12 col-form-label col-custom-label">Position Title</label>
                                  <div class="col-sm-12">
                                    <input type="text" class="form-control" id="qrname" value="None"> -->
                                <!-- <span class="error-msg">Please enter valid Organisation Name.</span> -->
                                <!-- </div> -->
                                <!-- </div> -->
                                <div class="row">
                                  <div class="col-xl-4 col-lg-12 col-md-4">
                                    <div class="form-group row">
                                      <label for="PhoneNumber" class="col-sm-12 col-form-label col-custom-label">Phone
                                        Number</label>
                                      <div class="col-sm-12">
                                        <select [(ngModel)]="selectedLevel" formControlName="country_code"
                                          class="form-control custom-select country-select" id="PhoneNumber"
                                          (change)="selected()">
                                          <option *ngFor="let item of selectitems" [ngValue]="item">
                                            {{ item.name }}
                                          </option>
                                        </select>
                                        <div *ngIf="submitted1 && f.country_code.errors">
                                          <span class="error-msg">Please select Country code</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-xl-8 col-lg-12 col-md-8">
                                    <div class="form-group row">
                                      <label for=""
                                        class="col-sm-12 col-form-label col-custom-label visiblity-hidden mb-none">Phone
                                        Number</label>
                                      <div class="col-sm-12">
                                        <input type="tel" maxlength="15" minlength="6" class="form-control"
                                          id="PhoneNumber" ngModel="{{ data.phone_number }}"
                                          formControlName="phone_number" />
                                        <span class="info-msg">Please enter the phone number without plus sign ("+")
                                          and country code</span>
                                        <div *ngIf="submitted1 && f.phone_number.errors">
                                          <span class="error-msg">Please enter valid Phone Number.</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- <div class="row">
                        <div class="col-sm-12">
                          <div *ngIf="successmsg">
                            <div *ngIf="
                                            !f.full_name.errors &&
                                            !f.email.errors &&
                                            !f.phone_number.errors &&
                                            !f.country_code.errors &&
                                            !f.home_address.errors
                                          ">
                              <span class="green-msg">Account details updated successfully</span>
                            </div>
                          </div>
                          <div *ngIf="showErrorMessageacc3">
                            <span class="error-msg">Invalid user</span>
                          </div>
                          <div *ngIf="showErrorMessageacc4">
                            <span class="error-msg">User is not active.please activate your
                              account.</span>
                          </div>
                          <div *ngIf="showErrorMessageacc5">
                            <span class="error-msg">Invalid user</span>
                          </div>
                          <div *ngIf="showErrorMessageacc">
                            <span class="error-msg">Account update request failed. Please try
                              again.</span>
                          </div>
                          <div *ngIf="showErrorMessageacc8">
                            <span class="error-msg">Please enter valid email address.</span>
                          </div>
                          <div *ngIf="showErrorMessageacc10">
                            <span class="error-msg">User is already registered with this email.</span>
                          </div>
                        </div>
                      </div> -->
                                <div class="row" style="margin-bottom: 20px;">
                                  <div class="col-lg-12">
                                    <div class="upload-profile-custom my-account-upload-profile">

                                      <div class="mb-profile-img-div mr-lg-3 mr-md-3">
                                        <span class="profile-photo-title">Profile Photo</span>
                                        <img
                                          *ngIf="!imagePreview && (data.profile_picture != '' && data.profile_picture != 'null' && data.profile_picture != null)"
                                          id="ImgPreview" src="{{ data.profile_picture}}" class="preview1"
                                          alt="Upload Img">
                                        <img
                                          *ngIf="!imagePreview && (data.profile_picture == '' || data.profile_picture == 'null' || data.profile_picture == null) "
                                          id="ImgPreview"
                                          src="https://veridochrlive.blob.core.windows.net/hrprofile/default.jpg"
                                          class="preview1" alt="Upload Img">
                                        <img *ngIf="imagePreview" id="ImgPreview" [src]="imagePreview" class="preview1"
                                          alt="Upload Img">
                                        <!-- <img id="ImgPreview" src="{{ data.profilepicture}}" class="preview1" alt="Upload Img"> -->
                                      </div>
                                      <div class="mb-profile-right">
                                        <!-- <span  class="error-msg">Please select file size upto 700 KB</span>
                        <span class="error-msg">Error: Please select valid image</span> -->
                                        <span class="profile-max-size">
                                          JPG, PNG, or GIF<br>
                                          (Max file size 2MB)
                                        </span>
                                        <div class="mb-btn-upload-remove">
                                          <div style="display: flex; justify-content: center; align-items: center;">
                                            <span class="btn_upload">
                                              <input type="file" accept="image/*" (change)="onFileSelected($event)"
                                                id="imag" title="" class="input-img">
                                              Change Photo
                                            </span>

                                            <button *ngIf="selectedFile" type="button" id="uploadimg"
                                              class="btn btn-vdg btn-circle" (click)="uploadFile()"><i
                                                class="bi bi-cloud-arrow-up"></i>
                                            </button>
                                            <button *ngIf="profilepicshow == 1" type="button" id="removeImage1"
                                              class="btn btn-vdg btn-circle" (click)="removeProfilePic()"><i
                                                class="fa fa-trash-o"></i></button>
                                          </div>
                                          <!-- <input  type="button"
                                  (click)="removeProfilePic()" id="removeImage1" value="Delete Photo"
                                  class="btn-rmv rmv"> -->
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row mb-0 billing">
                                  <label for="" class="col-md-3 col-form-label col-custom-label">Are your billing
                                    details the same as your
                                    sign up details?</label>

                                  <div class="col-md-9 select">
                                    <div class="check-box-group">
                                      <div class="custom-control custom-checkbox mr-3">
                                        <input [checked]="isBillingOn === true" (change)="selectBilling(true)"
                                          type="radio" class="custom-control-input" id="isBillingSameYes"
                                          formControlName="isBillingSame" value="true" name="isBillingSame">
                                        <label class="custom-control-label" for="isBillingSameYes">Yes</label>
                                      </div>
                                      <div class="custom-control custom-checkbox">
                                        <input [checked]="isBillingOn === false" (change)="selectBilling(false)"
                                          type="radio" class="custom-control-input" id="isBillingSameNo"
                                          formControlName="isBillingSame" value="false" name="isBillingSame">
                                        <label class="custom-control-label" for="isBillingSameNo">No</label>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div *ngIf="!isBillingOn">
                                  <div class="form-group row">
                                    <label for="company_name" class="col-md-3 col-form-label col-custom-label">Company
                                      Name</label>
                                    <div class="col-md-9">
                                      <input type="text" class="form-control" id="company_name"
                                        placeholder="Company Name" formControlName="billing_company">
                                      <div *ngIf="submitted && f.billing_company.errors"><span class="error-msg">Please
                                          enter your Company
                                          Name</span></div>

                                    </div>
                                  </div>
                                  <div class="form-group row">
                                    <label class="col-md-3 col-form-label col-custom-label">Address</label>
                                    <div class="col-md-9">
                                      <input type="text" class="form-control" style="margin-bottom: 1rem;" id="address"
                                        formControlName="billing_address" placeholder="Address Line 1">
                                      <div *ngIf="submitted && f.billing_address.errors"><span class="error-msg">Please
                                          enter your Company
                                          Address</span></div>
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-md-6" style="padding-left: 0px !important;">
                                            <input type="text" class="form-control" style="margin-bottom: 1rem;"
                                              id="city" formControlName="billing_city" placeholder="City">
                                            <div *ngIf="submitted && f.billing_city.errors"><span
                                                class="error-msg">Please enter
                                                City</span></div>

                                          </div>
                                          <div class="col-md-6" style="padding-right: 0px !important;">
                                            <input type="text" class="form-control" style="margin-bottom: 1rem;"
                                              id="state" formControlName="billing_state" placeholder="State">
                                            <div *ngIf="submitted && f.billing_state.errors"><span
                                                class="error-msg">Please enter
                                                State</span></div>

                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-12">
                                        <div class="row">
                                          <div class="col-md-6" style="padding-left: 0px !important;">
                                            <input type="text" class="form-control" style="margin-bottom: 1rem;"
                                              id="country" formControlName="billing_country" placeholder="Country">
                                            <div *ngIf="submitted && f.billing_country.errors"><span
                                                class="error-msg">Please enter
                                                Country</span></div>

                                          </div>
                                          <div class="col-md-6" style="padding-right: 0px !important;">
                                            <input type="text" class="form-control" style="margin-bottom: 1rem;"
                                              id="postcode" formControlName="billing_zip" placeholder="Postcode">
                                            <div *ngIf="submitted && f.billing_zip.errors"><span
                                                class="error-msg">Please enter
                                                Postcode</span></div>

                                          </div>
                                        </div>
                                      </div>
                                      <!-- <div class="col-md-3 mb-sm-3 mb-3">
                                <select [(ngModel)]="selectedLevel" name="selectedLevel" placeholder="select country code"
                                  formControlName="country_code" class="form-control custom-select country-select"
                                  (change)="selected()">
                                  
                                  <option *ngFor="let country of countries" [selected]="country.name == 'Select country'">
                                    {{country.name}}</option>
                                 
                                </select>
                                
          
                              </div> -->



                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-sm-12 d-flex mt-4">
                                    <input type="submit" class="gradiant-btn" id="qrname" value="Update" />
                                  </div>
                                </div>

                              </div>
                            </div>
                          </form>
                        </div>
                        <div class="from-box mt-4">
                          <form role="form" [formGroup]="changepassform" (ngSubmit)="changepasss()"
                            #changepass="ngForm">
                            <!-- <div *ngIf="f2.errors.MustMatch">
                          <span class="error-msg" >Password doesn't match.</span>
                        </div> -->

                            <div class="form-box mb-4">
                              <div class="form-heading">
                                <h2>Change Password</h2>
                              </div>
                              <div class="form-body">
                                <div class="form-group row">
                                  <label for="pass" class="col-sm-12 col-form-label col-custom-label">Reset
                                    Password</label>
                                  <div class="col-sm-12">

                                    <div class="tow">
                                      <input type="password" [type]="fieldTextType ? 'text' : 'password'"
                                        class="form-control active" id="pass" formControlName="old_password"
                                        placeholder="Enter new password" />

                                      <!-- <i id="icon" class="toggle-password fa fa-eye-slash"></i> -->
                                      <i class="toggle-password fa" [ngClass]="{
                                'fa-eye-slash': !fieldTextType,
                                'fa-eye': fieldTextType
                              }" (click)="toggleFieldTextType()"></i>


                                    </div>

                                    <div *ngIf="submitted && f2.old_password.errors">
                                      <span *ngIf="f2.old_password.errors.required" class="error-msg">Please enter
                                        password.</span>
                                      <span *ngIf="
                                          !f2.old_password.errors.required &&
                                          changepassform.controls[
                                            'old_password'
                                          ].hasError('whitespace')
                                        " class="error-msg">Password cannot contain whitespace</span>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label for="confPass" class="col-sm-12 col-form-label col-custom-label">Confirm
                                    Password</label>
                                  <div class="col-sm-12">
                                    <input type="password" [type]="confirmpass ? 'text' : 'password'"
                                      class="form-control" id="confPass" formControlName="new_password"
                                      placeholder="Enter confirm password" />
                                    <i class="toggle-password fa" [ngClass]="{
                              'fa-eye-slash': !confirmpass,
                              'fa-eye': confirmpass
                            }" (click)="tohggleconfirmpass()"></i>
                                    <div *ngIf="submitted && f2.new_password.errors">
                                      <div *ngIf="f2.new_password.errors.required">
                                        <span class="error-msg">Please enter valid confirm password.</span>
                                      </div>
                                      <div *ngIf="f2.new_password.errors.mustMatch">
                                        <span class="error-msg">Password does not match</span>
                                      </div>
                                      <!-- <div *ngIf="f2.new_password.errors.required">
                                    <span class="error-msg" >Passwords must match</span>
                                  </div> -->
                                    </div>

                                    <!-- <div *ngIf="showErrorMessage5">
                                  <span class="error-msg" >Please enter new Password.</span>
                                  </div> -->
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-sm-12">
                                    <div *ngIf="showErrorMessage">
                                      <span class="error-msg">Please enter correct password.</span>
                                    </div>
                                    <div *ngIf="showErrorMessage6">
                                      <span class="error-msg">Password and Confirm password must match.</span>
                                    </div>
                                    <div *ngIf="success">
                                      <div *ngIf="f2.new_password.errors == null">
                                        <span class="green-msg">Password changed successfully</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <div class="col-sm-12 d-flex mt-4">
                                    <input type="submit" class="gradiant-btn" id="qrname" value="Save Password" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>

                      </div>
                    </div>
                    <div class="tab-pane fade my-accout-div" id="tabs2" role="tabpanel" aria-labelledby="account-tab">
                      <div class="card mb-6">
                        <form id="update_pwd" class="form-horizontal" novalidate="novalidate">
                          <div class="form-heading">
                            <h2>Account Details</h2>
                          </div>
                          <div class="card-body ">
                            <div class="account-detail-actions">
                              <div class="row account-dt-role">
                                <div class="col-sm-4">
                                  <label>Your Role</label>
                                </div>
                                <div class="col-sm-6 ">
                                  <div class="user-role" id="litUserType">{{this.data.role == 1 && "Admin"}}</div>
                                </div>
                              </div>
                              <div class="row account-dt-act">
                                <div class="col-sm-4">
                                  <label>Actions Allowed </label>
                                </div>
                                <div class="col-sm-6">
                                  <div id="litActions">Create Logbook<br>Create QR<br>Manage Membership &amp;
                                    Billing<br>Manage Organisation Details</div>
                                </div>
                              </div>
                              <!-- <div class="current-admin-wrapper">
                      Current admins for your organisation
                    </div>
                    <div class="table-responsive">
                      <table class="myacc-admin-list table table-bordered" id="mytable">
                        <thead>
                          <tr>
                            <th>
                              User ID
                            </th>
                            <th>
                              First Name
                            </th>
                            <th>
                              Last Name
                            </th>
                            <th>
                              User Email
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr>
                            <td data-label="User ID" class="b3">SN-TES1-1</td>
                            <td data-label="User Name" class="b3">John</td>
                            <td data-label="User Name" class="b3"></td>
                            <td data-label="Email" class="b4"><a
                                style="color: #5e5e5e;text-decoration: none;"
                                href="mailto:karan@veridocglobal.com">karan@veridocglobal.com</a>
                            </td>
                          </tr>
                          <tr>
                            <td data-label="User ID" class="b3">SN-TES1-7</td>
                            <td data-label="User Name" class="b3">Guy</td>
                            <td data-label="User Name" class="b3">Scott</td>
                            <td data-label="Email" class="b4"><a
                                style="color: #5e5e5e;text-decoration: none;"
                                href="mailto:guy.scott@veridocglobal.com">guy.scott@veridocglobal.com</a>
                            </td>
                          </tr>
                          <tr>
                            <td data-label="User ID" class="b3">SN-TES1-8</td>
                            <td data-label="User Name" class="b3">Mina</td>
                            <td data-label="User Name" class="b3">Cullen</td>
                            <td data-label="Email" class="b4"><a
                                style="color: #5e5e5e;text-decoration: none;"
                                href="mailto:minacullen80@gmail.com">minacullen80@gmail.com</a>
                            </td>
                          </tr>
                          <tr>
                            <td data-label="User ID" class="b3">SN-TES1-10</td>
                            <td data-label="User Name" class="b3">test</td>
                            <td data-label="User Name" class="b3">Admin</td>
                            <td data-label="Email" class="b4"><a
                                style="color: #5e5e5e;text-decoration: none;"
                                href="mailto:testAdmin@testAdmin.co">testAdmin@testAdmin.co</a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div> -->
                            </div>
                          </div>
                        </form>
                      </div>

                    </div>
                    <div class="tab-pane fade my-accout-div" id="tabs3" role="tabpanel"
                      aria-labelledby="membership-tab">
                      <div class="card mb-4">
                        <form id="update_pwd" class="form-horizontal" novalidate="novalidate">
                          <div class="form-heading">
                            <div class="membership-dt" *ngIf="activePlan">Membership Since
                              {{formatdate(this.activePlan.registerDate)}}</div>
                            <h2>Membership & Billing</h2>
                          </div>
                          <div class="card-body">
                            <div class="subscription-details">
                              <div id="panPaymentDeclined" class="billing-row-wrapper" style="display: none;">
                                <div class="row">
                                  <div class="col-md-4 col-12 document-count-section">
                                    <label style="color: red !important">Action Required</label>
                                  </div>
                                  <div class="col-md-6 col-12 document-count-section" style="color: red !important">
                                    <div class="subHeading" style="color: red !important">Your last payment
                                      didn’t
                                      go through. </div>
                                    <div>To avoid plan cancellation and losing your rolled over Logbook
                                      balance,
                                      please pay within the next 10 days</div>
                                  </div>
                                  <div class="col-md-2 col-12 current-plan-section">
                                    <a target="_blank" id="lnkPaynow" href="#">Pay Now</a>
                                  </div>
                                </div>
                              </div>
                              <div class="billing-row-wrapper">
                                <div class="row">
                                  <div class="col-md-4 col-12 document-count-section">
                                    <label>Current Billing Cycle</label>

                                  </div>
                                  <div class="col-md-8 col-12">
                                    <div class="row">
                                      <div class="col-md-6 col-12 document-count-section">
                                        <div class="subHeading">Number of allowed scans Remaining</div>
                                        <div class="document-count envelope-balance" id="user_balance">
                                          {{this.remainingScan}}

                                        </div>
                                      </div>
                                      <div class="col-md-6 col-12 current-plan-section text-right">
                                        <div class="subHeading ">Billing Period</div>
                                        <div class="billing-date" *ngIf="activePlan">{{(activePlan.billPeriod === ''
                                          && activePlan.status === 'ACTIVE' && 'Free') || (activePlan.status ===
                                          'INACTIVE' && 'Inactive') || (formatdate(this.activePlan.registerDate) + '
                                          to ' + formatdate(this.activePlan.expiryDate))}}
                                        </div>
                                        <!-- <p>
                            {{ formatServicePeriod(servicePeriod) }}
                              </p> -->
                                      </div>
                                      <div class="col-md-12 error-notification-msg" id="envelopePlanLimit"
                                        style="display:none">
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="billing-row-wrapper">
                                <div class="row">
                                  <div class="col-md-4 col-12 ">
                                    <label>Plan Details</label>
                                  </div>
                                  <div class="col-md-8 col-12">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <div class="plan-dt-wrap">
                                          <div class="plan-detail billingPlanDetail" *ngIf="activePlan">
                                            {{this.activePlan.planName}} |
                                            {{formatDescriptionFirstPart(this.activePlan.description)}}
                                          </div>
                                          <span class="plan-detail-desc billingPlanDescription">
                                            <ng-container
                                              *ngIf="activePlan.planName === 'Free' || activePlan.status === 'INACTIVE'; else displayDescription">
                                              Subscribe to continue.
                                            </ng-container>
                                            <ng-template #displayDescription>
                                              {{formatDescriptionSecondPart(this.activePlan.description)}}
                                            </ng-template>
                                          </span>
                                        </div>
                                      </div>
                                      <div class="col-md-5 pl-md-0">
                                        <div id="panPlanDetail" class="plan-dt-links links" style="display: block;">
                                          <div class="" id="resubscribePlanLnk" style="display: none">
                                            <a href="javascript:void(0)">Resubscribe</a>
                                          </div>
                                          <div class="">
                                            <a class="accor-btn" id="changePlanLnk"
                                              data-collapse="collapseplan">{{activePlan.planName === 'Free' ||
                                              activePlan.status === 'INACTIVE' ? 'Subscribe' : 'Change Plan'}}</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                                <div id="collapseplan" class="change-plan-wrapper panel-collapse collapse"
                                  style="display: none;">
                                  <div class="change-plan-body">
                                    <div class="accordian-panel change-subscription">
                                      <div class="accordian-panel-heading">
                                        <span>
                                          {{activePlan.planName === 'Free' || activePlan.status === 'INACTIVE' ?
                                          'Select' : 'Change'}} Subscription
                                          <span class="arrow-custom">
                                            <button data-revive="collapseplan" class="hideBtn">
                                              <i class="fa fa-angle-down"></i>
                                            </button>
                                          </span>
                                        </span>
                                      </div>

                                      <ul class="nav nav-tabs" role="tablist">
                                        <li role="presentation" class=""><a href="#tabMonthlyPlan"
                                            aria-controls="tabMonthlyPlan" class="active" role="tab"
                                            id="lnkTabMonthlyPlan" data-toggle="tab">Monthly Plans</a>
                                        </li>
                                        <li role="presentation"><a href="#tabAnnualPlan" aria-controls="tabAnnualPlan"
                                            role="tab" id="lnkTabAnnualPlan" data-toggle="tab">Yearly Plans</a>
                                        </li>
                                      </ul>


                                      <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane active" id="tabMonthlyPlan">
                                          <label class="plans-list-row" for="radio-1" id="planRow-monthly-1">
                                            <div class="plan-list-col plan-name" id="planContainer-monthly-1">
                                              <div class="plan-list-col plan-radio-option">
                                                <input id="radio-1" class="radio-custom" name="radio-group" type="radio"
                                                  [checked]="activeTab === 'Monthly' && planName === 'Standard' && activePlan.status !== 'INACTIVE'"
                                                  (change)="MonthlyradioChanged($event, 'Standard', 30, 'MONTHLY')"
                                                  value="Standard">
                                                <label for="radio-1" class="radio-custom-label"></label>
                                              </div>
                                              STANDARD
                                              <span>$30 USD Per Venue/Monthly</span>
                                            </div>
                                            <div class="plan-list-col plan-detail-desc pr-md-4">
                                              <p>This plan gives you access to up to 30,000 free check-ins per month.
                                              </p>
                                            </div>
                                          </label>
                                          <label class="plans-list-row" for="radio-2" id="planRow-monthly-2">
                                            <div class="plan-list-col plan-name" id="planContainer-monthly-2">
                                              <div class="plan-list-col plan-radio-option">
                                                <input id="radio-2" class="radio-custom" name="radio-group" type="radio"
                                                  value="Pro"
                                                  [checked]="activeTab === 'Monthly' && planName === 'Pro' && activePlan.status !== 'INACTIVE'"
                                                  (change)="MonthlyradioChanged($event, 'Pro', 100, 'MONTHLY')">
                                                <label for="radio-2" class="radio-custom-label"></label>
                                              </div>
                                              PRO
                                              <span>$100 USD Per Venue/Monthly</span>
                                            </div>
                                            <div class="plan-list-col plan-detail-desc pr-md-4">
                                              <p>This plan gives you access to up to 100,000 free check-ins per month.
                                              </p>
                                            </div>
                                          </label>
                                          <label class="plans-list-row planRow-advanced planRow-monthly-advance"
                                            for="radio-6">
                                            <div class="plan-list-col plan-name planContainer-annual-advance">
                                              <div class="plan-list-col plan-radio-option">
                                                <input id="radio-6" class="radio-custom" name="radio-group"
                                                  type="radio">
                                                <label for="radio-6" class="radio-custom-label"></label>
                                              </div>
                                              Advanced
                                            </div>
                                            <div class="plan-list-col plan-detail-desc pr-md-4">
                                              <div class="plan-dt-info">
                                                <p>To get your customised plan, please contact our sales
                                                  representatives now.</p>
                                                <button class="gradient-btn collapsePricingEnquirylnk btn-disabled"
                                                  data-toggle="collapse" data-target="#collapsePricingEnquiry">Schedule
                                                  a
                                                  Call</button>
                                              </div>
                                            </div>
                                          </label>

                                          <div class="note-msg">
                                            Note: This change will be applied to your next billing cycle
                                          </div>
                                          <div class="action-wrapper">
                                            <button
                                              *ngIf="activePlan.planName === 'Free' || activePlan.status === 'INACTIVE'; else updatePlanButton"
                                              class="gradient-btn m-md-auto" (click)="openPaymentWindow()">
                                              Subscribe
                                            </button>
                                            <ng-template #updatePlanButton>
                                              <button class="gradient-btn m-md-auto updateSubscriptionPlanLnk"
                                                (click)="updatePlan()">Continue</button>
                                            </ng-template>
                                          </div>
                                        </div>
                                        <div role="tabpanel" class="tab-pane" id="tabAnnualPlan">
                                          <label class="plans-list-row" for="radio-4" id="planRow-annual-1">
                                            <div class="plan-list-col plan-name" id="planContainer-annual-1">
                                              <div class="plan-list-col plan-radio-option">
                                                <input id="radio-4" class="radio-custom" name="radio-group" type="radio"
                                                  [checked]="activeTab === 'Yearly' && planName === 'Standard' && activePlan.status !== 'INACTIVE'"
                                                  (change)="YearlyradioChanged($event, 'Standard', 24, 'YEARLY')"
                                                  value="Standard">
                                                <label for="radio-4" class="radio-custom-label"></label>
                                              </div>
                                              STANDARD
                                              <span>$24 USD Per Venue/Monthly</span>
                                            </div>
                                            <div class="plan-list-col plan-detail-desc pr-md-4">
                                              <p>This plan gives you access to up to 30,000 free check-ins per month.
                                              </p>
                                            </div>
                                          </label>
                                          <label class="plans-list-row" for="radio-5" id="planRow-annual-2">
                                            <div class="plan-list-col plan-name" id="planContainer-annual-2">
                                              <div class="plan-list-col plan-radio-option">
                                                <input id="radio-5" class="radio-custom" name="radio-group" type="radio"
                                                  [checked]="activeTab === 'Yearly' && planName === 'Pro' && activePlan.status !== 'INACTIVE'"
                                                  (change)="YearlyradioChanged($event, 'Pro', 80, 'YEARLY')"
                                                  value="Pro">
                                                <label for="radio-5" class="radio-custom-label"></label>
                                              </div>
                                              PRO
                                              <span>$80 USD Per Venue/Monthly</span>
                                            </div>
                                            <div class="plan-list-col plan-detail-desc pr-md-4">
                                              <p>This plan gives you access to up to 100,000 free check-ins per month.
                                              </p>
                                            </div>
                                          </label>
                                          <label class="plans-list-row planRow-advanced planRow-annual-advance"
                                            for="radio-6">
                                            <div class="plan-list-col plan-name planContainer-annual-advance">
                                              <div class="plan-list-col plan-radio-option">
                                                <input id="radio-6" class="radio-custom" name="radio-group"
                                                  type="radio">
                                                <label for="radio-6" class="radio-custom-label"></label>
                                              </div>
                                              Advanced
                                            </div>
                                            <div class="plan-list-col plan-detail-desc pr-md-4">
                                              <div class="plan-dt-info">
                                                <p>To get your customised plan, please contact our sales
                                                  representatives now.</p>
                                                <button class="gradient-btn collapsePricingEnquirylnk btn-disabled"
                                                  data-toggle="collapse" data-target="#collapsePricingEnquiry">Schedule
                                                  a
                                                  Call</button>
                                              </div>
                                            </div>
                                          </label>
                                          <div class="note-msg">
                                            Note: This change will be applied to your next billing cycle
                                          </div>
                                          <div class="action-wrapper">
                                            <button
                                              *ngIf="activePlan.planName === 'Free' || activePlan.status === 'INACTIVE'; else updatePlanButton"
                                              class="gradient-btn m-md-auto" (click)="openPaymentWindow()">
                                              Subscribe
                                            </button>
                                            <ng-template #updatePlanButton>
                                              <button class="gradient-btn m-md-auto updateSubscriptionPlanLnk"
                                                (click)="updatePlan()">Continue</button>
                                            </ng-template>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div id="collapsePricingEnquiry"
                                  class="row panel-collapse collapse pricing-enquiry-wrapper">
                                  <div class="panel-body">
                                    <div class="accordian-panel">
                                      <div class="accordian-panel-heading">
                                        <div class="accordion-head-title">
                                          Customer Pricing Enquiry
                                          <span class="arrow-custom">
                                            <button class="hideBtn" data-toggle="collapse"
                                              data-target="#collapsePricingEnquiry">
                                              <i class="fa fa-angle-down"></i>
                                            </button>
                                          </span>
                                        </div>
                                        <p>
                                          We can tailor the pricing plan based on the volume requirements of
                                          your
                                          business. Get in touch to find out more.
                                        </p>
                                      </div>
                                      <div class=" forms-tabs-main">
                                        <div class="form-group">
                                          <div class="row">
                                            <div class="col-sm-6">
                                              <label>Your Name*</label>
                                              <input name="enquiryCustomerName" type="text" id="enquiryCustomerName"
                                                class="form-control text-required" data-field-name="Name"
                                                placeholder="Name">
                                            </div>
                                            <div class="col-sm-6">
                                              <label>Business Name*</label>
                                              <input name="enquiryBuisnessName" type="text" id="enquiryBuisnessName"
                                                class="form-control text-required" data-field-name="Business Name"
                                                placeholder="Business Name">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="row">
                                            <div class="col-sm-6">
                                              <label>Email*</label>
                                              <input name="enquiryEmail" type="text" id="enquiryEmail"
                                                class="form-control text-required text-email" data-field-name="Email"
                                                placeholder="Email">
                                            </div>
                                            <div class="col-sm-6">
                                              <label>Phone Number</label>
                                              <input name="enquiryPhoneNumber" type="text" id="enquiryPhoneNumber"
                                                class="form-control" placeholder="Phone Number">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="row">
                                            <div class="col-sm-6">
                                              <label>Logbook Required</label>
                                              <input name="enquiryEnvelopeReq" type="number" min="0"
                                                id="enquiryEnvelopeReq" class="form-control"
                                                placeholder="Logbook Required">
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="row">
                                            <div class="col-md-12">
                                              <label>Additional Information or Request</label>
                                              <textarea id="enquiryRequestMessage"
                                                placeholder="Additional Information or Request"
                                                style="width: 100%; resize: none;"></textarea>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="form-box-btn">
                                          <button class="gradient-btn" id="btnSendEnquiry">Submit</button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div id="paymentDetail"
                                *ngIf="activePlan.planName !== 'Free' && activePlan.status !== 'INACTIVE'"
                                class="payment-row-wrapper">
                                <div class="row">
                                  <div class="col-md-4 col-12 py_dt_label">
                                    <label>Payment Details</label>
                                  </div>
                                  <div class="col-md-4 col-12 lastPaymentCard"><img class="credit-card-img img-fluid "
                                      width="30" src="assets/img/visa.png"> •••• •••• •••• {{this.activePlan.cardDigit
                                    || 'WXYZ'}}</div>
                                  <div class="col-md-4 col-12 links">
                                    <div id="paymentDetailLinks" class="payment-dt-links" style="display: flex;">
                                      <a id="lnkMangePaymentInfo" data-toggle="modal"
                                        data-target="#exampleModalCenter">Manage Payment Info</a>
                                      <a id="lnkBillHistory" data-collapse="collapseBillingHistory"
                                        class=" mb-lg-auto accor-btn">Billing History</a>
                                      <a id="lnkCollapseCancelSubs" data-collapse="collapseCancelSubs"
                                        class=" mb-lg-auto accor-btn">Cancel Subscription</a>
                                    </div>
                                  </div>

                                  <div class="user-note-msg col-md-12" id="userNote" style="display: none;">NOTE: Your
                                    account does not have access to make any changes to your organisation’s
                                    membership
                                    and billing details. Please contact your admin if you wish to make any changes
                                  </div>
                                  <div class="clear-float"></div>
                                  <div id="collapseBillingHistory" class="col-md-12 panel-collapse collapse">
                                    <div class="panel-body">
                                      <div class="accordian-panel">
                                        <div class="accordian-panel-heading">
                                          <span>
                                            Billing History <span class="arrow-custom">
                                              <button data-revive="collapseBillingHistory" class="hideBtn">
                                                <i class="fa fa-angle-down"></i>
                                              </button>
                                            </span>
                                          </span>
                                        </div>
                                        <div class="accordion-content-wrapper">
                                          <div class="billing-plan-detail white-inner-card ">
                                            <div class="row">
                                              <div class="col-md-4 col-12 ">
                                                <label>Your Plan</label>
                                              </div>
                                              <div class="col-md-8 col-12">
                                                <div class="plan-detail billingPlanDetail" id="billingPlanDetail"
                                                  *ngIf="activePlan">{{this.activePlan.planName}} |
                                                  {{formatDescriptionFirstPart(this.activePlan.description)}}
                                                </div>
                                                <span class="plan-detail-desc billingPlanDescription activeplandesc"
                                                  id="billingPlanDescription">
                                                  {{formatDescriptionSecondPart(this.activePlan.description)}}
                                                </span>
                                              </div>
                                            </div>
                                            <div class="row">
                                              <div class="col-md-4 col-12 ">
                                                <label>Your Next Bill</label>
                                              </div>
                                              <div class="col-md-8 col-12">
                                                <span class="billing-plan-date" id="nextBill"
                                                  *ngIf="activePlan">{{formatdate(this.activePlan.nextBill)}}</span>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="note-txt">Plan fees are billed at the beginning of each
                                            period and may take a few days after the billing date to appear
                                            on
                                            your account.</div>
                                          <div class="billing-table-wrapper table-responsive">
                                            <table class="table table-bordered billing-table" id="billingHistoryTable">
                                              <thead>
                                                <tr>
                                                  <th class="b2">Date</th>
                                                  <th class="b3">Service Period</th>
                                                  <th class="b4">Description</th>
                                                  <th class="b5">Payment Method</th>
                                                  <th class="b6" style="white-space: nowrap;">Amount (in AUD)</th>
                                                </tr>
                                              </thead>
                                              <tbody id="billingHistoryContainer">
                                                <tr *ngFor="let billingHistory1 of billingHistory">
                                                  <td *ngIf="billingHistory">
                                                    <p style="color: #007bff;">
                                                      {{formatMembershipDate(this.billingHistory1.date)}}</p>
                                                  </td>
                                                  <td *ngIf="billingHistory" style="white-space: nowrap;">
                                                    {{formatServicePeriod(this.billingHistory1.servicePeriod)}}
                                                  </td>
                                                  <td>
                                                    {{formatDescriptionSecondPart(this.billingHistory1.planDescription)}}
                                                  </td>
                                                  <td><img class="credit-card-img" width="20" height="auto"
                                                      src="assets/img/visa.png" alt="logo">
                                                    •••• •••• •••• {{this.billingHistory1.cardDigits || 'WXYZ'}}</td>
                                                  <td>AU ${{this.billingHistory1.amount}}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div id="collapseUpdatePayment" class=" panel-collapse col-md-12 collapse">
                                    <div class="panel-body">
                                      <div class="accordian-panel">
                                        test
                                      </div>

                                    </div>
                                  </div>
                                  <div id="collapseCancelSubs" class="panel-collapse col-md-12 collapse">
                                    <div class="panel-body">
                                      <div class="accordian-panel">
                                        <h2 class="panel-head-title">
                                          Cancel Subscription <span class="arrow-custom">
                                            <button data-revive="collapseCancelSubs" class="hideBtn">
                                              <i class="fa fa-angle-down"></i>
                                            </button>
                                          </span>
                                        </h2>
                                        <div class="cancel-subs-wrapper">
                                          <div class="cancel-subs-head">
                                            <div class="alert-head">Are You Sure?</div>
                                            <p>
                                              If you are ready to end your subscription now, kindly read the terms
                                              &amp; click on the “Finish Cancellation” option below.
                                            </p>
                                          </div>
                                          <div class="cancel-list-card">
                                            <ul>
                                              <li>Your cancellation will be effective from the moment you click on
                                                “Finish Cancellation” below.</li>
                                              <li>You can log in anytime using your account credentials to access the
                                                previously stored/generated data.</li>
                                              <li>If you resubscribe later, your billing cycle will start from the day
                                                you resubscribe.</li>
                                            </ul>
                                          </div>
                                          <div class="cancel-feedback-wrapper">
                                            <label>Reason For Leaving</label>
                                            <textarea id="feedback" name="feedback"
                                              placeholder="Your feedback is important to us as we are constantly looking for ways to improve."
                                              style="width: 100%; resize: none;" [(ngModel)]="feedback"></textarea>
                                          </div>
                                          <div class="action-wrapper">
                                            <button id="btnCancelSubscription" type="button" class="btn btn-danger"
                                              (click)="cancelPlan()">Finish
                                              Cancellation</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

        </main>
        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
          aria-labelledby="exampleModalCenterTitle" style="display: none;" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-body">
                <div class="confirm-popup-content">
                  <div class="confirm-continue-img">
                    !
                  </div>
                  <h3>Ready to continue?</h3>
                  <p>Are you sure you want to update payment information?</p>
                  <div class="goback">
                    <div data-dismiss="modal">
                      <a class="gradient-btn" href="javascript:void(0)">Go
                        Back</a>
                    </div>
                    <div id="confirmedPopupContent">
                      <button class="gradient-btn" type="button" (click)="updateCardDetails()"
                        data-dismiss="modal">Yes</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
<script>
  function updateitem(ev) {
    $(".plans-list-row.active").each((x, y) => $(y).removeClass('active'))
    $(ev).addClass("active");
    $(ev).find("input[type=radio]").prop('checked', true).trigger('change');
  }
</script>