<script src="http://ajax.googleapis.com/ajax/libs/angularjs/1.4.8/angular.min.js"></script>


<script>
  var app = angular.module('myapp', []);
  app.controller('myctrl', myfun);


  function myfun($scope) {
    // $scope.$data = [{ name: 'helicopter' }, { name: 'Aeroplane' }, { name: 'professional Team' }];
    $scope.isMax = function (index) {
      return $scope.editForm['name' + index].$error.maxlength;
    }
  }

</script>

<div class="main-wrapper">
  <app-loader [loadingMessage]="'Loading'" [countTo]="100" *ngIf="loading"></app-loader>
  <div class="wrapper-fix-footer">
    <app-header></app-header>
    <div class="page-body-wrapper">
      <div class="custom-container">
        
        <div class="page-body-content" ng-app="ShrTest">
          <div class="page-top-heading qr-top-heading">
            <h1 class="page-title">Edit QR Code</h1>
            <!-- <a class="model-button-info" href="#" data-toggle="modal" data-target="#exampleModalCenter">
            <i class="fa fa-info-circle" aria-hidden="true"></i>
        </a> -->
            <!-- Modal -->
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog"
              aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    ...
                  </div>

                </div>
              </div>
            </div>
          </div>
          <form [formGroup]="generateqrForm" (ngSubmit)="onSubmit()" ng-controller="myctrl" ng-app="myapp">
            <div class="border-box mb-30">
              <div class="border-box-title">
                <h2>Add QR Code Details</h2>
              </div>

              <div class="form-group row">
                <label for="qr_name" class="col-md-3 col-form-label col-custom-label">QR Code Name</label>
                <div class="col-md-9">
                  <input type="text" class="form-control" id="qr_name" formControlName="qr_name"
                    [ngClass]="{ 'is-invalid': submitted && f.qr_name.errors }" placeholder="QR Code Name">
                  <div *ngIf="submitted && f.qr_name.errors">
                    <span class="error-msg">Please enter QR Code Name</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="redirecturl" class="col-md-3 col-form-label col-custom-label">Redirect URL (optional)</label>
                <div class="col-md-9">
                  <input type="text" class="form-control" id="redirecturl" formControlName="redirecturl"
                    [ngClass]="{ 'is-invalid': submitted && f.redirecturl.errors }"
                    placeholder="Redirect URL (optional)">
                  <div *ngIf="submitted && f.redirecturl.errors">
                    <span class="error-msg">Please enter valid Redirect URL</span>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="qrname" class="col-md-3 col-form-label col-custom-label">Would you like to use admin venue
                  name and address for this QR code?</label>
                <div class="cols-md-9">
                  <div class="radiocheck organization-check check">
                    <input id="yes-check" class="text-box single-line" name="chkVenue" type="radio"
                      (change)="checkVenue(0)" [checked]="multiple_venue == 0 ? true : false">
                    <label class="" for="yes-check">YES</label>

                    <input id="no-check" class="ml-4 text-box single-line" name="chkVenue" type="radio"
                      (change)="checkVenue(1)" [checked]="multiple_venue == 1 ? true : false">
                    <label class="" for="no-check">NO</label>
                  </div>
                </div>
              </div>

              <div *ngIf="multiple_venue == 1">
                <div class="form-group row">
                  <label for="venue_name" class="col-md-3 col-form-label col-custom-label">Venue Name</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="venue_name" formControlName="venue_name"
                      (keyup)="onRemoveValidationVName($event)" (keydown)="onRemoveValidationVName($event)"
                      [ngClass]="{ 'is-invalid': submitted && validationVName }" placeholder="Venue Name">
                    <div *ngIf="submitted && f.venue_name.errors && !validationVName">
                      <span class="error-msg">Maximum characters are 100.</span>
                    </div>
                    <div *ngIf="submitted && validationVName && !f.venue_name.errors">
                      <span class="error-msg">Please enter venue name.</span>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="venue_address" class="col-md-3 col-form-label col-custom-label">Venue Address</label>
                  <div class="col-md-9">
                    <input type="text" class="form-control" id="venue_address" formControlName="venue_address"
                      (keyup)="onRemoveValidationVAdd($event)" (keydown)="onRemoveValidationVAdd($event)"
                      [ngClass]="{ 'is-invalid': submitted && validationVAddress }" placeholder="Venue Address">
                    <div *ngIf="submitted && !f.venue_address.errors && validationVAddress">
                      <span class="error-msg">Please enter venue address.</span>
                    </div>
                    <div *ngIf="submitted && f.venue_address.errors && !validationVAddress">
                      <span class="error-msg">Maximum characters are 500.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="border-box" ng-module="myApp" ng-controller="MyCtrl">
              <div class="border-box-title">
                <h2>Add Viewers</h2>
              </div>
              <div class="table-green">
                <div class="table-responsive" ng-controller="MyCtrl">
                  <table class="table table-bordered table-striped-even" id="viewers-list-table">
                    <thead>
                      <tr>
                        <th class="v-name">Viewer Name*</th>
                        <th class="v-email">Email*</th>
                        <th class="v-info">Additional Information (Optional)</th>
                        <th class="v-action">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let dynamic of dynamicArray; let i = index;">
                        <td data-label="Viewer Name*">
                          <input type="text" placeholder="Viewer Name"
                            class="form-control table-row-input-height table-row-input"
                            [(ngModel)]="dynamicArray[i].viewername"
                            [ngClass]="{'error-border-red': !dynamicArray[i].viewername || error1 }"
                            [disabled]="!dynamic.isEditable" [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td data-label="Email*">
                          <input type="text" placeholder="Email"
                            class="form-control table-row-input-height table-row-input"
                            [(ngModel)]="dynamicArray[i].email" ng-pattern="emailRegExp"
                            [ngClass]="{'error-border-red': !dynamicArray[i].email || error2 }"
                            [disabled]="!dynamic.isEditable" [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td data-label="Additional Information (Optional)">
                          <input type="text" placeholder="Additional Information (Optional)"
                            class="form-control table-row-input-height table-row-input"
                            [(ngModel)]="dynamicArray[i].additional_info" [ngClass]="{'error-border-red': error3 }"
                            [disabled]="!dynamic.isEditable" [ngModelOptions]="{standalone: true}" />
                        </td>
                        <td data-label="Actions">
                          <div class="action-icon">
                            <a class="add" title="Add" id="vieweradd" data-toggle="tooltip" data-placement="top"
                              *ngIf="dynamic.isEditable" (click)=add(dynamic)><i class="fa fa-check"
                                aria-hidden="true"></i></a>
                            <a class="edit" title="Edit " id="vieweredit" (click)=editDomain(dynamic)
                              *ngIf="!dynamic.isEditable" data-toggle="tooltip" data-placement="top"><i
                                class="fa fa-edit" aria-hidden="true"></i></a>
                            <a class="delete" title="Delete" id="viewerdlt" (click)="deleteRow(i)" data-toggle="tooltip"
                              data-placement="top"><i class="fa fa-trash" aria-hidden="true"></i></a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <input type="hidden" id="viewer_data">
                  <!-- <h1>{{ fields }}</h1> -->

                </div>
                <script>
                  function MyCtrl($scope) {


                    $scope.emailRegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
                  }
                </script>
              </div>
              <div class="add-row-div mt-1">
                <button type="button" class="add-new-tr" [disabled]="!enable1" (click)="addRow()" id="add-viewer-row"><i
                    class="fa fa-plus"></i>Add a New Row</button>
              </div>
            </div>
            <script>

            </script>
            <div class="bottom-btn-group mt-5">

              <button *ngIf="(currentUser.role == '1')" type="button" class="gradiant-btn" routerLink="/dashboard">Go
                Back</button>
              <button *ngIf="(currentUser.role == '2')" type="button" class="gradiant-btn"
                routerLink="/master-admin-dashboard">Go Back</button>
              <button type="submit" ng-click="map()" [disabled]="!enable2" class="gradiant-btn">Update</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
  <script>
    var app = angular.module("ShrTest", []);


    app.controller("Test1", function ($scope) {

      $scope.MainData = { "name": "1-06082015185338.txt", "headers": [{ "header": "Name" }, { "header": "Age" }, { "header": "Address" }], "records": [{ "Name": "Paul", "Age": "23", "Address": "1115 W Franklin" }, { "Name": "Bessy the Cow", "Age": "5", "Address": "Big Farm Way" }, { "Name": "Zeke", "Age": "45", "Address": "W Main St" }] };

      $scope.MapCol = [{ "ColName": "Col1" }, { "ColName": "Col2" }, { "ColName": "Col3" }];


      $scope.map = function () {
        $scope.fields = [];
        for (i in $scope.MapCol) {
          var obj = $scope.MapCol[i]; $scope.fields.push({ "source": obj.value, "destination": obj.ColName })
        }
        document.getElementById("viewer_data").value = $scope.fields;
        // var data = {};
        // data = $scope.fields;
        // $rootScope.data = data;
        // let viewer_data = [];
        // viewer_data.setData(data);

        // $scope.submitData = function(data)  {  //pass in ng-model
        //     user.setData(data);  //call 'user' service to set data
        // }
        console.log($scope.fields);
      }




    });
  </script>
