<!-- <div class="main-wrapper">
    <div class="wrapper-fix-footer">
        <app-header ></app-header>
        <div class="page-body-wrapper">
            <div class="custom-container">
               -->
            <router-outlet></router-outlet>
                <!-------footer code start--------->
            <!-- </div>
        </div>
    </div>
<app-footer></app-footer> -->