<div class="spanner d-flex flex-column align-items-center justify-content-center w-100">
  <div class="over-lay"></div>
  <img src="assets/img/loader.svg" alt="loader">
  <div class="loader_footer">
    <div class="white-bg countPercentage">
      <span [attr.data-count]="countTo">{{ currentCount }}</span> %
    </div>
    <h4><span class="custom-text">{{ loadingMessage }}</span> <span id="dots">{{ dots }}</span></h4>
    <img src="assets/img/VeriDocLogbook_Logo_RGB_H_Black.webp" alt="loader">
  </div>
</div>