<div class="main-wrapper">
    <div class="wrapper-fix-footer">
        <!-- <app-header ></app-header> -->
        <nav class="login-header">
            <div class="custom-container">
                <div class="login-nav">
                    <img class="login-logo" src="assets/img/vdg-logbook-logo-login.png" alt="img">
                </div>
            </div>
        </nav>
        <div class="page-body-wrapper">
            <div class="custom-container">
               
                <!-------header code end--------->

                <!-------page code start--------->
                <div class="page-body-content">
                    <div *ngIf="!isOtpVerifyed" class="pvt-verify-slider-section">
                        <div class="pvt-verify-slider-top">
                            <p class="verify-appstore-text">To record your details in this venue’s Logbook, please
                                download and install<br> the VDG Logbook mobile app using the clickable buttons below.
                            </p>
                            <div class="pvt-verify-appstore-img">
                                <a href="https://play.google.com/store/apps/details?id=com.vdglogbook.android1&hl=en_AU"
                                    class="mr-3" target="_blank">
                                    <img src="assets/img/google-play-store.png" alt="Android App Store">
                                </a>
                                <a href="https://apps.apple.com/tr/app/vdg-logbook/id1531827458" target="_blank">
                                    <img src="assets/img/ios-play-store.png" alt="Apple App Store">
                                </a>
                            </div>
                        </div>
                        <div class="verify-slider-div">
                            <div id="verify-slider" class="carousel slide" data-ride="carousel">

                                <!-- The slideshow -->
                                <div class="carousel-inner">
                                    <div class="carousel-item active">
                                        <img src="assets/img/Logbook-Verify-Slider-1.jpg" alt="Logbook Verify Slider 1">
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/img/Logbook-Verify-Slider-2.jpg" alt="Logbook Verify Slider 2">
                                    </div>
                                    <div class="carousel-item">
                                        <img src="assets/img/Logbook-Verify-Slider-3.jpg" alt="Logbook Verify Slider 3">
                                    </div>
                                </div>

                                <!-- Left and right controls -->
                                <a class="custom-control-prev" href="#verify-slider" data-slide="prev">
                                    <span class="custom-control-prev-icon"></span>
                                </a>
                                <a class="custom-control-next" href="#verify-slider" data-slide="next">
                                    <span class="custom-control-next-icon"></span>
                                </a>

                                <!-- Indicators -->
                                <ul class="carousel-indicators">
                                    <li data-target="#verify-slider" data-slide-to="0" class="active"></li>
                                    <li data-target="#verify-slider" data-slide-to="1"></li>
                                    <li data-target="#verify-slider" data-slide-to="2"></li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="!isOtpVerifyed" class="pvt-verify-otp-section">
                        <div class="pvt-verify-otp-div">
                            <div class="pvt-verify-otp-header">
                                <a href="#verifyid-col" class="verify-colspan-a" data-toggle="collapse" role="button"
                                    aria-expanded="false" aria-controls="verifyid-col">
                                    <h1>Admin Access</h1>
                                    <span class="verify-down-icon"><i class="fa fa-chevron-down"
                                            aria-hidden="true"></i></span>
                                </a>

                            </div>
                            <div class="collapse" id="verifyid-col">
                                <div class="pvt-verify-otp-body">
                                    <div class="pvt-verify-otp-text">
                                        <p>
                                            If you have been given access to this area, please enter your email to
                                            continue.
                                        </p>
                                    </div>
                                    <div class="pvt-verify-otp-form">

                                        <div class="pvt-verify-send-otp-div" *ngIf="!isCaptchaVerifyed">
                                            <form [formGroup]="verifyCaptchaForm" (ngSubmit)="onSubmitCaptcha()"
                                                role="form">
                                                <div *ngIf="captchaerror" class="pvt-verify-otp-error">
                                                    <p *ngIf="errorMessage1">Error: Incorrect Response!<br>The captcha
                                                        you
                                                        entered is incorrect.
                                                        Please try again.</p>
                                                    <p *ngIf="errorMessage2">Error: The email entered does not have
                                                        permission to access this page.
                                                        This section is for Venue Admins only. If you are trying to
                                                        check-in please re-scan the QR code using
                                                        the VDG Logbook mobile application.</p>
                                                    <p *ngIf="errorMessage3">Error: Please enter valid email.</p>
                                                </div>
                                                <div class="verify-otp-email-input">
                                                    <input type="text" class="form-control" id="email"
                                                        formControlName="email"
                                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                    <div *ngIf="submitted && f.email.errors"
                                                        class="validation-msg-verify">
                                                        <span *ngIf="f.email.errors.required" class="error-msg">Please
                                                            enter email.</span>
                                                        <span *ngIf="f.email.errors.email" class="error-msg">Please
                                                            enter valid email.</span>
                                                    </div>
                                                </div>
                                                <div class="verify-otp-capcha">
                                                    <div class="verify-otp-capcha-inner">
                                                        <div class="verify-otp-capcha-img">
                                                            <img [src]="captchaUrl" title="Captcha" alt="Captcha">
                                                        </div>
                                                        <div class="verify-otp-capcha-refresh">
                                                            <div class="verify-otp-capcha-refresh-img">
                                                                <img (click)="getCaptcha()">
                                                            </div>
                                                            <div class="verify-otp-capta-textbox">
                                                                <input autocomplete="off" class="capcha-input"
                                                                    id="captchanumber" formControlName="captchanumber"
                                                                    placeholder="Type the number displayed above"
                                                                    type="text"
                                                                    [ngClass]="{ 'is-invalid': submitted && f.captchanumber.errors }">
                                                                <div *ngIf="submitted && f.captchanumber.errors">
                                                                    <span *ngIf="f.captchanumber.errors.required"
                                                                        class="error-msg">Please enter captcha
                                                                        number.</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="send-otp-btn-div">
                                                    <input type="submit" class="gradiant-btn" value="Send OTP">
                                                </div>
                                            </form>
                                        </div>
                                        <div class="pvt-verify-enter-otp-div" *ngIf="isCaptchaVerifyed">
                                            <form [formGroup]="verifyOtpForm" (ngSubmit)="onSubmitOtp()" role="form">
                                                <!-- Filter By  -->
                                                <div class="pvt-verify-enter-otp-text">
                                                    <p>OTP sent to <span>{{userEmail}}</span><br> Please check
                                                        your email.</p>
                                                </div>
                                                <div class="pvt-verify-otp-error" *ngIf="otperror">
                                                    <p>Error: Wrong OTP!<br>The OTP you entered is incorrect. Please
                                                        check
                                                        your email for the correct OTP and try again.</p>
                                                </div>
                                                <div class="pvt-verify-enter-resend-otp">
                                                    <button type="button" class="gradiant-btn"
                                                        (click)="sendOtp()">Re-send
                                                        OTP</button>
                                                </div>
                                                <div class="pvt-verify-otp-submit-box">
                                                    <p>Enter OTP to continue</p>
                                                    <div class="pvt-verify-otp-submit-group">
                                                        <input class="pvt-verify-otp-enter-input" maxlength="6"
                                                            name="enteredotp" type="text" formControlName="otp">
                                                        <input type="submit" class="pvt-verify-otp-submit-btn"
                                                            value="Submit">
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="isOtpVerifyed" class="logbook-verify-section">

                        <div class="logbook-top-content-box">
                            <p>Table below contains all the visitors that have scanned the QR code using their mobile
                                device. Each entry contains a verify button using which you can verify the timestamp of
                                visit on the blockchain.</p>
                        </div>
                        <div class="event-table-header-parts">
                            <div class="event-tab-sec">
                                <span>Events</span>
                            </div>



                            <div class="right-header-table">

                                <div class="search-box-filter">
                                    <div class="search-filtter-main">
                                        <div class="logbook-search-box" id="search-logbook-id">
                                            <input type="text" class="logbook-search-input" placeholder="Search" />
                                            <span class="search-icon-bar"><i class="fa fa-search"
                                                    aria-hidden="true"></i></span>
                                            <span class="search-last-filter">
                                                <span class="web-search-box">Filter By <i class="fa fa-angle-down"
                                                        aria-hidden="true"></i></span>
                                            </span>
                                        </div>

                                        <!-- <ng-template #content let-model let-d="dismiss"> -->
                                        <div class="search-filter-dropdown" id="logbook-filter-show"
                                            style="display: none;">
                                            <div class="search-filter-dropdown-form">
                                                <form [formGroup]="searchform"
                                                    (ngSubmit)="search(searchBoxdate2.value,searchBoxvisitorname.value,searchBoxphno.value,searchboxHomeaddress.value,searchBoxtimehr.value,searchBoxtimemin.value)">
                                                    <div class="row row-mr-0">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="searchBoxvisitorname">Visitor Name</label>
                                                                <input type="text" class="form-control"
                                                                    #searchBoxvisitorname
                                                                    formControlName="searchBoxvisitorname"
                                                                    id="searchBoxvisitorname"
                                                                    placeholder="Enter a visitor’s name"
                                                                    name="searchBoxvisitorname">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group angular-calendar">
                                                                <label for="datepicker">Date</label>

                                                                <input class="form-control date-icon-input"
                                                                    #searchBoxdate2 formControlName="searchBoxdate2"
                                                                    placeholder="Select date" name="dp" ngbDatepicker
                                                                    #f="ngbDatepicker" (click)="f.toggle()"
                                                                    readonly="readonly">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="searchBoxphno">Phone Number</label>
                                                                <input type="text" class="form-control" #searchBoxphno
                                                                    formControlName="searchBoxphno" id="searchBoxphno"
                                                                    placeholder="Enter a phone number"
                                                                    name="searchBoxphno">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="row">
                                                                <div class="col-md-6 col-6">
                                                                    <div class="form-group overflow-mb">
                                                                        <label for="time">Time</label>
                                                                        <select class="custom-select country-select"
                                                                            #searchBoxtimehr
                                                                            formControlName="searchBoxtimehr">
                                                                            <option>Hour</option>
                                                                            <option>0</option>
                                                                            <option>1</option>
                                                                            <option>2</option>
                                                                            <option>3</option>
                                                                            <option>4</option>
                                                                            <option>5</option>
                                                                            <option>6</option>
                                                                            <option>7</option>
                                                                            <option>8</option>
                                                                            <option>9</option>
                                                                            <option>10</option>
                                                                            <option>11</option>
                                                                            <option>12</option>
                                                                            <option>13</option>
                                                                            <option>14</option>
                                                                            <option>15</option>
                                                                            <option>16</option>
                                                                            <option>17</option>
                                                                            <option>18</option>
                                                                            <option>19</option>
                                                                            <option>20</option>
                                                                            <option>21</option>
                                                                            <option>22</option>
                                                                            <option>23</option>
                                                                            <option>24</option>

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="time-center-div">
                                                                    <span>
                                                                        :
                                                                    </span>
                                                                </div>
                                                                <div class="col-md-6 col-6">
                                                                    <div class="form-group overflow-mb">
                                                                        <label
                                                                            class="logbook-label-visblity-hidden">Mins</label>
                                                                        <select class="custom-select country-select"
                                                                            #searchBoxtimemin
                                                                            formControlName="searchBoxtimemin">
                                                                            <option>Mins</option>
                                                                            <option>0</option>
                                                                            <option>1</option>
                                                                            <option>2</option>
                                                                            <option>3</option>
                                                                            <option>4</option>
                                                                            <option>5</option>
                                                                            <option>6</option>
                                                                            <option>7</option>
                                                                            <option>8</option>
                                                                            <option>9</option>
                                                                            <option>10</option>
                                                                            <option>11</option>
                                                                            <option>12</option>
                                                                            <option>13</option>
                                                                            <option>14</option>
                                                                            <option>15</option>
                                                                            <option>16</option>
                                                                            <option>17</option>
                                                                            <option>18</option>
                                                                            <option>19</option>
                                                                            <option>20</option>
                                                                            <option>21</option>
                                                                            <option>22</option>
                                                                            <option>23</option>
                                                                            <option>24</option>
                                                                            <option>25</option>
                                                                            <option>26</option>
                                                                            <option>27</option>
                                                                            <option>28</option>
                                                                            <option>29</option>
                                                                            <option>30</option>
                                                                            <option>31</option>
                                                                            <option>32</option>
                                                                            <option>33</option>
                                                                            <option>34</option>
                                                                            <option>35</option>
                                                                            <option>36</option>
                                                                            <option>37</option>
                                                                            <option>38</option>
                                                                            <option>39</option>
                                                                            <option>40</option>
                                                                            <option>41</option>
                                                                            <option>42</option>
                                                                            <option>43</option>
                                                                            <option>44</option>
                                                                            <option>45</option>
                                                                            <option>46</option>
                                                                            <option>47</option>
                                                                            <option>48</option>
                                                                            <option>49</option>
                                                                            <option>50</option>
                                                                            <option>51</option>
                                                                            <option>52</option>
                                                                            <option>53</option>
                                                                            <option>54</option>
                                                                            <option>55</option>
                                                                            <option>56</option>
                                                                            <option>57</option>
                                                                            <option>58</option>
                                                                            <option>59</option>
                                                                            <option>60</option>
                                                                        </select>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="add">Home Address</label>
                                                                <input type="text" class="form-control"
                                                                    #searchboxHomeaddress
                                                                    formControlName="searchboxHomeaddress" id="add"
                                                                    placeholder="Enter an address" name="add">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="logbook-filter-btn-group">
                                                                <input type="submit" value="Submit"
                                                                    id="learnMore-Submit"
                                                                    class="gradiant-btn margin-right-10">
                                                                <input type="button" value="Clear" (click)="clear()"
                                                                    id="learnMore-Submit" class="gradiant-btn">

                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <!-- </ng-template> -->

                                    </div>
                                </div>
                                <!-- </form> -->

                                <div class="search-box-export-btn search-box-export-btn-web">
                                    <div class="event-table-export-btn search-box-export-csv">
                                        <button *ngIf="searchcall" (click)="download(eventHistoryList2)" id=""
                                            class="gradiant-btn"><i class="fa fa-plus" aria-hidden="true"></i> Export as
                                            CSV</button>
                                        <button *ngIf="!searchcall" (click)="download(eventHistoryList)" id=""
                                            class="gradiant-btn"><i class="fa fa-plus" aria-hidden="true"></i> Export as
                                            CSV</button>
                                    </div>
                                    <!-- <div class="event-table-export-btn search-box-export-pdf">
                                        <a href="#" id="" class="gradiant-btn"><i class="fa fa-plus"
                                                aria-hidden="true"></i> Export as PDF</a>
                                    </div> -->
                                </div>
                                <div *ngIf="searchcall" class="search-box-export-btn-mobile">

                                    <button (click)="download(eventHistoryList2)" id="" class="gradiant-btn"><i
                                            class="fa fa-plus" aria-hidden="true"></i> Export as
                                        CSV</button>
                                </div>
                                <div *ngIf="!searchcall" class="search-box-export-btn-mobile">
                                    <button (click)="download(eventHistoryList)" id="" class="gradiant-btn"><i
                                            class="fa fa-plus" aria-hidden="true"></i> Export as
                                        CSV</button>
                                </div>
                            </div>
                            <!-- </form> -->
                        </div>
                        <div class="logbook-events-table-main">
                            <div class="logbook-events-table">
                                <div class="table-responsive horizontal-scroll">
                                    <!--Table-->
                                    <table class="table table-striped table-bordered">

                                        <!--Table head-->
                                        <thead>
                                            <tr>
                                                <th>Visitor Name</th>
                                                <th>Email</th>
                                                <th>Phone Number</th>
                                                <th class="h-address">Home Address</th>
                                                <th>Timestamp (local time)</th>
                                                <th>Venue Address</th>
                                                <!-- <th>Phone Unique ID</th> -->
                                                <!-- <th>Scanned By</th> -->
                                                <th>Scanned From</th>
                                                <th>Verify Link</th>
                                            </tr>
                                        </thead>
                                        <!--Table head-->
                                        <!--Table body-->
                                        <tbody *ngIf="!searchloadingbar && !loadercall">
                                            <tr *ngFor="let eventHistory of eventHistoryList">
                                                <td>{{ eventHistory.VisitorName }}</td>
                                                <td class="word-nowrap">{{ eventHistory.Email }}</td>
                                                <td class="word-nowrap">{{ eventHistory.PhoneNumber }}</td>
                                                <td>{{ eventHistory.HomeAddress }}</td>
                                                <td class="word-nowrap">{{ eventHistory.Timestamp}}
                                                </td>
                                                <td>{{ eventHistory.OrganisationAddress }}</td>
                                                <td>{{ eventHistory.ScannedLocation }}</td>
                                                <!-- <td class="cos-word-break">{{ eventHistory.PhoneUniqueId }}</td> -->
                                                <!-- <td>{{ eventHistory.ScannedBy }}</td> -->
                                                <!-- <td><span
                                                        *ngFor="let profile of eventHistory.profiles">{{ profile }},</span>
                                                </td> -->

                                                <td class="verify-btn-space verify-link-logbook-btn">
                                                    <div *ngIf="eventHistory.TransactionLink; else loadingSpinner">
                                                        <a href="{{eventHistory.TransactionLink}}" id="" target="_blank"
                                                            class="gradiant-btn verify-gradiant-btn">
                                                            <img src="assets/img/verify-btn-icon.png"><span
                                                                class="event-verify-btn">Verify</span>
                                                        </a>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr *ngIf="eventHistoryList==null">
                                                <td colspan="9" class="text-center">No Record Found</td>
                                            </tr>
                                        </tbody>

                                        <tbody *ngIf="searchloadingbar && !loadercall">
                                            <tr *ngFor="let eventHistory of eventHistoryList2">
                                                <td>{{ eventHistory.VisitorName }}</td>

                                                <td class="word-nowrap">{{ eventHistory.Email }}</td>
                                                <td class="word-nowrap">{{ eventHistory.PhoneNumber }}</td>
                                                <td>{{ eventHistory.HomeAddress }}</td>
                                                <td class="word-nowrap">{{ eventHistory.Timestamp}}
                                                </td>
                                                <td>{{ eventHistory.OrganisationAddress }}</td>
                                                <td>{{ eventHistory.ScannedLocation }}</td>
                                                <!-- <td class="cos-word-break">{{ eventHistory.PhoneUniqueId }}</td> -->
                                                <!-- <td>{{ eventHistory.ScannedBy }}</td> -->
                                                <!-- <td><span
                                                        *ngFor="let profile of eventHistory.profiles">{{ profile }},</span>
                                                </td> -->

                                                <td class="verify-btn-space verify-link-logbook-btn">
                                                    <div *ngIf="eventHistory.TransactionLink; else loadingSpinner">
                                                        <a href="{{eventHistory.TransactionLink}}" id="" target="_blank"
                                                            class="gradiant-btn verify-gradiant-btn">
                                                            <img src="assets/img/verify-btn-icon.png"><span
                                                                class="event-verify-btn">Verify</span>
                                                        </a>
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr *ngIf="eventHistoryList2==null">
                                                <td colspan="9" class="text-center">No Record Found</td>
                                            </tr>
                                        </tbody>
                                        <tbody *ngIf="searchloadingbar && loadercall">
                                            <tr>
                                                <td colspan="9" class="text-center">
                                                    Loading..
                                                </td>
                                            </tr>
                                        </tbody>
                                        <!--Table body-->
                                    </table>
                                    <!--Table-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-------page code end--------->

                <!-------footer code start--------->
            </div>
        </div>
    </div>
    <ng-template #loadingSpinner>
        <!-- Place your loading spinner here -->
        <img src="assets/img/verify-loader.svg" alt="loader" width="30" />
    </ng-template>

    <app-footer>

    </app-footer>
    <!-- <script src="assets/js/filterpopup.js"></script> -->
</div>