import { Renderer2,Component, OnInit ,Inject} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AlertService, AuthenticationService, PublicService, UserService } from '../_services';
import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {NgForm} from '@angular/forms';
import { interval, Subscription } from 'rxjs';

// import { FilterPipe } from './PIPE';
import { Timestamp } from 'rxjs/internal/operators/timestamp';
import { filter } from 'rxjs/operators';
import { stringify } from '@angular/compiler/src/util'
import * as moment from "moment";


@Component({ templateUrl: './qrverify.component.html' })
export class QrverifyComponent implements OnInit {
  submitted = false;
  captchaUrl: string = "assets/img/capcha-demo.png";
  userEmail: string = "";
  qrGUID: string = "";
  eventHistoryList: any = [];
  eventHistoryList2: any = [];
  orgtimezone:any;
  captchaerror = false;
  otperror = false;
  addtimechekc :any;
  errorMessage1 = false;
  errorMessage2 = false;
  errorMessage3 = false;
  searchcall:boolean=false;
  loadercall = false;
  // from_date = filter(Timestamp)($scope.from_date, 'MMM-dd-yyyy');
  userFilter: any = { VisitorName: '', PhoneNumber: '' ,HomeAddress:'',Timestamp:''};
  // console.log(userFilter)
  isCaptchaVerifyed = false;
  isOtpVerifyed = false;
  mySub: Subscription;
  slidertab=true;
  verifyCaptchaForm: FormGroup;
  verifyOtpForm: FormGroup;
  searchform:FormGroup;
  searchloadingbar=false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private publicService: PublicService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private _renderer2: Renderer2,
    // private FilterPipe
    @Inject(DOCUMENT) private _document: Document ) {
      // if(this.isOtpVerifyed)
      // {

        // this.mySub = interval(30).subscribe((func => {
        //   this.eventHistory();
        // }))

      // }else{

      // }



  }

  ngOnInit(): void {
    //Script adding for filter search 
    
    // script adding end
    // console.log(this.userFilter);
    // this.href = this.router.url;
    // console.log(this.isOtpVerifyed)
    
    const qrGUID: string = this.route.snapshot.queryParamMap.get('guid');

    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if(currentUser && Object.keys(currentUser).length > 0){
      //Exist data in local storage
      var token = currentUser.token;
      if(token){
            this.userService.checkVerifyOtp(qrGUID)
            .pipe(first())
            .subscribe(
            data => {
              console.log(data['returncode'])
              if (data['returncode'] == '1' || data['returncode'] == 1) {
                this.isOtpVerifyed = true

                if(this.isOtpVerifyed)
                {
                  this.loadjs()
                  this.mySub = interval(10000).subscribe((func => {
                      this.eventHistory();
                  }))
                }
              }
            },
            error => {
              // this.alertService.error(error);
              // this.loading = false;
            }
          );
      }
    }

    
    if (localStorage.getItem(this.route.snapshot.queryParamMap.get('guid')))
    {
      if(this.route.snapshot.queryParamMap.get('guid'))
      {
        if(this.route.snapshot.queryParamMap.get('guid') == qrGUID)
        {
          let cur = new Date();
          let gotdate = localStorage.getItem(this.route.snapshot.queryParamMap.get('guid'))
          // this.route.snapshot.queryParamMap.get('guid')
          let got_date = new Date(gotdate)
          if(cur > got_date)
          { 
            console.log(cur)
            console.log(got_date)
            console.log("yes")
          }else{
              console.log("no")
              console.log(cur)
            console.log(got_date)
              this.isOtpVerifyed = true
              this.slidertab = false;
          }
        }

      }

    }
    this.qrGUID = qrGUID
    localStorage.setItem('qrGUID', qrGUID);
    
    this.verifyCaptchaForm = this.formBuilder.group({
      email: ['', [Validators.required,Validators.email]],
      captchanumber: ['', Validators.required]
    });

    this.verifyOtpForm = this.formBuilder.group({
      otp: ['', [Validators.required]]
    });
    this.searchform = this.formBuilder.group({
      // otp: ['', [Validators.required]]
    searchBoxdate2: [''],
    searchBoxvisitorname: [''],
    searchBoxphno:[''],
    searchboxHomeaddress:[''],
    searchBoxtimehr:[''],
    searchBoxtimemin:['']
    });
    this.getCaptcha();
    // if(this.isOtpVerifyed == true)
    // {
       
    // }
    console.log("otp is verified")
    console.log(this.isOtpVerifyed)
    // this.searchcall
    if(this.isOtpVerifyed)
    {
      console.log(this.searchcall)
      console.log("search val")
      this.loadjs()
  
        this.mySub = interval(10000).subscribe((func => {
          // this.loadjs()
          console.log("no")
          console.log(typeof(this.searchcall))
  
          this.eventHistory();
        }))
    }
    this.eventHistory();

  }

  getCaptcha() {
    this.publicService.getCaptcha()
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data['returncode'] == '1' || data['returncode'] == 1) {

            console.log(data['pguid']);
            console.log(data['url']);

            localStorage.setItem('pguid', data['pguid']);
            this.captchaUrl = data['url'];
          }
        },
        error => {
          // this.alertService.error(error);
          // this.loading = false;
        });
  } 

  // convenience getter for easy access to form fields
  get f() { return this.verifyCaptchaForm.controls; }

  // convenience getter for easy access to form fields
  get f1() { return this.verifyOtpForm.controls; }


  get f3() { return this.searchform.controls; }
  onSubmitCaptcha() {
    this.submitted = true;
    this.userEmail = this.f.email.value;

    let captcha: any = {};
    captcha.captchanumber = this.f.captchanumber.value;
    captcha.guid = localStorage.getItem('pguid');

    // stop here if form is invalid
    if (this.verifyCaptchaForm.invalid) {
      return;
    }

    this.publicService.verifyCaptcha(captcha)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data['returncode'] == '1' || data['returncode'] == 1) {
            this.sendOtp();
          } else if (data['returncode'] == '3' || data['returncode'] == 3) {
            this.isCaptchaVerifyed = false;
            this.captchaerror = true;
            this.errorMessage1 = true;
            this.errorMessage2 = false;
            this.errorMessage3 = false;
          } else {
            this.isCaptchaVerifyed = false;
            this.captchaerror = true;
            this.errorMessage1 = false;
            this.errorMessage2 = true;
            this.errorMessage3 = false;
          }
        },
        error => {
          this.isCaptchaVerifyed = false;
          this.captchaerror = true;
          this.errorMessage1 = true;
          this.errorMessage2 = false;
          this.errorMessage3 = false;
        });
  }

  onSubmitOtp() {

    // stop here if form is invalid
    // if (this.verifyForm.invalid) {
    //   return;
    // }

    let otp: any = {};
    otp.email = encodeURIComponent(this.userEmail);
    otp.otp = this.f1.otp.value;

    this.publicService.verifyOtp(otp)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data['returncode'] == '1' || data['returncode'] == 1) {
            this.isOtpVerifyed = true;
            this.slidertab = false;
            let datefornow= new Date;
            console.log(datefornow)
            function AddMinutesToDate(datefornow, minutes) {
              return new Date(datefornow.getTime() + minutes*60000);
            }
            this.addtimechekc = AddMinutesToDate(datefornow,5)
            localStorage.setItem(this.route.snapshot.queryParamMap.get('guid'),this.addtimechekc)
            // localStorage.setItem("forobj",this.qrGUID)
            console.log(this.addtimechekc)
            this.ngOnInit()
            // this.loadjs()
            this.eventHistory();
            
          } else if (data['returncode'] == '4' || data['returncode'] == 4) {
            this.isOtpVerifyed = false;
            this.otperror = true;
          } else {
            this.isOtpVerifyed = false;
            this.otperror = true;
          }
        },
        error => {
          // this.alertService.error(error);
          // this.loading = false;
        });
  }

  sendOtp() {

    let otp: any = {};
    otp.email = encodeURIComponent(this.userEmail);
    otp.guid = this.qrGUID
    // localStorage.getItem('qrGUID');
    console.log(otp);

    this.publicService.sendOtp(otp)
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data['returncode'] == '1' || data['returncode'] == 1) {
            this.isCaptchaVerifyed = true;
            this.captchaerror = false;
            this.errorMessage1 = false;
            this.errorMessage2 = false;
            this.errorMessage3 = false;
          } else if (data['returncode'] == '2' || data['returncode'] == 2) {
            this.isCaptchaVerifyed = false;
            this.captchaerror = true;
            this.errorMessage1 = false;
            this.errorMessage2 = false;
            this.errorMessage3 = true;
          } else if (data['returncode'] == '3' || data['returncode'] == 3) {
            this.isCaptchaVerifyed = false;
            this.captchaerror = true;
            this.errorMessage1 = false;
            this.errorMessage2 = true;
            this.errorMessage3 = false;
          } else if (data['returncode'] == '6' || data['returncode'] == 6) {
            this.isCaptchaVerifyed = false;
            this.captchaerror = true;
            this.errorMessage1 = false;
            this.errorMessage2 = false;
            this.errorMessage3 = true;
          } else {
            this.isCaptchaVerifyed = false;
            this.captchaerror = true;
            this.errorMessage1 = true;
            this.errorMessage2 = false;
            this.errorMessage3 = false;
          }
        },
        error => {
          // this.alertService.error(error);
          // this.loading = false;
        });
  }
  loadjs()
  {
    let script = this._renderer2.createElement('script');
    script.type = "text/javascript";
    script.src = "assets/js/filterpopup.js";
    script.id = "f_scrip_1";

    // let script2 = this._renderer2.createElement('script');
    // script2.type = "text/javascript";
    // script2.src = "https://code.jquery.com/jquery-1.12.4.js";
    // script2.id = "f_scrip_2";
    
    // let script3 = this._renderer2.createElement('script');
    // script3.type = "text/javascript";
    // script3.src = "https://code.jquery.com/ui/1.12.1/jquery-ui.js";
    // script3.id = "f_scrip_3";
    
    let script4 = this._renderer2.createElement('script');
    script4.type = "text/javascript";
    script4.src = "assets/js/search.js";
    script4.id = "f_scrip_4";
    // <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.0.7/angular.min.js"></script>
    
    let script5 = this._renderer2.createElement('script');
    script5.type = "text/javascript";
    script5.src = "https://ajax.googleapis.com/ajax/libs/angularjs/1.0.7/angular.min.js";
    script5.id = "f_scrip_5";
    if(document.getElementById("f_scrip_5")==null)
    {
      this._renderer2.appendChild(this._document.body, script5);
    }else
    {
      document.getElementById("f_scrip_5").remove()
      this._renderer2.appendChild(this._document.body, script5);
    }
    if(document.getElementById("f_scrip_4")==null)
    {

      this._renderer2.appendChild(this._document.body, script4);
    }else{
      document.getElementById("f_scrip_4").remove()
      this._renderer2.appendChild(this._document.body, script4);
    }
    // if(document.getElementById("f_scrip_2")==null)
    // {
    //   this._renderer2.appendChild(this._document.body, script2);
    // }else{
    //   document.getElementById("f_scrip_2").remove()
    //   this._renderer2.appendChild(this._document.body, script2);
    // }
    // if(document.getElementById("f_scrip_3")==null)
    // {
    //   this._renderer2.appendChild(this._document.body, script3);
    // }else{
    //   document.getElementById("f_scrip_3").remove()
    //   this._renderer2.appendChild(this._document.body, script3);
    // }    
    if(document.getElementById("f_scrip_1")==null)
    {
      this._renderer2.appendChild(this._document.body, script);
    }else{
      document.getElementById("f_scrip_1").remove()
      this._renderer2.appendChild(this._document.body, script);
    }
  }
  eventHistory() {

    let otp: any = {};
    otp.guid = this.qrGUID 
    // localStorage.getItem('qrGUID');
    console.log(otp);
    this.publicService.eventHistory(otp)
      .pipe(first())
      .subscribe(
        data => {
          this.loadercall = false;
          console.log(data);
          // ${localStorage.getItem('qrGUID')}
          this.eventHistoryList = data['data'][this.qrGUID];
          console.log(this.eventHistoryList);
          let eventHistory2 = this.eventHistoryList;
          this.eventHistoryList.forEach((obj) => {
            for (const k in obj) {
              let zone;
              if(k === "org_timezone")
              {
                zone = obj[k]

              }
              if (k === "name") {
                obj["VisitorName"] = obj[k];
                delete obj[k]
              }
              if (k == "email") {
                obj["Email"] = obj[k];
                delete obj[k]
              }
              if (k == "phone_number") {
                obj["PhoneNumber"] = obj[k];
                delete obj[k]
              }
              if (k == "address") {
                obj["HomeAddress"] = obj[k];
                delete obj[k]
              }
              if (k == "timestamp") {
                obj["Timestamp"] = obj[k];
                let june = moment(obj["created_on"]);
                console.log(obj['org_timezone'])
                obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: obj['org_timezone']});
                obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                // org_timezone
                delete obj[k]
              }
              if (k == "location") {
                obj["ScannedLocation"] = obj[k];
                delete obj[k]
              }
              if (k == "device_id") {
                obj["PhoneUniqueId"] = obj[k];
                delete obj[k]
              }
              if (k == "scanned_by") {
                obj["ScannedBy"] = obj[k];
                delete obj[k]
              }
              if (k == "qr_verify_link") {
                obj["VerifyLink"] = obj[k];
                delete obj[k]
              }
              if (k == "shop_name") {
                obj["OrganisationName"] = obj[k];
                delete obj[k]
              }
              if (k == "profiles") {
                obj["Profiles"] = obj[k];
                delete obj[k]
              }
              if (k == "qr_guid") {
                obj["Guid"] = obj[k];
                delete obj[k]
              }
              if (k == "transaction_link") {
                obj["TransactionLink"] = obj[k];
                delete obj[k]
              }
              if (k == "org_address") {
                obj["OrganisationAddress"] = obj[k];
                delete obj[k]
              }
              
            }
          })
          console.log(eventHistory2);
          
          this.eventHistoryList = this.eventHistoryList.sort((a, b) => b.id - a.id);
          if(this.eventHistoryList.length==0)
          {
            this.eventHistoryList = null;
          }
        },
        error => {
          // this.alertService.error(error);
          // this.loading = false;
        });
  }

  // download(eventHistory){

  //   this.publicService.downloadFile2(eventHistory, 'eventhistory');
  // }
  replaceAll(string, search, replace) {
    return string.replace(/,/g,replace);
  }
  makeDoubleDigit(x){
    return (x < 10) ? "0" + x : x;
  }
  formateTime(year, month, day, hour, minute, second,am){
    return this.makeDoubleDigit(day) + "/" + 
    this.makeDoubleDigit(month) + "/" + 
    this.makeDoubleDigit(year) + " " + 
    this.makeDoubleDigit(hour) + ":" + 
    this.makeDoubleDigit(minute) + ":" + 
    this.makeDoubleDigit(second) + " " +
    am;
  }
  
  
  replaceTime(string){
    var date = new Date(string);
  
    var year = date.getFullYear();
    var month = (date.getMonth() +1);
    var day = date.getDate();
    
    var hour = date.getHours();
    if(hour > 12)
    {
      hour = hour-12;
    }
    var minute = date.getMinutes();
    var second = date.getSeconds();
    var am = (date.getHours() >= 12 ? "PM" : "AM")
    return this.formateTime(year, month, day, hour, minute, second,am);
  }
  download(eventHistorydata) {
    console.log("event history")
    // console.log(eventHistory);
    let eventHistorydata1 = eventHistorydata;
    // eventHistory = eventHistory
    for (let i in eventHistorydata1) {
      let checkstampformat = eventHistorydata1[i]["Timestamp"].split(" ")[0] 
      console.log(moment(checkstampformat,"dd/MM/YYYY").isValid())
      if(moment(checkstampformat,"dd/MM/YYYY").isValid())
      {
        eventHistorydata1[i]["Timestamp (local time)"] = eventHistorydata1[i]["Timestamp"];
        eventHistorydata1[i]["Timestamp (local time)"] = this.replaceAll(eventHistorydata1[i]["Timestamp (local time)"],',','');
      }else{
        eventHistorydata1[i]["Timestamp"] = this.replaceTime(eventHistorydata1[i]["Timestamp"]);
        eventHistorydata1[i]["Timestamp (local time)"] = eventHistorydata1[i]["Timestamp"];
        eventHistorydata1[i]["Timestamp (local time)"] = this.replaceAll(eventHistorydata1[i]["Timestamp (local time)"],',','');
      }
      // eventHistorydata1[i]["Timestamp (local time)"] = eventHistorydata1[i]["Timestamp"];
      // eventHistorydata1[i]["Timestamp (local time)"] = this.replaceTime(eventHistorydata1[i]["Timestamp (local time)"]);
      eventHistorydata1[i]["VerifyLink"] = eventHistorydata1[i]["TransactionLink"];
      eventHistorydata1[i]["ScannedBy"] = this.replaceAll(eventHistorydata1[i]["ScannedBy"], ',', '');
      eventHistorydata1[i]["HomeAddress"] = this.replaceAll(eventHistorydata1[i]["HomeAddress"], ',', '');
      eventHistorydata1[i]["ScannedLocation"] = this.replaceAll(eventHistorydata1[i]["ScannedLocation"], ',', '');
      eventHistorydata1[i]["Venue Address"] = this.replaceAll(eventHistorydata1[i]["OrganisationAddress"], ',', '');
      
      // eventHistory2[i]["TransactionLink
      // eventHistory2[i]["OrganisationAddress"] = this.replaceAll(eventHistory2[i]["OrganisationAddress"],',','');
    }
    this.publicService.downloadFile3(eventHistorydata1, 'eventhistory');
  }
  getFormattedTimeZone() {
    return new Date().toString().match(/([A-Z]+[\+-][0-9]+.*)/)[1].split(' (')[0].replace(/[A-Z]+/, '')
  }


  search(searchBoxdate2,searchBoxvisitorname,searchBoxphno,searchboxHomeaddress,searchBoxtimehr,searchBoxtimemin) {
    // console.log(searchBoxdate2)
    // console.log(searchBoxtimemin)
    this.searchcall = true;
    this.searchloadingbar = true;
    this.loadercall = true;
    this.eventHistoryList2 = []
    // clearInterval(this.mySub);
    this.mySub.unsubscribe()
    console.log("search called")
    searchBoxvisitorname = searchBoxvisitorname.trim();
    searchBoxphno = searchBoxphno.trim();
    searchboxHomeaddress = searchboxHomeaddress.trim();
    searchBoxtimehr = searchBoxtimehr.trim();
    searchBoxtimemin = searchBoxtimemin.trim();
    if(searchBoxtimehr=="Mins")
    {
      searchBoxtimehr=""
    }
    if(searchBoxtimemin=="Hour")
    {
      searchBoxtimemin=""
    }
    let guid = this.qrGUID
    if(searchBoxdate2=='' && searchBoxvisitorname=='' && searchBoxphno=='' && searchboxHomeaddress=='' && searchBoxtimehr=='' && searchBoxtimemin=='')
    {
      return;
    }
    // console.log(guid,searchBoxdate2,searchBoxvisitorname,searchBoxphno,searchboxHomeaddress);
    if((searchBoxdate2=='' && searchBoxvisitorname=='' && searchBoxphno=='' && searchboxHomeaddress=='') && (searchBoxtimehr!='' || searchBoxtimemin!=''))
    {
      let otp: any = {};
      otp.guid = localStorage.getItem('qrGUID');
      console.log(otp);
      this.publicService.eventHistory(otp)
        .pipe(first())
        .subscribe(
          data => {
            console.log(data);
            
            this.eventHistoryList2= data['data'][`${localStorage.getItem('qrGUID')}`];
            this.eventHistoryList2.forEach((obj) => {
              for (const k in obj) {
                if (k === "name") {
                  obj["VisitorName"] = obj[k];
                  delete obj[k]
                }
                if (k == "email") {
                  obj["Email"] = obj[k];
                  delete obj[k]
                }
                if (k == "phone_number") {
                  obj["PhoneNumber"] = obj[k];
                  delete obj[k]
                }
                if (k == "address") {
                  obj["HomeAddress"] = obj[k];
                  delete obj[k]
                }
                if (k == "timestamp") {
                  obj["Timestamp"] = obj[k];
                  delete obj[k]
                }
                if (k == "location") {
                  obj["ScannedLocation"] = obj[k];
                  delete obj[k]
                }
                if (k == "device_id") {
                  obj["PhoneUniqueId"] = obj[k];
                  delete obj[k]
                }
                if (k == "scanned_by") {
                  obj["ScannedBy"] = obj[k];
                  delete obj[k]
                }
                if (k == "qr_verify_link") {
                  obj["VerifyLink"] = obj[k];
                  delete obj[k]
                }
                if (k == "shop_name") {
                  obj["OrganisationName"] = obj[k];
                  delete obj[k]
                }
                if (k == "profiles") {
                  obj["Profiles"] = obj[k];
                  delete obj[k]
                }
                if (k == "qr_guid") {
                  obj["Guid"] = obj[k];
                  delete obj[k]
                }
                if (k == "transaction_link") {
                  obj["TransactionLink"] = obj[k];
                  delete obj[k]
                }
                if (k == "org_address") {
                  obj["OrganisationAddress"] = obj[k];
                  delete obj[k]
                }
                
              }
            })
            console.log(this.eventHistoryList2)
            if(searchBoxtimehr!="" && searchBoxtimemin=="")
            {
              console.log("infisratif");
              let dataeve = []; 
              // this.eventHistoryList
              this.eventHistoryList2.forEach((element,index) => {
                for (const k in element) {
                  if (k === "created_on") {
                    let l1 = element[k];
                    // let zone = data['org_timezone']
                    // console.log(element[index]['org_timezone'])
                    l1 = new Date(l1).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                    var l2 = new Date(l1).getDate();
                    if(l2 <= 9)
                    {
                      var l22 = "0"+l2  
                    }
                    else{
                      var l22 = stringify(l2)
                    }
                    var l3 = new Date(l1).getMonth() + 1;
                    if(l3 <= 9)
                    {
                      var l33 = "0"+l3
                    }else{
                      var l33 = stringify(l3)
                    }
                    var l4=  new Date(l1).getFullYear()
        
                    var daaaat = l4+"-"+l33+"-"+l22;
                    // console.log(daaaat)
                    // console.log(searchBoxdate)
                    var hr = new Date(l1).getHours();
                    if(hr > 12)
                    {
                      hr -= 12
                    }
                    var min = new Date(l1).getMinutes();
                    
                    // if(daaaat===searchBoxdate2)
                    // {
                    console.log(hr)
                      if(hr==searchBoxtimehr)
                      {
                        console.log("in if")
                        // obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: obj['org_timezone']});
                        dataeve.push(element)
                          // this.eventHistoryList.push(element)  
                      }
                      
                    
                  }
                }
              });
              if(dataeve.length!=0)
              {
                this.loadercall = false;
                this.eventHistoryList2 = dataeve
                this.eventHistoryList2.forEach((obj,index) => {
                  for (const k in obj) {
                    if (k == "Timestamp") {
                      obj["Timestamp"] = obj[k];
                      obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                            
                      obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                      obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                      // delete obj[k]
                    }
                  }
                });
              }else{
                this.loadercall = false;
                this.eventHistoryList2=null
              }
              


            }else if(searchBoxtimemin!="" && searchBoxtimehr=="")
            {
              console.log("in sec if")
              let dataeve2 = []; 
              this.eventHistoryList2.forEach((element,index) => {
                for (const k in element) {
                  if (k === "created_on") {
                    let l1 = element[k];
                    let zone = data['org_timezone']
                    l1 = new Date(l1).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                    var l2 = new Date(l1).getDate();
                    if(l2 <= 9)
                    {
                      var l22 = "0"+l2  
                    }
                    else{
                      var l22 = stringify(l2)
                    }
                    var l3 = new Date(l1).getMonth() + 1;
                    if(l3 <= 9)
                    {
                      var l33 = "0"+l3
                    }else{
                      var l33 = stringify(l3)
                    }
                    var l4=  new Date(l1).getFullYear()
        
                    var daaaat = l4+"-"+l33+"-"+l22;
                    // console.log(daaaat)
                    // console.log(searchBoxdate)
                    var hr = new Date(l1).getHours();
                    if(hr > 12)
                    {
                      hr -= 12
                    }
                    var min = new Date(l1).getMinutes();
                    
                    // if(daaaat===searchBoxdate2)
                    // {
                      
                      console.log("in if")
                      if(min==searchBoxtimemin)
                      {
                        dataeve2.push(element); 
                        // this.eventHistoryList.splice(this.eventHistoryList.indexOf(element),1)
                          // this.eventHistoryList.push(element)  
                      }
                    
                    
                  }
                }
              });
              if(dataeve2.length!=0)
              {
                this.loadercall = false;
                this.eventHistoryList2 = dataeve2
                this.eventHistoryList2.forEach((obj,index) => {
                  for (const k in obj) {
                    if (k == "Timestamp") {
                      obj["Timestamp"] = obj[k];
                      obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                            
                      obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                      obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                      // delete obj[k]
                    }
                  }
                });
              }else{
                this.loadercall = false;
                this.eventHistoryList2 = null;
              }

            }else if(searchBoxtimemin!="" && searchBoxtimehr!=""){
              console.log("in third if")
              let twomatch = [];
              this.eventHistoryList2.forEach((element,index) => {
                for (const k in element) {
                // console.log(k === 'org_timezone')
                        
                        if (k === "created_on") {
                          let l1 = element[k];
                          // console.log("in here 2")
                          
                          // console.log(this.orgtimezone)
                          console.log(this.eventHistoryList2[index]['org_timezone'])
                          l1 = new Date(l1).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                          console.log(l1)
                          var l2 = new Date(l1).getDate();
                          console.log(l2)
                          if(l2 <= 9)
                          {
                            var l22 = "0"+l2  
                          }
                          else{
                            var l22 = stringify(l2)
                          }
                          var l3 = new Date(l1).getMonth() + 1;
                          if(l3 <= 9)
                          {
                            var l33 = "0"+l3
                          }else{
                            var l33 = stringify(l3)
                          }
                          var l4=  new Date(l1).getFullYear()
              
                          var daaaat = l4+"-"+l33+"-"+l22;
                          // console.log(daaaat)
                          // console.log(searchBoxdate)
                          var hr = new Date(l1).getHours();
                          console.log(hr)
                          if(hr > 12)
                          {
                            hr -= 12
                          }
                          var min = new Date(l1).getMinutes();
                          console.log(min)
                          // if(daaaat===searchBoxdate2)
                          // {
                          if(hr==searchBoxtimehr)
                          {
                            console.log("in if")
                            if(min==searchBoxtimemin)
                            {
                              console.log(min)
                              console.log(searchBoxtimemin)
                              twomatch.push(element)
                              // this.eventHistoryList.splice(this.eventHistoryList.indexOf(element),1)
                                // this.eventHistoryList.push(element)  
                            }
                          }  
                            
                          
                          
                        }
                }
              });

              if(twomatch.length!=0)
              {
                      this.loadercall = false;
                      this.eventHistoryList2 = twomatch;
                      console.log(this.eventHistoryList2)
                      this.eventHistoryList2.forEach((obj,index) => {
                        for (const k in obj) {
                          if (k == "Timestamp") {
                            obj["Timestamp"] = obj[k];
                            obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                            obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                            obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                            // delete obj[k]
                          }
                        }
                      });
              }else{
                      this.loadercall = false;
                      this.eventHistoryList2 = null;
              }
            }
       
      }); 
    
           
    }else{

    console.log("inelse")
    console.log(searchBoxdate2,searchBoxvisitorname,searchBoxphno,searchboxHomeaddress,searchBoxtimehr,searchBoxtimemin);
    this.publicService.searcheventverify(guid,searchBoxdate2,searchBoxvisitorname,searchBoxphno,searchboxHomeaddress,searchBoxtimehr,searchBoxtimemin)
        .pipe(first())
        .subscribe(
          data => {
            this.loadercall = false;
            console.log(data);
            if(data['returncode']=='1' || data['returncode'] == 1)
            {
              let eventHistory34 = []
              eventHistory34 = data['data']['Event search']
              console.log(eventHistory34)

              
              if(this.eventHistoryList2!=null)
              {
                this.eventHistoryList2.splice(0,this.eventHistoryList.length) 
              }else{
                this.eventHistoryList2 = []
              }

              
              // this.eventHistoryList = eventHistory2
              if(searchBoxdate2!=""&&searchBoxtimehr!=""&& searchBoxtimemin!=""){
                let if1 = []
                eventHistory34.forEach((element,index) => {
                  for (const k in element) {
                    if (k === "created_on") {
                      let l1 = element[k];
                      let zone = eventHistory34[index]['org_timezone']
                      l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                      var l2 = new Date(l1).getDate();
                      if(l2 <= 9)
                      {
                        var l22 = "0"+l2  
                      }
                      else{
                        var l22 = stringify(l2)
                      }
                      var l3 = new Date(l1).getMonth() + 1;
                      if(l3 <= 9)
                      {
                        var l33 = "0"+l3
                      }else{
                        var l33 = stringify(l3)
                      }
                      var l4=  new Date(l1).getFullYear()

                      var daaaat = l4+"-"+l33+"-"+l22;
                      // console.log(daaaat)
                      // console.log(searchBoxdate)
                      var hr = new Date(l1).getHours();
                      if(hr > 12)
                      {
                        hr -= 12
                      }
                      var min = new Date(l1).getMinutes();
                      
                      if(daaaat===searchBoxdate2)
                      {
                        
                        
                        if(hr==searchBoxtimehr)
                        {
                          console.log(min)
                          console.log(searchBoxtimemin)
                          console.log("in if")
                          if(Number(min)===Number(searchBoxtimemin))
                          {
                            console.log("in if if")
                            console.log(min)
                            console.log(searchBoxtimemin)
                            console.log(element)
                            if1.push(element)
                          }

                        }
                        // console.log(typeof(element))
                        
                      }
                      // else{
                      //   console.log("in else")
                      //   console.log(eventHistory3.indexOf(element))
                      //   // console.log(eventHistory2.indexOf(element))
                      //   eventHistory3.splice(eventHistory3.indexOf(element),1)
                      // }
                      
                    }
                  }
                });
                console.log(if1)

                this.eventHistoryList2 = if1;
                console.log(this.eventHistoryList2.length)
                // if(this.eventHistoryList2.length != 0)
                // {
                //   console.log("inde here")
                //   this.eventHistoryList2.forEach((obj,index) => {
                //     for (const k in obj) {
                //       if (k == "Timestamp") {
                //         obj["Timestamp"] = obj[k];
                //         console.log(this.eventHistoryList2[index]['org_timezone'])
                //         console.log("here")
                //         // obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                //         // obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                //         // obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                //         // delete obj[k]
                //       }
                //     }
                //   });
                // }
                // this.loadercall = false;
                if(this.eventHistoryList2.length == 0)
                {
                  // this.loadercall = false;
                  this.eventHistoryList2 = null;
                }
              }else if(searchBoxdate2!=""&& searchBoxtimehr!=""&& searchBoxtimemin=="")
              {
                let if2 = [];
                eventHistory34.forEach((element,index) => {
                  for (const k in element) {
                    if (k === "created_on") {
                      let l1 = element[k];
                      let zone = eventHistory34[index]['org_timezone']
                      l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                      var l2 = new Date(l1).getDate();
                      if(l2 <= 9)
                      {
                        var l22 = "0"+l2  
                      }
                      else{
                        var l22 = stringify(l2)
                      }
                      var l3 = new Date(l1).getMonth() + 1;
                      if(l3 <= 9)
                      {
                        var l33 = "0"+l3
                      }else{
                        var l33 = stringify(l3)
                      }
                      var l4=  new Date(l1).getFullYear()

                      var daaaat = l4+"-"+l33+"-"+l22;
                      // console.log(daaaat)
                      // console.log(searchBoxdate)
                      var hr = new Date(l1).getHours();
                      if(hr > 12)
                      {
                        hr -= 12
                      }
                      var min = new Date(l1).getMinutes();
                      
                      if(daaaat===searchBoxdate2)
                      {
                        
                        if(hr==searchBoxtimehr)
                        {
                          
                            if2.push(element)
                          
                        }
                         
                      }
                      
                    }
                  }
                });
                this.eventHistoryList2 = if2;
                // if(this.eventHistoryList2.length != 0)
                // {
                //   this.eventHistoryList2.forEach((obj,index) => {
                //     for (const k in obj) {
                //       if (k == "Timestamp") {
                //         obj["Timestamp"] = obj[k];
                //         obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                //         // obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                //         // obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                //         // delete obj[k]
                //       }
                //     }
                //   });
                // }
                // this.loadercall = false;
                if(this.eventHistoryList2.length == 0)
                {
                  // this.loadercall = false;  
                  this.eventHistoryList2 = null;
                }


              }else if(searchBoxdate2!=""&&searchBoxtimehr==""&&searchBoxtimemin==""){
                let if3 = []
                eventHistory34.forEach((element,index) => {
                  for (const k in element) {
                    if (k === "created_on") {
                      let l1 = element[k];
                      let zone = eventHistory34[index]['org_timezone']
                      l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                      var l2 = new Date(l1).getDate();
                      if(l2 <= 9)
                      {
                        var l22 = "0"+l2  
                      }
                      else{
                        var l22 = stringify(l2)
                      }
                      var l3 = new Date(l1).getMonth() + 1;
                      if(l3 <= 9)
                      {
                        var l33 = "0"+l3
                      }else{
                        var l33 = stringify(l3)
                      }
                      var l4=  new Date(l1).getFullYear()

                      var daaaat = l4+"-"+l33+"-"+l22;
                      // console.log(daaaat)
                      // console.log(searchBoxdate)
                      var hr = new Date(l1).getHours();
                      if(hr > 12)
                      {
                        hr -= 12
                      }
                      var min = new Date(l1).getMinutes();
                      
                      if(daaaat===searchBoxdate2)
                      {
                        
                        console.log("in if")  
                        if3.push(element)
                          
                        
                        // console.log(typeof(element))
                        
                      }else{
                        console.log("in else")
                        console.log(eventHistory34.indexOf(element))
                        // console.log(eventHistory2.indexOf(element))
                        eventHistory34.splice(eventHistory34.indexOf(element),1)
                      }
                      
                    }
                  }
                });
                // this.loadercall = false;
                this.eventHistoryList2 = if3;
                // if(this.eventHistoryList2.length != 0)
                // {
                //   this.eventHistoryList2.forEach((obj,index) => {
                //     for (const k in obj) {
                //       if (k == "Timestamp") {
                //         obj["Timestamp"] = obj[k];
                //         obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                //         // obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                //         // obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                //         // delete obj[k]
                //       }
                //     }
                //   });
                // }
                if(this.eventHistoryList2.length == 0)
                {
                  // this.loadercall = false;
                  this.eventHistoryList2 = null;
                }

              }else if(searchBoxtimemin!=""&&searchBoxtimehr==""&&searchBoxdate2=="")
              {
                let if4 = []
                eventHistory34.forEach((element,index) => {
                  for (const k in element) {
                  if (k === "created_on") {
                    let l1 = element[k];
                    let zone = eventHistory34[index]['org_timezone']
                    l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                    var l2 = new Date(l1).getMinutes();
                    // if(l2 > 12)
                    // {
                    //   l2 -= 12
                    // }
                    if(l2==searchBoxtimemin)
                    {
                      console.log(element)
                      if4.push(element)
                    }else{
                      eventHistory34.splice(eventHistory34.indexOf(element),1)
                    }
                    var l3 = new Date(l1).getMinutes();
                    console.log(l2,l3)
                  }
                }
                });
                // this.loadercall = false;
                this.eventHistoryList2 = if4;
                // if(this.eventHistoryList2.length != 0)
                // {
                //   this.eventHistoryList2.forEach((obj,index) => {
                //     for (const k in obj) {
                //       if (k == "Timestamp") {
                //         obj["Timestamp"] = obj[k];
                //         obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                //         // obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                //         // obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                //         // delete obj[k]
                //       }
                //     }
                //   });
                // }
                if(this.eventHistoryList2.length == 0)
                {
                  // this.loadercall = false;
                  this.eventHistoryList2 = null;
                }

              }
              else if(searchBoxtimehr!=""&&searchBoxtimemin==""&&searchBoxdate2==""){
                let if5 = []
                eventHistory34.forEach((element,index) => {
                  for (const k in element) {
                  if (k === "created_on") {
                    let l1 = element[k];
                    let zone = eventHistory34[index]['org_timezone']
                    l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                    var l2 = new Date(l1).getHours();
                    if(l2 > 12)
                    {
                      l2 -= 12
                    }
                    if(l2==searchBoxtimehr)
                    {
                      console.log(element)
                      if5.push(element)
                    }else{
                      eventHistory34.splice(eventHistory34.indexOf(element),1)
                    }
                    var l3 = new Date(l1).getMinutes();
                    console.log(l2,l3)
                  }
                }
                });
                this.eventHistoryList2 = if5;
                // if(this.eventHistoryList2.length != 0)
                // {
                //   this.eventHistoryList2.forEach((obj,index) => {
                //     for (const k in obj) {
                //       if (k == "Timestamp") {
                //         obj["Timestamp"] = obj[k];
                //         obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                //         // obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                //         // obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                //         // delete obj[k]
                //       }
                //     }
                //   });
                // }
                // this.loadercall = false;
                if(this.eventHistoryList2.length == 0)
                {
                  this.eventHistoryList2 = null;
                  // this.loadercall = false;
                }


              }else if(searchBoxdate2==""&&searchBoxtimehr!=""&&searchBoxtimemin!=""){
                let if6 = [];
                eventHistory34.forEach((element,index) => {
                  for (const k in element) {
                  if (k === "created_on") {
                    let l1 = element[k];
                    let zone = eventHistory34[index]['org_timezone']
                    l1 = new Date(l1).toLocaleString("en-US", {timeZone: zone});
                    var l2 = new Date(l1).getHours();
                    if(l2 > 12)
                    {
                      l2 -= 12
                    }
                    var min = new Date(l1).getMinutes();
                    if(l2==searchBoxtimehr)
                    {
                      console.log(element)
                      if(min==searchBoxtimemin)
                      {
                        if6.push(element)
                      }

                    }
                  }
                  }
                });
                console.log(if6)
                // this.loadercall = false;
                this.eventHistoryList2 = if6;
                // if(this.eventHistoryList2 != 0)
                // {
                //   this.eventHistoryList2.forEach((obj,index) => {
                //     for (const k in obj) {
                //       if (k == "Timestamp") {
                //         obj["Timestamp"] = obj[k];
                //         obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                //         // obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                //         // obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                //         // delete obj[k]
                //       }
                //     }
                //   });
                // }
                if(this.eventHistoryList2.length == 0)
                {
                  // this.loadercall = false;
                  this.eventHistoryList2 = null;
                }

                // console.log(this.eventHistoryList)
              }
              else{
                // this.loadercall = false;
                this.eventHistoryList2 = eventHistory34
                // if(this.eventHistoryList2.length != 0)
                // {

                
                //   this.eventHistoryList2.forEach((obj,index) => {
                //     for (const k in obj) {
                //       if (k == "Timestamp") {
                //         obj["Timestamp"] = obj[k];
                //         obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                //         // obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                //         // obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                //         // delete obj[k]
                //       }
                //     }
                //   });
                // }
              }
              // this.eventHistoryList = eventHistory2
              this.eventHistoryList2.forEach((obj,index) => {
                for (const k in obj) {
                  if (k === "name") {
                    obj["VisitorName"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "email") {
                    obj["Email"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "phone_number") {
                    obj["PhoneNumber"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "address") {
                    obj["HomeAddress"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "timestamp") {
                    obj["Timestamp"] = obj[k];
                   
                    obj["Timestamp"] = new Date(obj["Timestamp"]).toLocaleString("en-US", {timeZone: this.eventHistoryList2[index]['org_timezone']});
                    obj["Timestamp"] = this.replaceAll(obj["Timestamp"],',','');
                    obj["Timestamp"] = this.replaceTime(obj["Timestamp"])
                    delete obj[k]
                  }
                  if (k == "location") {
                    obj["ScannedLocation"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "device_id") {
                    obj["PhoneUniqueId"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "scanned_by") {
                    obj["ScannedBy"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "qr_verify_link") {
                    obj["VerifyLink"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "shop_name") {
                    obj["OrganisationName"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "profiles") {
                    obj["Profiles"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "qr_guid") {
                    obj["Guid"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "transaction_link") {
                    obj["TransactionLink"] = obj[k];
                    delete obj[k]
                  }
                  if (k == "org_address") {
                    obj["OrganisationAddress"] = obj[k];
                    delete obj[k]
                  }
                }
              })
              // console.log(this.eventHistoryList);
              
            
              this.eventHistoryList2 = this.eventHistoryList2.sort((a, b) => b.id - a.id);
              if(this.eventHistoryList2.length==0)
              {
                // this.loadercall = false;
                this.eventHistoryList2 = null;
              }
            }else{
              // this.loadercall = false;
              this.eventHistoryList2 = null;
              // co
            }
          });

    }
  }

  clear()
  {
    // searchBoxdate2.value= '';
    // searchBoxvisitorname.value = '';
    // searchBoxphno.value=''
    // searchboxHomeaddress.value='';
    this.loadercall = true;
    console.log("this calles")
    this.searchform.reset();
    // this.loadjs()
    this.searchcall = false;
    this.searchloadingbar = false
    this.eventHistory();
    this.mySub = interval(10000).subscribe((func => {
      // this.loadjs()
      console.log("no")
      console.log(typeof(this.searchcall))

      this.eventHistory();
    }))
    // this.eventHistory()
    // this.loadAllUsers()
    // let script = this._renderer2.createElement('script');
    // script.type = "text/javascript";
    // script.src = "assets/js/filterpopup.js";
    // script.id = "f_scrip_1";

    // let script2 = this._renderer2.createElement('script');
    // script2.type = "text/javascript";
    // script2.src = "https://code.jquery.com/jquery-1.12.4.js";
    // script2.id = "f_scrip_2";
    
    // let script3 = this._renderer2.createElement('script');
    // script3.type = "text/javascript";
    // script3.src = "https://code.jquery.com/ui/1.12.1/jquery-ui.js";
    // script3.id = "f_scrip_3";
    
    // let script4 = this._renderer2.createElement('script');
    // script4.type = "text/javascript";
    // script4.src = "assets/js/search.js";
    // script4.id = "f_scrip_4";
    // // <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.0.7/angular.min.js"></script>
    
    // let script5 = this._renderer2.createElement('script');
    // script5.type = "text/javascript";
    // script5.src = "https://ajax.googleapis.com/ajax/libs/angularjs/1.0.7/angular.min.js";
    // script5.id = "f_scrip_5";
    // if(document.getElementById("f_scrip_5")==null)
    // {
    //   this._renderer2.appendChild(this._document.body, script5);
    // }else
    // {
    //   document.getElementById("f_scrip_5").remove()
    //   this._renderer2.appendChild(this._document.body, script5);
    // }
    // if(document.getElementById("f_scrip_4")==null)
    // {

    //   this._renderer2.appendChild(this._document.body, script4);
    // }else{
    //   document.getElementById("f_scrip_4").remove()
    //   this._renderer2.appendChild(this._document.body, script4);
    // }
    // if(document.getElementById("f_scrip_2")==null)
    // {
    //   this._renderer2.appendChild(this._document.body, script2);
    // }else{
    //   document.getElementById("f_scrip_2").remove()
    //   this._renderer2.appendChild(this._document.body, script2);
    // }
    // if(document.getElementById("f_scrip_3")==null)
    // {
    //   this._renderer2.appendChild(this._document.body, script3);
    // }else{
    //   document.getElementById("f_scrip_3").remove()
    //   this._renderer2.appendChild(this._document.body, script3);
    // }    
    // if(document.getElementById("f_scrip_1")==null)
    // {
    //   this._renderer2.appendChild(this._document.body, script);
    // }else{
    //   document.getElementById("f_scrip_1").remove()
    //   this._renderer2.appendChild(this._document.body, script);
    // }
  }


}
