<div class="main-wrapper">
  <!-- <app-loader [loadingMessage]="'Loading'" [countTo]="100" [duration]="5000" *ngIf="loading"></app-loader> -->
  <div class="wrapper-fix-footer">
    <app-header></app-header>
    <div class="page-body-wrapper">
      <div class="custom-container">
        
        <div class="page-body-content">
          <form [formGroup]="searchform"
            (ngSubmit)="search(searchBoxdate.value,searchBoxorganisationid.value,searchBoxorganisationame.value,searchBoxnetwork.value)">
            <div class="page-top-heading master-dash-heading">
              <h1 class="page-title">My Organisations</h1>
              <div class="page-search">
                <div class="search-div" data-toggle="collapse" href="#my-organisation-search" role="button"
                  aria-expanded="false">
                  <input class="form-control search-custom" name="DocumentName" placeholder="Search" readonly="readonly"
                    type="text" value="">
                  <span class="search-icon-bar"><i class="fa fa-search" aria-hidden="true"></i></span>
                  <span class="search-down-cart">
                    <i class="fa fa-caret-down" aria-hidden="true"></i>
                  </span>
                </div>
                <div class="search-dropdown collapse p-0" id="my-organisation-search">
                  <div class="search-dropdown-pd">
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Date Created</label>
                      <div class="col-lg-8 col-sm-8 angular-calendar">
                        <!-- <input type="text" #searchBoxdate formControlName="searchBoxdate" class="form-control date-icon-input" id="datepicker" placeholder="Select a date" readonly="readonly"> -->
                        <input class="form-control date-icon-input" #searchBoxdate formControlName="searchBoxdate"
                          placeholder="Select date" name="dp" ngbDatepicker #f="ngbDatepicker" (click)="f.toggle()"
                          readonly="readonly">
                      </div>
                    </div>
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Orgsanisation Id</label>
                      <div class="col-lg-8 col-sm-8">
                        <input type="text" #searchBoxorganisationid class="form-control"
                          formControlName="searchBoxorganisationid">
                      </div>
                    </div>
                    <div class="form-group row search-form-group mb-2">
                      <label class="col-lg-4 col-sm-4 col-form-label">Organisation Name</label>
                      <div class="col-lg-8 col-sm-8">
                        <input type="text" class="form-control" #searchBoxorganisationame
                          formControlName="searchBoxorganisationame" required>
                      </div>
                    </div>
                    <div class="form-group row search-form-group">
                      <label class="col-lg-4 col-sm-4 col-form-label">Network</label>
                      <div class="col-lg-8 col-sm-8">
                        <!-- <input type="text" #searchBoxnetwork  class="form-control" formControlName="searchBoxnetwork"> -->
                        <select class="form-control" id="networks" #searchBoxnetwork>
                          <option value="0">TESTNET</option>
                          <option value="1">MAINNET</option>
                        </select>
                      </div>
                    </div>
                    <div class="search-btn-group">
                      <input type="submit" class="btn-search" value="Search" data-toggle="collapse"
                        href="#my-organisation-search">
                      <input type="button" class="btn-search ml-3" value="Clear" (click)="clear()" id="clearButton">
                    </div>
                  </div>
                </div>
              </div>
              <a href="/create-new-organisation" class="gradiant-btn mb-xl-0 mb-lg-0 mb-md-4 mb-sm-4 mb-4"><i
                  class="fa fa-plus mr-1 mt-1"></i> Create New
              </a>
            </div>
          </form>

          <div class="dashboard-table" ng-controller="TodoController">
            <table class="table table-striped table-dash">
              <thead>
                <tr>
                  <th>Date Created</th>
                  <th>Organisation ID</th>
                  <th>Organisation Name</th>
                  <th>Current Scans</th>
                  <th>Scan Allowed</th>
                  <th>Network</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data1 of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize ;">
                  <td data-label="Date Created">{{ data1.created_on| date:'dd/MM/yyyy' }}</td>
                  <td data-label="Organisation ID">{{ data1.org_uniqueid }}</td>
                  <td data-label="Organisation Name" class="m-organisation-name">{{ data1.org_name }}</td>

                  <td data-label="Current Scans">
                    {{ data1.current_scan}}
                  </td>


                  <td data-label="Scan Allowed">{{ data1.subscription_counter }}</td>
                  <td data-label="Network">
                    <div *ngIf="data1.network == 1"> MAINNET </div>
                    <div *ngIf="data1.network == 0"> TESTNET </div>

                  </td>
                  <td data-label="Status">
                    <label class="switch">
                      <input type="checkbox" [(ngModel)]="data1.status" [checked]="!data1.status"
                        (click)="switchfunc($event,data1.org_uniqueid,i)">
                      <span class="slider round"></span>
                    </label>
                  </td>
                  <td data-label="Action">
                    <div class="table-action">
                      <a (click)="onEditOrganization(data1.org_uniqueid)" title="Edit">
                        <img src="assets/img/edit-icon.png" alt="icon">
                      </a>

                      <a (click)="goToPage(data1.org_uniqueid)" class="ml-3" title="view">
                        <img src="assets/img/view-icon.png" alt="icon">
                      </a>

                    </div>
                  </td>
                </tr>
                <tr *ngIf="data==null">
                  <td colspan="9">
                    No Records Found
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- loader -->
            <!-- <app-loader [loadingMessage]="'Loading'" [countTo]="100" [duration]="5000" *ngIf="loading"></app-loader> -->
          </div>


          <div *ngIf="data!=null">
            <div class="pager" *ngIf="data.length > 10">
              <ngb-pagination [collectionSize]="data.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <app-footer></app-footer>