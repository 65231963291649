<footer class="login-footer">
  <div class="footer-top">
    <div class="custom-container">
      <div class="row center-align">
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <div *ngIf="currentUser">
            <div class="footer-logo" *ngIf="(currentUser.role == '1')">
              <a href="/dashboard"> <img src="assets/img/footer_logo.svg" alt="logo"></a>
            </div>
            <div class="footer-logo" *ngIf="(currentUser.role == '2')">
              <a href="/master-admin-dashboard"> <img src="assets/img/footer_logo.svg" alt="logo"></a>
            </div>
          </div>
          <div class="footer-logo" *ngIf="!currentUser">
            <a href="/"> <img src="assets/img/footer_logo.svg" alt="logo"></a>
          </div>

        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <div class="footer-center">
            <div *ngIf="currentUser">
              <div *ngIf="(currentUser.role == '1' || currentUser.role == 1)">

                <ul class="footer-center-ul">


                  <!-- <li><a href="/create-new-organisation">Manage User</a></li> -->
                  <li><a href="/dashboard">Dashboard</a></li>
                  <li><a href="/generateqr">Create New QR</a></li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <div class="footer-social-follow">
            <div class="footer-follow-process">
              <!-- <h2>
                            FOLLOW OUR <br> <span>PROGRESS</span>
                          </h2> -->
              <img src="assets/img/FollowOurProgress.png" alt="img">
            </div>
            <div class="footer-social-icon">
              <a href="https://twitter.com/VeriDocGlobal" target="_blank"><i class="bi bi-twitter-x"></i></a>
              <a href="https://t.me/veridocglobal" target="_blank"><i class="fa fa-telegram" aria-hidden="true"></i></a>
              <a href="https://www.facebook.com/VeriDocGlobal" target="_blank"><i
                  class="fa fa-facebook-square social-font"></i></a>
              <a href="https://www.linkedin.com/company/veridocglobal" target="_blank"><i
                  class="fa fa-linkedin social-font"></i></a>
              <a href="https://www.instagram.com/VeriDocGlobal/" target="_blank"><i
                  class="fa fa-instagram social-font"></i></a>
              <a href="https://www.youtube.com/channel/UCbl5uvM3vd-XRm-aDj2YZJw" target="_blank"><i
                  class="fa fa-youtube-play social-font"></i></a>
              <a href="https://www.pinterest.com.au/veridocglobal/" target="_blank"><i
                  class="fa fa-pinterest social-font" aria-hidden="true"></i></a>
              <a href="https://medium.com/@veridocglobal" target="_blank"> <i class="fa fa-medium"
                  aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="custom-container">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <div class="copyright">
            <p>© copyright | all rights reserved, VeriDoc Global</p>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <div class="footer-mail"> <span><em>Have a question?</em></span><a href="mailto:admin@veridocglobal.com"><i
                class="fa fa-envelope-o"></i>admin@veridocglobal.com</a></div>
        </div>
        <div class="col-lg-4 col-md-12 col-sm-12 col-12">
          <div class="footer-slogan-div">
            <!-- <p>Changing the world one document at a time</p> -->
            <img src="assets/img/new-slogan.svg" alt="img">
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
