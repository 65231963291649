// import { Component, OnInit } from '@angular/core';
import { Renderer2,Component, OnInit ,Inject} from '@angular/core';

import { AuthenticationService } from '../../_services';
import { User } from '../../_models';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  currentUser: User;
  full_name:string;
  role:string;
  currentRoute:string;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2,
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.currentRoute = this.router.url;
    console.log(this.currentRoute);
   }

  ngOnInit(): void {

    // let script = this._renderer2.createElement('script');
    // script.type = "text/javascript";
    // script.id = "first"
    // script.src = "assets/js/filterpopup.js";
    // let script2 = this._renderer2.createElement('script');
    // script2.type = "text/javascript";
    // script2.id = "second"
    // script2.src = "https://code.jquery.com/jquery-1.12.4.js";
    
    // let script3 = this._renderer2.createElement('script');
    // script3.type = "text/javascript";
    // script3.id = "third"
    // script3.src = "https://code.jquery.com/ui/1.12.1/jquery-ui.js";

    // //For popup sequence is required for script
    // this._renderer2.appendChild(this._document.body, script2);
    // this._renderer2.appendChild(this._document.body, script3);
    
    // this._renderer2.appendChild(this._document.body, script);
  }

}
