import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { CreateqrComponent } from './createqr/createqr.component';
import { ViewerlistComponent } from './viewerlist/viewerlist.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { CreateNewOrganisationComponent } from './create-new-organisation/create-new-organisation.component';
import { MasterAdminDashboardComponent } from './master-admin-dashboard/master-admin-dashboard.component';
import { MasterAdminMyaccountComponent } from './master-admin-myaccount/master-admin-myaccount.component';
import { MasterAdminQrcodelistComponent } from './master-admin-qrcodelist/master-admin-qrcodelist.component';
import { MasterAdminRegisteredvisitorsComponent } from './master-admin-registeredvisitors/master-admin-registeredvisitors.component';
import { AuthGuard } from './_helpers';
import { QrverifyComponent } from './qrverify/qrverify.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { MasteradminCreateqrComponent } from './masteradmin-createqr/masteradmin-createqr.component';
import { EditorganisationComponent } from './editorganisation/editorganisation.component';
import { EmailsenttemplateComponent } from './components/emailsenttemplate/emailsenttemplate.component';
import { PasswordresetverifyComponent } from './components/passwordresetverify/passwordresetverify.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { EditqrComponent } from './editqr/editqr.component';

import { InforpageComponent } from './inforpage/inforpage.component';
import { HomepageComponent } from './homepage/homepage.component';

const routes: Routes = [
  // { path: '', component: HomepageComponent},
  { path: '', component: LoginComponent},
  { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard],},
  { path: 'generateqr', component: CreateqrComponent,canActivate: [AuthGuard],},
  { path: 'viewerlist', component: ViewerlistComponent,canActivate: [AuthGuard],},
  { path: 'myaccount', component: MyaccountComponent,canActivate: [AuthGuard],},
  { path: 'create-new-organisation', component: CreateNewOrganisationComponent,canActivate: [AuthGuard],},
  // { path: 'edit-organisation', component: CreateNewOrganisationComponent,canActivate: [AuthGuard],},
  { path: 'master-admin-dashboard', component: MasterAdminDashboardComponent,canActivate: [AuthGuard],},
  { path: 'master-admin-myaccount', component: MasterAdminMyaccountComponent,canActivate: [AuthGuard],},
  { path: 'master-admin-qrcodelist', component: MasterAdminQrcodelistComponent,canActivate: [AuthGuard],},
  { path: 'master-admin-registeredvisitors', component: MasterAdminRegisteredvisitorsComponent,canActivate: [AuthGuard],},
  { path: 'master-admin-createqr', component: MasteradminCreateqrComponent,canActivate: [AuthGuard],},
  { path: 'edit-organisation', component: EditorganisationComponent,canActivate: [AuthGuard],},
  { path: 'editqr', component: EditqrComponent,canActivate: [AuthGuard],},
  { path: "verify", component: QrverifyComponent },
  { path: 'recovery', component: ForgotpasswordComponent },
  { path: 'reset', component: ResetpasswordComponent },
  { path: 'recovery', component: ForgotpasswordComponent },
  { path: 'resetverify', component: PasswordresetverifyComponent },
  { path: 'emailsent', component:EmailsenttemplateComponent},
  { path: 'pagenotfound', component:PagenotfoundComponent},
  { path: 'info', component:InforpageComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
