import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'], 
})
export class LoaderComponent implements OnInit {
  @Input() loadingMessage: string = 'Loading';
  @Input() countTo: number = 100;
  @Input() duration: number = 2000;

  currentCount: number = 0;
  dots: string = '';
  loadingInterval: any;

  ngOnInit(): void {
    this.countUp();
    this.loadingInterval = setInterval(() => this.updateDots(), 600);
  }
  private updateDots(): void {
    if (this.dots.length < 3) {
      this.dots += '.';
    } else {
      this.dots = '';
    }
  }
  ngOnDestroy(): void {
    clearInterval(this.loadingInterval);
  }
  countUp() {
    const duration = this.duration;

    const interval = duration / this.countTo;

    const intervalId = setInterval(() => {
      this.currentCount += 1;

      if (this.currentCount === this.countTo) {
        clearInterval(intervalId);
      }
    }, interval);
  }
}
