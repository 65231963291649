import { Component, OnInit } from '@angular/core';
import { UserService, AuthenticationService } from '../_services';
import { User, Organisation } from '../_models';
import { first } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { MustMatch } from '../_helpers';
import { Http, Headers, RequestOptions } from '@angular/http';
import { stringify } from '@angular/compiler/src/util';
// import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { first } from 'rxjs/operators';
import { MustMatch } from '../_helpers/must-match.validator';

import * as $ from 'jquery';
import { AlertService } from '../_services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css'],
})
export class MyaccountComponent implements OnInit {
  // servicePeriod: string = 'Monthly • Aug 29 - Sep 29, 2023 • Next bill Sep 29, 2023';
  fieldTextType: boolean;
  confirmpass: boolean;
  selectedPlan: any = '';
  // radioData = [
  //   { planName: 'Standard', price: 30, planTimespan: 'Monthly' },
  //   { planName: 'Pro', price: 100, planTimespan: 'Monthly' },
  //   // Add more data for other radio buttons as needed
  // ];
  profilepicshow = 0;
  data: any = [];
  activePlan: any;
  billingHistory: any;
  alltimezonelist: Array<Object> = [
    { id: 0, name: 'Africa/Abidjan (+00:00)', value: 'Africa/Abidjan' },
    { id: 1, name: 'Africa/Accra (+00:00)', value: 'Africa/Accra' },
    { id: 2, name: 'Africa/Addis_Ababa (+03:00)', value: 'Africa/Addis_Ababa' },
    { id: 3, name: 'Africa/Algiers (+01:00)', value: 'Africa/Algiers' },
    { id: 4, name: 'Africa/Asmara (+03:00)', value: 'Africa/Asmara' },
    { id: 5, name: 'Africa/Asmera (+03:00)', value: 'Africa/Asmera' },
    { id: 6, name: 'Africa/Bamako (+00:00)', value: 'Africa/Bamako' },
    { id: 7, name: 'Africa/Bangui (+01:00)', value: 'Africa/Bangui' },
    { id: 8, name: 'Africa/Banjul (+00:00)', value: 'Africa/Banjul' },
    { id: 9, name: 'Africa/Bissau (+00:00)', value: 'Africa/Bissau' },
    { id: 10, name: 'Africa/Blantyre (+02:00)', value: 'Africa/Blantyre' },
    {
      id: 11,
      name: 'Africa/Brazzaville (+01:00)',
      value: 'Africa/Brazzaville',
    },
    { id: 12, name: 'Africa/Bujumbura (+02:00)', value: 'Africa/Bujumbura' },
    { id: 13, name: 'Africa/Cairo (+02:00)', value: 'Africa/Cairo' },
    { id: 14, name: 'Africa/Casablanca (+01:00)', value: 'Africa/Casablanca' },
    { id: 15, name: 'Africa/Ceuta (+01:00)', value: 'Africa/Ceuta' },
    { id: 16, name: 'Africa/Conakry (+00:00)', value: 'Africa/Conakry' },
    { id: 17, name: 'Africa/Dakar (+00:00)', value: 'Africa/Dakar' },
    {
      id: 18,
      name: 'Africa/Dar_es_Salaam (+03:00)',
      value: 'Africa/Dar_es_Salaam',
    },
    { id: 19, name: 'Africa/Djibouti (+03:00)', value: 'Africa/Djibouti' },
    { id: 20, name: 'Africa/Douala (+01:00)', value: 'Africa/Douala' },
    { id: 21, name: 'Africa/El_Aaiun (+01:00)', value: 'Africa/El_Aaiun' },
    { id: 22, name: 'Africa/Freetown (+00:00)', value: 'Africa/Freetown' },
    { id: 23, name: 'Africa/Gaborone (+02:00)', value: 'Africa/Gaborone' },
    { id: 24, name: 'Africa/Harare (+02:00)', value: 'Africa/Harare' },
    {
      id: 25,
      name: 'Africa/Johannesburg (+02:00)',
      value: 'Africa/Johannesburg',
    },
    { id: 26, name: 'Africa/Juba (+03:00)', value: 'Africa/Juba' },
    { id: 27, name: 'Africa/Kampala (+03:00)', value: 'Africa/Kampala' },
    { id: 28, name: 'Africa/Khartoum (+02:00)', value: 'Africa/Khartoum' },
    { id: 29, name: 'Africa/Kigali (+02:00)', value: 'Africa/Kigali' },
    { id: 30, name: 'Africa/Kinshasa (+01:00)', value: 'Africa/Kinshasa' },
    { id: 31, name: 'Africa/Lagos (+01:00)', value: 'Africa/Lagos' },
    { id: 32, name: 'Africa/Libreville (+01:00)', value: 'Africa/Libreville' },
    { id: 33, name: 'Africa/Lome (+00:00)', value: 'Africa/Lome' },
    { id: 34, name: 'Africa/Luanda (+01:00)', value: 'Africa/Luanda' },
    { id: 35, name: 'Africa/Lubumbashi (+02:00)', value: 'Africa/Lubumbashi' },
    { id: 36, name: 'Africa/Lusaka (+02:00)', value: 'Africa/Lusaka' },
    { id: 37, name: 'Africa/Malabo (+01:00)', value: 'Africa/Malabo' },
    { id: 38, name: 'Africa/Maputo (+02:00)', value: 'Africa/Maputo' },
    { id: 39, name: 'Africa/Maseru (+02:00)', value: 'Africa/Maseru' },
    { id: 40, name: 'Africa/Mbabane (+02:00)', value: 'Africa/Mbabane' },
    { id: 41, name: 'Africa/Mogadishu (+03:00)', value: 'Africa/Mogadishu' },
    { id: 42, name: 'Africa/Monrovia (+00:00)', value: 'Africa/Monrovia' },
    { id: 43, name: 'Africa/Nairobi (+03:00)', value: 'Africa/Nairobi' },
    { id: 44, name: 'Africa/Ndjamena (+01:00)', value: 'Africa/Ndjamena' },
    { id: 45, name: 'Africa/Niamey (+01:00)', value: 'Africa/Niamey' },
    { id: 46, name: 'Africa/Nouakchott (+00:00)', value: 'Africa/Nouakchott' },
    {
      id: 47,
      name: 'Africa/Ouagadougou (+00:00)',
      value: 'Africa/Ouagadougou',
    },
    { id: 48, name: 'Africa/Porto-Novo (+01:00)', value: 'Africa/Porto-Novo' },
    { id: 49, name: 'Africa/Sao_Tome (+00:00)', value: 'Africa/Sao_Tome' },
    { id: 50, name: 'Africa/Timbuktu (+00:00)', value: 'Africa/Timbuktu' },
    { id: 51, name: 'Africa/Tripoli (+02:00)', value: 'Africa/Tripoli' },
    { id: 52, name: 'Africa/Tunis (+01:00)', value: 'Africa/Tunis' },
    { id: 53, name: 'Africa/Windhoek (+02:00)', value: 'Africa/Windhoek' },
    { id: 54, name: 'America/Adak (-10:00)', value: 'America/Adak' },
    { id: 55, name: 'America/Anchorage (-09:00)', value: 'America/Anchorage' },
    { id: 56, name: 'America/Anguilla (-04:00)', value: 'America/Anguilla' },
    { id: 57, name: 'America/Antigua (-04:00)', value: 'America/Antigua' },
    { id: 58, name: 'America/Araguaina (-03:00)', value: 'America/Araguaina' },
    {
      id: 59,
      name: 'America/Argentina/Buenos_Aires (-03:00)',
      value: 'America/Argentina/Buenos_Aires',
    },
    {
      id: 60,
      name: 'America/Argentina/Catamarca (-03:00)',
      value: 'America/Argentina/Catamarca',
    },
    {
      id: 61,
      name: 'America/Argentina/ComodRivadavia (-03:00)',
      value: 'America/Argentina/ComodRivadavia',
    },
    {
      id: 62,
      name: 'America/Argentina/Cordoba (-03:00)',
      value: 'America/Argentina/Cordoba',
    },
    {
      id: 63,
      name: 'America/Argentina/Jujuy (-03:00)',
      value: 'America/Argentina/Jujuy',
    },
    {
      id: 64,
      name: 'America/Argentina/La_Rioja (-03:00)',
      value: 'America/Argentina/La_Rioja',
    },
    {
      id: 65,
      name: 'America/Argentina/Mendoza (-03:00)',
      value: 'America/Argentina/Mendoza',
    },
    {
      id: 66,
      name: 'America/Argentina/Rio_Gallegos (-03:00)',
      value: 'America/Argentina/Rio_Gallegos',
    },
    {
      id: 67,
      name: 'America/Argentina/Salta (-03:00)',
      value: 'America/Argentina/Salta',
    },
    {
      id: 68,
      name: 'America/Argentina/San_Juan (-03:00)',
      value: 'America/Argentina/San_Juan',
    },
    {
      id: 69,
      name: 'America/Argentina/San_Luis (-03:00)',
      value: 'America/Argentina/San_Luis',
    },
    {
      id: 70,
      name: 'America/Argentina/Tucuman (-03:00)',
      value: 'America/Argentina/Tucuman',
    },
    {
      id: 71,
      name: 'America/Argentina/Ushuaia (-03:00)',
      value: 'America/Argentina/Ushuaia',
    },
    { id: 72, name: 'America/Aruba (-04:00)', value: 'America/Aruba' },
    { id: 73, name: 'America/Asuncion (-03:00)', value: 'America/Asuncion' },
    { id: 74, name: 'America/Atikokan (-05:00)', value: 'America/Atikokan' },
    { id: 75, name: 'America/Atka (-10:00)', value: 'America/Atka' },
    { id: 76, name: 'America/Bahia (-03:00)', value: 'America/Bahia' },
    {
      id: 77,
      name: 'America/Bahia_Banderas (-06:00)',
      value: 'America/Bahia_Banderas',
    },
    { id: 78, name: 'America/Barbados (-04:00)', value: 'America/Barbados' },
    { id: 79, name: 'America/Belem (-03:00)', value: 'America/Belem' },
    { id: 80, name: 'America/Belize (-06:00)', value: 'America/Belize' },
    {
      id: 81,
      name: 'America/Blanc-Sablon (-04:00)',
      value: 'America/Blanc-Sablon',
    },
    { id: 82, name: 'America/Boa_Vista (-04:00)', value: 'America/Boa_Vista' },
    { id: 83, name: 'America/Bogota (-05:00)', value: 'America/Bogota' },
    { id: 84, name: 'America/Boise (-07:00)', value: 'America/Boise' },
    {
      id: 85,
      name: 'America/Buenos_Aires (-03:00)',
      value: 'America/Buenos_Aires',
    },
    {
      id: 86,
      name: 'America/Cambridge_Bay (-07:00)',
      value: 'America/Cambridge_Bay',
    },
    {
      id: 87,
      name: 'America/Campo_Grande (-04:00)',
      value: 'America/Campo_Grande',
    },
    { id: 88, name: 'America/Cancun (-05:00)', value: 'America/Cancun' },
    { id: 89, name: 'America/Caracas (-04:00)', value: 'America/Caracas' },
    { id: 90, name: 'America/Catamarca (-03:00)', value: 'America/Catamarca' },
    { id: 91, name: 'America/Cayenne (-03:00)', value: 'America/Cayenne' },
    { id: 92, name: 'America/Cayman (-05:00)', value: 'America/Cayman' },
    { id: 93, name: 'America/Chicago (-06:00)', value: 'America/Chicago' },
    { id: 94, name: 'America/Chihuahua (-07:00)', value: 'America/Chihuahua' },
    {
      id: 95,
      name: 'America/Coral_Harbour (-05:00)',
      value: 'America/Coral_Harbour',
    },
    { id: 96, name: 'America/Cordoba (-03:00)', value: 'America/Cordoba' },
    {
      id: 97,
      name: 'America/Costa_Rica (-06:00)',
      value: 'America/Costa_Rica',
    },
    { id: 98, name: 'America/Creston (-07:00)', value: 'America/Creston' },
    { id: 99, name: 'America/Cuiaba (-04:00)', value: 'America/Cuiaba' },
    { id: 100, name: 'America/Curacao (-04:00)', value: 'America/Curacao' },
    {
      id: 101,
      name: 'America/Danmarkshavn (+00:00)',
      value: 'America/Danmarkshavn',
    },
    { id: 102, name: 'America/Dawson (-07:00)', value: 'America/Dawson' },
    {
      id: 103,
      name: 'America/Dawson_Creek (-07:00)',
      value: 'America/Dawson_Creek',
    },
    { id: 9, name: 'America/Denver (-07:00)', value: 'America/Denver' },
    { id: 9, name: 'America/Detroit (-05:00)', value: 'America/Detroit' },
    { id: 9, name: 'America/Dominica (-04:00)', value: 'America/Dominica' },
    { id: 9, name: 'America/Edmonton (-07:00)', value: 'America/Edmonton' },
    { id: 9, name: 'America/Eirunepe (-05:00)', value: 'America/Eirunepe' },
    {
      id: 9,
      name: 'America/El_Salvador (-06:00)',
      value: 'America/El_Salvador',
    },
    { id: 9, name: 'America/Ensenada (-08:00)', value: 'America/Ensenada' },
    {
      id: 9,
      name: 'America/Fort_Nelson (-07:00)',
      value: 'America/Fort_Nelson',
    },
    { id: 9, name: 'America/Fort_Wayne (-05:00)', value: 'America/Fort_Wayne' },
    { id: 9, name: 'America/Fortaleza (-03:00)', value: 'America/Fortaleza' },
    { id: 9, name: 'America/Glace_Bay (-04:00)', value: 'America/Glace_Bay' },
    { id: 9, name: 'America/Godthab (-03:00)', value: 'America/Godthab' },
    { id: 9, name: 'America/Goose_Bay (-04:00)', value: 'America/Goose_Bay' },
    { id: 9, name: 'America/Grand_Turk (-05:00)', value: 'America/Grand_Turk' },
    { id: 9, name: 'America/Grenada (-04:00)', value: 'America/Grenada' },
    { id: 9, name: 'America/Guadeloupe (-04:00)', value: 'America/Guadeloupe' },
    { id: 9, name: 'America/Guatemala (-06:00)', value: 'America/Guatemala' },
    { id: 9, name: 'America/Guayaquil (-05:00)', value: 'America/Guayaquil' },
    { id: 9, name: 'America/Guyana (-04:00)', value: 'America/Guyana' },
    { id: 9, name: 'America/Halifax (-04:00)', value: 'America/Halifax' },
    { id: 9, name: 'America/Havana (-05:00)', value: 'America/Havana' },
    { id: 9, name: 'America/Hermosillo (-07:00)', value: 'America/Hermosillo' },
    {
      id: 9,
      name: 'America/Indiana/Indianapolis (-05:00)',
      value: 'America/Indiana/Indianapolis',
    },
    {
      id: 9,
      name: 'America/Indiana/Knox (-06:00)',
      value: 'America/Indiana/Knox',
    },
    {
      id: 9,
      name: 'America/Indiana/Marengo (-05:00)',
      value: 'America/Indiana/Marengo',
    },
    {
      id: 9,
      name: 'America/Indiana/Petersburg (-05:00)',
      value: 'America/Indiana/Petersburg',
    },
    {
      id: 9,
      name: 'America/Indiana/Tell_City (-06:00)',
      value: 'America/Indiana/Tell_City',
    },
    {
      id: 9,
      name: 'America/Indiana/Vevay (-05:00)',
      value: 'America/Indiana/Vevay',
    },
    {
      id: 9,
      name: 'America/Indiana/Vincennes (-05:00)',
      value: 'America/Indiana/Vincennes',
    },
    {
      id: 9,
      name: 'America/Indiana/Winamac (-05:00)',
      value: 'America/Indiana/Winamac',
    },
    {
      id: 9,
      name: 'America/Indianapolis (-05:00)',
      value: 'America/Indianapolis',
    },
    { id: 9, name: 'America/Inuvik (-07:00)', value: 'America/Inuvik' },
    { id: 9, name: 'America/Iqaluit (-05:00)', value: 'America/Iqaluit' },
    { id: 9, name: 'America/Jamaica (-05:00)', value: 'America/Jamaica' },
    { id: 9, name: 'America/Jujuy (-03:00)', value: 'America/Jujuy' },
    { id: 9, name: 'America/Juneau (-09:00)', value: 'America/Juneau' },
    {
      id: 9,
      name: 'America/Kentucky/Louisville (-05:00)',
      value: 'America/Kentucky/Louisville',
    },
    {
      id: 9,
      name: 'America/Kentucky/Monticello (-05:00)',
      value: 'America/Kentucky/Monticello',
    },
    { id: 9, name: 'America/Knox_IN (-06:00)', value: 'America/Knox_IN' },
    { id: 9, name: 'America/Kralendijk (-04:00)', value: 'America/Kralendijk' },
    { id: 9, name: 'America/La_Paz (-04:00)', value: 'America/La_Paz' },
    { id: 9, name: 'America/Lima (-05:00)', value: 'America/Lima' },
    {
      id: 9,
      name: 'America/Los_Angeles (-08:00)',
      value: 'America/Los_Angeles',
    },
    { id: 9, name: 'America/Louisville (-05:00)', value: 'America/Louisville' },
    {
      id: 9,
      name: 'America/Lower_Princes (-04:00)',
      value: 'America/Lower_Princes',
    },
    { id: 9, name: 'America/Maceio (-03:00)', value: 'America/Maceio' },
    { id: 9, name: 'America/Managua (-06:00)', value: 'America/Managua' },
    { id: 9, name: 'America/Manaus (-04:00)', value: 'America/Manaus' },
    { id: 9, name: 'America/Marigot (-04:00)', value: 'America/Marigot' },
    { id: 9, name: 'America/Martinique (-04:00)', value: 'America/Martinique' },
    { id: 9, name: 'America/Matamoros (-06:00)', value: 'America/Matamoros' },
    { id: 9, name: 'America/Mazatlan (-07:00)', value: 'America/Mazatlan' },
    { id: 9, name: 'America/Mendoza (-03:00)', value: 'America/Mendoza' },
    { id: 9, name: 'America/Menominee (-06:00)', value: 'America/Menominee' },
    { id: 9, name: 'America/Merida (-06:00)', value: 'America/Merida' },
    { id: 9, name: 'America/Metlakatla (-09:00)', value: 'America/Metlakatla' },
    {
      id: 9,
      name: 'America/Mexico_City (-06:00)',
      value: 'America/Mexico_City',
    },
    { id: 9, name: 'America/Miquelon (-03:00)', value: 'America/Miquelon' },
    { id: 9, name: 'America/Moncton (-04:00)', value: 'America/Moncton' },
    { id: 9, name: 'America/Monterrey (-06:00)', value: 'America/Monterrey' },
    { id: 9, name: 'America/Montevideo (-03:00)', value: 'America/Montevideo' },
    { id: 9, name: 'America/Montreal (-05:00)', value: 'America/Montreal' },
    { id: 9, name: 'America/Montserrat (-04:00)', value: 'America/Montserrat' },
    { id: 9, name: 'America/Nassau (-05:00)', value: 'America/Nassau' },
    { id: 9, name: 'America/New_York (-05:00)', value: 'America/New_York' },
    { id: 9, name: 'America/Nipigon (-05:00)', value: 'America/Nipigon' },
    { id: 9, name: 'America/Nome (-09:00)', value: 'America/Nome' },
    { id: 9, name: 'America/Noronha (-02:00)', value: 'America/Noronha' },
    {
      id: 9,
      name: 'America/North_Dakota/Beulah (-06:00)',
      value: 'America/North_Dakota/Beulah',
    },
    {
      id: 9,
      name: 'America/North_Dakota/Center (-06:00)',
      value: 'America/North_Dakota/Center',
    },
    {
      id: 9,
      name: 'America/North_Dakota/New_Salem (-06:00)',
      value: 'America/North_Dakota/New_Salem',
    },
    { id: 9, name: 'America/Nuuk (-03:00)', value: 'America/Nuuk' },
    { id: 9, name: 'America/Ojinaga (-07:00)', value: 'America/Nuuk' },
    { id: 9, name: 'America/Panama (-05:00)', value: 'America/Panama' },
    {
      id: 9,
      name: 'America/Pangnirtung (-05:00)',
      value: 'America/Pangnirtung',
    },
    { id: 9, name: 'America/Paramaribo (-03:00)', value: 'America/Paramaribo' },
    { id: 9, name: 'America/Phoenix (-07:00)', value: 'America/Phoenix' },
    {
      id: 9,
      name: 'America/Port-au-Prince (-05:00)',
      value: 'America/Port-au-Prince',
    },
    {
      id: 9,
      name: 'America/Port_of_Spain (-04:00)',
      value: 'America/Port_of_Spain',
    },
    { id: 9, name: 'America/Porto_Acre (-05:00)', value: 'America/Porto_Acre' },
    {
      id: 9,
      name: 'America/Porto_Velho (-04:00)',
      value: 'America/Porto_Velho',
    },
    {
      id: 9,
      name: 'America/Puerto_Rico (-04:00)',
      value: 'America/Puerto_Rico',
    },
    {
      id: 9,
      name: 'America/Punta_Arenas (-03:00)',
      value: 'America/Punta_Arenas',
    },
    {
      id: 9,
      name: 'America/Rainy_River (-06:00)',
      value: 'America/Rainy_River',
    },
    {
      id: 9,
      name: 'America/Rankin_Inlet (-06:00)',
      value: 'America/Rankin_Inlet',
    },
    { id: 9, name: 'America/Recife (-03:00)', value: 'America/Recife' },
    { id: 9, name: 'America/Regina (-06:00)', value: 'America/Regina' },
    { id: 9, name: 'America/Resolute (-06:00)', value: 'America/Resolute' },
    { id: 9, name: 'America/Rio_Branco (-05:00)', value: 'America/Rio_Branco' },
    { id: 9, name: 'America/Rosario (-03:00)', value: 'America/Rosario' },
    {
      id: 9,
      name: 'America/Santa_Isabel (-08:00)',
      value: 'America/Santa_Isabel',
    },
    { id: 9, name: 'America/Santarem (-03:00)', value: 'America/Santarem' },
    { id: 9, name: 'America/Santiago (-03:00)', value: 'America/Santiago' },
    {
      id: 9,
      name: 'America/Santo_Domingo (-04:00)',
      value: 'America/Santo_Domingo',
    },
    { id: 9, name: 'America/Sao_Paulo (-03:00)', value: 'America/Sao_Paulo' },
    {
      id: 9,
      name: 'America/Scoresbysund (-01:00)',
      value: 'America/Scoresbysund',
    },
    { id: 9, name: 'America/Shiprock (-07:00)', value: 'America/Shiprock' },
    { id: 9, name: 'America/Sitka (-09:00)', value: 'America/Sitka' },
    {
      id: 9,
      name: 'America/St_Barthelemy (-04:00)',
      value: 'America/St_Barthelemy',
    },
    { id: 9, name: 'America/St_Johns (-03:30)', value: 'America/St_Johns' },
    { id: 9, name: 'America/St_Kitts (-04:00)', value: 'America/St_Kitts' },
    { id: 9, name: 'America/St_Lucia (-04:00)', value: 'America/St_Lucia' },
    { id: 9, name: 'America/St_Thomas (-04:00)', value: 'America/St_Thomas' },
    { id: 9, name: 'America/St_Vincent (-04:00)', value: 'America/St_Vincent' },
    {
      id: 9,
      name: 'America/Swift_Current (-06:00)',
      value: 'America/Swift_Current',
    },
    {
      id: 9,
      name: 'America/Tegucigalpa (-06:00)',
      value: 'America/Tegucigalpa',
    },
    { id: 9, name: 'America/Thule (-04:00)', value: 'America/Thule' },
    {
      id: 9,
      name: 'America/Thunder_Bay (-05:00)',
      value: 'America/Thunder_Bay',
    },
    { id: 9, name: 'America/Tijuana (-08:00)', value: 'America/Tijuana' },
    { id: 9, name: 'America/Toronto (-05:00)', value: 'America/Toronto' },
    { id: 9, name: 'America/Tortola (-04:00)', value: 'America/Tortola' },
    { id: 9, name: 'America/Vancouver (-08:00)', value: 'America/Vancouver' },
    { id: 9, name: 'America/Virgin (-04:00)', value: 'America/Virgin' },
    { id: 9, name: 'America/Whitehorse (-07:00)', value: 'America/Whitehorse' },
    { id: 9, name: 'America/Winnipeg (-06:00)', value: 'America/Winnipeg' },
    { id: 9, name: 'America/Yakutat (-09:00)', value: 'America/Yakutat' },
    {
      id: 9,
      name: 'America/Yellowknife (-07:00)',
      value: 'America/Yellowknife',
    },
    { id: 9, name: 'Antarctica/Casey (+11:00)', value: 'Antarctica/Casey' },
    { id: 9, name: 'Antarctica/Davis (+07:00)', value: 'Antarctica/Davis' },
    {
      id: 9,
      name: 'Antarctica/DumontDUrville (+10:00)',
      value: 'Antarctica/DumontDUrville',
    },
    {
      id: 9,
      name: 'Antarctica/Macquarie (+11:00)',
      value: 'Antarctica/Macquarie',
    },
    { id: 9, name: 'Antarctica/Mawson (+05:00)', value: 'Antarctica/Mawson' },
    { id: 9, name: 'Antarctica/McMurdo (+13:00)', value: 'Antarctica/McMurdo' },
    { id: 9, name: 'Antarctica/Palmer (-03:00)', value: 'Antarctica/Palmer ' },
    { id: 9, name: 'Antarctica/Rothera (-03:00)', value: 'Antarctica/Rothera' },
    {
      id: 9,
      name: 'Antarctica/South_Pole (+13:00)',
      value: 'Antarctica/South_Pole',
    },
    { id: 9, name: 'Antarctica/Syowa (+03:00)', value: 'Antarctica/Syowa' },
    { id: 9, name: 'Antarctica/Troll (+00:00)', value: 'Antarctica/Troll' },
    { id: 9, name: 'Antarctica/Vostok (+06:00)', value: 'Antarctica/Vostok' },
    {
      id: 9,
      name: 'Arctic/Longyearbyen (+01:00)',
      value: 'Arctic/Longyearbyen',
    },
    { id: 9, name: 'Asia/Aden (+03:00)', value: 'Asia/Aden' },
    { id: 9, name: 'Asia/Almaty (+06:00)', value: 'Asia/Almaty' },
    { id: 9, name: 'Asia/Amman (+02:00)', value: 'Asia/Amman' },
    { id: 9, name: 'Asia/Anadyr (+12:00)', value: 'Asia/Anadyr' },
    { id: 9, name: 'Asia/Aqtau (+05:00)', value: 'Asia/Aqtau' },
    { id: 9, name: 'Asia/Aqtobe (+05:00)', value: 'Asia/Aqtobe' },
    { id: 9, name: 'Asia/Ashgabat (+05:00)', value: 'Asia/Ashgabat' },
    { id: 9, name: 'Asia/Ashkhabad (+05:00)', value: 'Asia/Ashkhabad' },
    { id: 9, name: 'Asia/Atyrau (+05:00)', value: 'Asia/Atyrau' },
    { id: 9, name: 'Asia/Baghdad (+03:00)', value: 'Asia/Baghdad' },
    { id: 9, name: 'Asia/Bahrain (+03:00)', value: 'Asia/Bahrain' },
    { id: 9, name: 'Asia/Baku (+04:00)', value: 'Asia/Baku' },
    { id: 9, name: 'Asia/Bangkok (+07:00)', value: 'Asia/Bangkok' },
    { id: 9, name: 'Asia/Barnaul (+07:00)', value: 'Asia/Barnaul' },
    { id: 9, name: 'Asia/Beirut (+02:00)', value: 'Asia/Beirut' },
    { id: 9, name: 'Asia/Bishkek (+06:00)', value: 'Asia/Bishkek' },
    { id: 9, name: 'Asia/Brunei (+08:00)', value: 'Asia/Brunei' },
    { id: 9, name: 'Asia/Calcutta (+05:30)', value: 'Asia/Calcutta' },
    { id: 9, name: 'Asia/Chita (+09:00)', value: 'Asia/Chita' },
    { id: 9, name: 'Asia/Choibalsan (+08:00)', value: 'Asia/Choibalsan' },
    { id: 9, name: 'Asia/Chongqing (+08:00)', value: 'Asia/Chongqing' },
    { id: 9, name: 'Asia/Chungking (+08:00)', value: 'Asia/Chungking' },
    { id: 9, name: 'Asia/Colombo (+05:30)', value: 'Asia/Colombo' },
    { id: 9, name: 'Asia/Dacca (+06:00)', value: 'Asia/Dacca' },
    { id: 9, name: 'Asia/Damascus (+02:00)', value: 'Asia/Damascus' },
    { id: 9, name: 'Asia/Dhaka (+06:00)', value: 'Asia/Dhaka' },
    { id: 9, name: 'Asia/Dili (+09:00)', value: 'Asia/Dili' },
    { id: 9, name: 'Asia/Dubai (+04:00)', value: 'Asia/Dubai' },
    { id: 9, name: 'Asia/Dushanbe (+05:00)', value: 'Asia/Dushanbe' },
    { id: 9, name: 'Asia/Famagusta (+02:00)', value: 'Asia/Famagusta' },
    { id: 9, name: 'Asia/Gaza (+02:00)', value: 'Asia/Gaza' },
    { id: 9, name: 'Asia/Harbin (+08:00)', value: 'Asia/Harbin' },
    { id: 9, name: 'Asia/Hebron (+02:00)', value: 'Asia/Hebron' },
    { id: 9, name: 'Asia/Ho_Chi_Minh (+07:00)', value: 'Asia/Ho_Chi_Minh' },
    { id: 9, name: 'Asia/Hong_Kong (+08:00)', value: 'Asia/Hong_Kong' },
    { id: 9, name: 'Asia/Hovd (+07:00)', value: 'Asia/Hovd' },
    { id: 9, name: 'Asia/Irkutsk (+08:00)', value: 'Asia/Irkutsk' },
    { id: 9, name: 'Asia/Istanbul (+03:00)', value: 'Asia/Istanbul' },
    { id: 9, name: 'Asia/Jakarta (+07:00)', value: 'Asia/Jakarta' },
    { id: 9, name: 'Asia/Jayapura (+09:00)', value: 'Asia/Jayapura' },
    { id: 9, name: 'Asia/Jerusalem (+02:00)', value: 'Asia/Jerusalem' },
    { id: 9, name: 'Asia/Kabul (+04:30)', value: 'Asia/Kabul' },
    { id: 9, name: 'Asia/Kamchatka (+12:00)', value: 'Asia/Kamchatka' },
    { id: 9, name: 'Asia/Karachi (+05:00)', value: 'Asia/Karachi' },
    { id: 9, name: 'Asia/Kashgar (+06:00)', value: 'Asia/Kashgar' },
    { id: 9, name: 'Asia/Kathmandu (+05:45)', value: 'Asia/Kathmandu' },
    { id: 9, name: 'Asia/Katmandu (+05:45)', value: 'Asia/Katmandu' },
    { id: 9, name: 'Asia/Khandyga (+09:00)', value: 'Asia/Khandyga' },
    { id: 9, name: 'Asia/Kolkata (+05:30)', value: 'Asia/Kolkata' },
    { id: 9, name: 'Asia/Krasnoyarsk (+07:00)', value: 'Asia/Krasnoyarsk' },
    { id: 9, name: 'Asia/Kuala_Lumpur (+08:00)', value: 'Asia/Kuala_Lumpur' },
    { id: 9, name: 'Asia/Kuching (+08:00)', value: 'Asia/Kuching' },
    { id: 9, name: 'Asia/Kuwait (+03:00)', value: 'Asia/Kuwait' },
    { id: 9, name: 'Asia/Macao (+08:00)', value: 'Asia/Macao' },
    { id: 9, name: 'Asia/Macau (+08:00)', value: 'Asia/Macau' },
    { id: 9, name: 'Asia/Magadan (+11:00)', value: 'Asia/Magadan' },
    { id: 9, name: 'Asia/Makassar (+08:00)', value: 'Asia/Makassar' },
    { id: 9, name: 'Asia/Manila (+08:00)', value: 'Asia/Manila' },
    { id: 9, name: 'Asia/Muscat (+04:00)', value: 'Asia/Muscat' },
    { id: 9, name: 'Asia/Nicosia (+02:00)', value: 'Asia/Nicosia' },
    { id: 9, name: 'Asia/Novokuznetsk (+07:00)', value: 'Asia/Novokuznetsk' },
    { id: 9, name: 'Asia/Novosibirsk (+07:00)', value: 'Asia/Novosibirsk' },
    { id: 9, name: 'Asia/Omsk (+06:00)', value: 'Asia/Omsk' },
    { id: 9, name: 'Asia/Oral (+05:00)', value: 'Asia/Oral' },
    { id: 9, name: 'Asia/Phnom_Penh (+07:00)', value: 'Asia/Phnom_Penh' },
    { id: 9, name: 'Asia/Pontianak (+07:00)', value: 'Asia/Pontianak' },
    { id: 9, name: 'Asia/Pyongyang (+09:00)', value: 'Asia/Pyongyang' },
    { id: 9, name: 'Asia/Qatar (+03:00)', value: 'Asia/Qatar' },
    { id: 9, name: 'Asia/Qostanay (+06:00)', value: 'Asia/Qostanay' },
    { id: 9, name: 'Asia/Qyzylorda (+05:00)', value: 'Asia/Qyzylorda' },
    { id: 9, name: 'Asia/Rangoon (+06:30)', value: 'Asia/Rangoon' },
    { id: 9, name: 'Asia/Riyadh (+03:00)', value: 'Asia/Riyadh' },
    { id: 9, name: 'Asia/Saigon (+07:00)', value: 'Asia/Saigon' },
    { id: 9, name: 'Asia/Sakhalin (+11:00)', value: 'Asia/Sakhalin' },
    { id: 9, name: 'Asia/Samarkand (+05:00)', value: 'Asia/Samarkand' },
    { id: 9, name: 'Asia/Seoul (+09:00)', value: 'Asia/Seoul' },
    { id: 9, name: 'Asia/Shanghai (+08:00)', value: 'Asia/Shanghai' },
    { id: 9, name: 'Asia/Singapore (+08:00)', value: 'Asia/Singapore' },
    { id: 9, name: 'Asia/Srednekolymsk (+11:00)', value: 'Asia/Srednekolymsk' },
    { id: 9, name: 'Asia/Taipei (+08:00)', value: 'Asia/Taipei' },
    { id: 9, name: 'Asia/Tashkent (+05:00)', value: 'Asia/Tashkent' },
    { id: 9, name: 'Asia/Tbilisi (+04:00)', value: 'Asia/Tbilisi' },
    { id: 9, name: 'Asia/Tehran (+03:30)', value: 'Asia/Tehran' },
    { id: 9, name: 'Asia/Tel_Aviv (+02:00)', value: 'Asia/Tel_Aviv' },
    { id: 9, name: 'Asia/Thimbu (+06:00)', value: 'Asia/Thimbu' },
    { id: 9, name: 'Asia/Thimphu (+06:00)', value: 'Asia/Thimphu' },
    { id: 9, name: 'Asia/Tokyo (+09:00)', value: 'Asia/Tokyo' },
    { id: 9, name: 'Asia/Tomsk (+07:00)', value: 'Asia/Tomsk' },
    { id: 9, name: 'Asia/Ujung_Pandang (+08:00)', value: 'Asia/Ujung_Pandang' },
    { id: 9, name: 'Asia/Ulaanbaatar (+08:00)', value: 'Asia/Ulaanbaatar' },
    { id: 9, name: 'Asia/Ulan_Bator (+08:00)', value: 'Asia/Ulan_Bator' },
    { id: 9, name: 'Asia/Urumqi (+06:00)', value: 'Asia/Urumqi' },
    { id: 9, name: 'Asia/Ust-Nera (+10:00)', value: 'Asia/Ust-Nera' },
    { id: 9, name: 'Asia/Vientiane (+07:00)', value: 'Asia/Vientiane' },
    { id: 9, name: 'Asia/Vladivostok (+10:00)', value: 'Asia/Vladivostok' },
    { id: 9, name: 'Asia/Yakutsk (+09:00)', value: 'Asia/Yakutsk' },
    { id: 9, name: 'Asia/Yangon (+06:30)', value: 'Asia/Yangon' },
    { id: 9, name: 'Asia/Yekaterinburg (+05:00)', value: 'Asia/Yekaterinburg' },
    { id: 9, name: 'Asia/Yerevan (+04:00)', value: 'Asia/Yerevan' },
    { id: 9, name: 'Atlantic/Azores (-01:00)', value: 'Atlantic/Azores' },
    { id: 9, name: 'Atlantic/Bermuda (-04:00)', value: 'Atlantic/Bermuda' },
    { id: 9, name: 'Atlantic/Canary (+00:00)', value: 'Atlantic/Canary' },
    {
      id: 9,
      name: 'Atlantic/Cape_Verde (-01:00)',
      value: 'Atlantic/Cape_Verde',
    },
    { id: 9, name: 'Atlantic/Faeroe (+00:00)', value: 'Atlantic/Faeroe' },
    { id: 9, name: 'Atlantic/Faroe (+00:00)', value: 'Atlantic/Faroe' },
    { id: 9, name: 'Atlantic/Jan_Mayen (+01:00)', value: 'Atlantic/Jan_Mayen' },
    { id: 9, name: 'Atlantic/Madeira (+00:00)', value: 'Atlantic/Madeira' },
    { id: 9, name: 'Atlantic/Reykjavik (+00:00)', value: 'Atlantic/Reykjavik' },
    {
      id: 9,
      name: 'Atlantic/South_Georgia (-02:00)',
      value: 'Atlantic/South_Georgia',
    },

    { id: 9, name: 'Atlantic/St_Helena (+00:00)', value: 'Atlantic/St_Helena' },
    { id: 9, name: 'Atlantic/Stanley (-03:00)', value: 'Atlantic/Stanley' },
    { id: 9, name: 'Australia/ACT (+11:00)', value: 'Australia/ACT' },
    { id: 9, name: 'Australia/Adelaide (+10:30)', value: 'Australia/Adelaide' },
    { id: 9, name: 'Australia/Brisbane (+10:00)', value: 'Australia/Brisbane' },
    {
      id: 9,
      name: 'Australia/Broken_Hill (+10:30)',
      value: 'Australia/Broken_Hill',
    },
    { id: 9, name: 'Australia/Canberra (+11:00)', value: 'Australia/Canberra' },
    { id: 9, name: 'Australia/Currie (+11:00)', value: 'Australia/Currie' },
    { id: 9, name: 'Australia/Darwin (+09:30)', value: 'Australia/Darwin' },
    { id: 9, name: 'Australia/Eucla (+08:45)', value: 'Australia/Eucla' },
    { id: 9, name: 'Australia/Hobart (+11:00)', value: 'Australia/Hobart' },
    { id: 9, name: 'Australia/LHI (+11:00)', value: 'Australia/LHI' },
    { id: 9, name: 'Australia/Lindeman (+10:00)', value: 'Australia/Lindeman' },
    {
      id: 9,
      name: 'Australia/Lord_Howe (+11:00)',
      value: 'Australia/Lord_Howe',
    },
    {
      id: 9,
      name: 'Australia/Melbourne (+11:00)',
      value: 'Australia/Melbourne',
    },
    { id: 9, name: 'Australia/NSW (+11:00)', value: 'Australia/NSW' },
    { id: 9, name: 'Australia/North (+09:30)', value: 'Australia/North' },
    { id: 9, name: 'Australia/Perth (+08:00)', value: 'Australia/Perth' },

    {
      id: 9,
      name: 'Australia/Queensland (+10:00)',
      value: 'Australia/Queensland',
    },
    { id: 9, name: 'Australia/South (+10:30)', value: 'Australia/South' },
    { id: 9, name: 'Australia/Sydney (+11:00)', value: 'Australia/Sydney' },
    { id: 9, name: 'Australia/Tasmania (+11:00)', value: 'Australia/Tasmania' },
    { id: 9, name: 'Australia/Victoria (+11:00)', value: 'Australia/Victoria' },
    { id: 9, name: 'Australia/West (+08:00)', value: 'Australia/West' },
    {
      id: 9,
      name: 'Australia/Yancowinna (+10:30)',
      value: 'Australia/Yancowinna',
    },
    { id: 9, name: 'Brazil/Acre (-05:00)', value: 'Brazil/Acre' },
    { id: 9, name: 'Brazil/DeNoronha (-02:00)', value: 'Brazil/DeNoronha' },

    { id: 9, name: 'Brazil/East (-03:00)', value: 'Brazil/East' },
    { id: 9, name: 'Brazil/West (-04:00)', value: 'Brazil/West' },
    { id: 9, name: 'CET (+01:00)', value: 'CET' },
    { id: 9, name: 'CST6CDT (-06:00)', value: 'CST6CDT' },
    { id: 9, name: 'Canada/Atlantic (-04:00)', value: 'Canada/Atlantic' },
    { id: 9, name: 'Canada/Central (-06:00)', value: 'Canada/Central' },
    { id: 9, name: 'Canada/Eastern (-05:00)', value: 'Canada/Eastern' },
    { id: 9, name: 'Canada/Mountain (-07:00)', value: 'Canada/Mountain' },
    {
      id: 9,
      name: 'Canada/Newfoundland (-03:30)',
      value: 'Canada/Newfoundland',
    },

    { id: 9, name: 'Canada/Pacific (-08:00)', value: 'Canada/Pacific' },
    {
      id: 9,
      name: 'Canada/Saskatchewan (-06:00)',
      value: 'Canada/Saskatchewan',
    },
    { id: 9, name: 'Canada/Yukon (-07:00)', value: 'Canada/Yukon' },
    { id: 9, name: 'Chile/Continental (-03:00)', value: 'Chile/Continental' },
    { id: 9, name: 'Chile/EasterIsland (-05:00)', value: 'Chile/EasterIsland' },
    { id: 9, name: 'Cuba (-05:00)', value: 'Cuba' },
    { id: 9, name: 'EET (+02:00)', value: 'EET' },
    { id: 9, name: 'EST (-05:00)', value: 'EST' },
    { id: 9, name: 'EST5EDT (-05:00)', value: 'EST5EDT' },
    { id: 9, name: 'Egypt (+02:00)', value: 'Egypt' },
    { id: 9, name: 'Eire (+00:00)', value: 'Eire' },
    { id: 9, name: 'Etc/GMT (+00:00)', value: 'Etc/GMT' },
    { id: 9, name: 'Etc/GMT+0 (+00:00)', value: 'Etc/GMT+0' },
    { id: 9, name: 'Etc/GMT+1 (-01:00)', value: 'Etc/GMT+1' },
    { id: 9, name: 'Etc/GMT+10 (-10:00)', value: 'Etc/GMT+10' },
    { id: 9, name: 'Etc/GMT+11 (-11:00)', value: 'Etc/GMT+11' },
    { id: 9, name: 'Etc/GMT+12 (-12:00)', value: 'Etc/GMT+12' },
    { id: 9, name: 'Etc/GMT+2 (-02:00)', value: 'Etc/GMT+2' },
    { id: 9, name: 'Etc/GMT+3 (-03:00)', value: 'Etc/GMT+3' },
    { id: 9, name: 'Etc/GMT+4 (-04:00)', value: 'Etc/GMT+4' },
    { id: 9, name: 'Etc/GMT+5 (-05:00)', value: 'Etc/GMT+5' },
    { id: 9, name: 'Etc/GMT+6 (-06:00)', value: 'Etc/GMT+6' },
    { id: 9, name: 'Etc/GMT+7 (-07:00)', value: 'Etc/GMT+7' },
    { id: 9, name: 'Etc/GMT+8 (-08:00)', value: 'Etc/GMT+8' },
    { id: 9, name: 'Etc/GMT+9 (-09:00)', value: 'Etc/GMT+9' },
    { id: 9, name: 'Etc/GMT-0 (+00:00)', value: 'Etc/GMT-0' },
    { id: 9, name: 'Etc/GMT-1 (+01:00)', value: 'Etc/GMT-1' },
    { id: 9, name: 'Etc/GMT-10 (+10:00)', value: 'Etc/GMT-10' },
    { id: 9, name: 'Etc/GMT-11 (+11:00)', value: 'Etc/GMT-11' },
    { id: 9, name: 'Etc/GMT-12 (+12:00)', value: 'Etc/GMT-12' },
    { id: 9, name: 'Etc/GMT-13 (+13:00)', value: 'Etc/GMT-13' },
    { id: 9, name: 'Etc/GMT-14 (+14:00)', value: 'Etc/GMT-14' },
    { id: 9, name: 'Etc/GMT-2 (+02:00)', value: 'Etc/GMT-2' },
    { id: 9, name: 'Etc/GMT-3 (+03:00)', value: 'Etc/GMT-3' },
    { id: 9, name: 'Etc/GMT-4 (+04:00)', value: 'Etc/GMT-4' },
    { id: 9, name: 'Etc/GMT-5 (+05:00)', value: 'Etc/GMT-5' },
    { id: 9, name: 'Etc/GMT-6 (+06:00)', value: 'Etc/GMT-6' },
    { id: 9, name: 'Etc/GMT-7 (+07:00)', value: 'Etc/GMT-7' },
    { id: 9, name: 'Etc/GMT-8 (+08:00)', value: 'Etc/GMT-8' },
    { id: 9, name: 'Etc/GMT-9 (+09:00)', value: 'Etc/GMT-9' },
    { id: 9, name: 'Etc/GMT0 (+00:00)', value: 'Etc/GMT0' },
    { id: 9, name: 'Etc/Greenwich (+00:00)', value: 'Etc/Greenwich' },
    { id: 9, name: 'Etc/UCT (+00:00)', value: 'Etc/UCT' },
    { id: 9, name: 'Etc/UTC (+00:00)', value: 'Etc/UTC' },
    { id: 9, name: 'Etc/Universal (+00:00)', value: 'Etc/Universal' },
    { id: 9, name: 'Etc/Zulu (+00:00)', value: 'Etc/Zulu' },
    { id: 9, name: 'Europe/Amsterdam (+01:00)', value: 'Europe/Amsterdam' },
    { id: 9, name: 'Europe/Andorra (+01:00)', value: 'Europe/Andorra' },
    { id: 9, name: 'Europe/Astrakhan (+04:00)', value: 'Europe/Astrakhan' },
    { id: 9, name: 'Europe/Athens (+02:00)', value: 'Europe/Athens' },
    { id: 9, name: 'Europe/Belfast (+00:00)', value: 'Europe/Belfast' },
    { id: 9, name: 'Europe/Belgrade (+01:00)', value: 'Europe/Belgrade' },
    { id: 9, name: 'Europe/Berlin (+01:00)', value: 'Europe/Berlin' },
    { id: 9, name: 'Europe/Bratislava (+01:00)', value: 'Europe/Bratislava' },
    { id: 9, name: 'Europe/Brussels (+01:00)', value: 'Europe/Brussels' },
    { id: 9, name: 'Europe/Bucharest (+02:00)', value: 'Europe/Bucharest' },
    { id: 9, name: 'Europe/Budapest (+01:00)', value: 'Europe/Budapest' },
    { id: 9, name: 'Europe/Busingen (+01:00)', value: 'Europe/Busingen' },
    { id: 9, name: 'Europe/Chisinau (+02:00)', value: 'Europe/Chisinau' },
    { id: 9, name: 'Europe/Copenhagen (+01:00)', value: 'Europe/Copenhagen' },
    { id: 9, name: 'Europe/Dublin (+00:00)', value: 'Europe/Dublin' },
    { id: 9, name: 'Europe/Gibraltar (+01:00)', value: 'Europe/Gibraltar' },
    { id: 9, name: 'Europe/Guernsey (+00:00)', value: 'Europe/Guernsey' },
    { id: 9, name: 'Europe/Helsinki (+02:00)', value: 'Europe/Helsinki' },
    { id: 9, name: 'Europe/Isle_of_Man (+00:00)', value: 'Europe/Isle_of_Man' },
    { id: 9, name: 'Europe/Istanbul (+03:00)', value: 'Europe/Istanbul' },
    { id: 9, name: 'Europe/Jersey (+00:00)', value: 'Europe/Jersey' },
    { id: 9, name: 'Europe/Kaliningrad (+02:00)', value: 'Europe/Kaliningrad' },
    { id: 9, name: 'Europe/Kiev (+02:00)', value: 'Europe/Kiev' },
    { id: 9, name: 'Europe/Kirov (+03:00)', value: 'Europe/Kirov' },
    { id: 9, name: 'Europe/Lisbon (+00:00)', value: 'Europe/Lisbon' },
    { id: 9, name: 'Europe/Ljubljana (+01:00)', value: 'Europe/Ljubljana' },
    { id: 9, name: 'Europe/London (+00:00)', value: 'Europe/London' },
    { id: 9, name: 'Europe/Luxembourg (+01:00)', value: 'Europe/Luxembourg' },
    { id: 9, name: 'Europe/Madrid (+01:00)', value: 'Europe/Madrid' },
    { id: 9, name: 'Europe/Malta (+01:00)', value: 'Europe/Malta' },
    { id: 9, name: 'Europe/Mariehamn (+02:00)', value: 'Europe/Mariehamn' },
    { id: 9, name: 'Europe/Minsk (+03:00)', value: 'Europe/Minsk' },
    { id: 9, name: 'Europe/Monaco (+01:00)', value: 'Europe/Monaco' },
    { id: 9, name: 'Europe/Moscow (+03:00)', value: 'Europe/Moscow' },
    { id: 9, name: 'Europe/Nicosia (+02:00)', value: 'Europe/Nicosia' },
    { id: 9, name: 'Europe/Oslo (+01:00)', value: 'Europe/Oslo' },
    { id: 9, name: 'Europe/Paris (+01:00)', value: 'Europe/Paris' },
    { id: 9, name: 'Europe/Podgorica (+01:00)', value: 'Europe/Podgorica' },
    { id: 9, name: 'Europe/Prague (+01:00)', value: 'Europe/Prague' },
    { id: 9, name: 'Europe/Riga (+02:00)', value: 'Europe/Riga' },
    { id: 9, name: 'Europe/Rome (+01:00)', value: 'Europe/Rome' },
    { id: 9, name: 'Europe/Samara (+04:00)', value: 'Europe/Samara' },
    { id: 9, name: 'Europe/San_Marino (+01:00)', value: 'Europe/San_Marino' },
    { id: 9, name: 'Europe/Sarajevo (+01:00)', value: 'Europe/Sarajevo' },
    { id: 9, name: 'Europe/Saratov (+04:00)', value: 'Europe/Saratov' },
    { id: 9, name: 'Europe/Simferopol (+03:00)', value: 'Europe/Simferopol' },
    { id: 9, name: 'Europe/Skopje (+01:00)', value: 'Europe/Skopje' },
    { id: 9, name: 'Europe/Sofia (+02:00)', value: 'Europe/Sofia' },
    { id: 9, name: 'Europe/Stockholm (+01:00)', value: 'Europe/Stockholm' },
    { id: 9, name: 'Europe/Tallinn (+02:00)', value: 'Europe/Tallinn' },
    { id: 9, name: 'Europe/Tirane (+01:00)', value: 'Europe/Tirane' },
    { id: 9, name: 'Europe/Tiraspol (+02:00)', value: 'Europe/Tiraspol' },
    { id: 9, name: 'Europe/Ulyanovsk (+04:00)', value: 'Europe/Ulyanovsk' },
    { id: 9, name: 'Europe/Uzhgorod (+02:00)', value: 'Europe/Uzhgorod' },
    { id: 9, name: 'Europe/Vaduz (+01:00)', value: 'Europe/Vaduz' },
    { id: 9, name: 'Europe/Vatican (+01:00)', value: 'Europe/Vatican' },
    { id: 9, name: 'Europe/Vienna (+01:00)', value: 'Europe/Vienna' },
    { id: 9, name: 'Europe/Vilnius (+02:00)', value: 'Europe/Vilnius' },
    { id: 9, name: 'Europe/Volgograd (+04:00)', value: 'Europe/Volgograd' },
    { id: 9, name: 'Europe/Warsaw (+01:00)', value: 'Europe/Warsaw' },
    { id: 9, name: 'Europe/Zagreb (+01:00)', value: 'Europe/Zagreb' },
    { id: 9, name: 'Europe/Zaporozhye (+02:00)', value: 'Europe/Zaporozhye' },
    { id: 9, name: 'Europe/Zurich (+01:00)', value: 'Europe/Zurich' },
    { id: 9, name: 'GB (+00:00)', value: 'GB' },
    { id: 9, name: 'GB-Eire (+00:00)', value: 'GB-Eire' },
    { id: 9, name: 'GMT (+00:00)', value: 'GMT' },
    { id: 9, name: 'GMT-0 (+00:00)', value: 'GMT-0' },
    { id: 9, name: 'GMT0 (+00:00)', value: 'GMT0' },
    { id: 9, name: 'Greenwich (+00:00)', value: 'Greenwich' },
    { id: 9, name: 'HST (-10:00)', value: 'HST' },
    { id: 9, name: 'Hongkong (+08:00)', value: 'Hongkong' },
    { id: 9, name: 'Iceland (+00:00)', value: 'Iceland' },
    {
      id: 9,
      name: 'Indian/Antananarivo (+03:00)',
      value: 'Indian/Antananarivo',
    },
    { id: 9, name: 'Indian/Chagos (+06:00)', value: 'Indian/Chagos' },
    { id: 9, name: 'Indian/Christmas (+07:00)', value: 'Indian/Christmas' },
    { id: 9, name: 'Indian/Cocos (+06:30)', value: 'Indian/Cocos' },
    { id: 9, name: 'Indian/Comoro (+03:00)', value: 'Indian/Comoro' },
    { id: 9, name: 'Indian/Kerguelen (+05:00)', value: 'Indian/Kerguelen' },
    { id: 9, name: 'Indian/Mahe (+04:00)', value: 'Indian/Mahe' },
    { id: 9, name: 'Indian/Maldives (+05:00)', value: 'Indian/Maldives' },
    { id: 9, name: 'Indian/Mauritius (+04:00)', value: 'Indian/Mauritius' },
    { id: 9, name: 'Indian/Mayotte (+03:00)', value: 'Indian/Mayotte' },
    { id: 9, name: 'Indian/Reunion (+04:00)', value: 'Indian/Reunion' },
    { id: 9, name: 'Iran (+03:30)', value: 'Iran' },
    { id: 9, name: 'Israel (+02:00)', value: 'Israel' },
    { id: 9, name: 'Jamaica (-05:00)', value: 'Jamaica' },
    { id: 9, name: 'Japan (+09:00)', value: 'Japan' },
    { id: 9, name: 'Kwajalein (+12:00)', value: 'Kwajalein' },
    { id: 9, name: 'Libya (+02:00)', value: 'Libya' },
    { id: 9, name: 'MET (+01:00)', value: 'MET' },
    { id: 9, name: 'MST (-07:00)', value: 'MST' },
    { id: 9, name: 'MST7MDT (-07:00)', value: 'MST7MDT' },
    { id: 9, name: 'Mexico/BajaNorte (-08:00)', value: 'Mexico/BajaNorte' },
    { id: 9, name: 'Mexico/BajaSur (-07:00)', value: 'Mexico/BajaSur' },
    { id: 9, name: 'Mexico/General (-06:00)', value: 'Mexico/General' },

    { id: 9, name: 'NZ (+13:00)', value: 'NZ' },
    { id: 9, name: 'NZ-CHAT (+13:45)', value: 'NZ-CHAT' },
    { id: 9, name: 'Navajo (-07:00)', value: 'Navajo' },
    { id: 9, name: 'PRC (+08:00)', value: 'PRC' },
    { id: 9, name: 'PST8PDT (-08:00)', value: 'PST8PDT' },
    { id: 9, name: 'Pacific/Apia (+14:00)', value: 'Pacific/Apia' },
    { id: 9, name: 'Pacific/Auckland (+13:00)', value: 'Pacific/Auckland' },
    {
      id: 9,
      name: 'Pacific/Bougainville (+11:00)',
      value: 'Pacific/Bougainville',
    },
    { id: 9, name: 'Pacific/Chatham (+13:45)', value: 'Pacific/Chatham' },
    { id: 9, name: 'Pacific/Chuuk (+10:00)', value: 'Pacific/Chuuk' },
    { id: 9, name: 'Pacific/Easter (-05:00)', value: 'Pacific/Easter' },
    { id: 9, name: 'Pacific/Efate (+11:00)', value: 'Pacific/Efate' },
    { id: 9, name: 'Pacific/Enderbury (+13:00)', value: 'Pacific/Enderbury' },
    { id: 9, name: 'Pacific/Fakaofo (+13:00)', value: 'Pacific/Fakaofo' },
    { id: 9, name: 'Pacific/Fiji (+12:00)', value: 'Pacific/Fiji' },
    { id: 9, name: 'Pacific/Funafuti (+12:00)', value: 'Pacific/Funafuti' },
    { id: 9, name: 'Pacific/Galapagos (-06:00)', value: 'Pacific/Galapagos' },
    { id: 9, name: 'Pacific/Gambier (-09:00)', value: 'Pacific/Gambier' },
    {
      id: 9,
      name: 'Pacific/Guadalcanal (+11:00)',
      value: 'Pacific/Guadalcanal',
    },
    { id: 9, name: 'Pacific/Guam (+10:00)', value: 'Pacific/Guam' },
    { id: 9, name: 'Pacific/Honolulu (-10:00)', value: 'Pacific/Honolulu' },
    { id: 9, name: 'Pacific/Johnston (-10:00)', value: 'Pacific/Johnston' },
    { id: 9, name: 'Pacific/Kiritimati (+14:00)', value: 'Pacific/Kiritimati' },
    { id: 9, name: 'Pacific/Kosrae (+11:00)', value: 'Pacific/Kosrae' },
    { id: 9, name: 'Pacific/Kwajalein (+12:00)', value: 'Pacific/Kwajalein' },
    { id: 9, name: 'Pacific/Majuro (+12:00)', value: 'Pacific/Majuro' },
    { id: 9, name: 'Pacific/Marquesas (-09:30)', value: 'Pacific/Marquesas' },
    { id: 9, name: 'Pacific/Midway (-11:00)', value: 'Pacific/Midway' },
    { id: 9, name: 'Pacific/Nauru (+12:00)', value: 'Pacific/Nauru' },
    { id: 9, name: 'Pacific/Niue (-11:00)', value: 'Pacific/Niue' },
    { id: 9, name: 'Pacific/Norfolk (+12:00)', value: 'Pacific/Norfolk' },
    { id: 9, name: 'Pacific/Noumea (+11:00)', value: 'Pacific/Noumea' },
    { id: 9, name: 'Pacific/Pago_Pago (-11:00)', value: 'Pacific/Pago_Pago' },
    { id: 9, name: 'Pacific/Palau (+09:00)', value: 'Pacific/Palau' },
    { id: 9, name: 'Pacific/Pitcairn (-08:00)', value: 'Pacific/Pitcairn' },
    { id: 9, name: 'Pacific/Pohnpei (+11:00)', value: 'Pacific/Pohnpei' },
    { id: 9, name: 'Pacific/Ponape (+11:00)', value: 'Pacific/Ponape' },
    {
      id: 9,
      name: 'Pacific/Port_Moresby (+10:00)',
      value: 'Pacific/Port_Moresby',
    },
    { id: 9, name: 'Pacific/Rarotonga (-10:00)', value: 'Pacific/Rarotonga' },
    { id: 9, name: 'Pacific/Saipan (+10:00)', value: 'Pacific/Saipan' },
    { id: 9, name: 'Pacific/Samoa (-11:00)', value: 'Pacific/Samoa' },
    { id: 9, name: 'Pacific/Tahiti (-10:00)', value: 'Pacific/Tahiti' },
    { id: 9, name: 'Pacific/Tarawa (+12:00)', value: 'Pacific/Tarawa' },
    { id: 9, name: 'Pacific/Tongatapu (+13:00)', value: 'Pacific/Tongatapu' },
    { id: 9, name: 'Pacific/Truk (+10:00)', value: 'Pacific/Truk' },
    { id: 9, name: 'Pacific/Wake (+12:00)', value: 'Pacific/Wake' },
    { id: 9, name: 'Pacific/Wallis (+12:00)', value: 'Pacific/Wallis' },
    { id: 9, name: 'Pacific/Yap (+10:00)', value: 'Pacific/Yap' },
    { id: 9, name: 'Poland (+01:00)', value: 'Poland' },
    { id: 9, name: 'Portugal (+00:00)', value: 'Portugal' },
    { id: 9, name: 'ROC (+08:00)', value: 'ROC' },
    { id: 9, name: 'ROK (+09:00)', value: 'ROK' },
    { id: 9, name: 'Singapore (+08:00)', value: 'Singapore' },
    { id: 9, name: 'Turkey (+03:00)', value: 'Turkey' },
    { id: 9, name: 'UCT (+00:00)', value: 'UCT' },
    { id: 9, name: 'US/Alaska (-09:00)', value: 'US/Alaska' },
    { id: 9, name: 'US/Aleutian (-10:00)', value: 'US/Aleutian' },
    { id: 9, name: 'US/Arizona (-07:00)', value: 'US/Arizona' },
    { id: 9, name: 'US/Central (-06:00)', value: 'US/Central' },
    { id: 9, name: 'US/East-Indiana (-05:00)', value: 'US/East-Indiana' },
    { id: 9, name: 'US/Eastern (-05:00)', value: 'US/Eastern' },
    { id: 9, name: 'US/Hawaii (-10:00)', value: 'US/Hawaii' },
    { id: 9, name: 'US/Indiana-Starke (-06:00)', value: 'US/Indiana-Starke' },
    { id: 9, name: 'US/Michigan (-05:00)', value: 'US/Michigan' },
    { id: 9, name: 'US/Mountain (-07:00)', value: 'US/Mountain' },
    { id: 9, name: 'US/Pacific (-08:00)', value: 'US/Pacific' },
    { id: 9, name: 'US/Samoa (-11:00)', value: 'US/Samoa' },
    { id: 9, name: 'UTC (+00:00)', value: 'UTC' },
    { id: 9, name: 'Universal (+00:00)', value: 'Universal' },
    { id: 9, name: 'W-SU (+03:00)', value: 'W-SU' },
    { id: 9, name: 'WET (+00:00)', value: 'WET' },
    { id: 9, name: 'Zulu (+00:00)', value: 'Zulu' },
  ];
  selectedLevel;
  selectitems: Array<Object> = [
    { id: 0, name: 'United States( +1 )', value: '+1' },
    { id: 1, name: 'Afghanistan( +93 )', value: '+93' },
    { id: 2, name: 'Albania( +355 )', value: '+355' },
    { id: 3, name: 'Algeria( +213 )', value: '+213' },
    // {id: 4, name: "American Samoa( +1 )", value:"+1"},
    { id: 5, name: 'Andorra( +376 )', value: '+376' },
    { id: 6, name: 'Angola( +244 )', value: '+244' },
    { id: 7, name: 'Anguilla( +1264 )', value: '+1264' },
    { id: 8, name: 'Antarctica( +672 )', value: '+672' },
    { id: 9, name: 'Antigua and Barbuda( +1268 )', value: '+1268' },
    { id: 10, name: 'Argentina( +54 )', value: '+54' },
    { id: 11, name: 'Armenia( +374 )', value: '+374' },
    { id: 12, name: 'Aruba( +297 )', value: '+297' },
    { id: 13, name: 'Australia( +61 )', value: '+61' },
    { id: 14, name: 'Austria( +43 )', value: '+43' },
    { id: 15, name: 'Azerbaijan( +994 )', value: '+994' },
    { id: 16, name: 'Bahamas( +1242 )', value: '+1242' },
    { id: 17, name: 'Bahrain( +973 )', value: '+973' },
    { id: 18, name: 'Bangladesh( +880 )', value: '+880' },
    { id: 19, name: 'Barbados( +1246 )', value: '+1246' },
    { id: 20, name: 'Belarus( +375 )', value: '+375' },
    { id: 21, name: 'Belgium( +32 )', value: '+32' },
    { id: 22, name: 'Belize( +501 )', value: '+501' },
    { id: 23, name: 'Benin( +229 )', value: '+229' },
    { id: 24, name: 'Bermuda( +1441 )', value: '+1441' },
    { id: 25, name: 'Bhutan( +975 )', value: '+975' },
    { id: 26, name: 'Bolivia( +591 )', value: '+591' },
    { id: 27, name: 'Bosnia and Herzegowina( +387 )', value: '+387' },
    { id: 28, name: 'Botswana( +267 )', value: '+267' },
    { id: 29, name: 'Bouvet Island( +47 )', value: '+47' },
    { id: 30, name: 'Brazil( +55 )', value: '+55' },
    { id: 31, name: 'British Indian Ocean Territory( +246 )', value: '+246' },
    { id: 32, name: 'Brunei Darussalam( +673 )', value: '+673' },
    { id: 33, name: 'Bulgaria( +359 )', value: '+359' },
    { id: 34, name: 'Burkina Faso( +226 )', value: '+226' },
    { id: 35, name: 'Burundi( +257 )', value: '+257' },
    { id: 36, name: 'Cambodia( +855 )', value: '+855' },
    { id: 37, name: 'Cameroon( +237 )', value: '+237' },
    // {id: 38, name: "Canada( +1 )", value:"+1"},
    { id: 39, name: 'Cape Verde( +238 )', value: '+238' },
    { id: 40, name: 'Cayman Islands( + 345 )', value: '+345' },
    { id: 41, name: 'Central African Republic( +236 )', value: '+236' },
    { id: 42, name: 'Chad( +235 )', value: '+235' },
    { id: 43, name: 'Chile( +56 )', value: '+56' },
    { id: 44, name: 'China( +86 )', value: '+86' },
    // {id: 45, name: "Christmas Island( +61 )", value:"+61"},
    // {id: 46, name: "Cocos (Keeling) Islands( +61 )", value:"+61"},
    { id: 47, name: 'Colombia( +57 )', value: '+57' },
    { id: 48, name: 'Comoros( +269 )', value: '+269' },
    { id: 49, name: 'Congo( +242 )', value: '+242' },
    {
      id: 50,
      name: 'Congo (Democratic Republic of the)( +243 )',
      value: '+243',
    },
    { id: 51, name: 'Cook Islands( +682 )', value: '+682' },
    { id: 52, name: 'Costa Rica( +506 )', value: '+506' },
    { id: 53, name: "Cote D'Ivoire( +225 )", value: '+225' },
    { id: 54, name: 'Croatia( +385 )', value: '+385' },
    { id: 55, name: 'Cuba( +53 )', value: '+53' },
    { id: 56, name: 'Cyprus( +357 )', value: '+357' },
    { id: 57, name: 'Czech Republic( +420 )', value: '+420' },
    { id: 58, name: 'Denmark( +45 )', value: '+45' },
    { id: 59, name: 'Djibouti( +253 )', value: '+253' },
    { id: 60, name: 'Dominica( +1767 )', value: '+1767' },
    { id: 61, name: 'Dominican Republic( +1849 )', value: '+1849' },
    { id: 62, name: 'East Timor( +670 )', value: '+670' },
    { id: 63, name: 'Ecuador( +593 )', value: '+593' },
    { id: 64, name: 'Egypt( +20 )', value: '+20' },
    { id: 65, name: 'El Salvador( +503 )', value: '+503' },
    { id: 66, name: 'Equatorial Guinea( +240 )', value: '+240' },
    { id: 67, name: 'Eritrea( +291 )', value: '+291' },
    { id: 68, name: 'Estonia( +372 )', value: '+372' },
    { id: 69, name: 'Ethiopia( +251 )', value: '+251' },
    { id: 70, name: 'Falkland Islands (Malvinas)( +500 )', value: '+500' },
    { id: 71, name: 'Faroe Islands( +298 )', value: '+298' },
    { id: 72, name: 'Fiji( +679 )', value: '+679' },
    { id: 73, name: 'Finland( +358 )', value: '+358' },
    { id: 74, name: 'France( +33 )', value: '+33' },
    { id: 75, name: 'French Guiana( +594 )', value: '+594' },
    { id: 76, name: 'French Polynesia( +689 )', value: '+689' },
    { id: 77, name: 'French Southern Territories(  )', value: '' },
    { id: 78, name: 'Gabon( +241 )', value: '+241' },
    { id: 79, name: 'Gambia( +220 )', value: '+220' },
    { id: 80, name: 'Georgia( +995 )', value: '+995' },
    { id: 81, name: 'Germany( +49 )', value: '+49' },
    { id: 82, name: 'Ghana( +233 )', value: '+233' },
    { id: 83, name: 'Gibraltar( +350 )', value: '+350' },
    { id: 84, name: 'Greece( +30 )', value: '+30' },
    { id: 85, name: 'Greenland( +299 )', value: '+299' },
    { id: 86, name: 'Grenada( +1473 )', value: '+1473' },
    { id: 87, name: 'Guadeloupe( +590 )', value: '+590' },
    { id: 88, name: 'Guam( +1671 )', value: '+1671' },
    { id: 89, name: 'Guatemala( +502 )', value: '+502' },
    { id: 90, name: 'Guinea( +224 )', value: '+224' },
    { id: 91, name: 'Guinea-bissau( +245 )', value: '+245' },
    { id: 92, name: 'Guyana( +595 )', value: '+595' },
    { id: 93, name: 'Haiti( +509 )', value: '+509' },
    { id: 94, name: 'Heard and Mc Donald Islands(  )', value: '+' },
    { id: 95, name: 'Honduras( +504 )', value: '+504' },
    { id: 96, name: 'Hong Kong( +852 )', value: '+852' },
    { id: 97, name: 'Hungary( +36 )', value: '+36' },
    { id: 98, name: 'Iceland( +354 )', value: '+354' },
    { id: 99, name: 'India( +91 )', value: '+91' },
    { id: 100, name: 'Indonesia( +62 )', value: '+62' },
    { id: 101, name: 'Iran (Islamic Republic of)( +98 )', value: '+98' },
    { id: 102, name: 'Iraq( +964 )', value: '+964' },
    { id: 103, name: 'Ireland( +353 )', value: '+353' },
    { id: 104, name: 'Israel( +972 )', value: '+972' },
    { id: 105, name: 'Italy( +39 )', value: '+39' },
    { id: 106, name: 'Jamaica( +1876 )', value: '+1876' },
    { id: 107, name: 'Japan( +81 )', value: '+81' },
    { id: 108, name: 'Jordan( +962 )', value: '+962' },
    { id: 109, name: 'Kazakhstan( +77 )', value: '+77' },
    { id: 110, name: 'Kenya( +254 )', value: '+254' },
    { id: 111, name: 'Kiribati( +686 )', value: '+686' },
    { id: 112, name: 'Korea( +82 )', value: '+82' },
    {
      id: 113,
      name: "Korea, Democratic People's Republic of( +850 )",
      value: '+850',
    },
    { id: 114, name: 'Kuwait( +965 )', value: '+965' },
    { id: 115, name: 'Kyrgyzstan( +996 )', value: '+996' },
    {
      id: 116,
      name: "Lao People's Democratic Republic( +850 )",
      value: '+850',
    },
    { id: 117, name: 'Latvia( +371 )', value: '+371' },
    { id: 118, name: 'Lebanon( +961 )', value: '+961' },
    { id: 119, name: 'Lesotho( +266 )', value: '+266' },
    { id: 120, name: 'Liberia( +231 )', value: '+231' },
    { id: 121, name: 'Libyan Arab Jamahiriya( +218 )', value: '+218' },
    { id: 122, name: 'Liechtenstein( +423 )', value: '+423' },
    { id: 123, name: 'Lithuania( +370 )', value: '+370' },
    { id: 124, name: 'Luxembourg( +352 )', value: '+352' },
    { id: 125, name: 'Macau( +853 )', value: '+853' },
    { id: 126, name: 'Macedonia( +389 )', value: '+389' },
    { id: 127, name: 'Madagascar( +261 )', value: '+261' },
    { id: 128, name: 'Malawi( +265 )', value: '+265' },
    { id: 129, name: 'Malaysia( +60 )', value: '+60' },
    { id: 130, name: 'Maldives( +960 )', value: '+960' },
    { id: 131, name: 'Mali( +223 )', value: '+223' },
    { id: 132, name: 'Malta( +356 )', value: '+356' },
    { id: 133, name: 'Marshall Islands( +692 )', value: '+692' },
    { id: 134, name: 'Martinique( +596 )', value: '+596' },
    { id: 135, name: 'Mauritania( +222 )', value: '+222' },
    { id: 136, name: 'Mauritius( +230 )', value: '+230' },
    { id: 137, name: 'Mayotte( +262 )', value: '+262' },
    { id: 138, name: 'Mexico( +52 )', value: '+52' },
    { id: 139, name: 'Micronesia( +691 )', value: '+691' },
    { id: 140, name: 'Moldova( +373 )', value: '+373' },
    { id: 141, name: 'Monaco( +377 )', value: '+377' },
    { id: 142, name: 'Mongolia( +976 )', value: '+976' },
    { id: 143, name: 'Montenegro( +382 )', value: '+382' },
    { id: 144, name: 'Montserrat( +1664 )', value: '+1664' },
    { id: 145, name: 'Mauritania( +222 )', value: '+222' },
    { id: 146, name: 'Morocco( +212 )', value: '+212' },
    { id: 147, name: 'Mozambique( +258 )', value: '+258' },
    { id: 148, name: 'Myanmar( +95 )', value: '+95' },
    { id: 149, name: 'Namibia( +264 )', value: '+264' },
    { id: 150, name: 'Nauru( +674 )', value: '+674' },
    { id: 151, name: 'Nepal( +977 )', value: '+977' },
    { id: 152, name: 'Netherlands( +31 )', value: '+31' },
    { id: 153, name: 'Netherlands Antilles( +599 )', value: '+599' },
    { id: 154, name: 'New Caledonia( +687 )', value: '+687' },
    { id: 155, name: 'New Zealand( +64 )', value: '+64' },
    { id: 156, name: 'Nicaragua( +505 )', value: '+505' },
    { id: 157, name: 'Niger( +227 )', value: '+227' },
    { id: 158, name: 'Nigeria( +234 )', value: '+234' },
    { id: 159, name: 'Niue( +683 )', value: '+683' },
    { id: 160, name: 'Norfolk Island( +672 )', value: '+672' },
    { id: 161, name: 'Northern Mariana Islands( +1670 )', value: '+1670' },
    { id: 162, name: 'Norway( +47 )', value: '+47' },
    { id: 163, name: 'Oman( +968 )', value: '+968' },
    { id: 164, name: 'Pakistan( +92 )', value: '+92' },
    { id: 165, name: 'Palau( +680 )', value: '+680' },
    { id: 166, name: 'Palestine( +970 )', value: '+970' },
    { id: 167, name: 'Panama( +507 )', value: '+507' },
    { id: 168, name: 'Papua New Guinea( +675 )', value: '+675' },
    { id: 169, name: 'Paraguay( +595 )', value: '+595' },
    { id: 170, name: 'Norfolk Island( +672 )', value: '+672' },
    { id: 171, name: 'Peru( +51 )', value: '+51' },
    { id: 172, name: 'Philippines( +63 )', value: '+63' },
    { id: 173, name: 'Pitcairn( +872 )', value: '+872' },
    { id: 174, name: 'Poland( +48 )', value: '+48' },
    { id: 175, name: 'Portugal( +351 )', value: '+351' },
    { id: 176, name: 'Puerto Rico( +1939 )', value: '+1939' },
    { id: 177, name: 'Qatar( +974 )', value: '+974' },
    { id: 178, name: 'Reunion( +262 )', value: '+262' },
    { id: 179, name: 'Romania( +40 )', value: '+40' },
    { id: 180, name: 'Russian Federation( +7 )', value: '+7' },
    { id: 181, name: 'Rwanda( +250 )', value: '+250' },
    { id: 182, name: 'Saint Kitts and Nevis( +1869 )', value: '+1869' },
    { id: 183, name: 'Saint Lucia( +1758 )', value: '+1758' },
    {
      id: 184,
      name: 'Saint Vincent and the Grenadines( +1784 )',
      value: '+1784',
    },
    { id: 185, name: 'San Marino( +378 )', value: '+378' },
    { id: 186, name: 'Sao Tome and Principe( +239 )', value: '+1939' },
    { id: 187, name: 'Saudi Arabia( +966 )', value: '+966' },
    { id: 188, name: 'Senegal( +221 )', value: '+221' },
    { id: 189, name: 'Serbia( +381 )', value: '+381' },
    { id: 190, name: 'Seychelles( +248 )', value: '+248' },
    { id: 191, name: 'Sierra Leone( +232 )', value: '+232' },
    { id: 192, name: 'Singapore( +65 )', value: '+65' },
    { id: 193, name: 'Slovakia (Slovak Republic)( +421 )', value: '+421' },
    { id: 194, name: 'Slovenia( +386 )', value: '+386' },
    { id: 195, name: 'Solomon Islands( +677 )', value: '+677' },
    { id: 196, name: 'Somalia( +252 )', value: '+252' },
    { id: 197, name: 'South Africa( +27 )', value: '+27' },
    {
      id: 198,
      name: 'South Georgia & South Sandwich Islands( +500 )',
      value: '+500',
    },
    { id: 199, name: 'South Sudan( +211 )', value: '+211' },
    { id: 200, name: 'Spain( +34 )', value: '+34' },
    { id: 201, name: 'Sri Lanka( +94 )', value: '+94' },
    { id: 202, name: 'St. Helena( +290 )', value: '+290' },
    { id: 203, name: 'St. Pierre and Miquelon( +508 )', value: '+508' },
    { id: 204, name: 'Sudan( +249 )', value: '+249' },
    { id: 205, name: 'Suriname( +597 )', value: '+597' },
    { id: 206, name: 'Svalbard and Jan Mayen Islands( +47 )', value: '+47' },
    { id: 207, name: 'Swaziland( +268 )', value: '+268' },
    { id: 208, name: 'Sweden( +46 )', value: '+46' },
    { id: 209, name: 'Switzerland( +41 )', value: '+41' },
    { id: 210, name: 'Syrian Arab Republic( +963 )', value: '+963' },
    { id: 211, name: 'Taiwan( +886 )', value: '+886' },
    { id: 212, name: 'Tajikistan( +992 )', value: '+992' },
    { id: 213, name: 'Tanzania( +255 )', value: '+255' },
    { id: 214, name: 'Thailand( +66 )', value: '+66' },
    { id: 215, name: 'Togo( +228 )', value: '+228' },
    { id: 216, name: 'Tokelau( +690 )', value: '+690' },
    { id: 217, name: 'Tonga( +676 )', value: '+676' },
    { id: 218, name: 'Trinidad and Tobago( +1868 )', value: '+1868' },
    { id: 219, name: 'Tunisia( +216 )', value: '+216' },
    { id: 220, name: 'Turkey( +90 )', value: '+90' },
    { id: 221, name: 'Turkmenistan( +993 )', value: '+993' },
    { id: 222, name: 'Turks and Caicos Islands( +1649 )', value: '+1649' },
    { id: 223, name: 'Tuvalu( +688 )', value: '+688' },
    { id: 224, name: 'Uganda( +256 )', value: '+256' },
    { id: 225, name: 'Ukraine( +380 )', value: '+380' },
    { id: 226, name: 'United Arab Emirates( +971 )', value: '+971' },
    { id: 227, name: 'United Kingdom( +44 )', value: '+44' },
    // {id: 228, name: "United States minor outlying islands( +1 )", value:"+1"},
    { id: 229, name: 'Uruguay( +598 )', value: '+598' },
    { id: 230, name: 'Uzbekistan( +998 )', value: '+998' },
    { id: 231, name: 'Vanuatu( +678 )', value: '+678' },
    { id: 232, name: 'Vatican City State (Holy See)( +84 )', value: '+84' },
    { id: 233, name: 'Venezuela( +58 )', value: '+58' },
    { id: 234, name: 'Viet Nam( +7 )', value: '+7' },
    // {id: 235, name: "Virgin Islands (British)( +1 )", value:"+1"},
    // {id: 236, name: "Virgin Islands (U.S.)( +1 )", value:"+1"},
    { id: 237, name: 'Wallis and Futuna Islands( +681 )', value: '+681' },
    { id: 238, name: 'Western Sahara( +212 )', value: '+212' },
    { id: 239, name: 'Yemen( +967 )', value: '+967' },
    { id: 240, name: 'Zambia( +260 )', value: '+260' },
    { id: 241, name: 'Zimbabwe( +263 )', value: '+263' },
  ];

  formattedBillingDate: string;
  adminaccountForm: FormGroup;
  changepassform: FormGroup;
  loading = false;
  submitted = false;
  submitted1 = false;
  returnUrl: string;
  showErrorMessage = false;
  showErrorMessage5 = false;
  showErrorMessage6 = false;
  showErrorMessageacc5 = false;
  showErrorMessageacc3 = false;
  showErrorMessageacc4 = false;
  showErrorMessageacc = false;
  showErrorMessageacc8 = false;
  showErrorMessageacc10 = false;
  success = false;
  successmsg = false;
  feedback: string;
  isBillingOn: boolean;
  activeTab: string;
  planName: string;
  selectedFile: File | null = null;
  imagePreview: string | null = null;
  dots: string = '';
  loadingInterval: any;
  remainingScan: number;
  // radio1Checked = false;
  // radio2Checked = false;
  // radio4Checked = false;
  // radio5Checked = false;

  selectBilling(targetType: boolean) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    if (targetType == true) {
      this.isBillingOn = true;
    } else if (targetType == false) {
      this.isBillingOn = false;
    }
    console.log(this.isBillingOn);
  }

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    // private sharedService: SharedService,
    private http: Http,
    private alertService: AlertService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  public noWhitespaceValidator(control: FormGroup) {
    const isSpace = (control.value || '').match(/\s/g);
    return isSpace ? { whitespace: true } : null;
  }

  ngOnInit(): void {
    this.loadingInterval = setInterval(() => this.updateDots(), 600);

    $("input[name='test-radio']").click(function () {
      $('#show-me').css(
        'display',
        $(this).val() === 'Mondal' ? 'flex' : 'none'
      );
    });
    $(".toggle-icon").click(function () {
      if ($(window).width() > 991) {
          $(".sidebar-main").toggleClass("active");

      }
      else {
          $(".sidebar-main").css("transform", "translateX(0)");
          $('.back-drop').toggle(500);
      }
  });

  $('.back-drop').click(function () {
      $(".sidebar-main").css("transform", "translateX(-316px)");
      $(this).toggle();
  });
    // function updateitem(ev) {
    //     $(".plans-list-row.active").each((x, y) => $(y).removeClass('active'))
    //     $(ev).addClass("active");
    //     $(ev).find("input[type=radio]").prop('checked', true).trigger('change');
    // }

    $('body').on('click', '.accor-btn', function () {
      var findDiv = $(this).data('collapse');
      $('#' + findDiv)
        .slideToggle('fast')
        .toggleClass('show');

      if ($('.panel-collapse.show').length > 1) {
        $('.panel-collapse')
          .not('#' + findDiv)
          .slideUp('fast')
          .removeClass('show');
      }
    });
    $(document).ready(function () {
      $('.radio-custom').change(function () {
        $('.plans-list-row').removeClass('active');
        if ($(this).is(':checked')) {
          $(this).parents('.plans-list-row').addClass('active');
        }
      });
    });

    $('.hideBtn').click(function () {
      var reviveDiv = $(this).data('revive');

      $('a[data-collapse="' + reviveDiv + '"]').click();
    });

    this.adminaccountForm = this.formBuilder.group({
      full_name: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email]],
      country_code: ['', [Validators.required]],
      phone_number: [
        '',
        [
          Validators.required,
          Validators.pattern('^\\d*$'),
          Validators.maxLength(15),
          Validators.minLength(6),
        ],
      ],
      home_address: ['', [Validators.required, Validators.maxLength(500)]],
      isBillingSame: [Boolean],
      billing_company: [''],
      billing_address: [''],
      billing_city: [''],
      billing_state: [''],
      billing_country: [''],
      billing_zip: [''],
    });

    this.changepassform = this.formBuilder.group(
      {
        old_password: ['', [Validators.required, this.noWhitespaceValidator]],
        new_password: ['', Validators.required],
      },
      {
        validator: MustMatch('old_password', 'new_password'),
      }
    );

    let email = this.authenticationService.currentUserValue.data.email;
    console.log(email);

    this.loadAllUsers();
  }

  ngOnDestroy(): void {
    clearInterval(this.loadingInterval);
  }

  private updateDots(): void {
    if (this.dots.length < 3) {
      this.dots += '.';
    } else {
      this.dots = '';
    }
  }
  //   pwdMatchValidator(frm: FormGroup) {
  //     return frm.get('old_password').value === frm.get('new_password').value
  //        ? null : {'mismatch': true};
  //  }
  private formvals() {
    this.f.full_name.setValue(this.adminaccountForm.value['full_name']);
    this.f.email.setValue(this.adminaccountForm.value['email']);

    this.f.home_address.setValue(this.adminaccountForm.value['home_address']);
    this.f.phone_number.setValue(this.adminaccountForm.value['phone_number']);
    this.f.isBillingSame.setValue(
      this.adminaccountForm.value['is_billing_same']
    );
    this.f.billing_company.setValue(
      this.adminaccountForm.value['billing_company']
    );
    this.f.billing_address.setValue(
      this.adminaccountForm.value['billing_address']
    );
    this.f.billing_city.setValue(this.adminaccountForm.value['billing_city']);
    this.f.billing_state.setValue(this.adminaccountForm.value['billing_state']);
    this.f.billing_country.setValue(
      this.adminaccountForm.value['billing_country']
    );
    this.f.billing_zip.setValue(this.adminaccountForm.value['billing_zip']);

    // this.selectitems
    // =this.selected()
    for (let i in this.selectitems) {
      // console.log(this.selectitems[i].toString);
      if (
        this.selectitems[i]['value'] ==
        this.adminaccountForm.value['country_code']
      ) {
        this.selectedLevel = this.selectitems[i];
        console.log(this.selectitems[i]);
      }
    }
  }
  private loadAllUsers() {
    this.loading = true;
    this.userService
      .getadminaccount()
      .pipe(first())
      .subscribe(
        (data) => {
          // console.log("POST call successful value returned in body",

          // val);

          if (data['returncode'] == '1' || data['returncode'] == 1) {
            this.data = data['data'];
            if (
              this.data.profile_picture == '' ||
              this.data.profile_picture == 'null' ||
              this.data.profile_picture == null ||
              this.data.profile_picture == 'None' ||
              this.data.profile_picture ==
                'https://veridocglobalhrstorage.blob.core.windows.net/hrprofile/default.jpg'
            ) {
              this.profilepicshow = 0;
            } else {
              this.profilepicshow = 1;
            }

            this.f.full_name.setValue(this.data.fullname);
            this.f.email.setValue(this.data.email);

            this.f.home_address.setValue(this.data.home_address);
            this.f.phone_number.setValue(this.data.phone_number);
            this.f.isBillingSame.setValue(this.data.is_billing_same);
            this.isBillingOn = this.data.is_billing_same;
            console.log(this.isBillingOn);
            this.f.billing_company.setValue(this.data.billing_company);
            this.f.billing_address.setValue(this.data.billing_address);
            this.f.billing_city.setValue(this.data.billing_city);
            this.f.billing_state.setValue(this.data.billing_state);
            this.f.billing_country.setValue(this.data.billing_country);
            this.f.billing_zip.setValue(this.data.billing_zip);

            // this.selectitems
            // =this.selected()
            for (let i in this.selectitems) {
              // console.log(this.selectitems[i].toString);
              if (
                this.selectitems[i]['value'] == this.data.country_code ||
                this.selectitems[i]['name'] == this.data.country_code
              ) {
                this.selectedLevel = this.selectitems[i];
                console.log(this.selectitems[i]);
              }
            }
            for (let i in this.alltimezonelist) {
              if (this.alltimezonelist[i]['value'] == this.data.org_timezone) {
                this.data.org_timezone = this.alltimezonelist[i]['name'];
              }
            }
            const currentScanValue = this.data.current_scan;
            const totalScanValue = this.data.subscription_counter;
            const remainingScans = totalScanValue - currentScanValue;
            this.remainingScan = remainingScans;
            //console.log(this.data.is_billing_same);
            console.log(this.data);
          } else {
            this.data = null;
          }
        },
        (response) => {
          console.log('POST call in error', response);
        },
        () => {
          console.log('The POST observable is now completed.');
        }
      );

    this.userService.getcurrentactiveplan().subscribe(
      (response) => {
        this.activePlan = response;
        console.log(this.activePlan);

        this.activeTab = this.activePlan.cadence;
        console.log(this.activeTab);
        this.planName = this.activePlan.planName;
        console.log(this.planName);
        if (this.activeTab === 'Yearly') {
          $('#lnkTabMonthlyPlan').removeClass('active');
          $('#lnkTabAnnualPlan').addClass('active');
          $('#tabMonthlyPlan').removeClass('active');
          $('#tabAnnualPlan').addClass('active');
        }

        // if (this.activePlan.cadence === 'MONTHLY') {
        //   if (this.activePlan.planName === 'Standard') {
        //     this.radio1Checked = true;
        //   } else if (this.activePlan.planName === 'Pro') {
        //     this.radio2Checked = true;
        //   }
        // }else if (this.activePlan.cadence === 'YEARLY') {
        //   if (this.activePlan.planName === 'Standard') {
        //     this.radio4Checked = true;
        //   } else if (this.activePlan.planName === 'Pro') {
        //     this.radio5Checked = true;
        //   }
        // }
      },
      (error) => {
        console.error('Error:', error);
      }
    );

    this.userService
      .getcurrentbillinghistory()
      .pipe(first())
      .subscribe(
        (response) => {
          this.billingHistory = response;
          // console.log(this.billingHistory);
          this.loading = false;
        },
        (error) => {
          console.error('Error:', error);
        }
      );
      
  }

  // on submit start

  get f() {
    return this.adminaccountForm.controls;
  }
  get f2() {
    return this.changepassform.controls;
  }

  selected() {
    console.log(this.selectedLevel.name);
  }
  onSubmit() {
    this.submitted1 = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.adminaccountForm.invalid) {
      this.successmsg = false;
      return;
    }

    this.loading = true;

    this.f.country_code.setValue(this.selectedLevel.value);
    // console.log(this.selectedLevel.value);
    this.authenticationService
      .adminaccountupdate(
        this.f.full_name.value,
        this.f.email.value,
        this.f.country_code.value,
        this.f.phone_number.value,
        this.f.home_address.value,
        this.f.isBillingSame.value,
        this.f.billing_company.value,
        this.f.billing_address.value,
        this.f.billing_city.value,
        this.f.billing_state.value,
        this.f.billing_country.value,
        this.f.billing_zip.value
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          console.log(data.returncode);
          if (data.returncode == 1 || data.returncode == '1') {
            this.successmsg = true;
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'Account details updated successfully.',
              timer: 2000, // Show for 2 seconds and automatically close
              showConfirmButton: false,
            }).then(() => {
              location.reload();
            });
            // this.loadAllUsers();
            // this.showErrorMessageacc3 = false;
            // this.showErrorMessageacc = false;
            // this.showErrorMessageacc4 = false;
            // this.showErrorMessageacc5 = false;
            // this.showErrorMessageacc8 = false;
          } else if (data.returncode == '3' || data.returncode == 3) {
            this.successmsg = false;
            this.showErrorMessageacc3 = true;
            this.showErrorMessageacc = false;
            this.showErrorMessageacc4 = false;
            this.showErrorMessageacc5 = false;
            this.showErrorMessageacc8 = false;
            this.formvals();
          } else if (data.returncode == '4' || data.returncode == 4) {
            this.successmsg = false;
            this.showErrorMessageacc3 = false;
            this.showErrorMessageacc = false;
            this.showErrorMessageacc4 = true;
            this.showErrorMessageacc5 = false;
            this.showErrorMessageacc8 = false;
            this.formvals();
          } else if (data.returncode == '5' || data.returncode == 5) {
            this.successmsg = false;
            this.showErrorMessageacc3 = false;
            this.showErrorMessageacc = false;
            this.showErrorMessageacc5 = true;
            this.showErrorMessageacc4 = false;
            this.showErrorMessageacc8 = false;
            this.formvals();
          } else if (data.returncode == '8' || data.returncode == 8) {
            this.successmsg = false;
            this.showErrorMessageacc3 = false;
            this.showErrorMessageacc = false;
            this.showErrorMessageacc5 = false;
            this.showErrorMessageacc4 = false;
            this.showErrorMessageacc8 = true;
            this.formvals();
          } else if (data.returncode == '10' || data.returncode == 10) {
            this.successmsg = false;
            this.showErrorMessageacc3 = false;
            this.showErrorMessageacc = false;
            this.showErrorMessageacc5 = false;
            this.showErrorMessageacc4 = false;
            this.showErrorMessageacc8 = false;
            this.showErrorMessageacc10 = true;
            this.formvals();
          } else {
            this.successmsg = false;
            this.showErrorMessageacc3 = false;
            this.showErrorMessageacc = true;
            this.showErrorMessageacc5 = false;
            this.showErrorMessageacc4 = false;
            this.showErrorMessageacc8 = false;
            this.formvals();
          }

          this.loading = false;
          console.log(data);
        },
        (error) => {
          // showErrorMessage = true;
          this.alertService.error(error);
          console.log(error);
        }
      );
  }
  //end
  onFileSelected(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];

      // Add your file validation logic here
      const isValidFile = this.validateFile(file);

      if (isValidFile) {
        this.selectedFile = file;

        // Show the image preview
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        // If file is not valid, reset the selectedFile and show a Swal.fire alert
        this.selectedFile = null;
        this.imagePreview = null;
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Please select file size upto 2 MB. Please select valid image.',
          timer: 2000, // Show for 2 seconds and automatically close
          showConfirmButton: false,
        });
      }
    }
  }

  validateFile(file: File): boolean {
    // Example validation criteria (you can customize this based on your needs)
    const allowedTypes = ['image/jpeg', 'image/png'];
    const maxSize = 2 * 1024 * 1024; // 5 MB

    if (!allowedTypes.includes(file.type)) {
      return false; // Invalid file type
    }

    if (file.size > maxSize) {
      return false; // File size exceeds the allowed limit
    }

    return true;
  }

  uploadFile() {
    this.loading = true;
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('profile_picture', this.selectedFile);
      this.authenticationService.uploadProfilePicture(formData).subscribe(
        (response) => {
          console.log('File uploaded successfully:', response.body.file_url);
          if (
            response.body.returncode == 1 ||
            response.body.returncode == '1'
          ) {
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'Profile picture Uploaded successfully.',
              timer: 2000, // Show for 2 seconds and automatically close
              showConfirmButton: false,
            }).then(() => {
              location.reload();
            });
          }
          this.loading = false;
        },
        (error) => {
          console.error('Error uploading file:', error.error);
          // Handle error, e.g., show an error message
        }
      );
    }
  }

  removeProfilePic() {
    this.loading = true;
    this.authenticationService.orgProfilePicRemove().subscribe(
      (response) => {
        console.log(response.body.message);
        // Handle success, e.g., update UI or show a success message
        console.log(response.body.returncode);
        if (response.body.returncode == 1 || response.body.returncode == '1') {
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'Profile picture deleted successfully.',
            timer: 2000, // Show for 2 seconds and automatically close
            showConfirmButton: false,
          }).then(() => {
            location.reload();
          });
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error removing file URL:', error.error.message);
        // Handle error, e.g., show an error message
        Swal.fire({
          icon: 'error',
          title: '',
          text: 'Error removing Profile picture.',
          timer: 2000, // Show for 2 seconds and automatically close
          showConfirmButton: false,
        }).then(() => {
          location.reload();
        });
      }
    );
  }

  changepasss() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.changepassform.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService
      .adminchangepass(this.f2.old_password.value, this.f2.new_password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          // console.log(data.returncode);
          if (data.returncode == 1 || data.returncode == '1') {
            this.success = true;
            Swal.fire({
              icon: 'success',
              title: '',
              text: 'Password changed successfully.',
              timer: 2000, // Show for 2 seconds and automatically close
              showConfirmButton: false,
            });
            this.showErrorMessage6 = false;
            // this.changepassform.reset(this.changepassform.value);
            // this.router.navigate(['/myaccount']);
          } else if (data.returncode == '6' || data.returncode == 6) {
            this.success = false;
            this.showErrorMessage6 = true;
          } else if (data.returncode == '5' || data.returncode == 5) {
            // this.showErrorMessage5 = true;
          } else {
            this.success = false;
            this.showErrorMessage6 = false;
            this.showErrorMessage = true;
          }
          this.loading = false;
          // console.log(data);
        },
        (error) => {
          // showErrorMessage = true;
          this.alertService.error(error);
        }
      );
  }

  cancelPlan() {
    this.loading = true;
    this.userService
      .cancelBilling(this.feedback)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(this.feedback);
          console.log(data);
          Swal.fire({
            icon: 'success',
            title: '',
            text: data.message,
            timer: 2000, // Show for 2 seconds and automatically close
            showConfirmButton: false,
          }).then(() => {
            location.reload();
          });
          this.feedback = '';

          this.loading = false;

          // console.log(data);
        },
        (error) => {
          // showErrorMessage = true;
          this.alertService.error(error);
        }
      );
  }

  updatePlan() {
    this.loading = true;
    this.userService
      .changeSubscription(
        this.selectedPlan.planName,
        this.selectedPlan.price,
        this.selectedPlan.planTimespan
      )
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(this.selectedPlan);
          console.log(data);
          Swal.fire({
            icon: 'success',
            title: '',
            text: 'Plan subscription updated successfully.',
            timer: 2000, // Show for 2 seconds and automatically close
            showConfirmButton: false,
          }).then(() => {
            window.location.reload();
          });

          // this.loadAllUsers();
          this.loading = false;
        },
        (error) => {
          console.log(error.error.content);
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: '',
            text: error.error.content,
            timer: 2000, // Show for 2 seconds and automatically close
            showConfirmButton: false,
          });
        }
      );
    // }
  }

  updateCardDetails() {
    this.loading = true;
    this.userService
      .updateCardDetails()
      .pipe(first())
      .toPromise()
      .then((response) => {
        console.log(response);
        console.log(response.url);

        // Perform any actions you need with response.url here
        // For example, opening the URL in a new tab
        window.open(response.url, '_blank');

        this.loading = false;
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  tohggleconfirmpass() {
    this.confirmpass = !this.confirmpass;
  }

  formatdate(date: string): string {
    // const dateAr = date.split('-');
    // const newDate = dateAr[2] + '/' + dateAr[1] + '/' + dateAr[0].slice(-2);
    // return newDate;
    if (!date) {
      return 'Null';
    }
    const dateParts = date.split('-');
    const year = dateParts[0];
    const month = new Date(Date.parse(date + 'T00:00:00')).toLocaleString(
      'default',
      { month: 'short' }
    );
    const day = dateParts[2];

    return `${day} ${month} ${year}`;
  }
  formatServicePeriod(servicePeriod: string): string {
    const parts = servicePeriod.match(
      /(\w{3} \d{1,2}) - (\w{3} \d{1,2}, \d{4}) • Next bill (\w{3} \d{1,2}, \d{4})/
    );

    if (parts && parts.length === 4) {
      const startDate = parts[1];
      const endDate = parts[2];
      const nextBillDate = parts[3];
      return `${startDate} - ${endDate} • Next bill ${nextBillDate}`;
    } else {
      return servicePeriod;
    }
  }
  formatMembershipDate(dateString: string): string {
    const date = new Date(dateString);

    const year = date.getFullYear();

    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return `${day}/${month}/${year}`;
  }
  formatDescriptionFirstPart(description: string) {
    const responseParts = description.split('. ');
    return responseParts[0];
  }

  formatDescriptionSecondPart(description: string): string {
    const responseParts = description.split('. ');
    return responseParts[1];
  }
  MonthlyradioChanged(
    event: any,
    planName: string,
    price: number,
    planTimespan: string
  ) {
    if (event.target.checked) {
      this.selectedPlan = {
        planName,
        price,
        planTimespan,
      };
      console.log('Selected Plan Details:', this.selectedPlan);
    } else {
      this.selectedPlan = null;
    }
  }
  YearlyradioChanged(
    event: any,
    planName: string,
    price: number,
    planTimespan: string
  ) {
    if (event.target.checked) {
      this.selectedPlan = {
        planName,
        price,
        planTimespan,
      };
      console.log('Selected Plan Details:', this.selectedPlan);
    } else {
      this.selectedPlan = null;
    }
  }

  openPaymentWindow() {
    const websiteUrl = 'https://veridoclogbook.com/';

    const squreCustomerId = this.data.square_customer_id;
    const PlanName = this.selectedPlan.planName;
    const PlanTimespan = this.selectedPlan.planTimespan;
    const name = this.data.org_name;

    const url = `${websiteUrl}payment/subscribe?uid=${squreCustomerId}&plan=${PlanName}&timespan=${PlanTimespan}&name=${name}`;
    // console.log(url);

    window.open(url, '_blank');
  }
}
