import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(dynamicArray: any[], searchText: string): any[] {
    console.log(searchText)
    if(!dynamicArray) return [];
    if(!searchText) return dynamicArray;
    
    searchText = searchText.toLowerCase();
    // console.log(dynamicArray)
    // var viewers = localStorage.getItem("letviewer")
    // console.log(viewers["viewerlist"])
    // dynamicArray.forEach(element => {
      // console.log(element)
      var dynamicArray = dynamicArray.filter(it =>{
        console.log(it.viewername)
        var name = it.viewername.toLowerCase().includes(searchText)
        if(name){
          return it.viewername.toLowerCase().includes(searchText)
        }
      })
      // console.log(dynamicArray)
      return dynamicArray

      // return dynamicArray.filter(it => {
      //   it.viewername.toLowerCase().includes(searchText)
      // });

    // });    
    // return dynamicArray.filter(
    //       it => {

    //       it.viewername.toLowerCase().includes(searchText)
    //       //   console.log(it)
    //       //   // return it
    //       // }
    //       // var dat2 = it.email.toLowerCase().includes(searchText);
    //       // var dat3  = it.additional_info.toLowerCase().includes(searchText);
    //       // return dat1||dat2||dat3;
    //     });
   } 
}