import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AlertComponent } from './_components';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { CreateqrComponent } from './createqr/createqr.component';
import { ViewerlistComponent } from './viewerlist/viewerlist.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { CreateNewOrganisationComponent } from './create-new-organisation/create-new-organisation.component';
import { MasterAdminDashboardComponent } from './master-admin-dashboard/master-admin-dashboard.component';
import { MasterAdminMyaccountComponent } from './master-admin-myaccount/master-admin-myaccount.component';
import { MasterAdminQrcodelistComponent } from './master-admin-qrcodelist/master-admin-qrcodelist.component';
import { MasterAdminRegisteredvisitorsComponent } from './master-admin-registeredvisitors/master-admin-registeredvisitors.component';
import { QrverifyComponent } from './qrverify/qrverify.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';

import { TableModule } from 'primeng/table';
// import {TableModule} from 'primeng/table';
import { ToastModule } from 'primeng/toast';
// import {CalendarModule} from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { QRCodeModule } from 'angularx-qrcode';
import { EditorganisationComponent } from './editorganisation/editorganisation.component';
import { MasteradminCreateqrComponent } from './masteradmin-createqr/masteradmin-createqr.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailsenttemplateComponent } from './components/emailsenttemplate/emailsenttemplate.component';
import { PasswordresetverifyComponent } from './components/passwordresetverify/passwordresetverify.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
// import { InforpageComponent } from './inforpage/inforpage.component'
import { FilterPipe } from './_helpers/filter.pipe';
// import { DatePickerModule } from 'angular-material-datepicker';
// import { DatepickerModule } from 'ngx-bootstrap/datepicker';

import { MatTabsModule } from '@angular/material/tabs';
// import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { InforpageComponent } from './inforpage/inforpage.component';
// import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { EditqrComponent } from './editqr/editqr.component';
import { HomepageComponent } from './homepage/homepage.component';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { LoaderComponent } from './loader/loader.component'; //add this

// import { TimezonePickerModule } from 'ng2-timezone-selector';
// export function momentAdapterFactory() {
//   return adapterFactory(moment);
// };
// declare module "@angular/core" {
//   interface ModuleWithProviders<T = any> {
//     ngModule: Type<T>;
//     providers?: Provider[];
//   }
// }
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    CreateqrComponent,
    ViewerlistComponent,
    MyaccountComponent,
    CreateNewOrganisationComponent,
    MasterAdminDashboardComponent,
    MasterAdminMyaccountComponent,
    MasterAdminQrcodelistComponent,
    MasterAdminRegisteredvisitorsComponent,
    AlertComponent,
    QrverifyComponent,
    ForgotpasswordComponent,
    ResetpasswordComponent,
    EditorganisationComponent,
    MasteradminCreateqrComponent,
    EmailsenttemplateComponent,
    PasswordresetverifyComponent,
    PagenotfoundComponent,

    FilterPipe,

    EditqrComponent,
    InforpageComponent,
    HomepageComponent,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    HttpModule,
    TableModule,
    // CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    ToastModule,
    InputTextModule,
    ProgressBarModule,
    QRCodeModule,
    NgxPaginationModule,
    NgbModule,
    MomentTimezonePickerModule,
    MatTabsModule, //add this
    // TimezonePickerModule,
    // DatePickerModule,
    // CalendarModule,
    // CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    // MaterialModule.forRoot(),
    // DatePickerModule,
    // ModalModule,
    // ButtonModule,
    // SharedModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // TimezonePickerModule
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
