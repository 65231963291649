<div class="main-wrapper">
    <div class="wrapper-fix-footer">
        <app-header ></app-header>
        <div class="page-body-wrapper">
            <div class="custom-container">
              
<div class="page-body-content">
    <div class="page-top-heading master-dash-heading">
        <h1 class="page-title">QR Code List</h1>
        <div class="page-search">
          <div class="search-div" data-toggle="collapse" href="#qr-code-list-search" role="button" aria-expanded="false">
          <input class="form-control search-custom" name="DocumentName" placeholder="Search" readonly="readonly" type="text" value="">
          <span class="search-icon-bar"><i class="fa fa-search" aria-hidden="true"></i></span>
            <span class="search-down-cart">
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </span>
          </div>
          <form [formGroup]="searchform" (ngSubmit)="search(searchBoxuid.value,searchBox2.value,searchBoxdate.value,searchBoxname.value)">
            <div class="search-dropdown collapse p-0" id="qr-code-list-search">
              <div class="search-dropdown-pd">
              <div class="form-group row search-form-group mb-2">
                <label class="col-lg-4 col-sm-4 col-form-label">Date Created</label>
                <div class="col-lg-8 col-sm-8 angular-calendar">
                  <!-- <input type="text" formControlName="searchBoxdate" #searchBoxdate class="form-control date-icon-input" id="datepicker" placeholder="Select a date" readonly="readonly"> -->
                  <input class="form-control date-icon-input" #searchBoxdate formControlName="searchBoxdate" placeholder="Select date"
                                       name="dp" ngbDatepicker #f="ngbDatepicker" (click)="f.toggle()" readonly="readonly">
                </div>
              </div>
              <div class="form-group row search-form-group mb-2">
                <label class="col-lg-4 col-sm-4 col-form-label">QR Code Name</label>
                <div class="col-lg-8 col-sm-8">
                  <!-- <label>QR Code Name</label> -->
                  <input type="text" class="form-control" #searchBoxname id="search-box" formControlName="searchBoxname">
                  <input type="hidden" class="form-control" value="{{orgid}}" #searchBox2 id="search-box">

                </div>
              </div>
              <div class="form-group row search-form-group">
                <label class="col-lg-4 col-sm-4 col-form-label">Unique ID</label>
                <div class="col-lg-8 col-sm-8">
                  <input type="text" #searchBoxuid id="search-box" class="form-control" formControlName="searchBoxuid">
                </div>
              </div>
              <div class="search-btn-group">
                <input type="submit" class="btn-search" value="Search" data-toggle="collapse" href="#qr-code-list-search">
                <input type="button" class="btn-search ml-3" value="Clear" (click)="clear()" id="clearButton">
              </div>
            </div>
            </div>
          </form>
        </div>
        <button type="button" (click)="goTocreatenewqr(orgid)" class="gradiant-btn mb-xl-0 mb-lg-0 mb-md-4 mb-sm-4 mb-4"><i class="fa fa-plus mr-1"></i> Create New QR Code
        </button>
    </div>
    <div class="qrcode-list-info">
        <span>ORG ID : {{ orgid }}</span>
        <span>ORG Name: {{ orgname }} </span>
    </div>
    <div class="dashboard-table">
        <table class="table table-striped table-dash">
            <thead>
              <tr >
                <th>Date Created</th>
                <th>QR Code Name</th>
                <th>Unique ID</th>
                <th>Scan Count</th>
                <th>Manage Access</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data1 of data | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                <td data-label="Date Created">{{ data1.created_on| date:'dd/MM/yyyy' :getFormattedTimeZone()}}</td>
                <td data-label="QR Code Name" class="m-qr-code-name">{{ data1.qr_name }}
                </td>
                <td data-label="Unique ID">{{ data1.guid }}</td>
                <td data-label="Scan Count">{{ data1.scan_counter }}</td>
                <td data-label="Manage Access"><button class="gradiant-btn access-gradiant-btn" (click)="goToPage(data1.guid)">Access<i class="fa fa-angle-right" aria-hidden="true"></i></button></td>
                <td data-label="Status"><label class="switch">
                  <input type="checkbox" [(ngModel)]="data1.status" [checked]="!data1.status" (change)="switchfunc($event,data1.guid)">
                  <span class="slider round"></span>
                </label></td>
                <td data-label="Action">
                  <div class="table-action">
                    <a href="javascript:void(0);" (click)="saveAsImage(data1.guid)" title="Download">
                      <img src="assets/img/download-icon.png" alt="icon">
                      </a>
                      <a href="https://my.veridoclogbook.com/verify?guid={{ data1.guid }}" class="ml-3" title="Verify" target="_blank">
                          <img src="assets/img/verify-link-icon.png" alt="icon">
                      </a>
                      <a href="javascript:void(0);" (click)="gotoeditqr(data1.guid)" class="ml-3" title="Edit" >
                        <img src="assets/img/edit-icon.png" alt="icon">
                      </a>

                  </div>
                </td>
              </tr>
              <tr *ngIf="data==null">
                <td colspan="7">No Record Found</td>
                </tr>
            </tbody>
          </table>
    </div>
    <div *ngIf="data!=null">
    <div class="pager" *ngIf="data.length > 10">
      <ngb-pagination [collectionSize]="data.length" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
    </div>
    </div>
</div>
  </div>
        </div>
    </div>
<app-footer></app-footer>
