import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { Injectable } from '@angular/core';
import { Car } from '../_models';

@Injectable({
  providedIn: 'root'
})
export class CarserviceService {

  constructor(private http: HttpClient) { }
  getCarsSmall() {
    return this.http.get<any>('assets/cars-small.json')
    .toPromise()
    .then(res => <Car[]>res.data)
    .then(data => { return data; });
}
}
