import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AlertService, PublicService } from '../../_services';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css'],
})
export class ForgotpasswordComponent implements OnInit {
  forgotpassword: FormGroup;
  loading = false;
  submitted = false;
  showErrorMessage = false;
  showErrorMessage2 = false;
  showErrorMessage3 = false;
  deactivatedacc = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private publicservice: PublicService,
    private alertService: AlertService
  ) {}

  
  ngOnInit() {
    this.forgotpassword = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  currentYear(): number {
    return new Date().getFullYear();
  }
  get f() {
    return this.forgotpassword.controls;
  }

  backbtn() {
    this.router.navigate(['/']);
  }

  onSubmit() {
    this.submitted = true;
    console.log('data submitted');
    // reset alerts on submit
    // this.alertService.clear();

    if (this.forgotpassword.invalid || !this.f.email.value) {
      this.showErrorMessage3 = true;
      return;
    }

    console.log(this.f.email.value);
    this.loading = true;
    // Clear previous error messages
    this.showErrorMessage2 = false;
    this.showErrorMessage3 = false;
    this.publicservice
      .forgotpass(this.f.email.value)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);

          // console.log(data.returncode);
          if (data['returncode'] == 1 || data['returncode'] == '1') {
            // this.showErrorMessage = true;
            this.router.navigate(['/emailsent']);
          } else if (data['returncode'] == 4 || data['returncode'] == '4') {
            this.router.navigate(['/emailsent']);
          } else if (data['returncode'] == 6 || data['returncode'] == '6') {
            this.showErrorMessage3 = true;
          } else if (data['returncode'] == 3 || data['returncode'] == '3') {
            console.log(this.showErrorMessage);
            this.showErrorMessage = true;
            console.log(this.showErrorMessage);
          } else if (data['returncode'] == 2 || data['returncode'] == '2') {
            this.showErrorMessage2 = true;
          } else if (data['returncode'] == 5 || data['returncode'] == '5') {
            this.deactivatedacc = true;
          }

          // }else if(data.returncode == "2" || data.returncode == 2)
          // {
          //     this.showErrorMessage = true;
          // }else{
          //     this.showErrorMessage = true;
          // }

          // console.log(data);
        },
        (error) => {
          // showErrorMessage = true;
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }
}
