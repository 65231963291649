import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, PublicService } from '../../_services';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MustMatch, passwordValidator } from '../../_helpers/must-match.validator';
import Swal from 'sweetalert2';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'login-btn',
  },
  buttonsStyling: false,
});
@Component({
  selector: 'app-passwordresetverify',
  templateUrl: './passwordresetverify.component.html',
  styleUrls: ['./passwordresetverify.component.css'],
})
export class PasswordresetverifyComponent implements OnInit {
  token: any;
  resetpass: FormGroup;
  fieldTextType: boolean;
  confirmpass: boolean;
  loading = false;
  submitted = false;
  showErrorMessage = false;
  showresetmsg = false;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private publicservice: PublicService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) {
    this.token = localStorage.getItem('temp_token');
    if (!this.token) {
      this.router.navigate(['pagenotfound']);
    }
    this.viewfunc(this.token);
  }

  ngOnInit(): void {
    this.resetpass = this.formBuilder.group(
      {
        password: ['', [Validators.required, passwordValidator()]],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }
  currentYear(): number {
    return new Date().getFullYear();
  }

  viewfunc(token) {
    this.publicservice
      .tokenauth(token)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data['returncode'] == 1 || data['returncode'] == '1') {
            // localStorage.setItem('temp_token',token);
            // this.router.navigate(['/resetverify']);
            // this.router.navigate(['/emailsent']);
          } else {
            this.router.navigate(['/pagenotfound']);
          }
        },
        (error) => {
          this.alertService.error(error);
          // this.loading = false;
          this.router.navigate(['/pagenotfound']);

        }
      );
  }
  get f() {
    return this.resetpass.controls;
  }
  //start
  onSubmit() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.resetpass.invalid) {
      return;
    }

    this.loading = true;
    this.publicservice
      .passchange(this.token, this.f.password.value)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data['returncode'] == 1 || data['returncode'] == '1') {
            swalWithBootstrapButtons
              .fire({
                icon: 'success',
                title: '',
                text: 'Password updated successfully.',
                showConfirmButton: true,
                confirmButtonText: 'OK',
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate(['/']);
                }
              });
            // this.router.navigate(['/']);
          } else {
            this.showErrorMessage = true;
          }
        },
        (error) => {
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  tohggleconfirmpass() {
    this.confirmpass = !this.confirmpass;
  }
}
