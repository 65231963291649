<div class="container">
    <div class="logbook-info-header">
        <img src="assets/img/vdg-logbook-logo.png" alt="logo">
    </div>
    <div class="logbook-info-body">
        <div class="logbook-info-step-box">
            <div class="logbook-info-step-img">
                <!-- <h2 class="logbook-info-mobile-title">Step 1: Download VeriDoc Logbook</h2> -->
                <img src="assets/img/logbook-step-1.png" alt="VDG Logbook Step 1">
            </div>
            <div class="logbook-info-step-content">
                <h2>Step 1: Download VDG Logbook</h2>
                <p>Download and install the VDG Logbook mobile app using Google Play or App Store.</p>
                <div class="logbook-info-playstore-img">
                    <a href="https://play.google.com/store/apps/details?id=com.vdglogbook.android1&hl=en_AU" class="mr-3" target="_blank">
                        <img src="assets/img/google-play-store.png" alt="Android App Store">
                    </a>
                    <a href="https://apps.apple.com/tr/app/vdg-logbook/id1531827458" target="_blank">
                        <img src="assets/img/ios-play-store.png" alt="Apple App Store">
                    </a>
                </div>
            </div>
        </div>
        <div class="logbook-info-step-box">
            <div class="logbook-info-step-content">
                <h2>Step 2: Create a Profile</h2>
                <p>Add your details to create a profile. You can also add additional profiles to your account and check-in multiple visitors at the same time. This is handy if you have dependents or children.</p>
            </div>
            <div class="logbook-info-step-img">
                <!-- <h2 class="logbook-info-mobile-title">Step 2: Create a Profile</h2> -->
                <img src="assets/img/logbook-step-2.png" alt="VDG Logbook Step 2">
            </div>
        </div>
        <div class="logbook-info-step-box">
            <div class="logbook-info-step-img mr-lg-4 mr-md-4">
                <!-- <h2 class="logbook-info-mobile-title">Step 3: Scan QR Code</h2> -->
                <img src="assets/img/logbook-step-3.png" alt="VDG Logbook Step 2">
            </div>
            <div class="logbook-info-step-content">
                <h2>Step 3: Scan QR Code</h2>
                <p>You’re all set! Just scan a VeriDoc Logbook QR code for an automatic check-in. There’s no need to fill out that same contact form over and over again.</p>
            </div>
        </div>
    </div>
</div>
