import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services';
import { User } from '../../_models';
import { Router, NavigationEnd } from '@angular/router';
import { map, filter, scan } from 'rxjs/operators';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  currentUser: User;
  full_name: string;
  role: string;
  currentRoute: string;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );

    // router.events.filter(event => event instanceof NavigationEnd)
    //       .subscribe(event =>
    //        {
    //           this.currentRoute = event.url;
    //           console.log(event);
    //        });
    console.log('ROUTER');
    this.currentRoute = this.router.url;
    // this.authenticationService.
    // let full_name = localStorage.getItem('full_name');
    //  console.log(full_name);
    //  this.role = localStorage.getItem('role')
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/']);
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $('.user-dropdown').on('click', function (e) {
        $('.fa-chevron-down').toggleClass('rotate'); // Toggle class for rotation
        $('.account-dropdown').slideToggle(280);
      });

      $(document).click(function (e) {
        if (
          !$(e.target).closest('.user-dropdown, .account-dropdown').length
        ) {
          $('.fa-chevron-down').removeClass('rotate'); // Remove rotation class
          $('.account-dropdown').slideUp(280);
        }
      });
    });
  }
}
